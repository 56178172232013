import React, { useState } from 'react';
import { useParams, Link, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoaderProvider } from '../utils/loaderUtils';
import { EmailProvider } from '../utils/EmailContext';

const AuthLayout = () => {
  const [showModal, setShowModal] = useState(false);
  const { country, lang } = useParams();
  const [playVideo, setPlayVideo] = useState(false);

  const handleVideo = () => {
    setPlayVideo(false);
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleBackdropClick = (e) => {
    if (e.target.className.includes('modal')) {
      closeModal();
    }
  };
  return (
    <LoaderProvider>
      <EmailProvider>
        <div className='flex-signin'>
          <div className='content-signin'>
            <div className='logo-signin'>
              {' '}
              <Link to={`/${country}/${lang}`}>
                <img src='/assets/images/logo.svg' alt='MyFinder' />
              </Link>
            </div>
            {/* OutLet */}
            <ToastContainer />
            <Outlet />
            <div className='copyright-signin'>
              <p>
                &copy; 2024 Copyright{' '}
                <Link to={`/${country}/${lang}`}>MyFinder.ai</Link>. All Rights
                Reserved.
              </p>
            </div>
          </div>
          <div className='video-signin'>
            <button
              // data-bs-target="#tutorial-modal"
              // data-bs-toggle="modal"
              role='button'
              onClick={openModal}
            >
              {' '}
              <img src='/assets/images/video-tutorial.jpg' alt='video' />{' '}
              <i className='fa-solid fa-play'></i>{' '}
            </button>
          </div>
        </div>
        {showModal && (
          <div
            className='modal fade show'
            style={{ display: 'block', zIndex: 1050 }}
            onClick={handleBackdropClick}
            tabIndex={-1}
            role='dialog'
          >
            <div
              className='modal-dialog modal-lg'
              role='document'
              style={{
                zIndex: 1060, // Reasonably high z-index
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#fff', // Modal dialog should have a white background
                borderRadius: '8px', // Optional: to make the modal look nicer
              }}
            >
              <div className='modal-content'>
                <button
                  type='button'
                  className='btn-close'
                  onClick={closeModal}
                  aria-label='Close'
                  style={{ zIndex: 1065 }} // Ensure the close button is visible
                >
                  <i className='fas fa-times' />
                </button>
                <div className='modal-body'>
                  <iframe
                    src='https://www.youtube.com/embed/o7mcXmlF8ys?si=dRZyX7fBIe2CbMPp'
                    title='YouTube video player'
                    frameBorder={0}
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen
                    style={{ width: '100%', height: '400px' }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </EmailProvider>
    </LoaderProvider>
  );
};
export default AuthLayout;
