import { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { cloudFrontUrl } from "../../../config";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import moment from "moment";
import "moment-duration-format";

const SocialActivity = ({ id, logo }) => {
  const [attractionReel, setAttractionReel] = useState([]);
  const { request, response } = useRequest();
  const [attractionStory, setAttractionStory] = useState([]);
  const { request: reelReq, response: reelRes } = useRequest();
  const [activeTab,setActiveTab] = useState(true);
  console.log("attractionReel : ", attractionReel);

  const fetchAttractionReel = async () => {
    await request("GET", `/public/attraction-reel/${id}`);
  };
  useEffect(() => {
    if (id !== undefined) {
      fetchAttractionReel();
    }
  }, [id]);

  useEffect(() => {
    if (response) {
      setAttractionReel(response.data);
    }
  }, [response]);

  const fetchAttractionStory = async () => {
    await reelReq("GET", `/public/attraction-story/${id}`);
  };
  useEffect(() => {
    if (id !== undefined) {
      fetchAttractionStory();
    }
  }, [id]);

  useEffect(() => {
    if (reelRes) {
      setAttractionStory(reelRes.data);
    }
  }, [reelRes]);

  return (
    <div className="social-activity">
      <div className="vtab">
        <ul className="vtab-menu">
          <li data-tab="mystories-vtab" onClick={()=> setActiveTab(true)} className={activeTab ? 'active' : ''}>
            <img src="/assets/images/icons/story.svg" alt="story" />
            MyStories
          </li>
          <li data-tab="myreels-vtab" onClick={()=> setActiveTab(false)} className={!activeTab ? 'active' : ''}>
            <img src="/assets/images/icons/reels.svg" alt="reels" />
            MyReels
          </li>
        </ul>
        <div className="wraper-vtab">
          <div id="mystories-vtab" className={`vtab-content ${activeTab ? 'active' : ''}`}>
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              //pagination={{ clickable: true }} // Automatically add the pagination element
              loop={true}
              //watchSlidesProgress={false}
              slidesPerView={4}
              spaceBetween={15}
              speed={1000}
              autoplay={{ delay: 99999999, disableOnInteraction: false }}
              navigation={{
                nextEl: ".story-vtab-next",
                prevEl: ".story-vtab-prev",
              }}
              breakpoints={{
                320: { slidesPerView: 2, spaceBetween: 10 },
                480: { slidesPerView: 2, spaceBetween: 10 },
                768: { slidesPerView: 3, spaceBetween: 10 },
                960: { slidesPerView: 4, spaceBetween: 15 },
              }}
            >
              {attractionStory &&
                attractionStory.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="item-story">
                      <div className="cap-item-story">
                        <div className="date-item-story">
                          {moment(item.created_at).fromNow()}
                        </div>
                      </div>
                      <div className="imgeffect">
                        <img
                          src={`${cloudFrontUrl}${item.image}`}
                          alt={item.image}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-control">
              <div className="swiper-button-prev story-vtab-prev"><i class="fas fa-arrow-left"></i></div>
              <div className="swiper-button-next story-vtab-next"><i class="fas fa-arrow-right"></i></div>
            </div>
          </div>

          <div id="myreels-vtab" className={`vtab-content ${!activeTab ? 'active' : ''}`}>
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              //pagination={{ clickable: true }}
              loop={true}
              //watchSlidesProgress={true}
              slidesPerView={4}
              spaceBetween={20}
              speed={1000}
              autoplay={{ delay: 99999999, disableOnInteraction: false }}
              navigation={{
                nextEl: ".reels-vtab-next",
                prevEl: ".reels-vtab-prev",
              }}
              breakpoints={{
                320: { slidesPerView: 2, spaceBetween: 10 },
                480: { slidesPerView: 2, spaceBetween: 10 },
                768: { slidesPerView: 3, spaceBetween: 10 },
                960: { slidesPerView: 4, spaceBetween: 15 },
              }}
            >
              {attractionReel.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="item-story">
                    <div className="cap-item-story">
                      <a href="#">
                        <img src={`${cloudFrontUrl}${logo}`} alt="alt" />
                      </a>
                      <small className="date-item-story">
                        {moment(item.created_at).fromNow()}
                      </small>
                    </div>
                    <div className="imgeffect">
                      <img src={`${cloudFrontUrl}${item.image}`} alt="alt" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-control">
              <div className="swiper-button-prev reels-vtab-prev"><i class="fas fa-arrow-left"></i></div>
              <div className="swiper-button-next reels-vtab-next"><i class="fas fa-arrow-right"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SocialActivity;
