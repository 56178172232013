import React, { useEffect, useState } from "react";
import MyReel from "../../components/Common/Community/MyReel";
import Author from "../../components/Common/Community/Author";
import RightSideNav from "../../components/Common/Community/SideNav";
import Groups from "../../components/Common/Community/Groups";
import Recomandation from "../../components/Common/Community/Recomandation";
import Trendings from "../../components/Common/Community/Trendings";
import useRequest from "../../hooks/useRequest";

const Friends = () => {
  const [friendData, setFriendData] = useState([]);
  const { request: friendRequest, response: friendResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    friendRequest("GET", "/friend");
  }, []);

  useEffect(() => {
    if (friendResponse && friendResponse.status === "SUCCESS") {
      setFriendData(friendResponse.data || {});
    }
  }, [friendResponse]);

  //Sort Friends by Latest
  const sortByLatest = () => {
    if (friendData.length === 0) {
      return;
    }
    const sortedFriends = [...friendData].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    setFriendData(sortedFriends);
  };

  const sortByAlphabet = () => {
    if (friendData.length === 0) {
      return;
    }
    const sortedFriends = [...friendData].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setFriendData(sortedFriends);
  };

  return (
    <div>
      <MyReel />
      <Author />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <RightSideNav />
              <Groups />
              <Recomandation />
              <Trendings />
            </div>
            <div className="col-sm-9">
              <div className="friends-page">
                <div className="search-filter">
                  <div className="search-cmty">
                    <form>
                      <i className="fa-solid fa-magnifying-glass" />
                      <input
                        type="text"
                        name="name"
                        placeholder="Search Friends"
                      />
                    </form>
                  </div>
                  <div className="btn-filter">
                    <button className="btn btn-primary" onClick={sortByLatest}>
                      Latest Friends
                    </button>{" "}
                    <button className="btn btn-primary" onClick={sortByAlphabet}>
                      Sort by A-Z
                    </button>
                  </div>

                </div>
                <div className="row">
                  {friendData.length > 0 ? (
                    friendData.map((data, index) => (
                      <div key={index} className="col-sm-3">
                        <div className="friend-list">
                          <div className="imgeffect">
                            <a href="#" className="comment-friend-list">
                              <i className="fa-solid fa-comment-dots" />
                            </a>
                            <img
                              src={`${cloudFrontUrl}${data.user_profile.logo}`}
                              alt={data.name}
                            />
                          </div>

                          <div className="info-friend-list">
                            <h3>
                              <a href="#">{data.name}</a>
                            </h3>
                            <p>
                              <img
                                src={`${cloudFrontUrl}${data.user_profile.logo}`}
                                alt="mutual followers"
                              />
                              13 Mutual Followers
                            </p>
                            <a href="#" className="btn btn-primary">
                              Add Friend
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No friends available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Friends;
