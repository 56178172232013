import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MyReel from "../../components/Common/Community/MyReel";
import Author from "../../components/Common/Community/Author";
import SideNav from "../../components/Common/Community/SideNav";
import Groups from "../../components/Common/Community/Groups";
import Recomandation from "../../components/Common/Community/Recomandation";
import Trendings from "../../components/Common/Community/Trendings";
import BtnPostSideBar from "../../components/Common/Community/BtnPostSideBar";
import BoostYourBusiness from "../../components/Common/Community/BoostYourBusiness";
import SuggestedGroups from "../../components/Common/Community/SuggestedGroups";
import Categories from "../../components/Common/Community/Categries";
import ListYourCompany from "../../components/Common/Community/ListYourCompany";
import CreateAPost from "../../components/Common/Community/CreateAPost";

const CommunityHome = () => {
  const [feedData, setFeedData] = useState([]);
  const { request: FeedRequest, response: feedResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
  useEffect(() => {
    FeedRequest(
      "GET",
      "/community/feed?page_num=0&page_size=10/community/feed?page_num=0&page_size=10"
    );
  }, []);
  useEffect(() => {
    if (feedResponse && feedResponse.status === "SUCCESS") {
      setFeedData(feedResponse.data || {});
    }
  }, [feedResponse]);
  return (
    <>
      <MyReel />
      <Author />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <SideNav />
              <Groups />
              <Recomandation />
              <Trendings />
            </div>
            <div className="col-sm-6">
              <div className="community-page">
                <div className="all-post-community">
                  {feedData.length > 0 ? (
                    feedData.map((feed, index) => (
                      <div key={index} className="post-community">
                        <div className="heading-post-cmty">
                          <div className="date-post-cmty">
                            <a href="#">
                              <i className="fa-solid fa-ellipsis-vertical" />
                            </a>{" "}
                            {feed.group && feed.group.updated_at && (
                              <p>
                                {new Date(
                                  feed.group.updated_at
                                ).toLocaleDateString("en-US", {
                                  month: "short", // "May"
                                  day: "numeric", // "31"
                                })}
                                ,{" "}
                                {new Date(
                                  feed.group.updated_at
                                ).toLocaleTimeString("en-US", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false, // 24-hour format, removes AM/PM
                                })}
                              </p>
                            )}
                          </div>
                          <img
                            src={`${cloudFrontUrl}${feed.image}`}
                            alt={`${feed.name}`}
                          />
                          <div className="name-post-cmty">
                            <h3>{feed.content}</h3>
                            <div className="author-post-cmty">
                              <img
                                src="/assets/images/author/1.jpg"
                                alt="alt"
                              />{" "}
                              Jonathan
                            </div>
                          </div>
                        </div>
                        <div className="desc-post-cmty">
                          {feed.group && feed.group.description && (
                            <p>{feed.group.description}</p>
                          )}
                          <div className="imgeffect">
                            <a href="#">
                              {feed.group && feed.group.cover_image && (
                                <img
                                  src={`${cloudFrontUrl}${feed.group.cover_image}`}
                                  alt="Group Cover"
                                />
                              )}
                            </a>
                          </div>
                        </div>
                        <div className="social-post-cmty">
                          {" "}
                          <a href="#">
                            <img
                              src="/assets/images/icons/thumb.svg"
                              alt="thumb"
                            />
                            Jackson and 12,850 others Liked
                          </a>
                          <ul>
                            <li>
                              <a href="#">
                                <img
                                  src="/assets/images/icons/thumb.svg"
                                  alt="thumb"
                                />
                                Like
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src="/assets/images/icons/comment.svg"
                                  alt="Comment"
                                />
                                Comment
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src="/assets/images/icons/bookmark.svg"
                                  alt="bookmark"
                                  className="bookmark-icon"
                                />
                                Save
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src="/assets/images/icons/share.svg"
                                  alt="Share"
                                />
                                Share
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Feed available</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <BtnPostSideBar />
              <CreateAPost />
              <BoostYourBusiness />
              <SuggestedGroups />
              <Categories />
              <ListYourCompany />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunityHome;
