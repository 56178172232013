import { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { cloudFrontUrl } from "../../../config";

const AdvBanner = () => {
  const [banner, setAdvBanner] = useState();
  const { request, response } = useRequest();
  const fetchAdvBanner = async () => {
    await request("GET", "/public/attraction-advertisement-banner");
  };
  useEffect(() => {
    fetchAdvBanner();
  }, []);

  useEffect(() => {
    if (response) {
      setAdvBanner(response.data);
    }
  }, [response]);

  return (
    <>
      {banner &&
        banner.map((item, index) => (
          <div key={index} class="ads-ml">
            <a href={item.button_url} target="_blank">
              <img src={`${cloudFrontUrl}${item.image}`} alt={item.image} />
            </a>
          </div>
        ))}
    </>
  );
};
export default AdvBanner;
