import './Auth.css';
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Toast } from '../../utils/ToastNotification';
import useRequest from '../../hooks/useRequest';
import { EmailContext } from '../../utils/EmailContext';
import SocialLogin from './SocialLogin';
import SocialLoginMain from './SocialLoginMain';
import AuthSubHeader from '../../components/Auth';

const Login = () => {
  const { country, lang } = useParams();
  const { search } = useLocation();
  console.log('location is ====>>>>>>>', search.split('=')[1]);
  const navigate = useNavigate();
  const { setEmail } = useContext(EmailContext);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    policy: false,
  });
  const { request: loginRequest, response: loginResponse } = useRequest();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.policy) {
      loginRequest('POST', '/auth/login', formData);
    } else {
      Toast(false, 'Please accept privacy and policy!');
    }
  };

  useEffect(() => {
    if (loginResponse && loginResponse?.status === 'SUCCESS') {
      Toast(true, loginResponse?.message);
      if (!loginResponse?.data?.is_email_verify) {
        setEmail(loginResponse.data?.email);
        handleNavigation(`/${country}/${lang}/auth/otp-verification`);
      } else {
        console.log('handling redirect');
        localStorage.setItem('access_token', loginResponse.data?.access_token);
        localStorage.setItem(
          'refresh_token',
          loginResponse.data?.refresh_token
        );
        localStorage.setItem('name', loginResponse.data?.name);
        localStorage.setItem('username', loginResponse.data?.username);
        localStorage.setItem('user_type', loginResponse.data?.user_type);
        localStorage.setItem('user_id', loginResponse.data?.id);
        console.log('User data is ', loginResponse?.data);
        console.log('Search is', search);
        if (search) {
          let route = `${search?.split('=')[1]}`;
          navigate(route);
        } else {
          handleNavigation(`/${country}/${lang}`);
        }
      }
    } else {
      Toast(false, loginResponse?.message);
    }
  }, [loginResponse]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className='tab-signin'>
      <div className='flex-tab-menu'>
        <AuthSubHeader />
      </div>
      <div id='signin-tab' className='tab-content active'>
        <p className='wel-signin'>Welcome back!</p>
        <form onSubmit={handleSubmit}>
          <div className='border border-[#BFBFBF] py-2 field-signin'>
            <i className='fa-solid fa-user'></i>
            <input
              type='text'
              placeholder='Username'
              name='email'
              className='poppins-medium  text-black placeholder-style'
              value={formData?.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className='border border-[#BFBFBF] py-2 field-signin'>
            <i className='fa-solid fa-lock'></i>
            <input
              type='password'
              placeholder='Password'
              name='password'
              className='poppins-medium  text-black placeholder-style'
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className='forget-password'>
            <div>
              <input type='checkbox' id='remember-me' />
              <label htmlFor='remember-me'>Remember me</label>
            </div>
            <a href='#'>Forgot password?</a>
          </div>
          <div className='accept-signin'>
            <input
              type='checkbox'
              id='policy'
              value='Policy'
              name='policy'
              checked={formData.policy}
              onChange={handleChange}
            />
            <label htmlFor='policy'>
              {' '}
              I agree to the{' '}
              <a href='#' style={{ color: '#FF10F0' }}>
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a href='#' style={{ color: '#FF10F0' }}>
                Privacy Policy
              </a>{' '}
            </label>
          </div>
          <input type='submit' value='Login' />
        </form>
        <div className='breaker'>Sign In with Social Media</div>
        <SocialLoginMain page='login' />

        <div className='dont-account'>
          Don't have an account?{' '}
          <a
            href='#'
            onClick={() => handleNavigation(`/${country}/${lang}/auth/signup`)}
          >
            Signup
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
