import { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";

const AttractionVideo = () => {
  const [attractionVideo, setAttractionVideo] = useState();
  const { request, response } = useRequest();
  const fetchAttractionVideo = async () => {
    await request("GET", "/public/attraction-videos");
  };
  useEffect(() => {
    fetchAttractionVideo();
  }, []);

  useEffect(() => {
    if (response) {
      setAttractionVideo(response.data);
    }
  }, [response]);
  console.log("attractionVideo : ", attractionVideo);

  return (
    <>
      <div class="video-ml">
        <img src="/assets/images/video-tutorial.jpg" alt="alt" />
        <div class="btn-video-ml">
          <button
            data-bs-target="#video-modal"
            data-bs-toggle="modal"
            role="button"
          >
            <i class="fa-solid fa-play"></i>
          </button>
        </div>
      </div>
      <div
        class="modal fade"
        id="video-modal"
        aria-hidden="true"
        aria-labelledby="video-modal"
        tabindex="-1"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
            <div class="modal-body">
              <iframe
                src={attractionVideo?.video_url}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttractionVideo;
