import { useParams } from "react-router-dom";
import AttractionGallery from "./gallery";
import ReviewModule from "./reviewModule";
import SimilarModule from "./similarModule";
import SocialActivity from "./socialActivity";
import useRequest from "../../../hooks/useRequest";
import { useEffect, useState } from "react";
import OfferSlider from "../offerSlider";
import DownloadApp from "../downloadApp";
import { cloudFrontUrl } from "../../../config";

const AttractionDetails = () => {
    const [attraction,setAttraction] = useState();
  const params = useParams();
//   const slug = params.id;
  const { request, response } = useRequest();
  useEffect(() => {
    (async () => {
        request("GET",`/public/attractions/${params.id}`)
    })();
  }, []);

  useEffect(()=>{
    if(response){
        setAttraction(response.data)
    }
  },[response])

  return (
    <div className="attraction-detail module-detail main-container">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="top-md">
              <div className="row">
                <div className="col-sm-7">
                  <AttractionGallery id={attraction?.id} />
                </div>
                <div className="col-sm-5">
                  <div className="chatbot">
                    <div className="header-chatbot">
                      <div className="non">
                        <img src="/assets/images/ai/fredo.png" alt="alt" />
                        <h3>
                          FREDO <small>AI ChatBot</small>
                        </h3>
                      </div>
                      <button>
                        <img
                          src="/assets/images/icons/voice-wave.svg"
                          alt="alt"
                        />
                      </button>
                    </div>
                    <div className="flex-chatbot">
                      <div className="all-chatbox">
                        <div className="chatbox">
                          <div className="logo-chatbox">
                            <img
                              src="/assets/images/icons/fredo.svg"
                              alt="fredo"
                            />
                          </div>
                          <div className="data-chatbox">
                            <p>
                              For Inquiry please give me, your following
                              information
                            </p>
                            <form>
                              <input
                                type="text"
                                name="name_field"
                                placeholder="Name"
                                required
                              />
                              <input
                                type="email"
                                name="email_field"
                                placeholder="Email"
                              />
                              <input
                                type="email"
                                name="phonel_field"
                                placeholder="Phone Number"
                              />
                              <textarea
                                name="message_field"
                                placeholder="Your Message"
                              ></textarea>
                            </form>
                          </div>
                        </div>
                        <div className="chatbox">
                          <div className="logo-chatbox">
                            <img
                              src="/assets/images/icons/fredo.svg"
                              alt="fredo"
                            />
                          </div>
                          <div className="data-chatbox">
                            <p>
                              FREDO, show me this Business' following
                              information
                            </p>
                            <div className="btns-chatbox">
                              <a href="#" className="whatsapp-chatbox">
                                <i className="fa-brands fa-whatsapp"></i>
                                Whatsapp
                              </a>
                              <a href="#" className="phone-chatbox">
                                <i className="fa-solid fa-phone-volume"></i>
                                Phone
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="chatbox">
                          <div className="logo-chatbox">
                            <img
                              src="/assets/images/icons/fredo.svg"
                              alt="fredo"
                            />
                          </div>
                          <div className="data-chatbox flex-data-chatbox">
                            <p>Fredo, request</p>
                            <div className="btns-chatbox">
                              <a href="#" className="phone-chatbox">
                                <i className="fa-solid fa-phone-volume"></i>Call
                                Back
                              </a>
                              <a href="#" className="phone-chatbox">
                                <i className="fa-solid fa-file"></i>e Brochure
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="footer-chatbot">
                        <form method="post">
                          <button>
                            <i className="fa-solid fa-arrow-up-from-bracket"></i>
                            Upload
                          </button>
                          <div className="field-footer-chatbot">
                            <input
                              type="text"
                              name="search"
                              placeholder="Search here"
                            />
                          </div>
                          <div className="submit-footer-chatbot">
                            <button>
                              <i className="fa-solid fa-microphone"></i>
                            </button>
                            <input type="submit" value="Sent" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec-title-md">
              <div className="row">
                <div className="col-sm-4">
                  <div className="title-md">
                    <div className="flex-title-md">
                      <div className="story-title-md">
                        <img src={`${cloudFrontUrl}${attraction?.logo}`} alt="alt" />
                      </div>
                      <div className="non">
                        <h1>{attraction?.title}</h1>
                        <div className="category-title-md">
                          <a href="#">Category | Subcategory</a>
                        </div>
                        <div className="location-title-md">
                          <img
                            src="/assets/images/icons/location.svg"
                            alt="location"
                          />
                          Location
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="rating-md">
                    <ul>
                      <li>
                        <h4>MyFinder Review</h4>
                        <div className="flex-rating-md">
                          <div className="logo-rating-md">
                            <img
                              src="/assets/images/favicon.png"
                              alt="favicon"
                            />
                          </div>
                          <div className="non">
                            <p>
                              4.5 <small>(45 Rating)</small>
                            </p>
                            <a href="#">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-regular fa-star"></i>
                              <i className="fa-regular fa-star"></i>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <h4>Google Review</h4>
                        <div className="flex-rating-md">
                          <div className="logo-rating-md">
                            <img
                              src="/assets/images/icons/google.svg"
                              alt="favicon"
                            />
                          </div>
                          <div className="non">
                            <p>
                              4.5 <small>(45 Rating)</small>
                            </p>
                            <a href="#">
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-solid fa-star"></i>
                              <i className="fa-regular fa-star"></i>
                              <i className="fa-regular fa-star"></i>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="activity">
                    <h3>Activity</h3>
                    <ul>
                      <li>
                        <div>
                          <img src="/assets/images/icons/eye.svg" alt="eye" />
                          158
                        </div>
                        <small>Unique Views</small>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/assets/images/icons/bookmark.svg"
                            alt="bookmark"
                            className="bookmark-icon"
                          />
                          12
                        </div>
                        <small>Favourite</small>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/assets/images/icons/phone-3.svg"
                            alt="phone"
                          />
                          4
                        </div>
                        <small>Contacted</small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mid-md">
              <div className="row">
                <div className="col-sm-8">
                  <div className="sec-status-md">
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="status-md">
                          <h3>Status</h3>
                          <p>We are off today!</p>
                        </div>
                      </div>
                      <div className="col-sm-7">
                        <div className="btn-sm">
                          <a href="#" className="btn btn-primary">
                            <i className="fa-solid fa-star"></i> Write a Review
                          </a>
                          <a href="#" className="btn btn-primary">
                            <i className="fa-solid fa-comment-dots"></i>
                            Recommendations
                          </a>
                          <a href="#" className="btn btn-primary">
                            <i className="fa-solid fa-star"></i> Opening Hours
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="description-md">
                    <div className="heading">
                      <h3>About Us</h3>
                    </div>
                    <p>
                      {attraction?.description}
                      <a href="#" className="btn-more">
                        Read More
                      </a>
                    </p>
                  </div>
                  <div className="location">
                    <div className="heading">
                      <h3>Location & Near by</h3>
                    </div>
                    <p>
                      <i className="fa-solid fa-location-dot"></i> NO 18 Mishui
                      Avenue Gaomi, 64258
                    </p>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462562.6509516108!2d54.94755498679835!3d25.07575945545691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1644662298002!5m2!1sen!2sin"
                      width="600"
                      height="450"
                      style={{ border: 0 }}
                      allowFullScreen
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="enquiry">
                    <h3>Inquire Now</h3>
                    <form>
                      <label>Your name</label>
                      <input type="text" placeholder="e.g Mohamed" required />
                      <label>Your email</label>
                      <input
                        type="email"
                        placeholder="e.g abcd@gmail.com"
                        required
                      />
                      <label>Your phone</label>
                      <input
                        type="text"
                        placeholder="e.g +971 42 524242"
                        required
                      />
                      <label>Your message</label>
                      <textarea placeholder="Sameer! I saw your ad on My Finder. When is it available for viewing? Thanks."></textarea>
                      <button>
                        <i className="fa-regular fa-envelope"></i> Inquiry Now
                      </button>
                      <small>
                        By clicking on 'Inquire Now', I agree to the MyFinder
                        <a href="#">Terms &amp; Conditions</a> and
                        <a href="#">Privacy Policy</a>
                      </small>
                    </form>
                    <div className="contact-enquiry">
                      
                      <a href="tel:971509545000">
                        Call <i className="fa-solid fa-phone"></i>
                      </a>
                      <a
                        href="https://api.whatsapp.com/send?phone=971509545000"
                        target="_blank"
                      >
                        <i className="fa-brands fa-whatsapp"></i> Whatsapp
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SocialActivity id={attraction?.id} logo={attraction?.logo} />
            <ReviewModule />
            <SimilarModule />
            <OfferSlider/>
            <DownloadApp />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttractionDetails;
