import React from 'react';
import { cloudFrontUrl } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';

const JobCard = ({ jobData, category }) => {
  const navigate = useNavigate();
  const { country, lang } = useParams();

  const jobDescription = (slug) => {
    if (category === 'jobs') {
      navigate(`/${country}/${lang}/jobDescription/${slug}`);
    } else if (category === 'Influencers') {
      navigate(`/${country}/${lang}/influencers`);
    } else {
      navigate(`/${country}/${lang}/listing/company/${slug}`);
    }
  };
  return (
    <div className='row'>
      {Array.isArray(jobData) && jobData.length > 0 ? (
        jobData.map((job) => (
          <div
            className='col-sm-6 col-md-4 mb-4'
            key={job.id}
            onClick={() => jobDescription(job.slug)}
          >
            <div className='itembox ib-job'>
              <div className='imgeffect'>
                <div className='ib-date'>5 Days ago</div>
                <div className='ib-wishlist'>
                  <a href='javascript:void(0)'>
                    <i className='fa-solid fa-star' />
                  </a>
                  <a href='javascript:void(0)'>
                    <i className='fa-regular fa-heart' />
                  </a>
                </div>
                <a href='javascript:void(0)'>
                  {/* Safely concatenate cloudFrontUrl and job.image */}
                  <img
                    src={
                      job?.image
                        ? `${cloudFrontUrl}${job.image}`
                        : '/assets/images/job/1.jpg'
                    }
                    alt='Job Image'
                  />
                </a>
              </div>
              <div className='info-itembox flex-itembox'>
                <div className='non'>
                  <h3>
                    <a href='javascript:void(0)'>{job?.title || job?.name}</a>
                  </h3>
                  <h4>{job?.title || job?.name}</h4>
                  <p>{job?.description}</p>
                </div>
                <a href='javascript:void(0)'>
                  {/* Fallback for user profile logo */}
                  <img
                    src={
                      job?.user_profile?.logo
                        ? `${cloudFrontUrl}${job.user_profile.logo}`
                        : '/assets/images/logo-1.png'
                    }
                    alt='Company Logo'
                  />
                </a>
              </div>
              <ul className='data-itembox'>
                <li>
                  <img
                    src='/assets/images/icons/clock.svg'
                    alt='clock'
                    onDragStart={() => false}
                  />
                  {job?.sub_category?.name || 'No category'}
                </li>
                <li>
                  <img
                    src='/assets/images/icons/location.svg'
                    alt='location'
                    onDragStart={() => false}
                  />
                  {job.location || 'Dubai, UAE'}
                </li>
              </ul>
              <ul className='data-itembox'>
                <li>
                  <img
                    src='/assets/images/icons/not-disclosed.svg'
                    alt='not-disclosed'
                    onDragStart={() => false}
                  />
                  {job?.salary || 'Salary not disclosed'}
                </li>
                <li>
                  
                    <img
                      src='/assets/images/icons/location.svg'
                      alt='apply'
                      onDragStart={() => false}
                    />
                    Apply Now
                </li>
              </ul>
            </div>
          </div>
        ))
      ) : jobData ? (
        <p>No {category} available.</p>
      ) : (
        <p>Loading jobs...</p>
      )}
    </div>
  );
};

export default JobCard;
