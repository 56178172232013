// import { useEffect, useState } from "react";
// import useRequest from "../../../hooks/useRequest";
// import { cloudFrontUrl } from "../../../config";
// import Swiper from "swiper";

// const AttractionGallery = ({id}) => {
//   const [attractionGallery, setAttractionGallery] = useState();
//   const { request, response } = useRequest();
//   const fetchAttractionGallery = async () => {
//     await request("GET", `/public/attraction-gallery/${id}`);
//   };
//   useEffect(() => {
//     if(id !== undefined){
//       fetchAttractionGallery();
//     }
//   }, [id]);

//   useEffect(() => {
//     if (response) {
//       setAttractionGallery(response.data);
//     }
//   }, [response]);


//   var sliderThumbnail = new Swiper('.thumb-slideshow', {
//     slidesPerView:4,
//     spaceBetween:10,
//     speed:1000,
//     freeMode: true,
//     watchSlidesVisibility: true,
//     watchSlidesProgress: true,
//     });
    
//     var slideslideshow = new Swiper('.slide-slideshow', {
//     speed:1000,
//     navigation: {
//     nextEl: '.swiper-button-next',
//     prevEl: '.swiper-button-prev',
//     },
//     thumbs: {
//     swiper: sliderThumbnail
//     }
//     });
//   return (
//     <div className="v-slideshow slideshow">
//       <div className="swiper slide-slideshow">
//         <div className="abs-btn-share">
//           <div className="verified-page">
//             <i className="fa-solid fa-check"></i> Verified
//           </div>
//           <div className="btn-share">
//             <a href="#">
//               <i className="fa-sharp fa-solid fa-share"></i>
//             </a>
//             <a href="#">
//               <i className="fa-regular fa-bookmark"></i>
//             </a>
//           </div>
//         </div>
//         <div className="swiper-wrapper">
//           {attractionGallery &&
//             attractionGallery.map((item, index) => (
//               <div key={index} className="swiper-slide">
//                 <img src={`${cloudFrontUrl}${item.image}`} alt={item.image} />
//               </div>
//             ))}
//         </div>
//         <div className="swiper-button-next"></div>
//         <div className="swiper-button-prev"></div>
//       </div>


//       <div className="swiper thumb-slideshow">
//         <div className="swiper-wrapper">
//           {attractionGallery &&
//             attractionGallery.map((item, index) => (
//               <div key={index} className="swiper-slide">
//                 <img src={`${cloudFrontUrl}${item.image}`} alt={item.image} />
//               </div>
//             ))}
//         </div>
//       </div>
//     </div>
//   );
// };
// export default AttractionGallery;

import { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { cloudFrontUrl } from "../../../config";
// Import Swiper and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const AttractionGallery = ({ id }) => {
  const [attractionGallery, setAttractionGallery] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null); // For controlling the thumbnail slider
  const { request, response } = useRequest();

  const fetchAttractionGallery = async () => {
    await request("GET", `/public/attraction-gallery/${id}`);
  };

  useEffect(() => {
    if (id !== undefined) {
      fetchAttractionGallery();
    }
  }, [id]);

  useEffect(() => {
    if (response) {
      setAttractionGallery(response.data);
    }
  }, [response]);

  return (
    <div className="v-slideshow slideshow">
      <Swiper
        modules={[Navigation, Thumbs]}
        speed={1000}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        className="slide-slideshow"
      >
        <div className="abs-btn-share">
          <div className="verified-page">
            <i className="fa-solid fa-check"></i> Verified
          </div>
          <div className="btn-share">
            <a href="#">
              <i className="fa-sharp fa-solid fa-share"></i>
            </a>
            <a href="#">
              <i className="fa-regular fa-bookmark"></i>
            </a>
          </div>
        </div>
        {attractionGallery.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={`${cloudFrontUrl}${item.image}`} alt={item.image} />
          </SwiperSlide>
        ))}
        <div className="swiper-button-next"><i class="fas fa-arrow-right"></i></div>
        <div className="swiper-button-prev"><i class="fas fa-arrow-left"></i></div>
      </Swiper>

      <Swiper
        modules={[Thumbs]}
        onSwiper={setThumbsSwiper}
        slidesPerView={4}
        spaceBetween={10}
        speed={1000}
        freeMode={true}
        watchSlidesVisibility={true}
        watchSlidesProgress
        className="thumb-slideshow"
      >
        {attractionGallery.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={`${cloudFrontUrl}${item.image}`} alt={item.image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default AttractionGallery;

