import { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { cloudFrontUrl } from "../../config";

const Destination = () => {
  const [destination, setDestination] = useState();
  const { request: destinationReq, response: destinationRes } = useRequest();
  const fetchDestination = async () => {
    await destinationReq("GET", "/public/attraction-destination");
  };
  useEffect(() => {
    fetchDestination();
  }, []);

  useEffect(() => {
    if (destinationRes) {
      setDestination(destinationRes.data);
    }
  }, [destinationRes]);

  console.log("destination : ", destination);
  return (
    <div className="explore-destination">
      <div className="heading-attraction-home">
        <h2 className="mb-3">Explore more destinations</h2>
        <p>Find things to do in cities around the world</p>
      </div>
      <div className="row">
        {destination &&
          destination.map((item, index) => (
            <div key={index} className="col-sm-3">
              <div className="item-explore-destination">
                <div className="imgeffect">
                  <img src={`${cloudFrontUrl}${item.image}`} alt={item.image} />
                  <div className="caption-explore-destination">
                    <h3>
                      <a href="#">{item.headline}</a>
                    </h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {/* <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img src="/assets/images/attraction/attraction-2.jpg" alt="alt" />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Abu Dhabi</a>
                </h3>
                <p>24 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img src="/assets/images/attraction/attraction-3.jpg" alt="alt" />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Sharjah</a>
                </h3>
                <p>12 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img src="/assets/images/attraction/attraction-4.jpg" alt="alt" />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Ras Al Khaimah</a>
                </h3>
                <p>12 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img
                src="/assets/images/attraction/attraction-13.jpg"
                alt="alt"
              />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Fujairah</a>
                </h3>
                <p>31 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img src="/assets/images/attraction/attraction-5.jpg" alt="alt" />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Ajman</a>
                </h3>
                <p>24 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img
                src="/assets/images/attraction/attraction-14.jpg"
                alt="alt"
              />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Umm Al Quwain</a>
                </h3>
                <p>12 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img
                src="/assets/images/attraction/attraction-15.jpg"
                alt="alt"
              />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Al Ain</a>
                </h3>
                <p>12 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img
                src="/assets/images/attraction/attraction-16.jpg"
                alt="alt"
              />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Hatta</a>
                </h3>
                <p>31 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img
                src="/assets/images/attraction/attraction-17.jpg"
                alt="alt"
              />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Jebel Ali</a>
                </h3>
                <p>24 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img src="/assets/images/attraction/attraction-7.jpg" alt="alt" />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Khorfakkan</a>
                </h3>
                <p>12 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img
                src="/assets/images/attraction/attraction-17.jpg"
                alt="alt"
              />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Dibba Al Fujairah</a>
                </h3>
                <p>12 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img
                src="/assets/images/attraction/attraction-18.jpg"
                alt="alt"
              />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Diba Al Sharjah</a>
                </h3>
                <p>31 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img
                src="/assets/images/attraction/attraction-19.jpg"
                alt="alt"
              />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Madinat Zayed</a>
                </h3>
                <p>24 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img
                src="/assets/images/attraction/attraction-20.jpg"
                alt="alt"
              />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">Mereiyeel</a>
                </h3>
                <p>12 things to do</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="item-explore-destination">
            <div className="imgeffect">
              <img
                src="/assets/images/attraction/attraction-21.jpg"
                alt="alt"
              />
              <div className="caption-explore-destination">
                <h3>
                  <a href="#">New Khalifa</a>
                </h3>
                <p>12 things to do</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default Destination;
