import Covered from "./covered";
import Destination from "./destination";
import LandingBanner from "./landingBanner";
import AttractionTop from "./attractionTop";
import AttractionCategory from "./attractionCategory";
import MasterBanner from "./masterBanner";
import OfferSlider from "./offerSlider";
import DownloadApp from "./downloadApp";

const AttractionHome = () => {
  return (
    <div className="attraction-home">
      <MasterBanner />
      <AttractionCategory />
      <Covered />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <AttractionTop />
            <LandingBanner />
            <Destination />
            <OfferSlider />
            <DownloadApp />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttractionHome;
