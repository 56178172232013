import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useRequest from "../../hooks/useRequest";
import { cloudFrontUrl } from "../../config";
import { toast, ToastContainer } from "react-toastify";

const HTabModule = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { country, lang, user_slug } = useParams();
  const [activeTab, setActiveTab] = useState("about");
  const [galleryActiveTab, setGalleryActiveTab] = useState("photo");

  // Tabs states
  const [galleries, setGalleries] = useState([]);
  const [stores, setStores] = useState([]);
  const [offers, setOffers] = useState([]);
  const [services, setServices] = useState([]);
  const [clients, setClients] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [recommendations, setRecommendations] = useState([]);

  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");
  const [rating, setRating] = useState(0);
  const [reviewFormData, setReviewFormData] = useState({
    name: "",
    email: "",
    message: "",
    listing_id: null,
    rating: rating,
    review_type: userType,
    user_id: userId,
  });

  const [recommendationFormData, setRecommendationFormData] = useState({
    recommendation_type: "",
    user_id: "",
    message: "",
    listing_id: null,
  });

  // Tabs request
  const { request: galleryRequest, response: galleryResponse } = useRequest();
  const { request: storeRequest, response: storeResponse } = useRequest();
  const { request: offerRequest, response: offerResponse } = useRequest();
  const { request: serviceRequest, response: serviceResponse } = useRequest();
  const { request: clientRequest, response: clientResponse } = useRequest();
  const { request: reviewRequest, response: reviewResponse } = useRequest();
  const { request: recommendationRequest, response: recommendationResponse } =
    useRequest();
  const { request: reviewPostRequest, response: reviewPostResponse } =
    useRequest();
  const {
    request: recommendationPostRequest,
    response: recommendationPostResponse,
  } = useRequest();

  useEffect(() => {
    const id = localStorage.getItem("user_id");
    const type = localStorage.getItem("user_type");
    const review = sessionStorage.getItem("reviewFormData");
    const recommendation = sessionStorage.getItem("recommendationFormData");

    if (review) {
      console.log(review);
      let data = JSON.parse(review);
      setReviewFormData(data);
      setRating(data?.rating);
    }

    if (recommendation) {
      console.log("Magic =========>>>>>>> ", recommendation);
      let data = JSON.parse(recommendation);
      setRecommendationFormData(data);
    }

    setUserId(id);
    setUserType(type);
  }, []);

  // Tab change event to get data
  useEffect(() => {
    if (activeTab === "gallery") {
      galleryRequest("GET", `/public/user-gallery/${user?.id}`);
    }
    if (activeTab === "store") {
      storeRequest("GET", `/public/listings/${user?.id}`);
    }
    if (activeTab === "offer") {
      offerRequest("GET", `/public/user-offer/${user?.id}`);
    }
    if (activeTab === "service") {
      serviceRequest("GET", `/public/user-service/${user?.id}`);
    }
    if (activeTab === "client") {
      clientRequest("GET", `/public/user-clients/${user?.id}`);
    }
    if (activeTab === "review") {
      reviewRequest("GET", `/public/reviews/${userType}/${userId}`);
    }
    if (activeTab === "recommendation") {
      recommendationRequest(
        "GET",
        `/public/recommendations/${userType}/${userId}`
      );
    }
  }, [activeTab]);

  useEffect(() => {
    if (galleryResponse && galleryResponse.status === "SUCCESS") {
      setGalleries(galleryResponse?.data);
    }
    if (storeResponse && storeResponse.status === "SUCCESS") {
      setStores(storeResponse?.data);
    }
    if (offerResponse && offerResponse.status === "SUCCESS") {
      setOffers(offerResponse?.data);
    }
    if (serviceResponse && serviceResponse.status === "SUCCESS") {
      setServices(serviceResponse?.data);
    }
    if (clientResponse && clientResponse.status === "SUCCESS") {
      setClients(clientResponse?.data);
    }
    if (reviewResponse && reviewResponse.status === "SUCCESS") {
      setReviews(reviewResponse?.data);
    }
    if (recommendationResponse && recommendationResponse.status === "SUCCESS") {
      setRecommendations(recommendationResponse?.data);
    }
  }, [
    galleryResponse,
    storeResponse,
    offerResponse,
    serviceResponse,
    clientResponse,
    reviewResponse,
    recommendationResponse,
  ]);

  useEffect(() => {
    setReviewFormData((prev) => ({
      ...prev,
      user_id: parseInt(userId),
      review_type: userType,
    }));

    setRecommendationFormData((prev) => ({
      ...prev,
      user_id: parseInt(userId),
      recommendation_type: userType,
    }));
  }, [userId, userType]);

  useEffect(() => {
    if (reviewPostResponse?.status === "SUCCESS") {
      console.log("success response");
      toast.success("Review Submitted", {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
      setReviewFormData((prev) => ({
        ...prev,
        name: "",
        email: "",
        message: "",
      }));
      setRating(0);
    } else if (reviewPostResponse === "FAIL") {
      toast.error("Review Submission Failed", {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
    }
  }, [reviewPostResponse]);

  useEffect(() => {
    if (recommendationPostResponse?.status === "SUCCESS") {
      toast.success("Recommendation Submitted", {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
      setRecommendationFormData((prev) => ({ ...prev, message: "" }));
    } else if (recommendationPostResponse?.status === "FAIL") {
      toast.error("Recommendation Submission Failed", {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
    }
  }, [recommendationPostResponse]);

  const handleReviewSubmit = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("access_token");
    if (token) {
      reviewPostRequest("POST", `/reviews`, reviewFormData);
    } else {
      sessionStorage.setItem("reviewFormData", JSON.stringify(reviewFormData));
      navigate(`/${country}/${lang}/auth/login?redirect=${location?.pathname}`);
    }
  };

  const handleRecommendationSubmit = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("access_token");
    if (token) {
      recommendationPostRequest(
        "POST",
        `/recommendations`,
        recommendationFormData
      );
    } else {
      sessionStorage.setItem(
        "recommendationFormData",
        JSON.stringify(recommendationFormData)
      );
      navigate(`/${country}/${lang}/auth/login?redirect=${location?.pathname}`);
    }
  };

  const handleClick = (value) => {
    setRating(value);
    setReviewFormData((prev) => ({ ...prev, rating: value }));
  };

  return (
    <div className="htab-module">
      {/* <ToastContainer /> */}
      <ul className="tab-menu">
        <li
          className={activeTab === "about" ? "active" : ""}
          onClick={() => setActiveTab("about")}
        >
          <span>
            <img
              src="/assets/images/icons/about.svg"
              alt="about"
              onDragStart={() => false}
            />
            About
          </span>
        </li>
        <li
          className={activeTab === "store" ? "active" : ""}
          onClick={() => setActiveTab("store")}
        >
          <span>
            <img
              src="/assets/images/icons/store.svg"
              alt="store"
              onDragStart={() => false}
            />
            MyStore
          </span>
        </li>
        <li
          className={activeTab === "gallery" ? "active" : ""}
          onClick={() => setActiveTab("gallery")}
        >
          <span>
            <img
              src="/assets/images/icons/gallery.svg"
              alt="gallery"
              onDragStart={() => false}
            />
            Gallery
          </span>
        </li>
        <li
          className={activeTab === "offer" ? "active" : ""}
          onClick={() => setActiveTab("offer")}
        >
          <span>
            <img
              src="/assets/images/icons/offer.svg"
              alt="offer"
              onDragStart={() => false}
            />
            Offers
          </span>
        </li>
        <li
          className={activeTab === "service" ? "active" : ""}
          onClick={() => setActiveTab("service")}
        >
          <span>
            <img
              src="/assets/images/icons/property.svg"
              alt="service"
              onDragStart={() => false}
            />
            Services
          </span>
        </li>
        <li
          className={activeTab === "client" ? "active" : ""}
          onClick={() => setActiveTab("client")}
        >
          <span>
            <img
              src="/assets/images/icons/store.svg"
              alt="clients"
              onDragStart={() => false}
            />
            Clients
          </span>
        </li>
        <li
          className={activeTab === "review" ? "active" : ""}
          onClick={() => setActiveTab("review")}
        >
          <span>
            <img
              src="/assets/images/icons/review-2.svg"
              alt="review"
              onDragStart={() => false}
            />
            Review
          </span>
        </li>
        <li
          className={activeTab === "recommendation" ? "active" : ""}
          onClick={() => setActiveTab("recommendation")}
        >
          <span>
            <img
              src="/assets/images/icons/recommendation.svg"
              alt="recommendation"
              onDragStart={() => false}
            />
            Recommendation
          </span>
        </li>
        <li
          className={activeTab === "contact" ? "active" : ""}
          onClick={() => setActiveTab("contact")}
        >
          <span>
            <img
              src="/assets/images/icons/contact.svg"
              alt="contact"
              onDragStart={() => false}
            />
            Contact Us
          </span>
        </li>
      </ul>

      <div
        id="about"
        className={`tab-content ${activeTab === "about" ? "active" : ""}`}
      >
        <p>{user?.user_profile?.description}</p>
      </div>
      <div
        id="store"
        className={`tab-content ${activeTab === "store" ? "active" : ""}`}
      >
        <Swiper
          spaceBetween={10}
          slidesPerView={3}
          navigation={{
            nextEl: ".offer-htab-next",
            prevEl: ".offer-htab-prev",
          }}
          className="service-htab"
        >
          {stores &&
            stores.map((data, index) => (
              <SwiperSlide key={index}>
                <Link
                  to={`/${country}/${lang}/company/${user_slug}/listing/${data?.slug}`}
                >
                  <img src={cloudFrontUrl + data?.logo} alt="alt" />
                  <div className="cap-service-htab">
                    <h4>{data?.title}</h4>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="swiper-control">
          <div className="swiper-button-prev servicetab-prev"></div>
          <div className="swiper-button-next servicetab-next"></div>
        </div>
      </div>
      <div
        id="gallery"
        className={`tab-content ${activeTab === "gallery" ? "active" : ""}`}
      >
        <div className="tab-gallery">
          <ul className="tabgallery-menu">
            <li
              className={`photo-gallery ${
                galleryActiveTab === "photo" ? "active" : ""
              }`}
              onClick={() => setGalleryActiveTab("photo")}
            >
              Photos
            </li>
            <li
              className={`video-gallery ${
                galleryActiveTab === "video" ? "active" : ""
              }`}
              onClick={() => setGalleryActiveTab("video")}
            >
              Videos
            </li>
          </ul>
          <div
            id="photo-gallery"
            className={`tabgallery-content ${
              galleryActiveTab === "photo" ? "active" : ""
            }`}
          >
            <ul>
              {galleries &&
                galleries.map((data, index) =>
                  data?.media_type === "IMAGE" ? (
                    <li key={index}>
                      <a
                        className="fancybox"
                        href={cloudFrontUrl + data?.image}
                        data-fancybox-group="gallery"
                      >
                        <img src={cloudFrontUrl + data?.image} alt="Gallery" />
                      </a>
                    </li>
                  ) : null
                )}
            </ul>
          </div>
          <div
            id="video-gallery"
            className={`tabgallery-content ${
              galleryActiveTab === "video" ? "active" : ""
            }`}
          >
            <ul>
              {galleries &&
                galleries.map((data, index) =>
                  data?.media_type === "VIDEO" ? (
                    <li key={index}>
                      <iframe
                        src={data?.video_url}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </li>
                  ) : null
                )}
            </ul>
          </div>
        </div>
      </div>
      <div
        id="offer"
        className={`tab-content ${activeTab === "offer" ? "active" : ""}`}
      >
        <Swiper
          spaceBetween={10}
          slidesPerView={3}
          navigation={{
            nextEl: ".offer-htab-next",
            prevEl: ".offer-htab-prev",
          }}
          className="offer-htab"
        >
          {offers &&
            offers.map((data, index) => (
              <SwiperSlide key={index}>
                <img src={cloudFrontUrl + data?.image} alt="alt" />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="swiper-control">
          <div className="swiper-button-prev offer-htab-prev"></div>
          <div className="swiper-button-next offer-htab-next"></div>
        </div>
      </div>
      <div
        id="service"
        className={`tab-content ${activeTab === "service" ? "active" : ""}`}
      >
        <Swiper
          spaceBetween={10}
          slidesPerView={3}
          navigation={{
            nextEl: ".offer-htab-next",
            prevEl: ".offer-htab-prev",
          }}
          className="service-htab"
        >
          {services &&
            services.map((data, index) => (
              <SwiperSlide key={index}>
                <img src={cloudFrontUrl + data?.image} alt="alt" />
                <div className="cap-service-htab">
                  <h4>{data?.title}</h4>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="swiper-control">
          <div className="swiper-button-prev servicetab-prev"></div>
          <div className="swiper-button-next servicetab-next"></div>
        </div>
      </div>
      <div
        id="clients"
        className={`tab-content ${activeTab === "client" ? "active" : ""}`}
      >
        <Swiper
          spaceBetween={10}
          slidesPerView={3}
          navigation={{
            nextEl: ".offer-htab-next",
            prevEl: ".offer-htab-prev",
          }}
          className="service-htab"
        >
          {clients &&
            clients.map((data, index) => (
              <SwiperSlide key={index}>
                <img src={cloudFrontUrl + data?.image} alt="alt" />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="swiper-control">
          <div className="swiper-button-prev servicetab-prev"></div>
          <div className="swiper-button-next servicetab-next"></div>
        </div>
      </div>
      <div
        id="review"
        className={`tab-content ${activeTab === "review" ? "active" : ""}`}
      >
        <div className="heading">
          <h2>Reviews ({reviews.length})</h2>
          <ul className="btn-heading">
            <li>
              <button className="btn" id="scrollbutton2">
                View All <i className="fa-solid fa-chevron-right"></i>
              </button>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="rating-progressbar">
              <div className="progressbars">
                <input type="checkbox" id="Excellent" />
                <label htmlFor="Excellent">Excellent</label>
                <div className="progress">
                  <div className="progress-bar progress100"></div>
                </div>
                <span>100</span>
              </div>
              <div className="progressbars">
                <input type="checkbox" id="Good" />
                <label htmlFor="Good">Very Good</label>
                <div className="progress">
                  <div className="progress-bar progress80"></div>
                </div>
                <span>80</span>
              </div>
              <div className="progressbars">
                <input type="checkbox" id="Average" />
                <label htmlFor="Average">Average</label>
                <div className="progress">
                  <div className="progress-bar progress40"></div>
                </div>
                <span>40</span>
              </div>
              <div className="progressbars">
                <input type="checkbox" id="Poor" />
                <label htmlFor="Poor">Poor</label>
                <div className="progress">
                  <div className="progress-bar progress10"></div>
                </div>
                <span>10</span>
              </div>
              <div className="progressbars">
                <input type="checkbox" id="Terrible" />
                <label htmlFor="Terrible">Terrible</label>
                <div className="progress">
                  <div className="progress-bar progress0"></div>
                </div>
                <span>0</span>
              </div>
            </div>
            <div className="enquiry">
              <form onSubmit={handleReviewSubmit}>
                <input
                  type="text"
                  name="name_field"
                  placeholder="Your Name"
                  required
                  value={reviewFormData?.name}
                  onChange={(e) =>
                    setReviewFormData((prev) => ({
                      ...prev,
                      name: e?.target?.value,
                    }))
                  }
                />
                <input
                  type="email"
                  name="email_field"
                  placeholder="info@company.com"
                  required
                  value={reviewFormData?.email}
                  onChange={(e) =>
                    setReviewFormData((prev) => ({
                      ...prev,
                      email: e?.target?.value,
                    }))
                  }
                />
                <textarea
                  name="message_field"
                  placeholder="Message"
                  value={reviewFormData?.message}
                  onChange={(e) =>
                    setReviewFormData((prev) => ({
                      ...prev,
                      message: e?.target?.value,
                    }))
                  }
                ></textarea>
                <div className="d-flex align-items-center">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <i
                      key={star}
                      className={`fa fa-star ${
                        star <= rating ? "text-warning" : "text-muted"
                      } mx-1`}
                      onClick={() => handleClick(star)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  ))}
                  <span className="ml-2">Rating: {rating} / 5</span>
                </div>
                <input type="submit" value="Send" />
              </form>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="all-comment-rm">
              {reviews &&
                reviews.map((data, index) => (
                  <div className="comment-rm" key={index}>
                    <div className="author-comment-rm">
                      <img
                        src={
                          cloudFrontUrl + data?.created_by?.user_profile?.logo
                        }
                        alt="alt"
                      />
                      <h4>
                        {data?.name} <small>Posted on 2 Hours ago</small>
                      </h4>
                    </div>
                    <p>{data?.message}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div
        id="recommendation"
        className={`tab-content ${
          activeTab === "recommendation" ? "active" : ""
        }`}
      >
        <div className="heading">
          <h2>Write Recommendation</h2>
          <ul className="btn-heading">
            <li>
              <button className="btn" id="scrollbutton3">
                View All <i className="fa-solid fa-chevron-right"></i>
              </button>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="recommendation-module">
              <form method="post" onSubmit={handleRecommendationSubmit}>
                <textarea
                  placeholder="Write recommendation here"
                  required
                  value={recommendationFormData?.message}
                  onChange={(e) =>
                    setRecommendationFormData((prev) => ({
                      ...prev,
                      message: e?.target?.value,
                    }))
                  }
                ></textarea>
                <div className="row">
                  {/* <div className="col-sm-6">
                    <label>Upload Photo</label>
                    <div className="attached-input">
                      {" "}
                      <i className="fa-solid fa-images"></i>
                      <input type="file" />
                      <small>Maximum Limit 22 MB</small>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label>Upload Video</label>
                    <div className="attached-input">
                      {" "}
                      <i className="fa-solid fa-video"></i>
                      <input type="file" />
                      <small>Maximum Limit 80 MB</small>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label>Video Link</label>
                    <div className="attached-input">
                      {" "}
                      <i className="fa-solid fa-video"></i>
                      <input
                        type="text"
                        placeholder="https://www.youtube.com/watch?v=DMH_IDMXs4k"
                      />
                    </div>
                  </div> */}
                  <div className="col-sm-6">
                    <input type="submit" name="submit" value="Submit" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="all-comment-rm">
              <div className="comment-rm">
                <div className="author-comment-rm">
                  <img src="/assets/images/user-2.png" alt="alt" />
                  <h4>
                    Jonathan <small>Posted on 2 Hours ago</small>
                  </h4>
                </div>
                <p>
                  We take great pride in providing the best culinary
                  experiences. We will be sure to pass your kind words to Rajan.
                </p>
              </div>
              <div className="comment-rm">
                <div className="author-comment-rm">
                  <img src="/assets/images/user-1.png" alt="alt" />
                  <h4>
                    Jonathan <small>Posted on 6 months ago</small>
                  </h4>
                </div>
                <p>
                  We take great pride in providing the best culinary
                  experiences. We will be sure to pass your kind words to Rajan.
                </p>
              </div>
              <div className="comment-rm">
                <div className="author-comment-rm">
                  <img src="/assets/images/user-2.png" alt="alt" />
                  <h4>
                    Jonathan <small>Posted on 2 Hours ago</small>
                  </h4>
                </div>
                <p>
                  We take great pride in providing the best culinary
                  experiences. We will be sure to pass your kind words to Rajan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="contact"
        className={`tab-content ${activeTab === "contact" ? "active" : ""}`}
      >
        <div className="btn-contact-htab">
          <button
            data-bs-target="#branch-popup"
            data-bs-toggle="modal"
            type="button"
          >
            Sharjah Branch
          </button>
          <button
            data-bs-target="#branch-popup"
            data-bs-toggle="modal"
            type="button"
          >
            Abu Dhabi Branch
          </button>
          <button
            data-bs-target="#branch-popup"
            data-bs-toggle="modal"
            type="button"
          >
            Dubai Head Office
          </button>
        </div>
        <div
          className="modal fade"
          id="branch-popup"
          aria-hidden="true"
          aria-labelledby="branch-popup"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
                <h2>Dubai Head Office</h2>
                <div className="slideshow">
                  <div className="swiper slide-slideshow">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <img src="/assets/images/profile/1.jpg" alt="alt" />
                      </div>
                      <div className="swiper-slide">
                        <img src="/assets/images/profile/2.jpg" alt="alt" />
                      </div>
                      <div className="swiper-slide">
                        <img src="/assets/images/profile/3.jpg" alt="alt" />
                      </div>
                      <div className="swiper-slide">
                        <img src="/assets/images/profile/4.jpg" alt="alt" />
                      </div>
                    </div>
                  </div>
                  <div className="swiper thumb-slideshow">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <img src="/assets/images/profile/1.jpg" alt="alt" />
                      </div>
                      <div className="swiper-slide">
                        <img src="/assets/images/profile/2.jpg" alt="alt" />
                      </div>
                      <div className="swiper-slide">
                        <img src="/assets/images/profile/3.jpg" alt="alt" />
                      </div>
                      <div className="swiper-slide">
                        <img src="/assets/images/profile/4.jpg" alt="alt" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-branch-popup">
                  <a href="#" className="btn btn-primary">
                    <i className="fa-solid fa-globe"></i>Website
                  </a>
                  <a href="#" className="btn btn-primary">
                    <i className="fa-solid fa-location-arrow"></i>Direction
                  </a>
                  <a href="#" className="btn btn-primary">
                    <img
                      src="/assets/images/icons/fredo.svg"
                      onDragStart={() => false}
                      alt="fredo"
                    />
                    Ask Fredo
                  </a>
                </div>
                <div className="heading">
                  <h3>Overview</h3>
                </div>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Adipiscing placerat
                  tortor at et molestie Diam ultricies nunc amet quisque auctor
                  dolor nunc tellus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HTabModule;
