import React from 'react';

const BlogTab = ({ blogs, cloudFrontUrl }) => {
    console.log('Rendering BlogTab with blogs:', blogs);
    return (
        <div id="blog-tabs-ms" className="">
            <div className="gallery-ms" style={{ paddingTop: '2rem' }}>
                {blogs ? (
                    blogs.length > 0 ? (
                        blogs.map((blog) => (
                            <div key={blog.id} className="blog-post">
                                <h3>{blog.title}</h3>
                                <p>{blog.content}</p>
                                {blog.feature_image && (
                                    <img src={cloudFrontUrl + blog.feature_image} alt={blog.title} />
                                )}
                                <p><strong>Meta Description:</strong> {blog.meta_description}</p>
                            </div>
                        ))
                    ) : (
                        <p>No blogs available.</p>
                    )
                ) : (
                    <p>Loading blogs...</p>
                )}
            </div>
        </div>
    );
};

export default BlogTab;
