import React, { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { useParams, Link, NavLink } from "react-router-dom";

const RightSideNav = () => {
  const { country, lang } = useParams();
  const [policyData, setPolicyData] = useState([]);
  const { request: policyRequest, response: policyResponse } = useRequest();
  useEffect(() => {
    policyRequest("GET", "/public/cms-privacy");
  }, []);
  useEffect(() => {
    if (policyResponse && policyResponse.status === "SUCCESS") {
      setPolicyData(policyResponse.data || {});
    }
  }, [policyResponse]);

  return (
    <>
      <div className="category-community box-community">
        <ul>
          <li>
            <NavLink
              to={`/${country}/${lang}/community`}
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              <img src="/assets/images/icons/home.svg" alt="Home" /> Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${country}/${lang}/community/groups`}
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              <img src="/assets/images/icons/group.svg" alt="Groups" /> Groups
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${country}/${lang}/community/friends`}
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              <img src="/assets/images/icons/friend.svg" alt="Friends" />{" "}
              Friends
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${country}/${lang}/community/events`}
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              <img src="/assets/images/icons/event.svg" alt="Events" /> Events
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${country}/${lang}/community/polls`}
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              <img src="/assets/images/icons/poll.svg" alt="Polls" /> Polls
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${country}/${lang}/community/blogs`}
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              <img src="/assets/images/icons/blog.svg" alt="Blogs" /> Blogs
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${country}/${lang}/community/news`}
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              <img src="/assets/images/icons/news.svg" alt="News" /> News
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${country}/${lang}/community/store`}
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              <img src="/assets/images/icons/store.svg" alt="Store" /> Store
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${country}/${lang}/community/listing`}
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              <img src="/assets/images/icons/listing.svg" alt="Listing" />{" "}
              Listing
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default RightSideNav;
