import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
const SimilarModule = () => {
  return (
    <div className="similar-module">
      <div className="heading">
        <h3>Similar Attraction (343)</h3>
      </div>
      <div className="swiper similar-module-slick">
        <Swiper
          modules={[Pagination, Navigation, Autoplay]}
          pagination={{ clickable: true }} // Automatically add the pagination element
          loop={true}
          watchSlidesProgress={true}
          slidesPerView={4}
          spaceBetween={20}
          speed={1000}
          autoplay={{ delay: 9999, disableOnInteraction: false }}
          navigation={{
            nextEl: ".similar-module-next",
            prevEl: ".similar-module-prev",
          }}
          breakpoints={{
            320: { slidesPerView: 1.2, spaceBetween: 10 },
            480: { slidesPerView: 1.2, spaceBetween: 10 },
            768: { slidesPerView: 2.2, spaceBetween: 10 },
            960: { slidesPerView: 4, spaceBetween: 20 },
          }}
        >
          <SwiperSlide>
            <div className="itembox ib-attraction">
              <div className="imgeffect">
                <div className="ib-date">5 Days ago</div>
                <div className="ib-wishlist">
                  <a href="#">
                    <i className="fa-regular fa-heart"></i>
                  </a>{" "}
                </div>
                <a href="#">
                  <img src="/assets/images/attraction/1.jpg" alt="alt" />
                </a>
              </div>
              <div className="info-itembox flex-itembox">
                <div className="non">
                  <h3>
                    <a href="#">Attraction Name</a>
                  </h3>
                  <p>
                    Antler is the investor backing the worlds most driven
                    founders, from day zero to greatness.
                  </p>
                </div>
                <a href="#">
                  <img src="/assets/images/logo-1.png" alt="alt" />
                </a>{" "}
              </div>
              <ul className="data-itembox">
                <li>
                  <img
                    src="/assets/images/icons/location.svg"
                    alt="list"
                    ondragstart="return false"
                  />
                  Abu Dhabi, UAE
                </li>
                <li className="review-ib">
                  <img
                    src="/assets/images/icons/google.svg"
                    alt="list"
                    ondragstart="return false"
                  />{" "}
                  <a href="#">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                  </a>{" "}
                  <strong>4.5</strong>
                </li>
              </ul>
              <ul className="data-itembox">
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/icons/enquiry.svg"
                      alt="enquiry"
                      ondragstart="return false"
                    />
                    Send a Inquiry
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/icons/ai.svg"
                      alt="ai"
                      ondragstart="return false"
                    />
                    Fredo AI Chatbot
                  </a>
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="itembox ib-attraction">
              <div className="imgeffect">
                <div className="ib-date">5 Days ago</div>
                <div className="ib-wishlist">
                  <a href="#">
                    <i className="fa-regular fa-heart"></i>
                  </a>{" "}
                </div>
                <a href="#">
                  <img src="/assets/images/attraction/2.jpg" alt="alt" />
                </a>
              </div>
              <div className="info-itembox flex-itembox">
                <div className="non">
                  <h3>
                    <a href="#">Attraction Name</a>
                  </h3>
                  <p>
                    Antler is the investor backing the worlds most driven
                    founders, from day zero to greatness.
                  </p>
                </div>
                <a href="#">
                  <img src="/assets/images/logo-1.png" alt="alt" />
                </a>{" "}
              </div>
              <ul className="data-itembox">
                <li>
                  <img
                    src="/assets/images/icons/location.svg"
                    alt="list"
                    ondragstart="return false"
                  />
                  Abu Dhabi, UAE
                </li>
                <li className="review-ib">
                  <img
                    src="/assets/images/icons/google.svg"
                    alt="list"
                    ondragstart="return false"
                  />{" "}
                  <a href="#">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                  </a>{" "}
                  <strong>4.5</strong>
                </li>
              </ul>
              <ul className="data-itembox">
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/icons/enquiry.svg"
                      alt="enquiry"
                      ondragstart="return false"
                    />
                    Send a Inquiry
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/icons/ai.svg"
                      alt="ai"
                      ondragstart="return false"
                    />
                    Fredo AI Chatbot
                  </a>
                </li>
              </ul>
            </div>
          </SwiperSlide>

          <SwiperSlide>
          <div className="itembox ib-attraction">
              <div className="imgeffect">
                <div className="ib-date">5 Days ago</div>
                <div className="ib-wishlist">
                  <a href="#">
                    <i className="fa-regular fa-heart"></i>
                  </a>{" "}
                </div>
                <a href="#">
                  <img src="/assets/images/attraction/3.jpg" alt="alt" />
                </a>
              </div>
              <div className="info-itembox flex-itembox">
                <div className="non">
                  <h3>
                    <a href="#">Attraction Name</a>
                  </h3>
                  <p>
                    Antler is the investor backing the worlds most driven
                    founders, from day zero to greatness.
                  </p>
                </div>
                <a href="#">
                  <img src="/assets/images/logo-1.png" alt="alt" />
                </a>{" "}
              </div>
              <ul className="data-itembox">
                <li>
                  <img
                    src="/assets/images/icons/location.svg"
                    alt="list"
                    ondragstart="return false"
                  />
                  Abu Dhabi, UAE
                </li>
                <li className="review-ib">
                  <img
                    src="/assets/images/icons/google.svg"
                    alt="list"
                    ondragstart="return false"
                  />{" "}
                  <a href="#">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                  </a>{" "}
                  <strong>4.5</strong>
                </li>
              </ul>
              <ul className="data-itembox">
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/icons/enquiry.svg"
                      alt="enquiry"
                      ondragstart="return false"
                    />
                    Send a Inquiry
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/icons/ai.svg"
                      alt="ai"
                      ondragstart="return false"
                    />
                    Fredo AI Chatbot
                  </a>
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="itembox ib-attraction">
              <div className="imgeffect">
                <div className="ib-date">5 Days ago</div>
                <div className="ib-wishlist">
                  <a href="#">
                    <i className="fa-regular fa-heart"></i>
                  </a>{" "}
                </div>
                <a href="#">
                  <img src="/assets/images/attraction/4.jpg" alt="alt" />
                </a>
              </div>
              <div className="info-itembox flex-itembox">
                <div className="non">
                  <h3>
                    <a href="#">Attraction Name</a>
                  </h3>
                  <p>
                    Antler is the investor backing the worlds most driven
                    founders, from day zero to greatness.
                  </p>
                </div>
                <a href="#">
                  <img src="/assets/images/logo-1.png" alt="alt" />
                </a>{" "}
              </div>
              <ul className="data-itembox">
                <li>
                  <img
                    src="/assets/images/icons/location.svg"
                    alt="list"
                    ondragstart="return false"
                  />
                  Abu Dhabi, UAE
                </li>
                <li className="review-ib">
                  <img
                    src="/assets/images/icons/google.svg"
                    alt="list"
                    ondragstart="return false"
                  />{" "}
                  <a href="#">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                    <i className="fa-regular fa-star"></i>
                  </a>{" "}
                  <strong>4.5</strong>
                </li>
              </ul>
              <ul className="data-itembox">
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/icons/enquiry.svg"
                      alt="enquiry"
                      ondragstart="return false"
                    />
                    Send a Inquiry
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/icons/ai.svg"
                      alt="ai"
                      ondragstart="return false"
                    />
                    Fredo AI Chatbot
                  </a>
                </li>
              </ul>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="swiper-pagination"></div>
      </div>
    </div>
  );
};
export default SimilarModule;
