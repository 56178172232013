import React, { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";

const BoostYourBusiness = () => {
  const [influencersData, setInfluencersData] = useState([]);
  const { request: InfluencersRequest, response: influencersResponse } =
    useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
  useEffect(() => {
    InfluencersRequest("GET", "/community/influencers");
  }, []);
  useEffect(() => {
    if (influencersResponse && influencersResponse.status === "SUCCESS") {
      setInfluencersData(influencersResponse.data || {});
    }
  }, [influencersResponse]);
  return (
    <>
      <div className="boost-business box-community">
        <h2>Boost Your Business</h2>

        <div className="filter-boost-business">
          <div className="sortby-boost-business">
            <select>
              <option selected="selected">Influencers</option>
              <option value="New to Old">New to Old</option>
              <option value="Old to New">Old to New</option>
            </select>
          </div>
          <div className="sortby-boost-business">
            <small>Sort</small>
            <select>
              <option selected="selected">Default</option>
              <option value="New to Old">New to Old</option>
              <option value="Old to New">Old to New</option>
            </select>
          </div>
        </div>

        <ul className="online-cmty">
          {influencersData.length > 0 ? (
            influencersData.map((influencers, index) => (
              <li key={index}>
                <i className="fa-solid fa-circle online-icon" />
                <div>
                  <img
                    src="/assets/images/author/4.jpg"
                    alt={`${influencers.username}`}
                  />
                  <h4>
                    {influencers.name} <i className="fa-solid fa-check" />{" "}
                    <small>{influencers.email}</small>
                  </h4>
                </div>
                <a href="#" className="btn btn-primary">
                  Chat Now
                </a>
              </li>
            ))
          ) : (
            <p>No influencers available</p>
          )}
        </ul>
      </div>
    </>
  );
};

export default BoostYourBusiness;
