import React from 'react'

const Trendings = () => {
  return (
    <>
                                  <div className="trending-community box-community">
                                <h2>Trending</h2>
                                <ul>
                                    <li>
                                    <a href="#">#artistaasiatico</a>

                                    </li>
                                    <li>
                                        <a href="#">#tiktok</a>
                                    </li>
                                    <li>
                                        <a href="#">#vivaelpoderpopular</a>
                                    </li>
                                    <li>
                                        <a href="#">#airdrop</a>
                                    </li>
                                    <li>
                                        <a href="#">#airdrop</a>
                                    </li>
                                    <li>
                                        <a href="#">#vivaelpoderpopular</a>
                                    </li>
                                </ul>
                            </div>
    </>
  )
}

export default Trendings
