import "./Auth.css";
import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { Toast } from "../../utils/ToastNotification";
import { EmailContext } from "../../utils/EmailContext";
import { useSearch } from "../../contexts/SearchContext";

const OtpVerification = () => {
  const { country, lang } = useParams();
  const navigate = useNavigate();
  const { email } = useContext(EmailContext);
  const { selectedRoute, routeSlug } = useSearch();

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);
  const { request: verifyRequest, response: verifyResponse } = useRequest();
  const { request: reSendRequest, response: reSendResponse } = useRequest();

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const otpValue = otp.join("");
    if (otpValue.length === 6) {
      // setLoading(true)
      verifyRequest("POST", "/auth/email-verify", {
        email: email,
        email_otp: otpValue,
      });
    } else {
      Toast(false, "Please enter a 6-digit OTP.");
    }
  };

  const handleReSend = () => {
    // Call resend OTP api
    reSendRequest("POST", "/auth/resend-email-otp", { email: email });
  };

  useEffect(() => {
    if (verifyResponse && verifyResponse?.status === "SUCCESS") {
      Toast(true, verifyResponse?.message);
      localStorage.setItem("access_token", verifyResponse.data?.access_token);
      localStorage.setItem("refresh_token", verifyResponse.data?.refresh_token);
      localStorage.setItem("name", verifyResponse.data?.name);
      localStorage.setItem("username", verifyResponse.data?.username);
      localStorage.setItem("user_type", verifyResponse.data?.user_type);
      if (selectedRoute === "jobDetails") {
        navigate(`/gb/en/jobDescription/${routeSlug}`);
      } else if (selectedRoute === "job") {
        navigate("/gb/en/joblisting");
      } else {
        navigate(`/${country}/${lang}`);
      }
    } else {
      Toast(false, verifyResponse?.message);
    }

    if (reSendResponse && reSendResponse?.status === "SUCCESS") {
      Toast(true, reSendResponse?.message);
    } else {
      Toast(false, reSendResponse?.message);
    }
  }, [verifyResponse, reSendResponse]);

  return (
    <>
      <div className="otp-verify">
        <button
          className="back-btn"
          onClick={() => navigate(`/${country}/${lang}/auth/signup`)}
        >
          <img
            src="/assets/images/icons/arrow-long.svg"
            alt="arrow"
            onDragStart={() => false}
          />
        </button>
        <h3>
          Please enter the 6-digit <span>OTP</span> verification code that was
          sent to your email.{" "}
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="flex-otp">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>
          <div className="resent-otp">
            <a href="#" className="resent-otp" onClick={handleReSend}>
              Resent OTP Code
            </a>
          </div>
          <input type="submit" value="Verified OTP Code" />
        </form>
      </div>
    </>
  );
};

export default OtpVerification;
