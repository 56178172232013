import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/JobListing/Sidebar';
import JobCard from '../../components/JobListing/JobCard';
import '../../pages/joblisting/joblisting.css';
import Categories from '../../components/JobListing/Categories';
import { useParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { useSearch } from '../../contexts/SearchContext';
import { Swiper, SwiperSlide } from 'swiper/react';

const JobListing = () => {
  const { country, lang } = useParams();

  const {
    selectedCategory,
    searchValue,
    setSelectedCategory,
    setSearchValue,
    selectedRoute,
    setSelectedRoute,
  } = useSearch();

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState(null);
  const [jobData, setJobData] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  const { request: jobRequest, response: jobResponse } = useRequest();
  const { request: categoryRequest, response: categoryResponse } = useRequest();
  const { request: subCategoryRequest, response: subCategoryResponse } =
    useRequest();

  useEffect(() => {
    setSelectedRoute('job');

    if (!selectedCategory?.id) {
      const queryString = `page_num=${pageNum}&page_size=${pageSize}`;
      jobRequest('GET', `/public/search?${queryString}`);
    } else if (selectedCategory?.id) {
      const queryString = `page_num=${pageNum}&page_size=${pageSize}&category_id=${selectedCategory?.id}`;

      jobRequest('GET', `/public/search?${queryString}`);
    }
  }, [selectedCategory?.id]);

  useEffect(() => {
    if (selectedCategory?.id) {
      let queryString = `page_num=${pageNum}&page_size=${pageSize}&category_id=${selectedCategory?.id}`;
      if (selectedSubCategoryId && selectedSubCategoryId !== '') {
        console.log('===> inside the true condition', selectedSubCategoryId);
        queryString = queryString + `&sub_category_id=${selectedSubCategoryId}`;
      }
      jobRequest('GET', `/public/search?${queryString}`);
    }
  }, [selectedSubCategoryId]);

  useEffect(() => {
    if (jobResponse && jobResponse.status === 'SUCCESS') {
      setJobData(jobResponse?.data?.results);
      setTotalRecords(jobResponse?.total);
      setPageNum(jobResponse.page_num);
      setIsLoading(false);
    } else if (
      jobResponse &&
      jobResponse.status === 'FAIL' &&
      jobResponse.message === '[Job_not_found.]'
    ) {
      setJobData('No jobs found');
      setIsLoading(false);
    }
  }, [jobResponse]);

  useEffect(() => {
    categoryRequest('GET', '/public/category');
    if (selectedCategory?.id) {
      subCategoryRequest('GET', `/public/sub-category/${selectedCategory.id}`);
    }
  }, [selectedCategory?.id]);

  useEffect(() => {
    if (subCategoryResponse && subCategoryResponse.status === 'SUCCESS') {
      setSubCategories(subCategoryResponse.data);
    }
  }, [subCategoryResponse]);

  useEffect(() => {
    if (categoryResponse && categoryResponse.status === 'SUCCESS') {
      console.log('===> setting');
      setCategories(categoryResponse.data);
    }
  }, [categoryResponse]);

  useEffect(() => {
    if (selectedCategory?.id) {
      subCategoryRequest('GET', `/public/sub-category/${selectedCategory.id}`);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (subCategory?.slug) {
      jobRequest('GET', `/public/jobs/${subCategory.slug}`);
    }
  }, [subCategory]);

  const handleSelectCategory = (e) => {
    if (e === '') {
      setSelectedCategory({ name: 'All' });
    } else {
      const selectedId = e;
      const category = categories.find((cat) => cat.id == selectedId);

      setSelectedCategory(category);
    }
  };

  const handleSubCategorySelection = (subcategoryId) => {
    if (subcategoryId && subcategoryId !== '') {
      setSelectedSubCategoryId(subcategoryId);
    } else {
      setSelectedSubCategoryId('');
    }
  };

  useEffect(() => {
    if (selectedCategory?.id) {
      let queryString = `page_num=${pageNum}&page_size=${pageSize}&category_id=${selectedCategory?.id}`;
      if (selectedSubCategoryId && selectedSubCategoryId !== '') {
        console.log('===> inside the true condition', selectedSubCategoryId);
        queryString = queryString + `&sub_category_id=${selectedSubCategoryId}`;
      }
      jobRequest('GET', `/public/search?${queryString}`);
    } else {
      const queryString = `page_num=${pageNum}&page_size=${pageSize}`;
      jobRequest('GET', `/public/search?${queryString}`);
    }
  }, [pageNum]);

  const displayedCategories = showAll ? categories : categories.slice(0, 10);

  // useEffect(() => {
  //   console.log('category name find', selectedCategory?.name);

  //   setSelectedCategory(
  //     selectedCategory?.id ? selectedCategory : { name: 'All', id: '' }
  //   );
  // }, [categories]);

  return (
    <>
      <div className='main-container container d-none'>
        <div className='joblisting'>
          <div className='top-panel d-flex justify-content-between align-items-center'>
            <h1 className='mb-0'>Find Graphic Designer {}</h1>
            <div className='filter-container'>
              <button className='filter-button'>Map</button>
              <button className='filter-button active'>List</button>
              <div className='dropdown'>
                <select>
                  <option value='category'>Category</option>
                  <option value='graphic-design'>Graphic Design</option>
                  <option value='web-design'>Web Design</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div className='dropdown'>
                <select>
                  <option value='subcategory'>Subcategory</option>
                  <option value='logo-design'>Logo Design</option>
                  <option value='illustration'>Illustration</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div className='dropdown'>
                <select>
                  <option value='sort'>Sort by</option>
                  <option value='date'>Date</option>
                  <option value='popularity'>Popularity</option>
                  {/* Add more options as needed */}
                </select>
              </div>
            </div>
          </div>

          <div className='main-container mt-4'>
            <Categories />
            <div className='content'>
              <div className='job-list'>
                <JobCard jobData={jobData} category={selectedCategory?.name} />
              </div>
              <Sidebar />
            </div>
          </div>
        </div>
      </div>

      <div className='job-listing module-listing bg-white pt-10'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='title-ml'>
                <div className='flex-wraper'>
                  <h1 className='capitalize'>Find {selectedCategory.name}</h1>
                  <div className='filter-ml'>
                    <ul className='btn-listview'>
                      <li className='map-lv'>
                        <a href='#'>
                          <img
                            src='/assets/images/icons/location.svg'
                            alt='location'
                            ondragstart='return false'
                          />{' '}
                          Map
                        </a>
                      </li>

                      <li>
                        <a href='#'>
                          <img
                            src='/assets/images/icons/list.svg'
                            alt='list'
                            ondragstart='return false'
                          />
                        </a>
                      </li>
                    </ul>
                    <div className='sort-filter'>
                      <div className='field-sort-filter'>
                        <select
                          value={selectedCategory?.id || ''}
                          onChange={(e) => handleSelectCategory(e.target.value)}
                        >
                          <option value=''>All</option>
                          {categories &&
                            categories.map((data, index) => (
                              <option key={index} value={data?.id}>
                                {data?.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className='field-sort-filter'>
                        <select
                          value={selectedSubCategoryId || ''} // Bind the select value to the state
                          onChange={(e) =>
                            handleSubCategorySelection(e?.target?.value)
                          } // Handle changes
                        >
                          <option value=''>All Sub Category</option>
                          {subCategories &&
                            subCategories.map((data) => (
                              <option key={data.id} value={data.id}>
                                {data.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className='field-sort-filter'>
                        <select>
                          <option selected='selected'>Sort by</option>
                          <option value='New to Old'>New to Old</option>
                          <option value='Old to New'>Old to New</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='category-ml'>
                <div className='flex-wraper'>
                  <Swiper
                    spaceBetween={24}
                    slidesPerView={4}
                    speed={1000}
                    loop={true}
                    autoplay={{ delay: 999, disableOnInteraction: false }}
                    breakpoints={{
                      320: { slidesPerView: 2, spaceBetween: 10 },
                      480: { slidesPerView: 2, spaceBetween: 10 },
                      768: { slidesPerView: 3, spaceBetween: 10 },
                      960: { slidesPerView: 5, spaceBetween: 24 },
                    }}
                  >
                    {subCategories.map(
                      (category) =>
                        category.is_active && (
                          <SwiperSlide key={category.id}>
                            <a
                              href='#'
                              onClick={() =>
                                handleSubCategorySelection(category?.id)
                              }
                            >
                              {category?.name}
                            </a>
                          </SwiperSlide>
                        )
                    )}
                    {!showAll && (
                      <SwiperSlide>
                        <a
                          style={{ color: '#F800C0' }}
                          href='#'
                          onClick={(e) => {
                            e.preventDefault();
                            setShowAll(true);
                          }}
                        >
                          More
                        </a>
                      </SwiperSlide>
                    )}
                  </Swiper>
                </div>
              </div>
              <div className='all-ml'>
                <div className='row'>
                  <div className='col-sm-9'>
                    <div className='row'>
                      <JobCard
                        jobData={jobData}
                        category={selectedCategory?.name}
                      />
                    </div>
                    <div className='btn-load'>
                      <button
                        onClick={() => {
                          console.log('handle load more');
                          setPageNum((prev) => prev + 1);
                        }}
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className='offer-slide'>
                <div className='swiper offer-slick'>
                  <div className='swiper-wrapper'>
                    <div className='swiper-slide'>
                      <img src='/assets/images/offer-slider.jpg' alt='alt' />
                      <div className='caption-offer-slide'>
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className='swiper-slide'>
                      <img src='/assets/images/offer-slider.jpg' alt='alt' />
                      <div className='caption-offer-slide'>
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className='swiper-slide'>
                      <img src='/assets/images/offer-slider.jpg' alt='alt' />
                      <div className='caption-offer-slide'>
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className='swiper-slide'>
                      <img src='/assets/images/offer-slider.jpg' alt='alt' />
                      <div className='caption-offer-slide'>
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className='swiper-slide'>
                      <img src='/assets/images/offer-slider.jpg' alt='alt' />
                      <div className='caption-offer-slide'>
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                  </div>
                  <div className='swiper-pagination' />
                </div>
              </div>
              <div className='news-updates mt-20'>
                <div className='heading'>
                  <h2>News &amp; Updates</h2>
                  <a href='#' className='btn-heading'>
                    View All
                    <i className='fa-solid fa-chevron-right' />
                  </a>{' '}
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='swiper news-ml-slick'>
                      <div className='swiper-wrapper'>
                        <div className='swiper-slide'>
                          <div className='itembox ib-news'>
                            <div className='imgeffect'>
                              <div className='ib-date'>5 Days ago</div>
                              <div className='ib-wishlist'>
                                <a href='#'>
                                  <i className='fa-regular fa-heart' />
                                </a>
                              </div>
                              <a href='#'>
                                <img src='/assets/images/news/1.jpg' alt='alt' />
                              </a>
                            </div>
                            <div className='info-itembox'>
                              <h3>
                                <a href='#'>Headline</a>
                              </h3>
                              <p>
                                Lorem ipsum dolor sit afsmbet, consectetur
                                adipiscing elit ru trum blandit justo, id rutruvl 9m
                                diam eleme ntumv eget.Aliquam lacinia consectetur.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='swiper-slide'>
                          <div className='itembox ib-news'>
                            <div className='imgeffect'>
                              <div className='ib-date'>5 Days ago</div>
                              <div className='ib-wishlist'>
                                <a href='#'>
                                  <i className='fa-regular fa-heart' />
                                </a>
                              </div>
                              <a href='#'>
                                <img src='/assets/images/news/2.jpg' alt='alt' />
                              </a>
                            </div>
                            <div className='info-itembox'>
                              <h3>
                                <a href='#'>Headline</a>
                              </h3>
                              <p>
                                Lorem ipsum dolor sit afsmbet, consectetur
                                adipiscing elit ru trum blandit justo, id rutruvl 9m
                                diam eleme ntumv eget.Aliquam lacinia consectetur.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='swiper-slide'>
                          <div className='itembox ib-news'>
                            <div className='imgeffect'>
                              <div className='ib-date'>5 Days ago</div>
                              <div className='ib-wishlist'>
                                <a href='#'>
                                  <i className='fa-regular fa-heart' />
                                </a>
                              </div>
                              <a href='#'>
                                <img src='/assets/images/news/3.jpg' alt='alt' />
                              </a>
                            </div>
                            <div className='info-itembox'>
                              <h3>
                                <a href='#'>Headline</a>
                              </h3>
                              <p>
                                Lorem ipsum dolor sit afsmbet, consectetur
                                adipiscing elit ru trum blandit justo, id rutruvl 9m
                                diam eleme ntumv eget.Aliquam lacinia consectetur.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='swiper-slide'>
                          <div className='itembox ib-news'>
                            <div className='imgeffect'>
                              <div className='ib-date'>5 Days ago</div>
                              <div className='ib-wishlist'>
                                <a href='#'>
                                  <i className='fa-regular fa-heart' />
                                </a>
                              </div>
                              <a href='#'>
                                <img src='/assets/images/news/4.jpg' alt='alt' />
                              </a>
                            </div>
                            <div className='info-itembox'>
                              <h3>
                                <a href='#'>Headline</a>
                              </h3>
                              <p>
                                Lorem ipsum dolor sit afsmbet, consectetur
                                adipiscing elit ru trum blandit justo, id rutruvl 9m
                                diam eleme ntumv eget.Aliquam lacinia consectetur.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='app-download'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <img
                      src='/assets/images/app-download.png'
                      alt='app'
                      className='pic-app-download'
                    />
                  </div>
                  <div className='col-sm-8'>
                    <h2>
                      Available at{' '}
                      <small>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                      </small>
                    </h2>
                    <div className='btn-app-download'>
                      <a href='#'>
                        <img
                          src='/assets/images/icons/android.svg'
                          alt='android'
                        />
                      </a>{' '}
                      <a href='#'>
                        <img src='/assets/images/icons/ios.svg' alt='ios' />
                      </a>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobListing;
