import React, { useEffect, useRef, useState } from 'react';
import Slider from '../../components/Influencer/MainSlider/Slider';
import InfluencerDetail from '../../components/Influencer/InfluencerDetail';
import useRequest from '../../hooks/useRequest';
import moment from 'moment/moment';
import { useSearch } from '../../contexts/SearchContext';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Influencer = () => {
  const { selectedCategory, searchValue, setSelectedCategory, setSearchValue } =
    useSearch();
  const navigate = useNavigate();
  const { country, lang, user_slug } = useParams();
  const location = useLocation();
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [influencersData, setInfluencersData] = useState(null);
  const [reviewsData, setReviewsData] = useState(null);
  const [recommendationData, setRecommendationData] = useState(null);
  const [imagesData, setImagesData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [rating, setRating] = useState(0);
  const [activeTab, setActiveTab] = useState('review-tab-module');
  const [reviewFormData, setReviewFormData] = useState({
    name: '',
    email: '',
    message: '',
    listing_id: null,
    rating: rating,
    review_type: userType,
    user_id: userId,
  });
  const [recommendationFormData, setRecommendationFormData] = useState({
    recommendation_type: '',
    user_id: '',
    message: '',
    listing_id: null,
  });
  const { request: influencersRequest, response: influencersResponse } =
    useRequest();
  const { request: reviewsRequest, response: reviewsResponse } = useRequest();
  const { request: imagesRequest, response: imagesResponse } = useRequest();
  const { request: recommendationRequest, response: recommendationResponse } =
    useRequest();
  const { request: reviewPostRequest, response: reviewPostResponse } =
    useRequest();
  const {
    request: recommendationPostRequest,
    response: recommendationPostResponse,
  } = useRequest();

  const handleClick = (value) => {
    setRating(value);
    setReviewFormData((prev) => ({ ...prev, rating: value }));
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  useEffect(() => {
    const id = localStorage.getItem('user_id');
    const type = localStorage.getItem('user_type');

    const getSessionData = async () => {
      let review = await sessionStorage.getItem('reviewFormData');
      let recommendation = await sessionStorage.getItem(
        'recommendationFormData'
      );
      if (review) {
        let data = JSON.parse(review);
        setReviewFormData(data);
        setRating(data?.rating);
      }

      console.log('Data is ', recommendation);
      if (recommendation) {
        let data = JSON.parse(recommendation);
        setRecommendationFormData(data);
      }
    };
    setUserId(id);
    setUserType(type);

    if (selectedCategory?.id) {
      influencersRequest(
        'GET',
        `/public/search?page_num=${pageNum}&page_size=${pageSize}&category_id=${selectedCategory?.id}`
      );
    }

    getSessionData();
  }, [selectedCategory?.id]);

  useEffect(() => {
    if (userId && userType) {
      imagesRequest('GET', `/public/user-gallery/${userId}`);
      reviewsRequest('GET', `/public/reviews/${userType}/${userId}`);
      recommendationRequest(
        'GET',
        `/public/recommendations/${userType}/${userId}`
      );

      setRecommendationFormData((prev) => ({
        ...prev,
        user_id: parseInt(userId),
        recommendation_type: userType,
      }));
      setReviewFormData((prev) => ({
        ...prev,
        user_id: parseInt(userId),
        review_type: userType,
      }));
    }
  }, [userId, userType]);

  useEffect(() => {
    if (influencersResponse && influencersResponse.status === 'SUCCESS') {
      setInfluencersData(influencersResponse?.data?.results);
      setIsLoading(false);
    } else if (
      influencersResponse &&
      influencersResponse.status === 'FAIL' &&
      influencersResponse.message === '[Job_not_found.]'
    ) {
      setInfluencersData('No jobs found');
      setIsLoading(false);
    }
  }, [influencersResponse]);

  useEffect(() => {
    if (reviewsResponse && reviewsResponse.status === 'SUCCESS') {
      setReviewsData(reviewsResponse.data);
      setIsLoading(false);
    } else if (
      reviewsResponse &&
      reviewsResponse.status === 'FAIL' &&
      reviewsResponse.message === '[Job_not_found.]'
    ) {
      setReviewsData('No jobs found');
      setIsLoading(false);
    }
  }, [reviewsResponse]);

  useEffect(() => {
    if (imagesResponse && imagesResponse.status === 'SUCCESS') {
      setImagesData(imagesResponse.data);
      setIsLoading(false);
    } else if (
      imagesResponse &&
      imagesResponse.status === 'FAIL' &&
      imagesResponse.message === '[Job_not_found.]'
    ) {
      setImagesData('No jobs found');
      setIsLoading(false);
    }
  }, [imagesResponse]);

  useEffect(() => {
    if (recommendationResponse && recommendationResponse.status === 'SUCCESS') {
      setRecommendationData(recommendationResponse.data);
      setIsLoading(false);
    } else if (
      recommendationResponse &&
      recommendationResponse.status === 'FAIL' &&
      recommendationResponse.message === '[Job_not_found.]'
    ) {
      setRecommendationData('No jobs found');
      setIsLoading(false);
    }
  }, [recommendationResponse]);

  const handleReviewSubmit = (e) => {
    e.preventDefault();
    let token = localStorage.getItem('access_token');
    if (token) {
      reviewPostRequest('POST', `/reviews`, reviewFormData);
    } else {
      sessionStorage.setItem('reviewFormData', JSON.stringify(reviewFormData));
      navigate(`/${country}/${lang}/auth/login?redirect=${location?.pathname}`);
    }
  };

  const handleRecommendationSubmit = (e) => {
    e.preventDefault();
    let token = localStorage.getItem('access_token');
    if (token) {
      recommendationPostRequest(
        'POST',
        `/recommendations`,
        recommendationFormData
      );
    } else {
      sessionStorage.setItem(
        'recommendationFormData',
        JSON.stringify(recommendationFormData)
      );
      navigate(`/${country}/${lang}/auth/login?redirect=${location?.pathname}`);
    }
  };

  const handleLoadMore = () => {
    setPageSize((prev) => prev + 10);
  };

  useEffect(() => {
    if (recommendationPostResponse?.status === 'SUCCESS') {
      toast.success('Recommendation Submitted', {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
      setRecommendationFormData((prev) => ({ ...prev, message: '' }));
    } else if (recommendationPostResponse?.status === 'FAIL') {
      toast.error('Recommendation Submission Failed', {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
    }
  }, [recommendationPostResponse]);

  useEffect(() => {
    if (reviewPostResponse?.status === 'SUCCESS') {
      console.log('success response');
      toast.success('Review Submitted', {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
      setReviewFormData((prev) => ({
        ...prev,
        name: '',
        email: '',
        message: '',
      }));
      setRating(0);
    } else if (reviewPostResponse === 'FAIL') {
      toast.error('Review Submission Failed', {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
    }
  }, [reviewPostResponse]);
  return (
    <>
      <ToastContainer />
      <div className='module-listing  d-none bg-white'>
        <div className='influencer bg-white'>
          {/* <Slider /> */}
          <InfluencerDetail />
        </div>
      </div>
      <div className='influencer-profile bg-white  '>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='mb-5'>
                {imagesData && imagesData?.length && (
                  <Slider data={imagesData} />
                )}
              </div>
              <div className='top-mp px-4'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className='title-mp'>
                      <div className='flex-title-mp'>
                        <div className='story-title-mp'>
                          <img src='/assets/images/logo-1.png' alt='alt' />
                        </div>
                        <div className='non'>
                          <h1>{influencersData && influencersData[0]?.name}</h1>
                          <div className='category-title-mp'>
                            <a href='#'>Category</a>
                          </div>
                          <div className='location-title-mp'>
                            <img
                              src='/assets/images/icons/location.svg'
                              alt='location'
                              ondragstart='return false'
                            />
                            Location
                          </div>
                          <div className='switch-mp'>
                            {' '}
                            <a href='#' className='active'>
                              Profile
                            </a>{' '}
                            <a href='#'>My Community</a>{' '}
                          </div>
                        </div>
                      </div>
                      <div className='share-vert'>
                        {' '}
                        <i>
                          <img
                            src='/assets/images/icons/tick.svg'
                            alt='tick'
                            ondragstart='return false'
                          />
                        </i>{' '}
                        <a href='#'>
                          <img
                            src='/assets/images/icons/share-2.svg'
                            alt='share'
                            ondragstart='return false'
                          />
                        </a>{' '}
                        <a href='#'>
                          <img
                            src='/assets/images/icons/bookmark.svg'
                            alt='bookmark'
                            className='bookmark-icon'
                            ondragstart='return false'
                          />
                        </a>{' '}
                      </div>
                    </div>
                    <div className='status-mp'>
                      <h3>Status</h3>
                      <p>We are off today!</p>
                    </div>
                    <div className='lg-btn-mp'>
                      <ul>
                        <li>
                          {' '}
                          <a href='#'>
                            <img
                              src='/assets/images/icons/live.svg'
                              alt='stlivear'
                              ondragstart='return false'
                            />
                            MyLive
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a href='#'>
                            <img
                              src='/assets/images/icons/listing.svg'
                              alt='listing'
                              ondragstart='return false'
                            />
                            MyListing
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a href='#'>
                            <img
                              src='/assets/images/icons/book.svg'
                              alt='book'
                              ondragstart='return false'
                            />
                            BookMyServices
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a href='#' className='review-lg-btn-mp'>
                            <img
                              src='/assets/images/icons/write-review.svg'
                              alt='write-review'
                              ondragstart='return false'
                            />
                            Write a Review
                          </a>{' '}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-sm-6 bg-[#F1F1F1] rounded-xl'>
                    <div className='chatbot'>
                      <div className='header-chatbot'>
                        <div className='non'>
                          {' '}
                          <img src='/assets/images/ai/fredo.png' alt='alt' />
                          <h3>
                            FREDO <small>AI ChatBot</small>
                          </h3>
                        </div>
                        <button>
                          <img
                            src='/assets/images/icons/voice-wave.svg'
                            alt='alt'
                          />
                        </button>
                      </div>
                      <div className='flex-chatbot'>
                        <div className='all-chatbox'>
                          <div className='chatbox'>
                            <div className='logo-chatbox'>
                              <img
                                src='/assets/images/icons/fredo.svg'
                                ondragstart='return false'
                                alt='fredo'
                              />
                            </div>
                            <div className='data-chatbox'>
                              <p>
                                For Inquiry please give me, your following
                                information
                              </p>
                              <form>
                                <input
                                  type='text'
                                  name='name_field_0'
                                  placeholder='Name'
                                  required=''
                                />
                                <input
                                  type='email'
                                  name='email_field_0'
                                  placeholder='Email'
                                />
                                <input
                                  type='email'
                                  name='phonel_field_0'
                                  placeholder='Phone Number'
                                />
                                <textarea
                                  name='message_field_0'
                                  placeholder='Your Message'
                                  defaultValue={''}
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className='footer-chatbot'>
                          <form method='post'>
                            <button>
                              <i className='fa-solid fa-arrow-up-from-bracket' />{' '}
                              Upload
                            </button>
                            <div className='field-footer-chatbot'>
                              <input
                                type='text'
                                name='search'
                                placeholder='Search here'
                              />
                            </div>
                            <div className='submit-footer-chatbot'>
                              <button>
                                <i className='fa-solid fa-microphone' />
                              </button>
                              <input type='submit' defaultValue='Sent' />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='followers-influencer'>
                <ul>
                  <li>
                    <a href='#' target='_blank'>
                      <i className='fa-brands fa-youtube' />
                      <p>
                        <small>Subscribers</small> 1,000,000
                      </p>
                    </a>
                  </li>
                  <li>
                    <a href='#' target='_blank'>
                      <i className='fa-brands fa-instagram' />
                      <p>
                        <small>Followers</small> 1,000,000
                      </p>
                    </a>
                  </li>
                  <li>
                    <a href='#' target='_blank'>
                      <i className='fa-brands fa-linkedin-in' />
                      <p>
                        <small>Followers</small> 1,000,000
                      </p>
                    </a>
                  </li>
                  <li>
                    <a href='#' target='_blank'>
                      <i className='fa-brands fa-x-twitter' />
                      <p>
                        <small>Followers</small> 1,000,000
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='htab-module'>
                <ul className='tab-menu'>
                  <li data-tab='about-htab' className='active'>
                    <span>
                      <img
                        src='/assets/images/icons/about.svg'
                        alt='about'
                        ondragstart='return false'
                      />
                      About
                    </span>
                  </li>
                  <li data-tab='gallery-htab'>
                    <span>
                      <img
                        src='/assets/images/icons/gallery.svg'
                        alt='gallery'
                        ondragstart='return false'
                      />
                      Gallery
                    </span>
                  </li>
                  <li data-tab='service-htab'>
                    <span>
                      <img
                        src='/assets/images/icons/property.svg'
                        alt='property'
                        ondragstart='return false'
                      />
                      Services
                    </span>
                  </li>
                  <li data-tab='review-htab'>
                    <span>
                      <img
                        src='/assets/images/icons/review-2.svg'
                        alt='review'
                        ondragstart='return false'
                      />
                      Review
                    </span>
                  </li>
                  <li data-tab='recommendation-htab'>
                    <span>
                      <img
                        src='/assets/images/icons/recommendation.svg'
                        alt='recommendation'
                        ondragstart='return false'
                      />
                      Recommendation
                    </span>
                  </li>
                  <li data-tab='contact-htab'>
                    <span>
                      <img
                        src='/assets/images/icons/contact.svg'
                        alt='contact'
                        ondragstart='return false'
                      />
                      Contact Us
                    </span>
                  </li>
                </ul>
                <div id='about-htab' className='tab-content active'>
                  <h3>About us</h3>
                  <p>
                    Welcome to August update from HCLTech Trends and Insights.
                    This month, the raging wildfires in Hawaii and Canada
                    captured global news headlines. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor.
                    Incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation. Welcome to August s
                    update from HCLTech Trends and Insights. This month, the
                    raging wildfires in Hawaii and Canada captured global news.
                  </p>
                  <p>
                    August update from HCLTech Trends and Insights. This month,
                    the raging wildfires in Hawaii and Canada captured global
                    news headlines. August s update from HCLTech Trends and
                    Insights. This month, the raging wildfires in Hawaii and
                    Canada captured global news headlinesLorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et Welcome to August s update from
                    HCLTech Trends and Insights. This month, the raging wildfir.
                  </p>
                </div>
                <div id='service-htab' className='tab-content'>
                  <div className='service-htab'>
                    <div className='swiper servicetab-slick'>
                      <div className='swiper-wrapper'>
                        <div className='swiper-slide'>
                          <div className='imgeffect'>
                            {' '}
                            <img src='/assets/images/offer.jpg' alt='alt' />
                            <div className='cap-service-htab'>
                              <h4>Service Name</h4>
                            </div>
                          </div>
                        </div>
                        <div className='swiper-slide'>
                          <div className='imgeffect'>
                            {' '}
                            <img src='/assets/images/offer.jpg' alt='alt' />
                            <div className='cap-service-htab'>
                              <h4>Service Name</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='swiper-control'>
                        <div className='swiper-button-prev servicetab-prev' />
                        <div className='swiper-button-next servicetab-next' />
                      </div>
                    </div>
                  </div>
                </div>
                <div id='gallery-htab' className='tab-content'>
                  <div className='tab-gallery'>
                    <ul className='tabgallery-menu'>
                      <li data-tab='photo-gallery-htab' className='active'>
                        Photos
                      </li>
                      <li data-tab='video-gallery-htab'>Videos</li>
                    </ul>
                    <div
                      id='photo-gallery-htab'
                      className='tabgallery-content active'
                    >
                      <ul>
                        <li>
                          <a
                            className='fancybox'
                            href='images/profile/1.jpg'
                            data-fancybox-group='gallery'
                          >
                            <img
                              src='/assets/images/profile/1.jpg'
                              alt='Gallery'
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            className='fancybox'
                            href='images/profile/2.jpg'
                            data-fancybox-group='gallery'
                          >
                            <img
                              src='/assets/images/profile/2.jpg'
                              alt='Gallery'
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            className='fancybox'
                            href='images/profile/3.jpg'
                            data-fancybox-group='gallery'
                          >
                            <img
                              src='/assets/images/profile/3.jpg'
                              alt='Gallery'
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            className='fancybox'
                            href='images/profile/4.jpg'
                            data-fancybox-group='gallery'
                          >
                            <img
                              src='/assets/images/profile/4.jpg'
                              alt='Gallery'
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            className='fancybox'
                            href='images/profile/1.jpg'
                            data-fancybox-group='gallery'
                          >
                            <img
                              src='/assets/images/profile/1.jpg'
                              alt='Gallery'
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            className='fancybox'
                            href='images/profile/2.jpg'
                            data-fancybox-group='gallery'
                          >
                            <img
                              src='/assets/images/profile/2.jpg'
                              alt='Gallery'
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div id='video-gallery-htab' className='tabgallery-content'>
                      <ul>
                        <li>
                          <iframe
                            src='https://www.youtube.com/embed/pKpN4j1IGfI?si=9mf6hb1ucDdJW4_S'
                            title='YouTube video player'
                            frameBorder={0}
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            referrerPolicy='strict-origin-when-cross-origin'
                            allowFullScreen=''
                          />
                        </li>
                        <li>
                          <iframe
                            src='https://www.youtube.com/embed/pKpN4j1IGfI?si=9mf6hb1ucDdJW4_S'
                            title='YouTube video player'
                            frameBorder={0}
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            referrerPolicy='strict-origin-when-cross-origin'
                            allowFullScreen=''
                          />
                        </li>
                        <li>
                          <iframe
                            src='https://www.youtube.com/embed/pKpN4j1IGfI?si=9mf6hb1ucDdJW4_S'
                            title='YouTube video player'
                            frameBorder={0}
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            referrerPolicy='strict-origin-when-cross-origin'
                            allowFullScreen=''
                          />
                        </li>
                        <li>
                          <iframe
                            src='https://www.youtube.com/embed/pKpN4j1IGfI?si=9mf6hb1ucDdJW4_S'
                            title='YouTube video player'
                            frameBorder={0}
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            referrerPolicy='strict-origin-when-cross-origin'
                            allowFullScreen=''
                          />
                        </li>
                        <li>
                          <iframe
                            src='https://www.youtube.com/embed/pKpN4j1IGfI?si=9mf6hb1ucDdJW4_S'
                            title='YouTube video player'
                            frameBorder={0}
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            referrerPolicy='strict-origin-when-cross-origin'
                            allowFullScreen=''
                          />
                        </li>
                        <li>
                          <iframe
                            src='https://www.youtube.com/embed/pKpN4j1IGfI?si=9mf6hb1ucDdJW4_S'
                            title='YouTube video player'
                            frameBorder={0}
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            referrerPolicy='strict-origin-when-cross-origin'
                            allowFullScreen=''
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div id='review-htab' className='tab-content '>
                  <div className='heading'>
                    <h2>Reviews (101)</h2>
                    <ul className='btn-heading'>
                      <li>
                        <button className='btn' id='scrollbutton2'>
                          View All <i className='fa-solid fa-chevron-right' />
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='rating-progressbar'>
                        <div className='progressbars'>
                          <input type='checkbox' id='Excellent' />
                          <label htmlFor='Excellent'>Excellent</label>
                          <div className='progress'>
                            <div className='progress-bar progress100' />
                          </div>
                          <span>100</span>{' '}
                        </div>
                        <div className='progressbars'>
                          <input type='checkbox' id='Good' />
                          <label htmlFor='Good'>Very Good</label>
                          <div className='progress'>
                            <div className='progress-bar progress80' />
                          </div>
                          <span>80</span>{' '}
                        </div>
                        <div className='progressbars'>
                          <input type='checkbox' id='Average' />
                          <label htmlFor='Average'>Average</label>
                          <div className='progress'>
                            <div className='progress-bar progress40' />
                          </div>
                          <span>40</span>{' '}
                        </div>
                        <div className='progressbars'>
                          <input type='checkbox' id='Poor' />
                          <label htmlFor='Poor'>Poor</label>
                          <div className='progress'>
                            <div className='progress-bar progress10' />
                          </div>
                          <span>10</span>{' '}
                        </div>
                        <div className='progressbars'>
                          <input type='checkbox' id='Terrible' />
                          <label htmlFor='Terrible'>Terrible</label>
                          <div className='progress'>
                            <div className='progress-bar progress0' />
                          </div>
                          <span>0</span>{' '}
                        </div>
                      </div>
                      <div className='enquiry'>
                        <form onSubmit={handleReviewSubmit} method='POST'>
                          <input
                            type='text'
                            name='name_field_x'
                            placeholder='Your Name'
                            required=''
                            onChange={(e) => {
                              setReviewFormData((prev) => ({
                                ...prev,
                                name: e?.target?.value,
                              }));
                            }}
                          />
                          <input
                            type='email'
                            name='email_field_x'
                            placeholder='info@company.com'
                            required=''
                            onChange={(e) => {
                              setReviewFormData((prev) => ({
                                ...prev,
                                email: e?.target?.value,
                              }));
                            }}
                          />
                          <textarea
                            name='message_field_x'
                            placeholder='Message'
                            defaultValue={''}
                            onChange={(e) => {
                              setReviewFormData((prev) => ({
                                ...prev,
                                message: e?.target?.value,
                              }));
                            }}
                          />
                          <div className='d-flex align-items-center'>
                            {[1, 2, 3, 4, 5].map((star) => (
                              <i
                                key={star}
                                className={`fa fa-star ${
                                  star <= rating ? 'text-warning' : 'text-muted'
                                } mx-1`}
                                onClick={() => handleClick(star)}
                                style={{ cursor: 'pointer' }}
                              ></i>
                            ))}
                            <span className='ml-2'>Rating: {rating} / 5</span>
                          </div>
                          <input type='submit' defaultValue='Send' />
                        </form>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='all-comment-rm'>
                        {reviewsData &&
                          reviewsData.map((review) => (
                            <div className='comment-rm'>
                              <div className='author-comment-rm'>
                                {' '}
                                <img
                                  src='/assets/images/user-1.png'
                                  alt='alt'
                                />
                                <h4>
                                  {review?.name}{' '}
                                  <small>
                                    Posted{' '}
                                    {moment(review?.created_at).fromNow()}
                                  </small>
                                </h4>
                              </div>
                              <p>{review?.message}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div id='recommendation-htab' className='tab-content'>
                  <div className='heading'>
                    <h2>Write Recommendation</h2>
                    <ul className='btn-heading'>
                      <li>
                        <button className='btn' id='scrollbutton3'>
                          View All <i className='fa-solid fa-chevron-right' />
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='recommendation-module'>
                        <form onSubmit={handleRecommendationSubmit}>
                          <textarea
                            placeholder='Write recommendation here'
                            required=''
                            defaultValue={''}
                            value={recommendationFormData?.message}
                            onChange={(e) =>
                              setRecommendationFormData((prev) => ({
                                ...prev,
                                message: e?.target?.value,
                              }))
                            }
                          />
                          <div className='row'>
                            {/* <div className="col-sm-6">
                              <label>Upload Photo</label>
                              <div className="attached-input">
                                {" "}
                                <i className="fa-solid fa-images" />
                                <input type="file" />
                                <small>Maximum Limit 22 MB</small>{" "}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label>Upload Video</label>
                              <div className="attached-input">
                                {" "}
                                <i className="fa-solid fa-video" />
                                <input type="file" />
                                <small>Maximum Limit 80 MB</small>{" "}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label>Video Link</label>
                              <div className="attached-input">
                                {" "}
                                <i className="fa-solid fa-video" />
                                <input
                                  type="text"
                                  placeholder="https://www.youtube.com/watch?v=DMH_IDMXs4k"
                                />
                              </div>
                            </div> */}
                            <div className='col-sm-6'>
                              <input type='submit' defaultValue='Send' />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='all-comment-rm'>
                        {recommendationData &&
                          recommendationData?.length > 0 &&
                          recommendationData?.map((review) => (
                            <div className='comment-rm'>
                              <div className='author-comment-rm'>
                                {' '}
                                <img
                                  src='/assets/images/user-1.png'
                                  alt='alt'
                                />
                                <h4>
                                  {review?.name}{' '}
                                  <small>
                                    Posted{' '}
                                    {moment(review?.created_at).fromNow()}
                                  </small>
                                </h4>
                              </div>
                              <p>{review?.message}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div id='contact-htab' className='tab-content'>
                  <div className='btn-contact-htab'>
                    <button
                      data-bs-target='#branch-popup'
                      data-bs-toggle='modal'
                      type='button'
                    >
                      Sharjah Branch
                    </button>
                    <button
                      data-bs-target='#branch-popup'
                      data-bs-toggle='modal'
                      type='button'
                    >
                      Abu Dhabi Branch
                    </button>
                    <button
                      data-bs-target='#branch-popup'
                      data-bs-toggle='modal'
                      type='button'
                    >
                      Dubai Head Office
                    </button>
                  </div>
                </div>
              </div>
              <div className='social-activity'>
                <div className='vtab'>
                  <ul className='vtab-menu'>
                    <li data-tab='mystories-vtab' className='active'>
                      <img src='/assets/images/icons/story.svg' alt='story' />{' '}
                      MyStories
                    </li>
                    <li data-tab='myreels-vtab'>
                      <img src='/assets/images/icons/reels.svg' alt='reels' />{' '}
                      MyReels
                    </li>
                    <li data-tab='mylive-vtab'>
                      <img src='/assets/images/icons/live.svg' alt='live' />{' '}
                      MyLive
                    </li>
                    <li data-tab='mycommunity-vtab'>
                      <img
                        src='/assets/images/icons/community.svg'
                        alt='community'
                      />{' '}
                      MyCommunity
                    </li>
                  </ul>
                  <div className='wraper-vtab'>
                    <div id='mystories-vtab' className='vtab-content active'>
                      <div className='swiper story-slick-vtab'>
                        <div className='swiper-wrapper'>
                          <div className='swiper-slide'>
                            <div className='item-story'>
                              <div className='cap-item-story'>
                                <div className='date-item-story'>
                                  1 days ago
                                </div>
                              </div>
                              <div className='imgeffect'>
                                {' '}
                                <img
                                  src='/assets/images/reels-1.jpg'
                                  alt='alt'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='item-story'>
                              <div className='cap-item-story'>
                                <div className='date-item-story'>
                                  1 days ago
                                </div>
                              </div>
                              <div className='imgeffect'>
                                {' '}
                                <img
                                  src='/assets/images/reels-2.jpg'
                                  alt='alt'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='item-story'>
                              <div className='cap-item-story'>
                                <div className='date-item-story'>
                                  1 days ago
                                </div>
                              </div>
                              <div className='imgeffect'>
                                {' '}
                                <img
                                  src='/assets/images/reels-1.jpg'
                                  alt='alt'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='item-story'>
                              <div className='cap-item-story'>
                                <div className='date-item-story'>
                                  1 days ago
                                </div>
                              </div>
                              <div className='imgeffect'>
                                {' '}
                                <img
                                  src='/assets/images/reels-2.jpg'
                                  alt='alt'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='swiper-control'>
                        <div className='swiper-button-prev story-vtab-prev' />
                        <div className='swiper-button-next story-vtab-next' />
                      </div>
                    </div>
                    <div id='myreels-vtab' className='vtab-content'>
                      <div className='swiper reels-slick-vtab'>
                        <div className='swiper-wrapper'>
                          <div className='swiper-slide'>
                            <div className='item-story'>
                              <div className='cap-item-story'>
                                <a href='#'>
                                  <img
                                    src='/assets/images/logo-1.png'
                                    alt='alt'
                                  />
                                </a>
                                <small className='date-item-story'>
                                  1 days ago
                                </small>
                              </div>
                              <div className='imgeffect'>
                                {' '}
                                <img
                                  src='/assets/images/reels-1.jpg'
                                  alt='alt'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='item-story'>
                              <div className='cap-item-story'>
                                <a href='#'>
                                  <img
                                    src='/assets/images/logo-1.png'
                                    alt='alt'
                                  />
                                </a>
                                <small className='date-item-story'>
                                  1 days ago
                                </small>
                              </div>
                              <div className='imgeffect'>
                                {' '}
                                <img
                                  src='/assets/images/reels-2.jpg'
                                  alt='alt'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='item-story'>
                              <div className='cap-item-story'>
                                <a href='#'>
                                  <img
                                    src='/assets/images/logo-1.png'
                                    alt='alt'
                                  />
                                </a>
                                <small className='date-item-story'>
                                  1 days ago
                                </small>
                              </div>
                              <div className='imgeffect'>
                                {' '}
                                <img
                                  src='/assets/images/reels-1.jpg'
                                  alt='alt'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='item-story'>
                              <div className='cap-item-story'>
                                <a href='#'>
                                  <img
                                    src='/assets/images/logo-1.png'
                                    alt='alt'
                                  />
                                </a>
                                <small className='date-item-story'>
                                  1 day ago
                                </small>
                              </div>
                              <div className='imgeffect'>
                                {' '}
                                <img
                                  src='/assets/images/reels-2.jpg'
                                  alt='alt'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='swiper-control'>
                        <div className='swiper-button-prev reels-vtab-prev' />
                        <div className='swiper-button-next reels-vtab-next' />
                      </div>
                    </div>
                    <div id='mylive-vtab' className='vtab-content'>
                      <div className='swiper live-slick-vtab'>
                        <div className='swiper-wrapper'>
                          <div className='swiper-slide'>
                            <div className='item-mylive'>
                              <div className='imgeffect'>
                                {' '}
                                <a href='#'>
                                  <img
                                    src='/assets/images/reels-1.jpg'
                                    alt='mylive'
                                  />
                                </a>
                                <div className='cap-item-mylive'>
                                  <div className='btns-mylive'>
                                    {' '}
                                    <a href='#'>
                                      <i className='fa-brands fa-youtube' />{' '}
                                      Live
                                    </a>{' '}
                                    <span>
                                      <i className='fa-solid fa-eye' />
                                      10.8K
                                    </span>{' '}
                                  </div>
                                  <small className='date-item-mylive'>
                                    1 days ago
                                  </small>{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='item-mylive'>
                              <div className='imgeffect'>
                                {' '}
                                <a href='#'>
                                  <img
                                    src='/assets/images/reels-2.jpg'
                                    alt='mylive'
                                  />
                                </a>
                                <div className='cap-item-mylive'>
                                  <div className='btns-mylive'>
                                    {' '}
                                    <a href='#'>
                                      <i className='fa-brands fa-youtube' />{' '}
                                      Live
                                    </a>{' '}
                                    <span>
                                      <i className='fa-solid fa-eye' />
                                      10.8K
                                    </span>{' '}
                                  </div>
                                  <small className='date-item-mylive'>
                                    1 days ago
                                  </small>{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='item-mylive'>
                              <div className='imgeffect'>
                                {' '}
                                <a href='#'>
                                  <img
                                    src='/assets/images/reels-1.jpg'
                                    alt='mylive'
                                  />
                                </a>
                                <div className='cap-item-mylive'>
                                  <div className='btns-mylive'>
                                    {' '}
                                    <a href='#'>
                                      <i className='fa-brands fa-youtube' />{' '}
                                      Live
                                    </a>{' '}
                                    <span>
                                      <i className='fa-solid fa-eye' />
                                      10.8K
                                    </span>{' '}
                                  </div>
                                  <small className='date-item-mylive'>
                                    1 days ago
                                  </small>{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='item-mylive'>
                              <div className='imgeffect'>
                                {' '}
                                <a href='#'>
                                  <img
                                    src='/assets/images/reels-2.jpg'
                                    alt='mylive'
                                  />
                                </a>
                                <div className='cap-item-mylive'>
                                  <div className='btns-mylive'>
                                    {' '}
                                    <a href='#'>
                                      <i className='fa-brands fa-youtube' />{' '}
                                      Live
                                    </a>{' '}
                                    <span>
                                      <i className='fa-solid fa-eye' />
                                      10.8K
                                    </span>{' '}
                                  </div>
                                  <small className='date-item-mylive'>
                                    1 days ago
                                  </small>{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='swiper-control'>
                        <div className='swiper-button-prev live-vtab-prev' />
                        <div className='swiper-button-next live-vtab-next' />
                      </div>
                    </div>
                    <div id='mycommunity-vtab' className='vtab-content'>
                      <div className='swiper cmty-slick-vtab'>
                        <div className='swiper-wrapper'>
                          <div className='swiper-slide'>
                            <div className='sm-cmty'>
                              <h3>
                                <a href='#'>Dribble Shots Community</a>{' '}
                                <small>5h</small>
                              </h3>
                              <p>
                                MyFinder leading <a href='#'>@SearchPortal</a>{' '}
                                that makes it easy to find, possibly in the{' '}
                                <a href='#'>@smartest</a> way.
                              </p>
                              <div className='imgeffect'>
                                <a href='#'>
                                  <img
                                    src='/assets/images/offer.jpg'
                                    alt='alt'
                                  />
                                </a>
                              </div>
                              <div className='btn-sm-cmty'>
                                {' '}
                                <a href='#'>
                                  <i className='fa-regular fa-heart' />
                                </a>{' '}
                                <a href='#'>
                                  <i className='fa-regular fa-comment' />
                                </a>{' '}
                                <a href='#'>
                                  {' '}
                                  <i className='fa-regular fa-bookmark' />
                                </a>{' '}
                                <a href='#'>
                                  <i className='fa-regular fa-paper-plane' />
                                </a>{' '}
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='sm-cmty'>
                              <h3>
                                <a href='#'>Dribble Shots Community</a>{' '}
                                <small>5h</small>
                              </h3>
                              <p>
                                MyFinder leading <a href='#'>@SearchPortal</a>{' '}
                                that makes it easy to find, possibly in the{' '}
                                <a href='#'>@smartest</a> way.
                              </p>
                              <div className='imgeffect'>
                                <a href='#'>
                                  <img
                                    src='/assets/images/offer.jpg'
                                    alt='alt'
                                  />
                                </a>
                              </div>
                              <div className='btn-sm-cmty'>
                                {' '}
                                <a href='#'>
                                  <i className='fa-regular fa-heart' />
                                </a>{' '}
                                <a href='#'>
                                  <i className='fa-regular fa-comment' />
                                </a>{' '}
                                <a href='#'>
                                  {' '}
                                  <i className='fa-regular fa-bookmark' />
                                </a>{' '}
                                <a href='#'>
                                  <i className='fa-regular fa-paper-plane' />
                                </a>{' '}
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='sm-cmty'>
                              <h3>
                                <a href='#'>Dribble Shots Community</a>{' '}
                                <small>5h</small>
                              </h3>
                              <p>
                                MyFinder leading <a href='#'>@SearchPortal</a>{' '}
                                that makes it easy to find, possibly in the{' '}
                                <a href='#'>@smartest</a> way.
                              </p>
                              <div className='imgeffect'>
                                <a href='#'>
                                  <img
                                    src='/assets/images/offer.jpg'
                                    alt='alt'
                                  />
                                </a>
                              </div>
                              <div className='btn-sm-cmty'>
                                {' '}
                                <a href='#'>
                                  <i className='fa-regular fa-heart' />
                                </a>{' '}
                                <a href='#'>
                                  <i className='fa-regular fa-comment' />
                                </a>{' '}
                                <a href='#'>
                                  {' '}
                                  <i className='fa-regular fa-bookmark' />
                                </a>{' '}
                                <a href='#'>
                                  <i className='fa-regular fa-paper-plane' />
                                </a>{' '}
                              </div>
                            </div>
                          </div>
                          <div className='swiper-slide'>
                            <div className='sm-cmty'>
                              <h3>
                                <a href='#'>Dribble Shots Community</a>{' '}
                                <small>5h</small>
                              </h3>
                              <p>
                                MyFinder leading <a href='#'>@SearchPortal</a>{' '}
                                that makes it easy to find, possibly in the{' '}
                                <a href='#'>@smartest</a> way.
                              </p>
                              <div className='imgeffect'>
                                <a href='#'>
                                  <img
                                    src='/assets/images/offer.jpg'
                                    alt='alt'
                                  />
                                </a>
                              </div>
                              <div className='btn-sm-cmty'>
                                {' '}
                                <a href='#'>
                                  <i className='fa-regular fa-heart' />
                                </a>{' '}
                                <a href='#'>
                                  <i className='fa-regular fa-comment' />
                                </a>{' '}
                                <a href='#'>
                                  {' '}
                                  <i className='fa-regular fa-bookmark' />
                                </a>{' '}
                                <a href='#'>
                                  <i className='fa-regular fa-paper-plane' />
                                </a>{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='swiper-control'>
                        <div className='swiper-button-prev cmty-vtab-prev' />
                        <div className='swiper-button-next cmty-vtab-next' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='review-module'>
                <div className='tab-review-module'>
                  <ul className='tab-menu'>
                    <li
                      data-tab='review-tab-module'
                      className={
                        activeTab === 'review-tab-module' ? 'active' : ''
                      }
                      onClick={() => handleTabClick('review-tab-module')} // Click handler
                    >
                      Reviews
                    </li>
                    <li
                      data-tab='recommendation-tab-module'
                      className={
                        activeTab === 'recommendation-tab-module'
                          ? 'active'
                          : ''
                      }
                      onClick={() =>
                        handleTabClick('recommendation-tab-module')
                      } // Click handler
                    >
                      Recommendations
                    </li>
                  </ul>
                  <div
                    id='review-tab-module'
                    className={`tab-content ${
                      activeTab === 'review-tab-module' ? 'active' : ''
                    }`}
                  >
                    <div className='row'>
                      <div className='col-sm-8'>
                        <div className='all-comment-rm'>
                          {reviewsData &&
                            reviewsData.map((review) => (
                              <div className='comment-rm' key={review.id}>
                                <div className='author-comment-rm'>
                                  <img
                                    src='/assets/images/user-1.png'
                                    alt='alt'
                                  />
                                  <h4>
                                    {review?.name}{' '}
                                    <small>
                                      Posted{' '}
                                      {moment(review?.created_at).fromNow()}
                                    </small>
                                  </h4>
                                </div>
                                <p>{review?.message}</p>
                              </div>
                            ))}
                          <div className='btn-load'>
                            <button onClick={handleLoadMore}>Load More</button>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-4'>
                        <div className='enquiry'>
                          <h3 className='mb-3'>Write a review</h3>
                          <form onSubmit={handleReviewSubmit}>
                            <input
                              type='text'
                              name='name_field_1'
                              placeholder='Your name'
                              required=''
                              value={reviewFormData?.name}
                              onChange={(e) =>
                                setReviewFormData((prev) => ({
                                  ...prev,
                                  name: e.target.value,
                                }))
                              }
                            />
                            <input
                              type='email'
                              name='email_field_1'
                              placeholder='Email id'
                              required=''
                              value={reviewFormData?.email}
                              onChange={(e) =>
                                setReviewFormData((prev) => ({
                                  ...prev,
                                  email: e.target.value,
                                }))
                              }
                            />
                            <textarea
                              name='message_field_1'
                              placeholder='Your message'
                              value={reviewFormData?.message}
                              onChange={(e) =>
                                setReviewFormData((prev) => ({
                                  ...prev,
                                  message: e.target.value,
                                }))
                              }
                            />
                            <div className='d-flex align-items-center mt-2 mb-3'>
                              {[1, 2, 3, 4, 5].map((star) => (
                                <i
                                  key={star}
                                  className={`fa fa-star ${
                                    star <= rating
                                      ? 'text-warning'
                                      : 'text-muted'
                                  } mx-1`}
                                  onClick={() => handleClick(star)}
                                  style={{ cursor: 'pointer' }}
                                ></i>
                              ))}
                              <span className='ml-2'>Rating: {rating} / 5</span>
                            </div>
                            <input type='submit' className='w-100' value='Submit' />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id='recommendation-tab-module'
                    className={`tab-content ${
                      activeTab === 'recommendation-tab-module' ? 'active' : ''
                    }`}
                  >
                    <div className='row'>
                      <div className='col-sm-8'>
                        <div className='all-comment-rm'>
                          {recommendationData &&
                            recommendationData?.length > 0 &&
                            recommendationData?.map((recommendation) => (
                              <div
                                className='comment-rm'
                                key={recommendation.id}
                              >
                                <div className='author-comment-rm'>
                                  <img
                                    src='/assets/images/user-1.png'
                                    alt='alt'
                                  />
                                  <h4>
                                    {recommendation?.name}{' '}
                                    <small>
                                      Posted{' '}
                                      {moment(
                                        recommendation?.created_at
                                      ).fromNow()}
                                    </small>
                                  </h4>
                                </div>
                                <p>{recommendation?.message}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className='col-sm-4'>
                        <div className='enquiry'>
                          <h3>Write a recommendation</h3>
                          <form onSubmit={handleRecommendationSubmit}>
                            <textarea
                              name='message_field_y'
                              placeholder='Your message'
                              value={recommendationFormData?.message}
                              onChange={(e) =>
                                setRecommendationFormData((prev) => ({
                                  ...prev,
                                  message: e.target.value,
                                }))
                              }
                            />
                            <input type='submit' value='Submit' />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='offer-slide'>
                <div className='swiper offer-slick'>
                  <div className='swiper-wrapper'>
                    <div className='swiper-slide'>
                      {' '}
                      <img src='/assets/images/offer-slider.jpg' alt='alt' />
                      <div className='caption-offer-slide'>
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className='swiper-slide'>
                      {' '}
                      <img src='/assets/images/offer-slider.jpg' alt='alt' />
                      <div className='caption-offer-slide'>
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className='swiper-slide'>
                      {' '}
                      <img src='/assets/images/offer-slider.jpg' alt='alt' />
                      <div className='caption-offer-slide'>
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className='swiper-slide'>
                      {' '}
                      <img src='/assets/images/offer-slider.jpg' alt='alt' />
                      <div className='caption-offer-slide'>
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className='swiper-slide'>
                      {' '}
                      <img src='/assets/images/offer-slider.jpg' alt='alt' />
                      <div className='caption-offer-slide'>
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                  </div>
                  <div className='swiper-pagination' />
                </div>
              </div>
              <div className='app-download mt-lg-5'>
                <div className='container'>
                  <div className='flex-app-download'>
                    <div className='row'>
                      <div className='col-sm-4'>
                        <img
                          src='/assets/images/app-download.png'
                          alt='app'
                          className='pic-app-download'
                        />
                      </div>
                      <div className='col-sm-8'>
                        <h2>
                          Available at{' '}
                          <small>
                            Lorem ipsum dolor sit amet consectetur adipiscing
                            elit
                          </small>
                        </h2>
                        <div className='btn-app-download'>
                          {' '}
                          <a href='#'>
                            <img
                              src='/assets/images/icons/android.svg'
                              alt='android'
                            />
                          </a>{' '}
                          <a href='#'>
                            <img src='/assets/images/icons/ios.svg' alt='ios' />
                          </a>{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Influencer;
