import { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { cloudFrontUrl } from "../../config";
import { Swiper, SwiperSlide } from "swiper/react";
const AttractionCategory = () => {
  const { request, response } = useRequest();
  const [category, setCategory] = useState();
  const fetchLandingCategory = async () => {
    await request("GET", "/public/attraction-category");
  };
  useEffect(() => {
    fetchLandingCategory();
  }, []);

  useEffect(() => {
    if (response) {
      setCategory(response.data);
    }
  }, [response]);
  return (
    <div className="category-attraction">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="heading-attraction-home">
              <h2 className="mb-3">Top Things to Do by category</h2>
              <p>Travelers Choice Best of the Best winners</p>
            </div>
            {/* <div className="swiper category-attraction-slick"> */}
              <Swiper
                spaceBetween={24}
                slidesPerView={4}
                speed={1000}
                loop={true}
                autoplay={{ delay: 99999999, disableOnInteraction: false }}
                breakpoints={{
                  320: { slidesPerView: 2, spaceBetween: 10 },
                  480: { slidesPerView: 2, spaceBetween: 10 },
                  768: { slidesPerView: 3, spaceBetween: 10 },
                  960: { slidesPerView: 4, spaceBetween: 24 },
                }}
              >
                {category &&
                  category.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="item-category-attraction">
                        <div className="imgeffect" style={{height : '100%'}}>
                          <a href="#">
                            <img
                              src={`${cloudFrontUrl}${item.image}`}
                              alt={item.image}
                              style={{height : '100%'}}
                            />
                          </a>
                        </div>
                        <h3>{item.headline}</h3>
                        <p>{item.description}</p>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttractionCategory;
