const DownloadApp = () => {
  return (
    <div class="app-download">
      <div class="row">
        <div class="col-sm-4">
          <img
            src="/assets/images/app-download.png"
            alt="app"
            class="pic-app-download"
          />
        </div>
        <div class="col-sm-8">
          <h2>
            Available at
            <small>
              Lorem ipsum dolor sit amet consectetur adipiscing elit
            </small>
          </h2>
          <div class="btn-app-download">
            <a href="#">
              <img src="/assets/images/icons/android.svg" alt="android" />
            </a>
            <a href="#">
              <img src="/assets/images/icons/ios.svg" alt="ios" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DownloadApp;
