import { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { cloudFrontUrl } from "../../config";

const HowItWorks = () => {
  const { request, response } = useRequest();
  const [masterVideo, setMasterVideo] = useState();
  const [videoURL, setVideoURL] = useState("");
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    (async () => {
      await request("GET", "/public/cms-how-it-works");
    })();
  }, []);

  useEffect(() => {
    if (response) {
      setMasterVideo(response.master_video);
      setVideos(response.videos);
    }
  }, [response]);

  const handleVideUrl = (url) => {
    setVideoURL(url);
  };

  return (
    <div className="howork-page main-container bg-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="video-howork">
              <div className="row">
                <div className="col-sm-12">
                  <div className="top-video-howork item-video-howork">
                    <button
                      data-bs-target="#video-modal"
                      data-bs-toggle="modal"
                      role="button"
                      onClick={() => handleVideUrl(masterVideo?.video_url)}
                    >
                      <img
                        src={`${cloudFrontUrl}${masterVideo?.cover_video_image}`}
                        className="w-100"
                        alt="banner"
                      />
                      <span>
                        <i className="fa-solid fa-play"></i>
                      </span>
                    </button>
                  </div>
                </div>

                {videos &&
                  videos.map((item, index) => (
                    <div key={index} className="col-sm-4">
                      <div className="item-video-howork">
                        <button
                          data-bs-target="#video-modal"
                          data-bs-toggle="modal"
                          role="button"
                          onClick={() => handleVideUrl(item?.video_url)}
                        >
                          <img
                            src={`${cloudFrontUrl}${item.cover_video_image}`}
                            alt={item.cover_video_image}
                            className="w-100"
                          />
                          <span>
                            <i className="fa-solid fa-play"></i>
                          </span>
                        </button>
                        <h3>{item.category_id}</h3>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="btn-load">
                <button>Load More</button>
              </div>
            </div>
            <div
              className="modal fade"
              id="video-modal"
              aria-hidden="true"
              aria-labelledby="video-modal"
              tabIndex="-1"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                  <div className="modal-body">
                    <iframe
                      src={videoURL}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>

            <div className="offer-slide">
              <div className="swiper offer-slick">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <img src="/assets/images/offer-slider.jpg" alt="alt" />
                    <div className="caption-offer-slide">
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <img src="/assets/images/offer-slider.jpg" alt="alt" />
                    <div className="caption-offer-slide">
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <img src="/assets/images/offer-slider.jpg" alt="alt" />
                    <div className="caption-offer-slide">
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <img src="/assets/images/offer-slider.jpg" alt="alt" />
                    <div className="caption-offer-slide">
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <img src="/assets/images/offer-slider.jpg" alt="alt" />
                    <div className="caption-offer-slide">
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </div>
            <div className="news-updates mt-5">
              <div className="heading">
                <h2>News & Updates</h2>
                <a href="#" className="btn-heading">
                  View All
                  <i className="fa-solid fa-chevron-right"></i>
                </a>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="swiper news-ml-slick">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/news/1.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget. Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/news/2.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget. Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/news/3.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget. Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/news/4.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget. Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/news/5.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget. Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/news/6.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget. Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/news/7.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget. Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-pagination"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
