import React, { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { useParams,NavLink } from "react-router-dom";

const Groups = () => {
  const {country,lang}=useParams();
  const [groupData, setGroupData] = useState([]);
  const { request: GroupRequest, response: groupResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
  useEffect(() => {
    GroupRequest("GET", "/community/all-groups");
  }, []);
  useEffect(() => {
    if (groupResponse && groupResponse.status === "SUCCESS") {
      setGroupData(groupResponse.data || {});
    }
  }, [groupResponse]);

  const PlaceholderImage = ({ name }) => {
    const firstChar = name.charAt(0).toUpperCase();
    return (
      <div className="placeholder-image" style={{
        width: "30px", /* Adjust as needed */
        height: "30px", /* Adjust as needed */
        backgroundColor: "#ccc", /* Placeholder background color */
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "6px", /* Make it circular */
        fontSize: "24px", /* Adjust font size */
        color: "white",
        marginRight:"23px" /* Text color */
      }}>
        {firstChar}
      </div>
    );
  };
  
  return (
    <>
      <div className="group-cmty box-community">
        <h2>Groups</h2>

        {groupData.length > 0 ? (
          groupData.map((group, index) => (
            <div key={index} className="item-group-cmty">
              <NavLink to={`/${country}/${lang}/community/groups/${group.slug}`}>
                {group.logo ? (
                              <img
                                src={`${cloudFrontUrl}${group.logo}`}
                                alt={group.name}
                              />
                            ) : (
                              <PlaceholderImage name={group.name} />
                            )}
              </NavLink>
              <h4>
                <NavLink to={`/${country}/${lang}/community/groups/${group.slug}`}>{group.name}</NavLink>
              </h4>
            </div>
          ))
        ) : (
          <p>No groups available</p>
        )}
      </div>
    </>
  );
};

export default Groups;
