import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useRequest from "../../hooks/useRequest";
import { cloudFrontUrl } from "../../config";

const VTabModule = ({ user }) => {
  const { country, lang, user_slug } = useParams();
  const [activeTab, setActiveTab] = useState("story");

  // Tabs states
  const [stories, setStories] = useState([]);
  const [reels, setReels] = useState([]);
  const [listings, setListings] = useState([]);
  const [agents, setAgents] = useState([]);

  // Tabs request
  const { request: storyRequest, response: storyResponse } = useRequest();
  const { request: reelRequest, response: reelResponse } = useRequest();
  const { request: listingRequest, response: listingResponse } = useRequest();
  const { request: agentRequest, response: agentResponse } = useRequest();

  // Tab change event to get data
  useEffect(() => {
    if (user && activeTab === "story") {
      storyRequest("GET", `/public/my-stories/CUSTOMER/${user?.id}`);
    }
    if (user && activeTab === "reel") {
      reelRequest("GET", `/public/my-reels/CUSTOMER/${user?.id}`);
    }
    if (user && activeTab === "listing") {
      listingRequest("GET", `/public/listings/${user?.id}`);
    }
    if (user && activeTab === "agent") {
      agentRequest("GET", `/public/agents/${user?.id}`);
    }
  }, [user, activeTab]);

  useEffect(() => {
    if (storyResponse && storyResponse.status === "SUCCESS") {
      setStories(storyResponse?.data);
    }
    if (reelResponse && reelResponse.status === "SUCCESS") {
      setReels(reelResponse?.data);
    }
    if (listingResponse && listingResponse.status === "SUCCESS") {
      setListings(listingResponse?.data);
    }
    if (agentResponse && agentResponse.status === "SUCCESS") {
      setAgents(agentResponse?.data);
    }
  }, [storyResponse, reelResponse, listingResponse, agentResponse]);

  // Handle active tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="social-activity">
      <div className="vtab">
        <ul className="vtab-menu">
          <li
            className={activeTab === "story" ? "active" : ""}
            onClick={() => handleTabChange("story")}
          >
            <img src="/assets/images/icons/story.svg" alt="story" /> MyStories
          </li>
          <li
            className={activeTab === "reel" ? "active" : ""}
            onClick={() => handleTabChange("reel")}
          >
            <img src="/assets/images/icons/reels.svg" alt="reels" /> MyReels
          </li>
          <li
            className={activeTab === "listing" ? "active" : ""}
            onClick={() => handleTabChange("listing")}
          >
            <img src="/assets/images/icons/listings.svg" alt="listings" />{" "}
            MyListing
          </li>
          <li
            className={activeTab === "agent" ? "active" : ""}
            onClick={() => handleTabChange("agent")}
          >
            <img src="/assets/images/icons/users.svg" alt="user" /> MyAgents
          </li>
          <li
            className={activeTab === "live" ? "active" : ""}
            onClick={() => handleTabChange("live")}
          >
            <img src="/assets/images/icons/live.svg" alt="live" /> MyLive
          </li>
          <li
            className={activeTab === "community" ? "active" : ""}
            onClick={() => handleTabChange("community")}
          >
            <img src="/assets/images/icons/community.svg" alt="community" />{" "}
            MyCommunity
          </li>
        </ul>
        <div className="wraper-vtab">
          <div
            id="mystories-vtab"
            className={`vtab-content ${activeTab === "story" ? "active" : ""}`}
          >
            <Swiper
              spaceBetween={10}
              slidesPerView={3}
              navigation={{
                prevEl: ".story-vtab-prev",
                nextEl: ".story-vtab-next",
              }}
            >
              {stories.map((story, index) => (
                <SwiperSlide key={index}>
                  <div className="item-story">
                    <div className="cap-item-story">
                      <div className="date-item-story">{story.date}</div>
                    </div>
                    <div className="imgeffect">
                      <img src={cloudFrontUrl + story.image} alt="alt" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-control">
              <div className="swiper-button-prev story-vtab-prev">
                <i class="fa-solid fa-arrow-left"></i>{" "}
              </div>
              <div className="swiper-button-next story-vtab-next">
                <i class="fa-solid fa-arrow-right"></i>{" "}
              </div>
            </div>
          </div>

          {/* Reel Tab */}
          <div
            id="myreels-vtab"
            className={`vtab-content ${activeTab === "reel" ? "active" : ""}`}
          >
            <Swiper
              spaceBetween={10}
              slidesPerView={3}
              navigation={{
                prevEl: ".reels-vtab-prev",
                nextEl: ".reels-vtab-next",
              }}
            >
              {reels.map((reel, index) => (
                <SwiperSlide key={index}>
                  <div className="item-story">
                    <div className="cap-item-story">
                      <div className="date-item-story">{reel.date}</div>
                    </div>
                    <div className="imgeffect">
                      <img src={cloudFrontUrl + reel.image} alt="alt" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-control">
              <div className="swiper-button-prev reels-vtab-prev">
                <i class="fa-solid fa-arrow-left"></i>
              </div>
              <div className="swiper-button-next reels-vtab-next">
                <i class="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </div>

          {/* Listing Tab */}
          <div
            id="mylisting-vtab"
            className={`vtab-content ${
              activeTab === "listing" ? "active" : ""
            }`}
          >
            <Swiper
              spaceBetween={10}
              slidesPerView={3}
              navigation={{
                prevEl: ".listing-vtab-prev",
                nextEl: ".listing-vtab-next",
              }}
            >
              {listings &&
                listings.map((data, index) => (
                  <SwiperSlide key={index}>
                    <div className="listing-vtab">
                      <div className="imgeffect">
                        <div className="ib-date">1 days ago</div>
                        <Link
                          to={`/${country}/${lang}/company/${user_slug}/listing/${data?.slug}`}
                        >
                          <img
                            src={
                              data?.galleries.length > 0
                                ? cloudFrontUrl + data?.galleries[0].image
                                : ""
                            }
                            alt="alt"
                          />
                        </Link>
                      </div>
                      <label>{data?.category?.name}</label>
                      <h3>
                        <Link
                          to={`/${country}/${lang}/company/${user_slug}/listing/${data?.slug}`}
                        >
                          {data?.title}
                        </Link>
                      </h3>
                      <p>{data?.description}</p>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-control">
              <div className="swiper-button-prev listing-vtab-prev">
                <i class="fa-solid fa-arrow-left"></i>
              </div>
              <div className="swiper-button-next listing-vtab-next">
                <i class="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </div>

          {/* Agents Tab */}
          <div
            id="myagents-vtab"
            className={`vtab-content ${activeTab === "agent" ? "active" : ""}`}
          >
            <Swiper
              spaceBetween={10}
              slidesPerView={3}
              navigation={{
                prevEl: ".agent-vtab-prev",
                nextEl: ".agent-vtab-next",
              }}
            >
              {agents &&
                agents.map((data, index) => (
                  <SwiperSlide key={index}>
                    <div className="listing-vtab">
                      <div className="imgeffect">
                        <div className="ib-date">1 days ago</div>
                        <a href="#">
                          <img src={cloudFrontUrl + data?.logo} alt="alt" />
                        </a>
                      </div>
                      <label>Agent</label>
                      <h3>
                        <a href="#">{data?.name}</a>
                      </h3>
                      <p>{data?.description}</p>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-control">
              <div className="swiper-button-prev agent-vtab-prev">
                <i class="fa-solid fa-arrow-left"></i>
              </div>
              <div className="swiper-button-next agent-vtab-next">
                <i class="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </div>

          {/* Live Tab */}
          <div
            id="mylive-vtab"
            className={`vtab-content ${activeTab === "live" ? "active" : ""}`}
          >
            <div className="swiper live-slick-vtab">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="item-mylive">
                    <div className="imgeffect">
                      <a href="#">
                        <img src="/assets/images/reels-1.jpg" alt="mylive" />
                      </a>
                      <div className="cap-item-mylive">
                        <div className="btns-mylive">
                          <a href="#">
                            <i className="fa-brands fa-youtube"></i> Live
                          </a>
                          <span>
                            <i className="fa-solid fa-eye"></i>10.8K
                          </span>
                        </div>
                        <small className="date-item-mylive">1 days ago</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item-mylive">
                    <div className="imgeffect">
                      <a href="#">
                        <img src="/assets/images/reels-2.jpg" alt="mylive" />
                      </a>
                      <div className="cap-item-mylive">
                        <div className="btns-mylive">
                          <a href="#">
                            <i className="fa-brands fa-youtube"></i> Live
                          </a>
                          <span>
                            <i className="fa-solid fa-eye"></i>10.8K
                          </span>
                        </div>
                        <small className="date-item-mylive">1 days ago</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item-mylive">
                    <div className="imgeffect">
                      <a href="#">
                        <img src="/assets/images/reels-1.jpg" alt="mylive" />
                      </a>
                      <div className="cap-item-mylive">
                        <div className="btns-mylive">
                          <a href="#">
                            <i className="fa-brands fa-youtube"></i> Live
                          </a>
                          <span>
                            <i className="fa-solid fa-eye"></i>10.8K
                          </span>
                        </div>
                        <small className="date-item-mylive">1 days ago</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item-mylive">
                    <div className="imgeffect">
                      <a href="#">
                        <img src="/assets/images/reels-2.jpg" alt="mylive" />
                      </a>
                      <div className="cap-item-mylive">
                        <div className="btns-mylive">
                          <a href="#">
                            <i className="fa-brands fa-youtube"></i> Live
                          </a>
                          <span>
                            <i className="fa-solid fa-eye"></i>10.8K
                          </span>
                        </div>
                        <small className="date-item-mylive">1 days ago</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-control">
              <div className="swiper-button-prev live-vtab-prev">
                <i class="fa-solid fa-arrow-left"></i>
              </div>
              <div className="swiper-button-next live-vtab-next">
                <i class="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </div>

          {/* Community Tab */}
          <div
            id="mycommunity-vtab"
            className={`vtab-content ${
              activeTab === "community" ? "active" : ""
            }`}
          >
            <div className="swiper cmty-slick-vtab">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="sm-cmty">
                    <h3>
                      <a href="#">Dribble Shots Community</a> <small>5h</small>
                    </h3>
                    <p>
                      MyFinder leading <a href="#">@SearchPortal</a> that makes
                      it easy to find, possibly in the
                      <a href="#">@smartest</a> way.
                    </p>
                    <div className="imgeffect">
                      <a href="#">
                        <img src="/assets/images/offer.jpg" alt="alt" />
                      </a>
                    </div>
                    <div className="btn-sm-cmty">
                      <a href="#">
                        <i className="fa-regular fa-heart"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-comment"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-bookmark"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-paper-plane"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sm-cmty">
                    <h3>
                      <a href="#">Dribble Shots Community</a> <small>5h</small>
                    </h3>
                    <p>
                      MyFinder leading <a href="#">@SearchPortal</a> that makes
                      it easy to find, possibly in the
                      <a href="#">@smartest</a> way.
                    </p>
                    <div className="imgeffect">
                      <a href="#">
                        <img src="/assets/images/offer.jpg" alt="alt" />
                      </a>
                    </div>
                    <div className="btn-sm-cmty">
                      <a href="#">
                        <i className="fa-regular fa-heart"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-comment"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-bookmark"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-paper-plane"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sm-cmty">
                    <h3>
                      <a href="#">Dribble Shots Community</a> <small>5h</small>
                    </h3>
                    <p>
                      MyFinder leading <a href="#">@SearchPortal</a> that makes
                      it easy to find, possibly in the
                      <a href="#">@smartest</a> way.
                    </p>
                    <div className="imgeffect">
                      <a href="#">
                        <img src="/assets/images/offer.jpg" alt="alt" />
                      </a>
                    </div>
                    <div className="btn-sm-cmty">
                      <a href="#">
                        <i className="fa-regular fa-heart"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-comment"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-bookmark"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-paper-plane"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sm-cmty">
                    <h3>
                      <a href="#">Dribble Shots Community</a> <small>5h</small>
                    </h3>
                    <p>
                      MyFinder leading <a href="#">@SearchPortal</a> that makes
                      it easy to find, possibly in the
                      <a href="#">@smartest</a> way.
                    </p>
                    <div className="imgeffect">
                      <a href="#">
                        <img src="/assets/images/offer.jpg" alt="alt" />
                      </a>
                    </div>
                    <div className="btn-sm-cmty">
                      <a href="#">
                        <i className="fa-regular fa-heart"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-comment"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-bookmark"></i>
                      </a>
                      <a href="#">
                        <i className="fa-regular fa-paper-plane"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-control">
              <div className="swiper-button-prev cmty-vtab-prev">
                <i class="fa-solid fa-arrow-left"></i>
              </div>
              <div className="swiper-button-next cmty-vtab-next">
                <i class="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VTabModule;
