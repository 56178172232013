import "./Auth.css";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from "../../utils/ToastNotification";
import useRequest from "../../hooks/useRequest";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import FacebookLogin from "../../components/FacebookLogin";
import { useSearch } from "../../contexts/SearchContext";

const SocialLogin = () => {
  const { country, lang } = useParams();
  const navigate = useNavigate();
  const { selectedRoute, setSelectedRoute } = useSearch();
  const [userRoleType, setUserRoleType] = useState("CUSTOMER");

  useEffect(() => {
    if (selectedRoute === "job" || selectedRoute==="jobDetails") {
      setUserRoleType("GUEST");
    } else {
      setUserRoleType("CUSTOMER");
    }
  }, []);

  console.log("Route value is ", selectedRoute);
  const { request: googleCallbackRequest } = useRequest();
  const { request: facebookCallbackRequest } = useRequest();
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    console.log("Credentials are ========== >>>>>>>>>>>> ", credentialResponse);
    console.log("Google Token:", token);

    const decodedToken = jwtDecode(token);
    const { name, email } = decodedToken;

    console.log("User Name:", name);
    console.log("User Email:", email);

    const data = {
      user_role_type: userRoleType,
      social_type: "GOOGLE",
      social_id: token,
      name,
      username: name,
      email,
      device_type: "WEB",
      device_id: "",
    };
    console.log(data);

    googleCallbackRequest("POST", `/auth/social-login`, data);

    // Optionally send the token to the backend for further processing
    // googleCallbackRequest('POST', `/auth/social-login`);

    // Show a success toast
    Toast(true, `Google login successful! Welcome, ${name}`);

    // Save user data (optional)
    localStorage.setItem("user_name", name);
    localStorage.setItem("user_email", email);
  };

  // Handle Google Login Failure
  const handleGoogleLoginFailure = (error) => {
    console.error("Google Login Failed:", error);
    Toast(false, "Google login failed. Please try again.");
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginFailure,
  });

  // Handle Facebook Login Success
  const handleFacebookLoginSuccess = async (accessToken) => {
    facebookCallbackRequest(
      "GET",
      `/auth/facebook/callback?code=${accessToken}`
    );
    Toast(true, "Facebook login successful!");
  };

  // Handle Facebook Login Failure
  const handleFacebookLoginFailure = (error) => {
    Toast(false, "Facebook login failed. Please try again.");
  };

  return (
    <div className="social-login">
      {/* Google OAuth Login */}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {/* <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginFailure}
        /> */}
        <a href="javascript:void(0);" className="google">
          <img src="/assets/images/icons/google-icon.png" alt="google" />
          Google
        </a>
      </GoogleOAuthProvider>

      {/* Facebook OAuth Login */}
      <a href="javascript:void(0);">
        <i class="fa-brands fa-apple apple"></i> Apple
      </a>
      <FacebookLogin
        onLoginSuccess={handleFacebookLoginSuccess}
        onLoginFailure={handleFacebookLoginFailure}
      />
    </div>
  );
};

export default SocialLogin;
