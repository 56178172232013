import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../hooks/useRequest';
import { useSearch } from '../../contexts/SearchContext';

const Home = () => {
  const { country, lang } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const { selectedCategory, setSelectedCategory, searchValue, setSearchValue } =
    useSearch();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const { request: categoryRequest, response: categoryResponse } = useRequest();

  const handleToggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  const handleOptionClick = (data) => {
    console.log(data);
    setSelectedCategory(data);
    setIsDropdownVisible(false);
    navigate(`/${country}/${lang}/jobListing`);
  };

  const categoryRoutes = {
    jobs: 'joblisting',
    influencer: 'influencers',
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const selectedSlug =
      selectedCategory.slug === 'all' ? null : selectedCategory.slug;
    const route = selectedSlug ? categoryRoutes[selectedSlug] : null;

    if (searchValue) {
      navigate(`/${country}/${lang}/search`, {
        state: { category: selectedSlug, search: searchValue },
      });
    } else if (route) {
      navigate(`/${country}/${lang}/${route}`);
    } else {
      navigate(`/${country}/${lang}/search`, {
        state: { category: selectedSlug },
      });
    }
  };

  useEffect(() => {
    if (categoryResponse && categoryResponse.status === 'SUCCESS') {
      setCategories(categoryResponse.data);
    }
  }, [categoryResponse]);

  useEffect(() => {
    categoryRequest('GET', '/public/category');
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className='slider h-screen'>
      <div className='swiper primary-slider'>
        <div className='swiper-wrapper'>
          <div className='swiper-slide'>
            {' '}
            <img src='/assets/images/slider.jpg' alt='alt' />{' '}
          </div>
          <div className='swiper-slide'>
            {' '}
            <img src='/assets/images/slider.jpg' alt='alt' />{' '}
          </div>
        </div>
      </div>
      <div className='caption-slider'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-sm-10'>
              <div className='search-fp'>
                <h2>
                  AI Help You Find, <span>Anything to Everything!</span>
                </h2>
                <div className='flex-search-fp'>
                  <div className='sb-search-fp'>
                    <div className='all-search' ref={dropdownRef}>
                      <a
                        className='pr-5 btn-all-search'
                        style={{ color: 'black' }}
                        onClick={handleToggleDropdown}
                      >
                        {selectedCategory?.name || selectedCategory}
                        <i
                          className={`fa-solid fa-caret-down arrow-all-dropdown ${
                            isDropdownVisible ? 'arrow-all-dropdown-hide' : ''
                          }`}
                        ></i>
                        <i
                          className={`fa-solid fa-xmark close-all-dropdown ${
                            isDropdownVisible ? 'close-all-dropdown-show' : ''
                          }`}
                        ></i>
                      </a>
                      <ul
                        className='all-dropdown mt-7 h-[35vh] overflow-auto'
                        style={{
                          display: isDropdownVisible ? 'block' : 'none',
                        }}
                      >
                        <li style={{ color: 'black' }}>
                          <a
                            href='javascript:void(0);'
                            onClick={() =>
                              handleOptionClick({ name: 'All', slug: 'all' })
                            }
                          >
                            All
                          </a>
                        </li>
                        {categories.map((data, index) => (
                          <li key={index} className='mt-10'>
                            <a
                              href='javascript:void(0);'
                              onClick={() => handleOptionClick(data)}
                              style={{ textTransform: 'capitalize' }}
                            >
                              {/* <img
                                src={`/assets/images/icons/${data.slug}.svg`}
                                alt={data.slug}
                                draggable='false'
                              /> */}
                              {data.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <form onSubmit={handleSearchSubmit}>
                      <input
                        type='text'
                        name='search'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder='Search here...'
                      />
                      <div className='btn-searchbox'>
                        {' '}
                        <a href='#'>
                          <img
                            src='/assets/images/icons/voice.svg'
                            alt='voice'
                            onDragStart={() => false}
                          />
                        </a>{' '}
                      </div>
                      <button type=' '>
                        <img
                          src='/assets/images/icons/submit-arrow.svg'
                          alt='submit'
                          className='h-60 w-40 object-contain'
                        />
                      </button>
                    </form>
                  </div>
                  <div className='ai-search-fp ai-img flex justify-items-center items-center text-center object-contain mx-2 w-24 h-20 rounded-full bg-white'>
                    <img
                      src='/assets/images/ai/fredo.png'
                      alt='ai'
                      className="object-contain rounded-full border-1 border-blue-700"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
