import React, { useEffect, useState } from "react";
import { facebookAppId } from "../config";

const FacebookLogin = ({ onLoginSuccess, onLoginFailure }) => {
    const [isFbSdkReady, setFbSdkReady] = useState(false);

    useEffect(() => {
        // Load the Facebook SDK for JavaScript
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: "v12.0",
            });
            setFbSdkReady(true); // Set SDK as ready
        };

        // Load the Facebook SDK script
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }, []);

    const handleFacebookLogin = () => {
        if (!isFbSdkReady) {
            onLoginFailure("Facebook SDK not initialized yet.");
            return;
        }

        window.FB.login(
            function (response) {
                if (response.authResponse) {
                    const accessToken = response.authResponse.accessToken;
                    onLoginSuccess(accessToken);
                } else {
                    onLoginFailure("User cancelled login or did not fully authorize.");
                }
            },
            { scope: "public_profile,email" }
        );
    };

    return (
        <a href="javascript:void(0);" onClick={handleFacebookLogin} id="facebookLoginButton">
            <i className="fa-brands fa-facebook-f fb"></i>Facebook
        </a>
    );
};

export default FacebookLogin;
