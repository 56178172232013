import React, { useEffect, useState } from "react";
import MyReel from "../../../components/Common/Community/MyReel";
import Author from "../../../components/Common/Community/Author";
import RightSideNav from "../../../components/Common/Community/SideNav";
import Groups from "../../../components/Common/Community/Groups";
import Recomandation from "../../../components/Common/Community/Recomandation";
import Trendings from "../../../components/Common/Community/Trendings";
import useRequest from "../../../hooks/useRequest";
import { NavLink, useParams } from "react-router-dom";

const CommunityGroup = () => {
  const { country, lang } = useParams();
  const [admindata, setAdminData] = useState({});
  const { request: adminRequest, response: adminResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    adminRequest("GET", "/groups");
  }, []);

  useEffect(() => {
    if (adminResponse && adminResponse.status === "SUCCESS") {
      setAdminData(adminResponse.data || {});
    }
  }, [adminResponse]);

  // Filter public and private groups from group_admins
  const publicGroupsAdmin =
    admindata.group_admins?.filter((group) => group.privacy === "PUBLIC") || [];

  const privateGroupsJoined =
    admindata.group_admins?.filter((group) => group.privacy === "PRIVATE") ||
    [];

    const PlaceholderImage = ({ name }) => {
      const firstChar = name.charAt(0).toUpperCase();
      return (
        <div className="placeholder-image" style={{
          width: "70px", /* Adjust as needed */
          height: "70px", /* Adjust as needed */
          backgroundColor: "#ccc", /* Placeholder background color */
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "12px", /* Make it circular */
          fontSize: "24px", /* Adjust font size */
          color: "white" /* Text color */
        }}>
          {firstChar}
        </div>
      );
    };
    
  return (
    <>
      <div>
        <MyReel />
        <Author />
        <div className="main-container">
          <div className="container">
            <div className="row">
              <div className="col-sm-3">
                <RightSideNav />
                <Groups />
                <Recomandation />
                <Trendings />
              </div>
              <div className="col-sm-9">
                <div className="groups-page">
                  <div className="btn-groups">
                    <ul>
                      <li>
                        <NavLink to={`/${country}/${lang}/community`}>
                          <i className="fa-regular fa-comment-dots" /> Your Feed
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/${country}/${lang}/community/groups`}
                          className="active"
                        >
                          <i className="fa-solid fa-user-group" /> Your Groups
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/${country}/${lang}/community/creategroup`}
                        >
                          <i className="fa-solid fa-plus" /> Create New Group
                        </NavLink>
                      </li>
                    </ul>
                    <div className="search-cmty">
                      <form>
                        <i className="fa-solid fa-magnifying-glass" />
                        <input
                          type="text"
                          name="name"
                          placeholder="Search Friends"
                        />
                      </form>
                    </div>
                  </div>

                  {/* Section to show Public Groups */}
                  <div className="admin-groups">
                    <h2 className="heading-community">
                      Public Groups (Admin) ({publicGroupsAdmin.length})
                    </h2>
                    <div className="flex-admin-groups">
                      {publicGroupsAdmin.length > 0 ? (
                        publicGroupsAdmin.map((group, index) => (
                          <div key={index} className="item-admin-groups">
                            {group.logo ? (
                              <img
                                src={`${cloudFrontUrl}${group.logo}`}
                                alt={group.name}
                              />
                            ) : (
                              <PlaceholderImage name={group.name} />
                            )}

                            <div className="info-admin-groups">
                              <h3>
                                <NavLink
                                  to={`/${country}/${lang}/community/groups/${group.slug}`}
                                >
                                  {group.name}
                                </NavLink>
                              </h3>
                              <h4>
                                <i className="fa-solid fa-earth-oceania" />
                                {group.privacy}
                              </h4>
                              <p>{group.description}</p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No Public Admin groups available.</p>
                      )}
                    </div>
                  </div>

                  {/* Section to show Private Groups */}
                  <div className="admin-groups">
                    <h2 className="heading-community">
                      Private Groups (Admin) ({privateGroupsJoined.length})
                    </h2>
                    <div className="flex-admin-groups">
                      {privateGroupsJoined.length > 0 ? (
                        privateGroupsJoined.map((group, index) => (
                          <div key={index} className="item-admin-groups">
                            {group.logo ? (
                              <img
                                src={`${cloudFrontUrl}${group.logo}`}
                                alt={group.name}
                              />
                            ) : (
                              <PlaceholderImage name={group.name} />
                            )}
                            <div className="info-admin-groups">
                              <h3>
                                <NavLink
                                  to={`/${country}/${lang}/community/groups/${group.slug}`}
                                >
                                  {group.name}
                                </NavLink>
                              </h3>
                              <h4>
                                <i className="fa-solid fa-earth-oceania" />
                                {group.privacy}
                              </h4>
                              <p>{group.description}</p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No Private Admin groups available.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunityGroup;
