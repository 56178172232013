import React, { useState } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { dashboardUrl } from '../../config';
import { useSearch } from '../../contexts/SearchContext';
import AiTools from '../../pages/ai-tools/AiTools';

const HomeHeader = () => {
  const { country, lang } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { setSelectedCategory } = useSearch();
  const isLogin = Boolean(localStorage.getItem('access_token'));

  const handleDashboardClick = () => {
    if (isLogin) {
      window.open(dashboardUrl, '_blank');
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem('access_token');
    navigate(`/${country}/${lang}`);
  };

  const handleModal = () => {
    setShowModal(true);
  };

  return (
    <header className='header-home'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='flex-wraper'>
              <div className='logo'>
                <Link to={`/${country}/${lang}`}>
                  <img src='/assets/images/logo.svg' alt='MyFinder' />
                </Link>
              </div>
              <div className='menubar'>
                <ul>
                  {/* <li>
                    <NavLink
                      onClick={() =>
                        setSelectedCategory({
                          name: 'jobs',
                          icon: 'category/icon/20240923_120652_jobs.png',
                          is_active: true,
                          id: 4,
                          slug: 'jobs-1',
                          created_at: '2024-08-01T10:18:45.536994+00:00',
                          updated_at: '2024-09-23T12:06:52.090095+00:00',
                        })
                      }
                      to={`/${country}/${lang}/joblisting`}
                      className={({ isActive }) =>
                        isActive ? 'active' : 'link-menubar'
                      }
                    >
                      Jobs
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink
                      onClick={() =>
                        setSelectedCategory({
                          icon: 'category/icon/20240923_120703_influencer.png',
                          id: 5,
                          is_active: true,
                          name: 'Influencers',
                          slug: 'influencers',
                        })
                      }
                      to={`/${country}/${lang}/joblisting`}
                      className={({ isActive }) =>
                        isActive ? 'active' : 'link-menubar'
                      }
                    >
                      Influencers
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      onClick={() =>
                        setSelectedCategory({
                          icon: 'category/icon/20240923_120821_buy-sell.png',
                          id: 11,
                          is_active: true,
                          name: 'buy&sell',
                          slug: 'buy-sell-1',
                        })
                      }
                      to={`/${country}/${lang}/joblisting`}
                      className={({ isActive }) =>
                        isActive ? 'active' : 'active link-menubar'
                      }
                    >
                      List &amp; Sell
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/${country}/${lang}/community`}
                      className={({ isActive }) =>
                        isActive ? 'active' : 'link-menubar'
                      }
                    >
                      MyCommunity
                    </NavLink>
                  </li>
                  <li><NavLink 
                    to={`/${country}/${lang}/attraction`}
                    className={({ isActive }) =>
                      isActive ? 'active' : 'link-menubar'
                    }
                    >Attractions</NavLink></li>
                  <li className=''>
                    <button
                      onClick={handleModal}
                      // className='!bg-white flex items-center justify-center px-2 py-[3px] rounded-[15px]'
                      className='link-menubar'
                    >
                      AI Tools
                    </button>
                  </li>
                  <li>
                    <NavLink
                      to={`/${country}/${lang}/how-it-works`}
                      className={({ isActive }) =>
                        isActive ? 'active' : 'link-menubar'
                      }
                    >
                      How it Work?
                    </NavLink>
                  </li>
                  <li className='signin-menubar'>
                    {isLogin ? (
                      <>
                        <a
                          href='#'
                          className='link-menubar'
                          onClick={handleDashboardClick}
                        >
                          Dashboard
                        </a>
                      </>
                    ) : (
                      <>
                        <Link
                          to={`/${country}/${lang}/auth/login`}
                          className='active link-menubar'
                        >
                          Sign In
                        </Link>
                        <Link
                          to={`/${country}/${lang}/auth/signup`}
                          className='link-menubar'
                        >
                          Sign Up
                        </Link>
                      </>
                    )}
                  </li>
                  {/* Separate Sign Out Link */}
                  {isLogin && (
                    <li className='signout-menubar'>
                      <a
                        href='#'
                        className='link-menubar'
                        onClick={handleSignOut}
                      >
                        Sign Out
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && <AiTools setShowModal={setShowModal} />}
    </header>
  );
};

export default HomeHeader;
