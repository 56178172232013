import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useRequest from "../../../hooks/useRequest";

const Author = () => {
  const [authorData, setAuthorData] = useState([]);
  const { request: authorRequest, response: authorResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    authorRequest("GET", "/community/top-profiles");
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (authorResponse && authorResponse.status === "SUCCESS") {
      setAuthorData(authorResponse.data || {});
    }
  }, [authorResponse]);

  const settings = {
    items: 12,
    loop: true,
    autoplay: true,
    AnimationTimeline: 2000,
    animateOut: "slideOutUp",
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      1100: {
        items: 12,
      },
      724: {
        items: 7,
      },
      500: {
        items: 5,
      },
      370: {
        items: 3,
      },
    },
  };
  return (
    <>
      <div className="author-slide">
        <OwlCarousel className="owl-theme" {...settings}>
          {authorData.length > 0 ? (
            authorData.map((author) => (
              <div key={author.id} className="item-author-slide">
                <a href={`/profile/${author.slug}`}>
                  <img
                    src={
                      author.user_profile?.logo
                        ? `${cloudFrontUrl}${author.user_profile.logo}`
                        : "/assets/images/author/1.jpg"
                    }
                    alt={author.name}
                  />
                  <i className="fa-solid fa-check" />
                </a>
              </div>
            ))
          ) : (
            <p>No authors found.</p>
          )}
        </OwlCarousel>
      </div>
    </>
  );
};

export default Author;
// /assets/images/author/1.jpg
