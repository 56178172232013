import './Auth.css';
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Toast } from '../../utils/ToastNotification';
import useRequest from '../../hooks/useRequest';
import { EmailContext } from '../../utils/EmailContext';
import SocialLogin from './SocialLogin';
import SocialLoginMain from './SocialLoginMain';
import { useSearch } from '../../contexts/SearchContext';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import AuthSubHeader from '../../components/Auth';

const SignUp = () => {
  const { country, lang } = useParams();
  const navigate = useNavigate();
  const { setEmail } = useContext(EmailContext);
  const { selectedRoute, formDataValues } = useSearch();

  const [userRoleType, setUserRoleType] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCode, setPhoneCode] = useState('');

  const [formData, setFormData] = useState({
    user_role_type: 'GUEST',
    name: '',
    username: '',
    password: '',
    mobile: '',
    email: '',
    policy: false,
  });

  useEffect(() => {
    setUserRoleType('GUEST');
  }, [selectedRoute]);

  const { request: signupRequest, response: signupResponse } = useRequest();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneChange = (e) => {
    console.log(e);
    const value = typeof e === 'string' ? e : e.target.value;
    console.log(value);
    setPhoneNumber(value);
    setFormData((prev) => ({
      ...prev,
      mobile: value,
    }));
  };

  const handlePhoneCodeChange = (e) => {
    setPhoneCode(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to backend
    if (formData.policy) {
      signupRequest('POST', '/auth/register', {
        ...formData,
        mobile: `${phoneCode}${phoneNumber}`,
      });
    } else {
      Toast(false, 'Please accept privacy and policy!');
    }
  };

  useEffect(() => {
    if (signupResponse && signupResponse?.status === 'SUCCESS') {
      Toast(true, signupResponse?.message);
      if (!signupResponse.data?.is_email_vverify) {
        setEmail(formData.email);
        handleNavigation(`/${country}/${lang}/auth/otp-verification`);
      }
    } else {
      Toast(false, signupResponse?.message);
    }
  }, [signupResponse]);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, user_role_type: userRoleType }));
  }, [userRoleType]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className='tab-signin'>
      <div className='flex-tab-menu'>
        <AuthSubHeader />
      </div>
      <div id='signup-tab' className='tab-content active'>
        <form onSubmit={handleSubmit}>
          <div className='border border-[#BFBFBF] field-signin rounded-md flex items-center'>
            <i className='fa-solid fa-user'></i>
            <select
              className='poppins-medium text-black placeholder-style'
              name='User Type Category'
              value={formData?.user_role_type}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  user_role_type: e.target.value,
                }))
              }
            >
              <option
                className='poppins-medium text-black placeholder-style'
                value='GUEST'
              >
                Register as a GUEST/VISITOR
              </option>
              <option
                className='poppins-medium text-black placeholder-style'
                value='CUSTOMER'
              >
                Register as an Individual/Company
              </option>
            </select>
          </div>

          <div className='border border-[#BFBFBF] py-2 font-regular field-signin'>
            <i className='fa-solid fa-user'></i>
            <input
              type='text'
              placeholder='Type Name'
              name='name'
              className='poppins-medium  text-black placeholder-style'
              value={formData?.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className='border border-[#BFBFBF] py-2 field-signin'>
            <i className='fa-solid fa-user'></i>
            <input
              type='text'
              placeholder='Type Username'
              name='username'
              className='poppins-medium  text-black placeholder-style'
              value={formData?.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className='border border-[#BFBFBF] py-2 field-signin'>
            <i className='fa-solid fa-lock'></i>
            <input
              type='password'
              placeholder='Type Password'
              name='password'
              className='poppins-medium  text-black placeholder-style'
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          {/* <div className='border border-[#BFBFBF] py-1 field-signin mobile relative'>
            <PhoneInput
              country={'ae'}
              placeholder='Mobile'
              className='poppins-medium text-black placeholder-style'
              value={phoneNumber}
              disableCountryCode={false} // Show country code
              preferredCountries={['ae', 'us', 'in']} // Preferred countries
              autoFormat={true}
              onChange={handlePhoneChange}
              inputProps={{
                name: 'mobile',
                required: true,
                style: {
                  border: 'none',
                  outline: 'none',
                },
              }}
              containerClass='w-full' // Full width
              buttonClass='bg-gray-200 pl-2 pr-4 flex items-center border-r border-gray-300' // Flex container for the flag and code
            />
          </div> */}

          <div className='border border-[#BFBFBF] flex items-center rounded-md mb-2.5'>
            <div className='flex items-center justify-center bg-gray-300 px-2 h-11 w-24'>
              <PhoneInput
                country={'ae'}
                placeholder='Mobile'
                // value={phoneNumber}
                // onChange={handlePhoneChange}
                onChange={handlePhoneCodeChange}
                disableCountryCode={false}
                inputClass='hidden'
                buttonClass='flex items-center space-x-2'
                dropdownClass='custom-dropdown'
              />

              <span className='text-black font-medium'>
                +{phoneCode || '971'}
              </span>
            </div>

            <input
              type='text'
              placeholder='Mobile'
              name='phone'
              value={phoneNumber}
              className='poppins-medium input-mobile pt-2.5 pl-5 w-full placeholder-style'
              onChange={handlePhoneChange}
              required
            />
          </div>

          <div className='border border-[#BFBFBF] py-2 field-signin'>
            <i className='fa-solid fa-envelope'></i>
            <input
              type='email'
              placeholder='Email*'
              name='email'
              className='poppins-medium  text-black placeholder-style'
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className='accept-signin'>
            <input
              type='checkbox'
              id='policy'
              name='policy'
              value='Policy'
              checked={formData.policy}
              onChange={handleChange}
            />
            <label for='policy'>
              I agree to the
              <Link
                to={`/${country}/${lang}/help-center`}
                style={{ color: '#FF10F0' }}
              >
                <span> Terms & Conditions </span>
              </Link>
              <span> and </span>
              <Link
                to={`/${country}/${lang}/help-center`}
                style={{ color: '#FF10F0' }}
              >
                Privacy Policy
              </Link>
            </label>
          </div>
          <input type='submit' value='Sign Up' />
        </form>
        <div className='breaker'> Sign Up with Social Media</div>
        <SocialLoginMain page='signUp' />
      </div>
    </div>
  );
};
export default SignUp;
