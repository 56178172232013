import { useState } from "react";

const ReviewModule = () => {
  const [activeTab,setActiveTab] = useState(true);
  return (
    <div className="review-module">
      <div className="tab-review-module">
        <ul className="tab-menu">
          <li data-tab="review-tab-module" onClick={()=> setActiveTab(true)} className={activeTab ? 'active' : ''}>
            Reviews
          </li>
          <li data-tab="recommendation-tab-module" onClick={()=> setActiveTab(false)} className={!activeTab ? 'active' : ''}>Recommendations</li>
        </ul>
        <div id="review-tab-module" className={`tab-content ${activeTab ? 'active': ''}`}>
          <div className="row">
            <div className="col-sm-8">
              <div className="all-comment-rm">
                <div className="comment-rm">
                  <div className="author-comment-rm">
                    {" "}
                    <img src="/assets/images/user-1.png" alt="alt" />
                    <h4>
                      Jonathan <small>Posted on 1 hour ago</small>
                    </h4>
                  </div>
                  <p>
                    We take great pride in providing the best culinary
                    experiences. We will be sure to pass your kind words to
                    Rajan, who will be delighted to hear that he made your
                    experience particularly memorable. We very much look forward
                    to having you with us again in the near future.Management,
                    Outdoor Operations.
                  </p>
                </div>
                <div className="comment-rm">
                  <div className="author-comment-rm">
                    {" "}
                    <img src="/assets/images/user-2.png" alt="alt" />
                    <h4>
                      David <small>Posted on 1 hour ago</small>
                    </h4>
                  </div>
                  <p>
                    I pop in here for a drink with my friends once in a while,
                    great service!. Friendly staff who al speak English as well.
                    The drinks are always good and baked goods are fresh. They
                    open at 12pm and close at 2 am.
                  </p>
                </div>
                <div className="btn-load">
                  <button>Load More</button>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="enquiry">
                <h3 className='mb-3'>Write a review</h3>
                <form>
                  <input
                    type="text"
                    name="name_field"
                    placeholder="Your name"
                    required
                  />
                  <input
                    type="email"
                    name="email_field"
                    placeholder="Email id"
                    required
                  />
                  <textarea
                    name="message_field"
                    placeholder="Your message"
                  ></textarea>
                  <input type="submit" className='w-100' value="Submit" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div id="recommendation-tab-module" className={`tab-content ${!activeTab ? 'active': ''}`}>
          <div className="row">
            <div className="col-sm-8">
              <div className="all-comment-rm">
                <div className="comment-rm">
                  <div className="author-comment-rm">
                    {" "}
                    <img src="/assets/images/user-2.png" alt="alt" />
                    <h4>
                      David <small>Posted on 1 hour ago</small>
                    </h4>
                  </div>
                  <p>
                    I pop in here for a drink with my friends once in a while,
                    great service!. Friendly staff who al Friendly staff who al
                    speak English as well speak English as well. The drinks are
                    always good and baked goods are fresh. They open at 12pm and
                    close at 2 am. Friendly staff who al speak English as well.
                  </p>
                </div>
                <div className="comment-rm">
                  <div className="author-comment-rm">
                    {" "}
                    <img src="/assets/images/user-1.png" alt="alt" />
                    <h4>
                      Jonathan <small>Posted on 1 hour ago</small>
                    </h4>
                  </div>
                  <p>
                    We take great pride in providing the best culinary
                    experiences. We will be sure to pass your kind words to
                    Rajan, who will be delighted to hear that he made your
                    experience particularly memorable. We very much look forward
                    to having you with us again in the near future.Management,
                    Outdoor Operations.
                  </p>
                </div>
                <div className="btn-load">
                  <button>Load More</button>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="enquiry">
                <h3>Write a recommendation</h3>
                <form>
                  <input
                    type="text"
                    name="name_field"
                    placeholder="Your name"
                    required
                  />
                  <input
                    type="email"
                    name="email_field"
                    placeholder="Email id"
                    required
                  />
                  <textarea
                    name="message_field"
                    placeholder="Your message"
                  ></textarea>
                  <input type="submit" value="Submit" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReviewModule;
