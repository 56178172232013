import React, { useState } from "react";
import "./Slider.css";

const Slider = (props) => {
  const { data } = props;
  console.log("Data is ======>>>>>>>>>>> ", data);
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderItems = data;
  // const sliderItems = [
  //   {
  //     type: "image",
  //     label: "Image",
  //     content: "Image 1 Content",
  //     verified: true,
  //   },
  //   { type: "image", label: "Image", content: "Image 2 Content" },
  //   { type: "image", label: "Image", content: "Image 3 Content" },
  //   { type: "video", label: "Video", content: "Video Content" },
  // ];

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < sliderItems.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : sliderItems.length - 1
    );
  };

  console.log(
    "url is ===========magic========>>>>>>>>>>> ",
    `${process.env.REACT_APP_CLOUDFRONT_URL}${sliderItems[activeIndex].image}`
  );

  return (
    <div className="slider-container container px-2">
      <div className="row">
        <div className="col-sm-10">
          <div className="slider-main">
            {sliderItems[activeIndex].verified && (
              <div className="verified-badge">Verified</div>
            )}
            <div className="slider-content">
              <img
                src={`${process.env.REACT_APP_CLOUDFRONT_URL}${sliderItems[activeIndex].image}`}
                alt="slider image"
                style={{
                  height: "45vh",
                  width: "54vw",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
              />
              {/* {sliderItems[activeIndex].image} */}
              {/* <div class="top-icon">
    <div class="left">
      <div class="verified">
        <span class="verified-icon">✔</span>
        <span class="verified-text">Verified</span>
      </div>
    </div>
    <div class="right">
      <div class="icon share">
        <span>🔗</span> 
      </div>
      <div class="icon save">
        <span>🔖</span> 
      </div>
    </div>
  </div> */}
            </div>
            <button className="prev-btn" onClick={handlePrev}>
              <img
                src="/assets/images/icons/arrow-left-black.svg"
                alt="arrow"
              />
            </button>
            <button className="next-btn" onClick={handleNext}>
              <img
                src="/assets/images/icons/arrow-right-black.svg"
                alt="arrow"
              />
            </button>
          </div>
        </div>
        <div className="col-sm-2 ps-0">
          <div className="slider-thumbnails">
            {sliderItems.map((item, index) => (
              <div
                key={index}
                className={`thumbnail ${activeIndex === index ? "active" : ""}`}
                onClick={() => handleThumbnailClick(index)}
                style={{
                  backgroundColor:
                    item.type === "video" ? "#ff00ff" : "#0000ff",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_CLOUDFRONT_URL}${item.image}`}
                  alt="pic"
                  style={{
                    height: "10vh",
                    width: "9.5vw",
                    borderRadius: 10,
                    objectFit: "cover",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
