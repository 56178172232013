import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { cloudFrontUrl } from '../../config';
import HTabModule from '../../pages/detail/HTabModule';
import VTabModule from '../../pages/detail/VTabModule';
// import SocialStats from './SocialCard';

const InfluencerDetail = () => {
  const { country, lang, user_slug } = useParams();

  const [data, setData] = useState(null);
  const { request: searchRequest, response: searchResponse } = useRequest();

  useEffect(() => {
    searchRequest('GET', `/public/search/${user_slug}`);
  }, []);

  useEffect(() => {
    if (searchResponse && searchResponse.status === 'SUCCESS') {
      setData(searchResponse?.data);
    }
  }, [searchResponse]);

  return (
    <div className=' main-container'>
      <div className='container px-1'>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='title-mp'>
              <div className='flex-title-mp'>
                <div className='story-title-mp'>
                  <img
                    src={
                      data?.user_profile?.logo
                        ? cloudFrontUrl + data?.user_profile?.logo
                        : '/assets/images/logo-1.png'
                    }
                    alt='alt'
                  />
                </div>
                <div className='non'>
                  <h1>{data?.name}</h1>
                  <div className='category-title-mp'>
                    <a href='#'>
                      {data?.user_profile?.category?.name} |{' '}
                      {data?.user_profile?.sub_category?.name}
                    </a>
                  </div>
                  <div className='location-title-mp'>
                    <img
                      src='/assets/images/icons/location.svg'
                      alt='location'
                      ondragstart='return false'
                    />
                    {data?.user_contact?.location}
                  </div>
                  <div className='switch-mp'>
                    {' '}
                    <a href='#' className='active'>
                      My Profile
                    </a>{' '}
                    <a href='#'>My Community</a>
                  </div>
                </div>
              </div>
              <div className='share-vert'>
                <i>
                  <img
                    src='/assets/images/icons/tick.svg'
                    alt='tick'
                    ondragstart='return false'
                  />
                </i>
                <a href='#'>
                  <img
                    src='/assets/images/icons/share-2.svg'
                    alt='share'
                    ondragstart='return false'
                  />
                </a>
                <a href='#'>
                  <img
                    src='/assets/images/icons/bookmark.svg'
                    alt='bookmark'
                    className='bookmark-icon'
                    ondragstart='return false'
                  />
                </a>
              </div>
            </div>
            <div className='status-mp'>
              <h3>Status</h3>
              <p>We are off today!</p>
            </div>
            <div className='lg-btn-mp'>
              <ul>
                <li>
                  {' '}
                  <a href='#'>
                    <img
                      src='/assets/images/icons/live.svg'
                      alt='stlivear'
                      ondragstart='return false'
                    />
                    MyLive
                  </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href='#'>
                    <img
                      src='/assets/images/icons/listing.svg'
                      alt='listing'
                      ondragstart='return false'
                    />
                    MyListing
                  </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href='#'>
                    <img
                      src='/assets/images/icons/book.svg'
                      alt='book'
                      ondragstart='return false'
                    />
                    BookMyServices
                  </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href='#' className='review-lg-btn-mp'>
                    <img
                      src='/assets/images/icons/write-review.svg'
                      alt='write-review'
                      ondragstart='return false'
                    />
                    Write a Review
                  </a>{' '}
                </li>
              </ul>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='chatbot'>
              <div className='header-chatbot'>
                <div className='non'>
                  {' '}
                  <img src='/assets/images/ai/fredo.png' alt='alt' />
                  <h3>
                    FREDO <small>AI ChatBot</small>
                  </h3>
                </div>
                <button>
                  <img src='/assets/images/icons/voice-wave.svg' alt='alt' />
                </button>
              </div>
              <div className='flex-chatbot'>
                <div className='all-chatbox'>
                  <div className='chatbox'>
                    <div className='logo-chatbox'>
                      <img
                        src='/assets/images/icons/fredo.svg'
                        ondragstart='return false'
                        alt='fredo'
                      />
                    </div>
                    <div className='data-chatbox'>
                      <p>
                        For Inquiry please give me, your following information
                      </p>
                      <form>
                        <input
                          type='text'
                          name='name_field'
                          placeholder='Name'
                          required
                        />
                        <input
                          type='email'
                          name='email_field'
                          placeholder='Email'
                        />
                        <input
                          type='email'
                          name='phonel_field'
                          placeholder='Phone Number'
                        />
                        <textarea
                          name='message_field'
                          placeholder='Your Message'
                        ></textarea>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='footer-chatbot'>
                  <form method='post'>
                    <button>
                      <i className='fa-solid fa-arrow-up-from-bracket'></i>{' '}
                      Upload
                    </button>
                    <div className='field-footer-chatbot'>
                      <input
                        type='text'
                        name='search'
                        placeholder='Search here'
                      />
                    </div>
                    <div className='submit-footer-chatbot'>
                      <button>
                        <i className='fa-solid fa-microphone'></i>
                      </button>
                      <input type='submit' value='Sent' />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>{/* <SocialStats /> */}</div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <HTabModule user={data} />
            <VTabModule user={data} />
          </div>
        </div>
        <div className='review-module'>
          <div className='tab-review-module'>
            <ul className='tab-menu'>
              <li data-tab='review-tab-module' className='active'>
                Reviews
              </li>
              <li data-tab='recommendation-tab-module'>Recommendations</li>
            </ul>
            <div id='review-tab-module' className='tab-content active'>
              <div className='row'>
                <div className='col-sm-8'>
                  <div className='all-comment-rm'>
                    <div className='comment-rm'>
                      <div className='author-comment-rm'>
                        {' '}
                        <img src='/assets/images/user-1.png' alt='alt' />
                        <h4>
                          Jonathan <small>Posted on 1 hour ago</small>
                        </h4>
                      </div>
                      <p>
                        We take great pride in providing the best culinary
                        experiences. We will be sure to pass your kind words to
                        Rajan, who will be delighted to hear that he made your
                        experience particularly memorable. We very much look
                        forward to having you with us again in the near
                        future.Management, Outdoor Operations.
                      </p>
                    </div>
                    <div className='comment-rm'>
                      <div className='author-comment-rm'>
                        {' '}
                        <img src='/assets/images/user-2.png' alt='alt' />
                        <h4>
                          David <small>Posted on 1 hour ago</small>
                        </h4>
                      </div>
                      <p>
                        I pop in here for a drink with my friends once in a
                        while, great service!. Friendly staff who al speak
                        English as well. The drinks are always good and baked
                        goods are fresh. They open at 12pm and close at 2 am.
                      </p>
                    </div>
                    <div className='btn-load'>
                      <button>Load More</button>
                    </div>
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='enquiry'>
                    <h3 className='mb-3'>Write a review</h3>
                    <form>
                      <input
                        type='text'
                        name='name_field'
                        placeholder='Your name'
                        required
                      />
                      <input
                        type='email'
                        name='email_field'
                        placeholder='Email id'
                        required
                      />
                      <textarea
                        name='message_field'
                        placeholder='Your message'
                      ></textarea>
                      <input type='submit' className='w-100' value='Submit' />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div id='recommendation-tab-module' className='tab-content'>
              <div className='row'>
                <div className='col-sm-8'>
                  <div className='all-comment-rm'>
                    <div className='comment-rm'>
                      <div className='author-comment-rm'>
                        {' '}
                        <img src='/assets/images/user-2.png' alt='alt' />
                        <h4>
                          David <small>Posted on 1 hour ago</small>
                        </h4>
                      </div>
                      <p>
                        I pop in here for a drink with my friends once in a
                        while, great service!. Friendly staff who al Friendly
                        staff who al speak English as well speak English as
                        well. The drinks are always good and baked goods are
                        fresh. They open at 12pm and close at 2 am. Friendly
                        staff who al speak English as well.
                      </p>
                    </div>
                    <div className='comment-rm'>
                      <div className='author-comment-rm'>
                        {' '}
                        <img src='/assets/images/user-1.png' alt='alt' />
                        <h4>
                          Jonathan <small>Posted on 1 hour ago</small>
                        </h4>
                      </div>
                      <p>
                        We take great pride in providing the best culinary
                        experiences. We will be sure to pass your kind words to
                        Rajan, who will be delighted to hear that he made your
                        experience particularly memorable. We very much look
                        forward to having you with us again in the near
                        future.Management, Outdoor Operations.
                      </p>
                    </div>
                    <div className='btn-load'>
                      <button>Load More</button>
                    </div>
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='enquiry'>
                    <h3>Write a recommendation</h3>
                    <form>
                      <input
                        type='text'
                        name='name_field'
                        placeholder='Your name'
                        required
                      />
                      <input
                        type='email'
                        name='email_field'
                        placeholder='Email id'
                        required
                      />
                      <textarea
                        name='message_field'
                        placeholder='Your message'
                      ></textarea>
                      <input type='submit' value='Submit' />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='offer-slide'>
          <div className='swiper offer-slick'>
            <div className='swiper-wrapper'>
              <div className='swiper-slide'>
                {' '}
                <img src='/assets/images/offer-slider.jpg' alt='alt' />
                <div className='caption-offer-slide'>
                  <h3>Register Now!</h3>
                  <h4>World's First AI Powered Search Engine</h4>
                </div>
              </div>
              <div className='swiper-slide'>
                {' '}
                <img src='/assets/images/offer-slider.jpg' alt='alt' />
                <div className='caption-offer-slide'>
                  <h3>We have thousands of verified venues</h3>
                </div>
              </div>
              <div className='swiper-slide'>
                {' '}
                <img src='/assets/images/offer-slider.jpg' alt='alt' />
                <div className='caption-offer-slide'>
                  <h3>We have thousands of verified venues</h3>
                </div>
              </div>
              <div className='swiper-slide'>
                {' '}
                <img src='/assets/images/offer-slider.jpg' alt='alt' />
                <div className='caption-offer-slide'>
                  <h3>We have thousands of verified venues</h3>
                </div>
              </div>
              <div className='swiper-slide'>
                {' '}
                <img src='/assets/images/offer-slider.jpg' alt='alt' />
                <div className='caption-offer-slide'>
                  <h3>We have thousands of verified venues</h3>
                </div>
              </div>
            </div>
            <div className='swiper-pagination'></div>
          </div>
        </div>
        <div className='app-download'>
          <div className='container'>
            <div className='flex-app-download'>
              <div className='row'>
                <div className='col-sm-4'>
                  <img
                    src='/assets/images/app-download.png'
                    alt='app'
                    className='pic-app-download'
                  />
                </div>
                <div className='col-sm-8'>
                  <h2>
                    Available at{' '}
                    <small>
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                    </small>
                  </h2>
                  <div className='btn-app-download'>
                    {' '}
                    <a href='#'>
                      <img
                        src='/assets/images/icons/android.svg'
                        alt='android'
                      />
                    </a>{' '}
                    <a href='#'>
                      <img src='/assets/images/icons/ios.svg' alt='ios' />
                    </a>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerDetail;
