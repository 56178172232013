import './Auth.css';
import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import AuthSubHeader from '../../components/Auth';

const Face = () => {
  const { country, lang } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className='tab-signin'>
      <div className='flex-tab-menu'>
        <AuthSubHeader />
      </div>

      {location.pathname.includes('/auth/face') && (
        <div className='tab-content active'>
          <div className='face-login'>
            <div className='content-face-login'>
              <h3>Face Recognition</h3>
              <img src='/assets/images/faceid.jpg' alt='Face Recognition' />
              <p>
                Please face your camera to start the facial Authentication
                process.
              </p>
            </div>
            <ul>
              <li>
                <i className='fa-solid fa-circle-info'></i> No photo or image
                will be saved during your enrollment.
              </li>
              <li>
                <i className='fa-solid fa-circle-info'></i> Only an anonymized
                feature vector that cannot be reverse engineered!
              </li>
            </ul>
            <button>Login</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Face;
