import React from 'react';
import BlogTab from './Blogs';
import ImagesTab from './Images';
import VideosTab from './Videos';

const Tab = ({ activeTab, blogs, images, videoData, cloudFrontUrl, setActiveTab }) => {
    return (
        <div className="sidebar-ms">
            <div className="tabs-ms">
                <div className="flex-tabs-ms">
                    <div className="tabs-ms-slick">
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div
                                className={`menu-tabs-ms ${activeTab === 'blogs' ? 'active' : ''}`}
                                onClick={() => setActiveTab('blogs')}
                                data-tab="blog-tabs-ms">
                                Blog
                            </div>
                            <div
                                className={`menu-tabs-ms ${activeTab === 'images' ? 'active' : ''}`}
                                onClick={() => setActiveTab('images')}
                                data-tab="images-tabs-ms">
                                Images
                            </div>
                            <div
                                className={`menu-tabs-ms ${activeTab === 'videos' ? 'active' : ''}`}
                                onClick={() => setActiveTab('videos')}
                                data-tab="videos-tabs-ms">
                                Videos
                            </div>
                        </div>
                    </div>
                    {/* <div className="swiper-control">
                        <div className="swiper-button-prev tabs-ms-prev"></div>
                        <div className="swiper-button-next tabs-ms-next"></div>
                    </div> */}
                </div>
                <div>
                    {activeTab === 'videos' && videoData && (
                        <VideosTab videoData={videoData} cloudFrontUrl={cloudFrontUrl} />
                    )}
                    {activeTab === 'images' && images && (
                        <ImagesTab images={images} cloudFrontUrl={cloudFrontUrl} />
                    )}
                    {activeTab === 'blogs' && blogs && (
                        <BlogTab blogs={blogs} cloudFrontUrl={cloudFrontUrl} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Tab;
