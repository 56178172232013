import React, { useState } from "react";
import MyReel from "../../../components/Common/Community/MyReel";
import Author from "../../../components/Common/Community/Author";
import RightSideNav from "../../../components/Common/Community/SideNav";
import Groups from "../../../components/Common/Community/Groups";
import Recomandation from "../../../components/Common/Community/Recomandation";
import Trendings from "../../../components/Common/Community/Trendings";
import { useParams, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const CreateCommunityGroup = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { country, lang } = useParams();

  const [name, setName] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [logo, setLogo] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!name) {
      alert("Group name is required");
      return;
    }
    if (!privacy) {
      alert("Privacy option is required");
      return;
    }
    try {
      let accessToken = localStorage.getItem("access_token");
      const formData = new FormData();
      formData.append("name", name);
      formData.append("privacy", privacy);
      if (logo) {
        formData.append("logo", logo);
      } else {
        console.error("No logo selected");
      }

      const response = await fetch(`${BASE_URL}/groups`, {
        method: "POST",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
        body: formData,
      });

      const data = await response.json();
      if (data?.status === "SUCCESS") {
        alert("Group created successfully!");
        navigate("/gb/en/community/groups");
      }
    } catch (error) {
      console.error("Status", error);
    } finally {
      // Optional cleanup code can go here
    }
  };

  return (
    <>
      <MyReel />
      <Author />

      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <RightSideNav />
              <Groups />
              <Recomandation />
              <Trendings />
            </div>
            <div className="col-sm-9">
              <div className="create-group-page">
                <div className="btn-groups">
                  <ul>
                    <li>
                      <NavLink to="/">
                        <i className="fa-regular fa-comment-dots" /> Your Feed
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`/${country}/${lang}/community/groups`}>
                        <i className="fa-solid fa-user-group" /> Your Groups
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/${country}/${lang}/community/creategroup`}
                        className="active"
                      >
                        <i className="fa-solid fa-plus" /> Create New Group
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="create-group">
                  <h2 className="heading-community">Create group</h2>
                  {/* name: {name}
                  privacy: {privacy} */}
                  <div className="flex-wraper">
                    <div className="form">
                      <div className="logo-create-group mb-3">
                        {" "}
                        <i className="fa-solid fa-users" />{" "}
                      </div>
                      <input
                        className="mb-3"
                        type="file"
                        onChange={(e) => setLogo(e.target.files[0])} // Correct file handling
                      />
                      <input
                        type="text"
                        value={name}
                        placeholder="Group name"
                        required=""
                        onChange={(e) => setName(e.target.value)}
                      />
                      <select
                        value={privacy}
                        required=""
                        onChange={(e) => setPrivacy(e.target.value)}
                      >
                        <option selected="selected">Choose Privacy</option>
                        <option value="PUBLIC">Public</option>
                        <option value="PRIVATE">Private</option>
                      </select>
                      <input
                        type="button"
                        defaultValue="Create Group"
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCommunityGroup;
