import { useEffect, useState } from "react";
import { cloudFrontUrl } from "../../config";
import useRequest from "../../hooks/useRequest";

const MasterBanner = () => {
  const [masterBanner, setMasterBanner] = useState();

  const { request: masterBannerReq, response: masterBannerRes } = useRequest();

  const fetchMasterBanner = async () => {
    await masterBannerReq("GET", "/public/attraction-master-banner");
  };

  useEffect(() => {
    fetchMasterBanner();
  }, []);

  useEffect(() => {
    if (masterBannerRes) {
      setMasterBanner(masterBannerRes.data);
    }
  }, [masterBannerRes]);

  return (
    <div
      className="banner-attraction-home"
      style={{
        backgroundImage: `url(${cloudFrontUrl}${masterBanner?.image}) !important`,
      }}

      
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-8 offset-2">
            <h1>{masterBanner?.headline}</h1>
            <p>{masterBanner?.sub_headline}</p>
            <form action="#">
              <div className="field-bah w-50">
                <label>Location</label>
                <input type="text" name="name" placeholder="Type Location" />
              </div>
              <div className="field-bah">
                <label>Sep 23</label>
                <input type="text" name="date" placeholder="Select a Date" />
              </div>
              <div className="field-bah">
                <label>Categories</label>
                <select>
                  <option value="Attraction">Attraction</option>
                </select>
              </div>
              <div className="field-bah">
                <label>Filters</label>
                <select>
                  <option value="Price, Beds & More">Price, Beds & More</option>
                </select>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MasterBanner;
