import React, { useEffect, useState } from 'react';
import useRequest from '../../hooks/useRequest';

const Privacy = () => {
    const [policyData, setPolicyData] = useState([]);

    const { request: policyRequest, response: policyResponse } = useRequest();

    useEffect(() => {
        policyRequest('GET', '/public/cms-privacy');
    }, []);

    useEffect(() => {
        if (policyResponse && policyResponse.status === "SUCCESS") {
            setPolicyData(policyResponse.data || {});
        }
    }, [policyResponse]);

    return (
        <div className="policy-page main-container bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        {policyData ? (
                            <>
                                <h1 className="text-2xl mb-3">{policyData.headline}</h1>
                                <p>{policyData.description}</p>
                            </>
                        ) : (
                            <p>Loading...</p>
                        )}
                        {/* <h1>Privacy</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi hendrerit bibendum luctus. Quisque ultrices
                            sapien lectus, id volutpat velit imperdiet et. Integer id imperdiet mi. Aliquam feugiat ipsum gravida,
                            imperdiet arcu elementum, ultrices diam. Mauris placerat, mauris ut ornare auctor, dolor felis placerat
                            tortor, quis mollis urna tellus sit amet dui. Cras justo turpis, tristique id venenatis sit amet, tincidunt
                            ac elit. Vivamus eu condimentum dolor. Nunc tortor odio, tincidunt eu massa in, hendrerit fermentum massa.
                            Curabitur placerat interdum placerat. Vestibulum pharetra erat neque. Quisque volutpat luctus massa, quis
                            eleifend urna vehicula eget. Maecenas quis libero elit. Pellentesque aliquam malesuada rutrum.</p>
                        <h3>Proin convallis nisl </h3>
                        <p>Vel velit semper, nec eleifend metus varius. Ut auctor lectus a massa tempor, ut fermentum neque tincidunt.
                            Nunc id fermentum magna. Sed placerat hendrerit leo, et pretium arcu. Proin aliquet risus ut neque commodo
                            laoreet. Cras venenatis odio augue, vitae pretium justo laoreet non. Nullam pulvinar massa ipsum, at
                            vulputate magna pellentesque vel. Quisque at neque turpis. Aliquam sit amet eros eget massa sodales blandit.
                        </p>
                        <h3>In ut consequat odio</h3>
                        <p>Sed efficitur lectus eget dapibus ullamcorper. Proin id laoreet nisi. Donec tempor ante a turpis mollis
                            pellentesque. Maecenas fringilla odio ac
                            nibh faucibus, quis feugiat lectus interdum. Nulla facilisi. Quisque eu erat quis sem feugiat ornare id eu
                            arcu. Praesent feugiat arcu tincidunt,
                            ullamcorper nunc sit amet, sodales urna. Sed pulvinar risus vitae est laoreet ultricies.</p>
                        <h3>Quisque eu lectus hendrerit </h3>
                        <p>Vehicula velit eget, tincidunt metus. Sed maximus, tellus vitae malesuada aliquet, erat velit volutpat
                            purus, ut commodo enim velit ut diam. Nunc ac turpis at quam feugiat euismod. Vestibulum ante ipsum primis
                            in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus varius dolor id euismod ultricies. Sed
                            pharetra nisl eu urna vulputate condimentum. Ut sit amet efficitur velit. Nam blandit augue non justo
                            tristique viverra. Etiam mauris urna, dictum aliquet sagittis a, congue id dui.</p>
                        <p>Duis tempus porta nunc id fringilla. Donec volutpat nibh eu auctor molestie. Quisque a luctus eros.
                            Maecenas porta, enim eu pulvinar molestie, velit eros sollicitudin dui, at porta massa est vel ipsum. Cras
                            convallis quam tincidunt est lobortis blandit. Mauris maximus, ex et vehicula suscipit, tellus dui facilisis
                            massa, in egestas urna odio eu purus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                            posuere cubilia curae; Suspendisse nec varius est. Mauris rutrum mi et porta tempus. Nunc eget orci eu elit
                            faucibus ultricies. Morbi volutpat magna ante, quis hendrerit orci vehicula ut. Ut sit amet magna urna. Duis
                            facilisis erat sit amet diam congue, ac volutpat quam gravida. Fusce ligula libero, sodales eu ante eget,
                            sollicitudin accumsan nunc.</p>
                        <h3>Nsequat odio. Sed efficitur</h3>
                        <p>lectus eget dapibus ullamcorper. Proin id laoreet nisi. Donec tempor ante a turpis mollis pellentesque.
                            Maecenas fringilla odio ac nibh faucibus, quis feugiat lectus interdum. Nulla facilisi. Quisque eu erat quis
                            sem feugiat ornare id eu arcu. Praesent feugiat arcu tincidunt, ullamcorper nuncsit amet, sodales urna. Sed
                            pulvinar risus vitae est laoreet ultricies.</p>
                        <p>Quisque eu lectus hendrerit, vehicula velit eget, tincidunt metus. Sed maximus, tellus vitae malesuada
                            aliquet, erat velit volutpat purus, utcommodo enim velit ut diam. Nunc ac turpis at quam feugiat euismod.
                            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus varius
                            dolor id euismod ultricies. Sed pharetra nisl eu urna vulputate condimentum. Ut sit amet efficitur velit.
                            Nam blandit augue non justo tristique viverra. Etiam mauris urna, dictum aliquet sagittis a, congue id dui.
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
