import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import MyReel from "../../components/Common/Community/MyReel";
import Author from "../../components/Common/Community/Author";
import RightSideNav from "../../components/Common/Community/SideNav";
import Groups from "../../components/Common/Community/Groups";
import Recomandation from "../../components/Common/Community/Recomandation";
import Trendings from "../../components/Common/Community/Trendings";
import BtnPostSideBar from "../../components/Common/Community/BtnPostSideBar";
import ListYourCompany from "../../components/Common/Community/ListYourCompany";
import Categries from "../../components/Common/Community/Categries";
import SuggestedGroups from "../../components/Common/Community/SuggestedGroups";
import BoostYourBusiness from "../../components/Common/Community/BoostYourBusiness";

const Listing = () => {
  const [listingData, setListingData] = useState([]);
  const { request: ListingRequest, response: listingResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
  useEffect(() => {
    ListingRequest("GET", "/listing?page_num=0&page_size=10");
  }, []);
  useEffect(() => {
    if (listingResponse && listingResponse.status === "SUCCESS") {
      setListingData(listingResponse.data || {});
    }
  }, [listingResponse]);
  return (
    <div>
      <MyReel />
      <Author />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <RightSideNav />
              <Groups />
              <Recomandation />
              <Trendings />
            </div>
            <div className="col-sm-6">
              <div className="listing-page">
                <div className="row">
                  {listingData.length > 0 ? (
                    listingData.map((listing, index) => (
                      <div key={index} className="col-sm-6">
                        <div className="item-listing">
                          <div className="imgeffect">
                            <div className="wishlist-item-listing">
                              <a href="#">
                                <i className="fa-regular fa-heart" />
                              </a>
                            </div>
                            <a href="#">
                              <img
                                src="/assets/images/listing/1.jpg"
                                alt="alt"
                              />
                            </a>{" "}
                          </div>
                          <div className="info-item-listing">
                            <h3>
                              <img
                                src={`${cloudFrontUrl}${listing.logo}`}
                                alt={`${listing.title}`}
                              />{" "}
                              {listing.title}
                            </h3>
                            <p>{listing.description}</p>
                          </div>
                          <ul>
                            <li>
                              <i className="fa-solid fa-users" /> 3,507
                              Followers
                            </li>
                            <li>
                              <i className="fa-solid fa-list" /> 32 Listing
                            </li>
                          </ul>
                          <ul className="rating-item-listing">
                            <li>
                              <i className="fa-solid fa-location-dot" />{" "}
                              {listing.location_near_by}
                            </li>
                            <li>
                              {" "}
                              <img
                                src="/assets/images/icons/google.svg"
                                alt="favicon"
                                ondragstart="return false"
                              />{" "}
                              <strong>4.5</strong> (45 Rating){" "}
                              <a href="#">
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-regular fa-star" />
                                <i className="fa-regular fa-star" />
                              </a>{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Feed available</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <BtnPostSideBar />
              <BoostYourBusiness />
              <SuggestedGroups />
              <Categries />
              <ListYourCompany />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
