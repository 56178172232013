import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { cloudFrontUrl } from '../../config';
import HTabModule from './HTabModule';
import VTabModule from './VTabModule';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';

const CustomerDetail = () => {
  const navigate = useNavigate();
  const { country, lang, user_slug } = useParams();
  const location = useLocation();
  console.log('Params are =====>>>>>', location);
  const [isloading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [reviewsData, setReviewsData] = useState(null);
  const [recommendationData, setRecommendationData] = useState(null);
  const [rating, setRating] = useState(0);
  const [reviewFormData, setReviewFormData] = useState({
    name: '',
    email: '',
    message: '',
    listing_id: null,
    rating: rating,
    review_type: userType,
    user_id: userId,
  });

  const [recommendationFormData, setRecommendationFormData] = useState({
    recommendation_type: '',
    user_id: '',
    message: '',
    listing_id: null,
  });
  const { request: searchRequest, response: searchResponse } = useRequest();
  const { request: reviewsRequest, response: reviewsResponse } = useRequest();
  const { request: recommendationRequest, response: recommendationResponse } =
    useRequest();
  const { request: reviewPostRequest, response: reviewPostResponse } =
    useRequest();
  const {
    request: recommendationPostRequest,
    response: recommendationPostResponse,
  } = useRequest();

  useEffect(() => {
    const id = localStorage.getItem('user_id');
    const type = localStorage.getItem('user_type');
    const review = sessionStorage.getItem('reviewFormData');
    const recommendation = sessionStorage.getItem('recommendationFormData');

    if (review != null) {
      console.log('review Data', review);
      let data = JSON.parse(review);
      console.log('Paresed data', data);
      setReviewFormData(data);
      setRating(data?.rating);
    }

    if (recommendation != null) {
      let data = JSON.parse(review);
      setRecommendationFormData(data);
    }

    setUserId(id);
    setUserType(type);
    searchRequest('GET', `/public/search/${user_slug}`);
  }, []);

  useEffect(() => {
    if (userId && userType) {
      setReviewFormData((prev) => ({
        ...prev,
        user_id: userId,
        review_type: userType,
      }));

      setRecommendationFormData((prev) => ({
        ...prev,
        user_id: userId,
        recommendation_type: userType,
      }));

      reviewsRequest('GET', `/public/reviews/${userType}/${userId}`);
      recommendationRequest(
        'GET',
        `/public/recommendations/${userType}/${userId}`
      );
    }
  }, [userId, userType]);

  useEffect(() => {
    if (searchResponse && searchResponse.status === 'SUCCESS') {
      setData(searchResponse?.data);
    }
  }, [searchResponse]);

  useEffect(() => {
    if (reviewsResponse && reviewsResponse.status === 'SUCCESS') {
      setReviewsData(reviewsResponse.data);
      setIsLoading(false);
    } else if (
      reviewsResponse &&
      reviewsResponse.status === 'FAIL' &&
      reviewsResponse.message === '[Job_not_found.]'
    ) {
      setReviewsData('No jobs found');
      setIsLoading(false);
    }
  }, [reviewsResponse]);

  useEffect(() => {
    if (recommendationResponse && recommendationResponse.status === 'SUCCESS') {
      setRecommendationData(recommendationResponse.data);
      setIsLoading(false);
    } else if (
      recommendationResponse &&
      recommendationResponse.status === 'FAIL' &&
      recommendationResponse.message === '[Job_not_found.]'
    ) {
      setRecommendationData('No jobs found');
      setIsLoading(false);
    }
  }, [recommendationResponse]);

  useEffect(() => {
    if (reviewPostResponse?.status === 'SUCCESS') {
      console.log('success response');
      sessionStorage.setItem('reviewFormData', null);
      toast.success('Review Submitted', {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
      setReviewFormData((prev) => ({
        ...prev,
        name: '',
        email: '',
        message: '',
      }));
      setRating(0);
    } else if (reviewPostResponse === 'FAIL') {
      toast.error('Review Submission Failed', {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
    }
  }, [reviewPostResponse]);

  useEffect(() => {
    if (recommendationPostResponse?.status === 'SUCCESS') {
      sessionStorage.setItem('recommendationFormData', null);
      toast.success('Recommendation Submitted', {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
      setRecommendationFormData((prev) => ({ ...prev, message: '' }));
    } else if (recommendationPostResponse?.status === 'FAIL') {
      toast.error('Recommendation Submission Failed', {
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 4000,
      });
    }
  }, [recommendationPostResponse]);

  const handleReviewSubmit = (e) => {
    e.preventDefault();
    let token = localStorage.getItem('access_token');
    if (token) {
      reviewPostRequest('POST', `/reviews`, reviewFormData);
    } else {
      console.log('Saving');
      sessionStorage.setItem('reviewFormData', JSON.stringify(reviewFormData));
      navigate(`/${country}/${lang}/auth/login?redirect=${location?.pathname}`);
    }
  };

  const handleRecommendationSubmit = (e) => {
    e.preventDefault();
    let token = localStorage.getItem('access_token');
    console.log('token is ', token);
    if (token) {
      recommendationPostRequest(
        'POST',
        `/recommendations`,
        recommendationFormData
      );
    } else {
      console.log('implementing');
      sessionStorage.setItem(
        'recommendationFormData',
        JSON.stringify(recommendationFormData)
      );
      navigate(`/${country}/${lang}/auth/login?redirect=${location?.pathname}`);
    }
  };

  const handleClick = (value) => {
    setRating(value);
    setReviewFormData((prev) => ({ ...prev, rating: value }));
  };

  return (
    <div className='module-profile bg-white pt-20'>
      <ToastContainer />
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='top-mp'>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='title-mp'>
                    <div className='flex-title-mp'>
                      <div className='story-title-mp'>
                        <img
                          src={
                            data?.user_profile?.logo
                              ? cloudFrontUrl + data?.user_profile?.logo
                              : '/assets/images/logo-1.png'
                          }
                          alt='alt'
                        />
                      </div>
                      <div className='non'>
                        <h1>{data?.name}</h1>
                        <div className='category-title-mp'>
                          <a href='#'>
                            {data?.user_profile?.category?.name} |{' '}
                            {data?.user_profile?.sub_category?.name}
                          </a>
                        </div>
                        <div className='location-title-mp'>
                          <img
                            src='/assets/images/icons/location.svg'
                            alt='location'
                            ondragstart='return false'
                          />
                          {data?.user_contact?.location}
                        </div>
                        <div className='switch-mp'>
                          {' '}
                          <a href='#' className='active'>
                            My Profile
                          </a>{' '}
                          <a href='#'>My Community</a>
                        </div>
                      </div>
                    </div>
                    <div className='share-vert'>
                      <i>
                        <img
                          src='/assets/images/icons/tick.svg'
                          alt='tick'
                          ondragstart='return false'
                        />
                      </i>
                      <a href='#'>
                        <img
                          src='/assets/images/icons/share-2.svg'
                          alt='share'
                          ondragstart='return false'
                        />
                      </a>
                      <a href='#'>
                        <img
                          src='/assets/images/icons/bookmark.svg'
                          alt='bookmark'
                          className='bookmark-icon'
                          ondragstart='return false'
                        />
                      </a>
                    </div>
                  </div>
                  <div className='status-mp'>
                    <h3>Status</h3>
                    <p>We are off today!</p>
                  </div>
                  <div className='lg-btn-mp'>
                    <ul>
                      <li>
                        {' '}
                        <a href='#'>
                          <img
                            src='/assets/images/icons/live.svg'
                            alt='stlivear'
                            ondragstart='return false'
                          />
                          MyLive
                        </a>{' '}
                      </li>
                      <li>
                        {' '}
                        <a href='#'>
                          <img
                            src='/assets/images/icons/listing.svg'
                            alt='listing'
                            ondragstart='return false'
                          />
                          MyListing
                        </a>{' '}
                      </li>
                      <li>
                        {' '}
                        <a href='#'>
                          <img
                            src='/assets/images/icons/book.svg'
                            alt='book'
                            ondragstart='return false'
                          />
                          BookMyServices
                        </a>{' '}
                      </li>
                      <li>
                        {' '}
                        <a href='#' className='review-lg-btn-mp'>
                          <img
                            src='/assets/images/icons/write-review.svg'
                            alt='write-review'
                            ondragstart='return false'
                          />
                          Write a Review
                        </a>{' '}
                      </li>
                    </ul>
                  </div>
                  <div className='wrap-rating-mp'>
                    <div className='heading'>
                      <h2>Reviews &amp; Ratings</h2>
                    </div>
                    <div className='row'>
                      <div className='col-sm-4'>
                        <div className='rating-mp'>
                          <div className='flex-rating-mp'>
                            {' '}
                            <span>4.5</span>
                            <p>
                              8 Ratings{' '}
                              <a href='#'>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-regular fa-star'></i>
                                <i className='fa-regular fa-star'></i>
                              </a>
                            </p>
                          </div>
                          <div className='non'>
                            <h4>Start your Review</h4>
                            <a href='#'>
                              <i className='fa-regular fa-star'></i>
                              <i className='fa-regular fa-star'></i>
                              <i className='fa-regular fa-star'></i>
                              <i className='fa-regular fa-star'></i>
                              <i className='fa-regular fa-star'></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-8'>
                        <div className='activity'>
                          <h3>Activity</h3>
                          <ul>
                            <li>
                              <div>
                                <img
                                  src='/assets/images/icons/eye.svg'
                                  alt='eye'
                                  ondragstart='return false'
                                />
                                158
                              </div>
                              <small>Unique Views</small>
                            </li>
                            <li>
                              <div>
                                <img
                                  src='/assets/images/icons/bookmark.svg'
                                  alt='bookmark'
                                  className='bookmark-icon'
                                  ondragstart='return false'
                                />
                                12
                              </div>
                              <small>Favourite</small>
                            </li>
                            <li>
                              <div>
                                <img
                                  src='/assets/images/icons/phone-3.svg'
                                  alt='phone'
                                  ondragstart='return false'
                                />
                                4
                              </div>
                              <small>Contacted</small>
                            </li>
                            <li>
                              <div>
                                <img
                                  src='/assets/images/icons/google.svg'
                                  alt='google'
                                  ondragstart='return false'
                                />
                                858
                              </div>
                              <small>Reviews</small>
                            </li>
                          </ul>
                          <div className='mf-reviews'>
                            <div className='icons-mf-reviews'>
                              {' '}
                              <img
                                src='/assets/images/favicon.png'
                                alt='alt'
                              />{' '}
                              <a href='#'>
                                <i className='fa-regular fa-star'></i>
                                <i className='fa-regular fa-star'></i>
                                <i className='fa-regular fa-star'></i>
                                <i className='fa-regular fa-star'></i>
                                <i className='fa-regular fa-star'></i>
                              </a>{' '}
                              <small>0 Reviews</small>{' '}
                            </div>
                            <button>Submit Review</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='chatbot'>
                    <div className='header-chatbot'>
                      <div className='non'>
                        {' '}
                        <img src='/assets/images/ai/fredo.png' alt='alt' />
                        <h3>
                          FREDO <small>AI ChatBot</small>
                        </h3>
                      </div>
                      <button>
                        <img
                          src='/assets/images/icons/voice-wave.svg'
                          alt='alt'
                        />
                      </button>
                    </div>
                    <div className='flex-chatbot'>
                      <div className='all-chatbox'>
                        <div className='chatbox'>
                          <div className='logo-chatbox'>
                            <img
                              src='/assets/images/icons/fredo.svg'
                              ondragstart='return false'
                              alt='fredo'
                            />
                          </div>
                          <div className='data-chatbox'>
                            <p>
                              For Inquiry please give me, your following
                              information
                            </p>
                            <form>
                              <input
                                type='text'
                                name='name_field'
                                placeholder='Name'
                                required
                              />
                              <input
                                type='email'
                                name='email_field'
                                placeholder='Email'
                              />
                              <input
                                type='email'
                                name='phonel_field'
                                placeholder='Phone Number'
                              />
                              <textarea
                                name='message_field'
                                placeholder='Your Message'
                              ></textarea>
                            </form>
                          </div>
                        </div>
                        <div className='chatbox'>
                          <div className='logo-chatbox'>
                            <img
                              src='/assets/images/icons/fredo.svg'
                              ondragstart='return false'
                              alt='fredo'
                            />
                          </div>
                          <div className='data-chatbox'>
                            <p>
                              FREDO, show me this Business' following
                              information
                            </p>
                            <div className='btns-chatbox'>
                              <a href='#' className='whatsapp-chatbox'>
                                <i className='fa-brands fa-whatsapp'></i>
                                Whatsapp
                              </a>
                              <a href='#' className='phone-chatbox'>
                                <i className='fa-solid fa-phone-volume'></i>
                                Phone
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className='chatbox'>
                          <div className='logo-chatbox'>
                            <img
                              src='/assets/images/icons/fredo.svg'
                              ondragstart='return false'
                              alt='fredo'
                            />
                          </div>
                          <div className='data-chatbox flex-data-chatbox'>
                            <p>Fredo, request</p>
                            <div className='btns-chatbox'>
                              <a href='#' className='phone-chatbox'>
                                <i className='fa-solid fa-phone-volume'></i>Call
                                Back
                              </a>
                              <a href='#' className='phone-chatbox'>
                                <i className='fa-solid fa-file'></i>e Brochure
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='footer-chatbot'>
                        <form method='post'>
                          <button>
                            <i className='fa-solid fa-arrow-up-from-bracket'></i>{' '}
                            Upload
                          </button>
                          <div className='field-footer-chatbot'>
                            <input
                              type='text'
                              name='search'
                              placeholder='Search here'
                            />
                          </div>
                          <div className='submit-footer-chatbot'>
                            <button>
                              <i className='fa-solid fa-microphone'></i>
                            </button>
                            <input type='submit' value='Sent' />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <HTabModule user={data} />
            <VTabModule user={data} />
            <div className='review-module'>
              <div className='tab-review-module'>
                <ul className='tab-menu'>
                  <li data-tab='review-tab-module' className='active'>
                    Reviews
                  </li>
                  <li data-tab='recommendation-tab-module'>Recommendations</li>
                </ul>
                <div id='review-tab-module' className='tab-content active'>
                  <div className='row'>
                    <div className='col-sm-8'>
                      <div className='all-comment-rm'>
                        {reviewsData &&
                          reviewsData?.map((data) => (
                            <div className='comment-rm'>
                              <div className='author-comment-rm'>
                                {' '}
                                <img
                                  src='/assets/images/user-1.png'
                                  alt='alt'
                                />
                                <h4>
                                  {data?.name}{' '}
                                  <small>
                                    {moment(data?.created_at).fromNow()}
                                  </small>
                                </h4>
                              </div>
                              <p>{data?.message}</p>
                            </div>
                          ))}
                        <div className='btn-load'>
                          <button>Load More</button>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='enquiry'>
                        <h3 className='mb-3'>Write a review</h3>
                        <form onSubmit={handleReviewSubmit}>
                          <input
                            type='text'
                            name='name_field'
                            placeholder='Your name'
                            required
                            value={reviewFormData?.name}
                            onChange={(e) =>
                              setReviewFormData((prev) => ({
                                ...prev,
                                name: e?.target?.value,
                              }))
                            }
                          />
                          <input
                            type='email'
                            name='email_field'
                            placeholder='Email id'
                            required
                            value={reviewFormData?.email}
                            onChange={(e) =>
                              setReviewFormData((prev) => ({
                                ...prev,
                                email: e?.target?.value,
                              }))
                            }
                          />
                          <textarea
                            name='message_field'
                            placeholder='Your message'
                            value={reviewFormData?.message}
                            onChange={(e) =>
                              setReviewFormData((prev) => ({
                                ...prev,
                                message: e?.target?.value,
                              }))
                            }
                          ></textarea>
                          <div className='d-flex align-items-center mt-2 mb-3'>
                            {[1, 2, 3, 4, 5].map((star) => (
                              <i
                                key={star}
                                className={`fa fa-star ${
                                  star <= rating ? 'text-warning' : 'text-muted'
                                } mx-1`}
                                onClick={() => handleClick(star)}
                                style={{ cursor: 'pointer' }}
                              ></i>
                            ))}
                            <span className='ml-2'>Rating: {rating} / 5</span>
                          </div>
                          <input type='submit' className='w-100' value='Submit' />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div id='recommendation-tab-module' className='tab-content'>
                  <div className='row'>
                    <div className='col-sm-8'>
                      <div className='all-comment-rm'>
                        {recommendationData &&
                          recommendationData?.map((data) => (
                            <div className='comment-rm'>
                              <div className='author-comment-rm'>
                                {' '}
                                <img
                                  src='/assets/images/user-2.png'
                                  alt='alt'
                                />
                                <h4>
                                  {/* David  */}
                                  <small>
                                    {moment(data?.created_at)?.fromNow()}
                                  </small>
                                </h4>
                              </div>
                              <p>{data?.message}</p>
                            </div>
                          ))}
                        <div className='btn-load'>
                          <button>Load More</button>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='enquiry'>
                        <h3>Write a recommendation</h3>
                        <form onSubmit={handleRecommendationSubmit}>
                          <textarea
                            name='message_field'
                            placeholder='Your message'
                            value={recommendationFormData?.message}
                            onChange={(e) =>
                              setRecommendationFormData((prev) => ({
                                ...prev,
                                message: e?.target?.value,
                              }))
                            }
                          ></textarea>
                          <input type='submit' value='Submit' />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='offer-slide'>
              <div className='swiper offer-slick'>
                <div className='swiper-wrapper'>
                  <div className='swiper-slide'>
                    {' '}
                    <img src='/assets/images/offer-slider.jpg' alt='alt' />
                    <div className='caption-offer-slide'>
                      <h3>Register Now!</h3>
                      <h4>World's First AI Powered Search Engine</h4>
                    </div>
                  </div>
                  <div className='swiper-slide mt-lg-5'>
                    {' '}
                    <img src='/assets/images/offer-slider.jpg' alt='alt' />
                    <div className='caption-offer-slide'>
                      <h3>We have thousands of verified venues</h3>
                    </div>
                  </div>
                  <div className='swiper-slide'>
                    {' '}
                    <img src='/assets/images/offer-slider.jpg' alt='alt' />
                    <div className='caption-offer-slide'>
                      <h3>We have thousands of verified venues</h3>
                    </div>
                  </div>
                  <div className='swiper-slide'>
                    {' '}
                    <img src='/assets/images/offer-slider.jpg' alt='alt' />
                    <div className='caption-offer-slide'>
                      <h3>We have thousands of verified venues</h3>
                    </div>
                  </div>
                  <div className='swiper-slide'>
                    {' '}
                    <img src='/assets/images/offer-slider.jpg' alt='alt' />
                    <div className='caption-offer-slide'>
                      <h3>We have thousands of verified venues</h3>
                    </div>
                  </div>
                </div>
                <div className='swiper-pagination'></div>
              </div>
            </div>
            <div className='app-download'>
              <div className='container'>
                <div className='flex-app-download'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <img
                        src='/assets/images/app-download.png'
                        alt='app'
                        className='pic-app-download'
                      />
                    </div>
                    <div className='col-sm-8'>
                      <h2>
                        Available at{' '}
                        <small>
                          Lorem ipsum dolor sit amet consectetur adipiscing elit
                        </small>
                      </h2>
                      <div className='btn-app-download'>
                        {' '}
                        <a href='#'>
                          <img
                            src='/assets/images/icons/android.svg'
                            alt='android'
                          />
                        </a>{' '}
                        <a href='#'>
                          <img src='/assets/images/icons/ios.svg' alt='ios' />
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetail;
