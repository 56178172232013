import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./App.css";

import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { getUserLocale } from "./utils/getUserLocale";
import ScrollTop from "./utils/ScrollTop";
import HomeLayout from "./layout/HomeLayout";
import Home from "./pages/home/Home";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/Signup";
import AuthLayout from "./layout/AuthLayout";
import OtpVerification from "./pages/auth/OtpVerification";
import About from "./pages/about/About";
import CommonLayout from "./layout/CommonLayout";
import Privacy from "./pages/privacy/Privacy";
import Help from "./pages/help/Help";
import Search from "./pages/search/Search";
import GoogleAuthCallback from "./pages/auth/GoogleAuthCallback";
import { SearchProvider } from "./contexts/SearchContext";
import CustomerDetail from "./pages/detail/CustomerDetail";
import ListingDetail from "./pages/listing_detail/ListingDetail";
import CommunityLayout from "./layout/CommunityLayout";
import CommunityHome from "./pages/community/CommunityHome";
import CommunityGroup from "./pages/community/group/CommunityGroup";
import CreateCommunityGroup from "./pages/community/group/CreateCommunityGroup";
import GroupDetails from "./pages/community/group/GroupDetails";
import Friends from "./pages/community/Friends";
import Events from "./pages/community/events/Events";
import EventDetails from "./pages/community/events/EventDetails";
import Polls from "./pages/community/Polls";
import Blog from "./pages/community/Blog";
import News from "./pages/community/News";
import Store from "./pages/community/Store";
import Listing from "./pages/community/Listing";
import AttractionHome from "./pages/attraction/home";
import AttractionListing from "./pages/attraction/listing/listing";
import AttractionDetails from "./pages/attraction/details/details";
import JobListing from "./pages/joblisting/JobListing";
import Influencer from "./pages/influencer/Influencer";
import HowItWorks from "./pages/how-it-works";
import Face from "./pages/auth/Face";
import ListAndSell from "./pages/lsitandsell/ListAndSell";
import AiTools from "./pages/ai-tools/AiTools";
import JobDescription from "./pages/joblisting/jobDescription";
import AgentDetialPage from "./pages/agent/AgentDetialPage";

function App() {
  const { lang, country } = getUserLocale();

  return (
    <BrowserRouter forceRefresh={true}>
      <SearchProvider>
        <ScrollTop />
        <Routes>
          {/* Fallback route for unmatched paths */}
          <Route
            path="*"
            element={<Navigate to={`/${country}/${lang}`} replace />}
          />

          {/* Redirect from root to country/lang */}
          <Route
            path="/"
            element={<Navigate to={`/${country}/${lang}`} replace />}
          />

          <Route path="/:country/:lang" element={<HomeLayout />}>
            <Route index element={<Home />} />
          </Route>

          <Route path="/:country/:lang" element={<CommonLayout />}>
            <Route path="jobDescription/:slug" element={<JobDescription />} />
            <Route path="about" element={<About />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="help-center" element={<Help />} />
            <Route path="how-it-works" element={<HowItWorks />} />
            {/* Attraction */}
            <Route path="attraction" element={<AttractionHome />} />
            <Route path="attraction/listing" element={<AttractionListing />} />
            <Route
              path="attraction/listing/:id"
              element={<AttractionDetails />}
            />
            {/* Search */}
            <Route path="search" element={<Search />} />
            <Route
              path="search/company/:user_slug"
              element={<CustomerDetail />}
            />
            <Route
              path="company/:user_slug/listing/:listing_slug"
              element={<ListingDetail />}
            />

            {/* Listing */}
            <Route path="listing" element={<Listing />} />
            <Route
              path="listing/company/:user_slug"
              element={<CustomerDetail />}
            />
            <Route
              path="company/:user_slug/listing/:listing_slug"
              element={<ListingDetail />}
            />
            <Route path="joblisting" element={<JobListing />} />

            <Route path="list-and-sell" element={<ListAndSell />} />

            {/* Influencers */}
            <Route path="influencers" element={<Influencer />} />

            <Route path="ai-tools" element={<AiTools />} />
            <Route path="agent/:agent_slug" element={<AgentDetialPage />} />
          </Route>

          {/* Community  */}
          <Route path="/:country/:lang/community" element={<CommunityLayout />}>
            <Route index element={<CommunityHome />} />
            <Route path="groups" element={<CommunityGroup />} />
            <Route path="creategroup" element={<CreateCommunityGroup />} />
            <Route path="groups/:slug" element={<GroupDetails />} />
            <Route path="friends" element={<Friends />} />
            <Route path="events" element={<Events />} />
            <Route path="events/:slug" element={<EventDetails />} />
            <Route path="polls" element={<Polls />} />
            <Route path="blogs" element={<Blog />} />
            <Route path="news" element={<News />} />
            <Route path="store" element={<Store />} />
            <Route path="listing" element={<Listing />} />

            {/* Community  */}
            <Route
              path="/:country/:lang/community"
              element={<CommunityLayout />}
            >
              <Route index element={<CommunityHome />} />
              <Route path="groups" element={<CommunityGroup />} />
              <Route path="creategroup" element={<CreateCommunityGroup />} />
              <Route path="groups/:slug" element={<GroupDetails />} />
              <Route path="friends" element={<Friends />} />
              <Route path="events" element={<Events />} />
              <Route path="eventdetails" element={<EventDetails />} />
              <Route path="polls" element={<Polls />} />
              <Route path="blogs" element={<Blog />} />
              <Route path="news" element={<News />} />
              <Route path="store" element={<Store />} />
              <Route path="listing" element={<Listing />} />
            </Route>
          </Route>

          <Route path="/:country/:lang/auth" element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="face" element={<Face />} />
            <Route path="otp-verification" element={<OtpVerification />} />
            <Route path="google" element={<GoogleAuthCallback />} />
          </Route>
        </Routes>
      </SearchProvider>
    </BrowserRouter>
  );
}

export default App;
