import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BoostYourBusiness from "../../components/Common/Community/BoostYourBusiness";
import SuggestedGroups from "../../components/Common/Community/SuggestedGroups";
import Categries from "../../components/Common/Community/Categries";
import ListYourCompany from "../../components/Common/Community/ListYourCompany";
import MyReel from "../../components/Common/Community/MyReel";
import Author from "../../components/Common/Community/Author";
import RightSideNav from "../../components/Common/Community/SideNav";
import Groups from "../../components/Common/Community/Groups";
import Recomandation from "../../components/Common/Community/Recomandation";
import Trendings from "../../components/Common/Community/Trendings";
import BtnPostSideBar from "../../components/Common/Community/BtnPostSideBar";

const Store = () => {
  const [listingData, setListingData] = useState([]);
  const { request: ListingRequest, response: listingResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
  useEffect(() => {
    ListingRequest("GET", "/listing?page_num=0&page_size=10");
  }, []);
  useEffect(() => {
    if (listingResponse && listingResponse.status === "SUCCESS") {
      setListingData(listingResponse.data || {});
    }
  }, [listingResponse]);
  return (
    <div>
      <MyReel />
      <Author />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <RightSideNav />
              <Groups />
              <Recomandation />
              <Trendings />
            </div>
            <div className="col-sm-6">
              <div className="store-page">
                <div className="row">
                  {listingData.length > 0 ? (
                    listingData.map((listing, index) => (
                      <div key={index} className="col-sm-6">
                        <div className="store-list">
                          <div className="imgeffect">
                            <div className="wishlist-store-list">
                              <a href="#">
                                <i className="fa-regular fa-heart" />
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/store/1.jpg" alt="alt" />
                            </a>{" "}
                          </div>
                          <div className="info-store-list">
                            <div className="non">
                              <h3>{listing.title}</h3>
                              <p>{listing.description}</p>
                            </div>
                            <a href="#">
                              <img
                                src={`${cloudFrontUrl}${listing.logo}`}
                                alt={`${listing.title}`}
                              />
                            </a>{" "}
                          </div>
                          <ul className="rating-store-list">
                            <li>
                              <i className="fa-solid fa-gas-pump" /> Open -
                              until 10PM
                            </li>
                            <li>
                              {" "}
                              <a href="#">
                                <i className="fa-solid fa-star" />{" "}
                                <i className="fa-regular fa-star" />
                                <i className="fa-regular fa-star" />
                                <i className="fa-regular fa-star" />
                                <i className="fa-regular fa-star" />
                              </a>
                              12 Reviews
                            </li>
                          </ul>
                          <ul className="contact-store-list">
                            <li>
                              <a href="tel:971509545000">
                                <img
                                  src="/assets/images/icons/phone.svg"
                                  className="phone-icon"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src="/assets/images/icons/retweet.svg"
                                  className="retweet-icon"
                                />
                              </a>
                            </li>
                            <li>
                              <div className="share-widget">
                                {" "}
                                <img
                                  src="/assets/images/icons/share-2.svg"
                                  className="btn-share-widget"
                                />{" "}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Feed available</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <BtnPostSideBar />
              <BoostYourBusiness />
              <SuggestedGroups />
              <Categries />
              <ListYourCompany />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
