import { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { cloudFrontUrl } from "../../config";
import { Swiper, SwiperSlide } from "swiper/react";

const AttractionTop = () => {
  const { request, response } = useRequest();
  const [attraction, setAttraction] = useState();
  const fetchLandingAttraction = async () => {
    await request("GET", "/public/attraction-top");
  };
  useEffect(() => {
    fetchLandingAttraction();
  }, []);

  useEffect(() => {
    if (response) {
      setAttraction(response.data);
    }
  }, [response]);

  return (
    <div className="similar-attraction-home">
      <h2>You might like these</h2>
      <Swiper
        spaceBetween={20}
        slidesPerView={4}
        speed={1000}
        loop={true}
        autoplay={{ delay:9999, disableOnInteraction: false }}
        navigation={{ nextEl:'.similar-attraction-next', prevEl:'.similar-attraction-prev', }}
        breakpoints={{
          320: { slidesPerView:1.2, spaceBetween:12, },
          480: { slidesPerView:2.2, spaceBetween:12, },
          768: { slidesPerView:2.2, spaceBetween:12, },
          960: { slidesPerView:4, spaceBetween:20, },
          }}
      >
        {attraction &&
          attraction.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="itembox ib-attraction">
                <div className="imgeffect">
                  <div className="ib-wishlist">
                    <a href="#">
                      <i className="fa-solid fa-heart"></i>
                    </a>
                  </div>
                  <a href="#">
                    <img
                      src={`${cloudFrontUrl}${item.image}`}
                      alt={item.image}
                    />
                  </a>
                </div>
                <div className="info-itembox">
                  <h3>{item.headline}</h3>
                  <p>{item.description}</p>
                  <div className="review-ib">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-regular fa-star"></i> 2,182 Reviews
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
export default AttractionTop;
