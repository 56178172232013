import React from 'react';
import './style.css';
import { useState } from 'react';

const services = [
  {
    id: 1,
    title: 'AI Image Editing',
    description: 'Lorem ipsum dolor essay sit amet consectetur',
    icon: 'https://myfinder.ai/assets/images/icons/Group%20(6).png',
  },
  {
    id: 2,
    title: 'AI Video Editing',
    description: 'Lorem ipsum dolor essay sit amet consectetur',
    icon: 'https://myfinder.ai/assets/images/icons/Group%20(7).png',
  },
  {
    id: 3,
    title: 'AI Content Writing',
    description: 'Lorem ipsum dolor essay sit amet consectetur',
    icon: 'https://myfinder.ai/assets/images/icons/Group.png',
  },
  {
    id: 4,
    title: 'AI Empty Space to Decorate',
    description: 'Lorem ipsum dolor essay sit amet consectetur',
    icon: 'https://myfinder.ai/assets/images/icons/Line_Spacing.png',
  },
  {
    id: 5,
    title: 'AI QR Code Generator',
    description: 'Lorem ipsum dolor essay sit amet consectetur',
    icon: 'https://myfinder.ai/assets/images/icons/Group%20(3).png',
  },
  {
    id: 6,
    title: 'Text to Video',
    description: 'Lorem ipsum dolor essay sit amet consectetur',
    icon: 'https://myfinder.ai/assets/images/icons/Group%20(2).png',
  },
  {
    id: 5,
    title: 'AI QR Code Generator',
    description: 'Lorem ipsum dolor essay sit amet consectetur',
    icon: 'https://myfinder.ai/assets/images/icons/Group%20(3).png',
  },
  {
    id: 8,
    title: 'Video to Video',
    description: 'Lorem ipsum dolor essay sit amet consectetur',
    icon: 'https://myfinder.ai/assets/images/icons/Video_Editor.png',
  },
  {
    id: 7,
    title: 'AI CRM',
    description: 'Lorem ipsum dolor essay sit amet consectetur',
    icon: 'https://myfinder.ai/assets/images/icons/Layout.png',
  },
  {
    id: 8,
    title: 'Image to Image',
    description: 'Lorem ipsum dolor essay sit amet consectetur',
    icon: 'https://myfinder.ai/assets/images/icons/Group%20(5).png',
  },
];

const ServiceCard = ({ title, description, icon, handleClick }) => (

  <div className='w-72'>
    <div className='card w-56 h-100 shadow-sm px-3 py-2 cursor-pointer' onClick={handleClick}>
      <div className='bg-[#f4f4f4] w-9 h-10 mb-2 rounded-[12px] flex items-center justify-center'>
        <img src={icon} alt={title} className='object-center' style={{ width: '32px', height: '32px' }} />
      </div>
      <h5 className='card-title poppins-semibold text-[15px]'>{title}</h5>
      <p className='card-text text-[12px] poppins-regular'>{description}</p>
    </div>
  </div>
);

const AiTools = ({ setShowModal }) => {

  const [popupMessage, setPopupMessage] = useState(null);

  const handleClick = (serviceTitle) => {
    setPopupMessage(`${serviceTitle} Coming Soon...`);
  };


  return (
    <div className='fixed row inset-0 bg-black bg-opacity-50 flex items-center justify-center z-index'>
      <div
        className='py-7 px-8 max-w-5xl grid relative grid-cols-4 gap-3 rounded-lg'
        style={{
          background: 'linear-gradient(260.04deg, #E7EEFD 0%, #F7D1F2 100%)',
        }}
      >
        <i
          className='fa-solid fa-close absolute -top-2 -right-2 bg-red-500 text-white cursor-pointer px-2 py-[7px] flex items-center rounded-full'
          onClick={() => setShowModal(false)}
        ></i>
        {services.map((service) => (
          <ServiceCard
            key={service.id}
            title={service.title}
            description={service.description}
            icon={service.icon}
            handleClick={() => handleClick(service.title)}
          />
        ))}
        {popupMessage && (
          <div className={`absolute min-w-96 h-72 flex flex-col gap-3 top-32 right-80 bg-white p-3 text-center items-center justify-center rounded-lg shadow-lg transform transition-all duration-300 ease-in-out ${popupMessage ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`} style={{ transition: 'all' }}>
            <div className='w-20 h-12 text-3xl mb-2'>
              <i class="fa-solid fa-circle-exclamation fa-2xl" style={{
                color: '#ddb0cb',
              }} />
            </div>
            <p className='text-xl'>{popupMessage}</p>
            <button
              className="mt-16 bg-red-500 text-white px-3 py-1 rounded"
              onClick={() => setPopupMessage(null)}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AiTools;
