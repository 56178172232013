import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const ListYourCompany = () => {
    const options = {
        items: 9,
        loop: true,
        autoplay: true,
        AnimationTimeline: 2000,
        animateOut: "slideOutUp",
        nav: true,
        dots: false,
        margin: 10,
        responsive: {
          1100: {
            items: 9,
          },
          724: {
            items: 7,
          },
          500: {
            items: 5,
          },
          370: {
            items: 3,
          },
        },
      };
  return (
    <>
      <div className="offer-slide">
        {/* <OwlCarousel className="owl-theme" {...options}> */}
                                <div className="swiper offer-slide-slick">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            {" "}
                                            <img src="/assets/images/reels-2.jpg" alt="alt" />
                                            <div className="cap-offer-slide">
                                                <h3>List your</h3>
                                                <a href="#" className="btn btn-primary">
                                                    <img src="/assets/images/icons/company.svg" alt="Company" />{" "}
                                                    Company
                                                </a>{" "}
                                                <a href="#" className="btn btn-secondary">
                                                    <img src="/assets/images/icons/service.svg" alt="Services" />{" "}
                                                    Services
                                                </a>{" "}
                                                <a href="#" className="btn btn-info">
                                                    <img src="/assets/images/icons/product.svg" alt="Products" />{" "}
                                                    Products
                                                </a>
                                                <h3>With us</h3>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            {" "}
                                            <img src="/assets/images/offer-slider.jpg" alt="alt" />
                                            <div className="cap-offer-slide">
                                                <h3>List your</h3>
                                                <a href="#" className="btn btn-primary">
                                                    <img src="/assets/images/icons/company.svg" alt="Company" />{" "}
                                                    Company
                                                </a>{" "}
                                                <a href="#" className="btn btn-secondary">
                                                    <img src="/assets/images/icons/service.svg" alt="Services" />{" "}
                                                    Services
                                                </a>{" "}
                                                <a href="#" className="btn btn-info">
                                                    <img src="/assets/images/icons/product.svg" alt="Products" />{" "}
                                                    Products
                                                </a>
                                                <h3>With us</h3>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            {" "}
                                            <img src="/assets/images/offer-slider.jpg" alt="alt" />
                                            <div className="cap-offer-slide">
                                                <h3>List your</h3>
                                                <a href="#" className="btn btn-primary">
                                                    <img src="/assets/images/icons/company.svg" alt="Company" />{" "}
                                                    Company
                                                </a>{" "}
                                                <a href="#" className="btn btn-secondary">
                                                    <img src="/assets/images/icons/service.svg" alt="Services" />{" "}
                                                    Services
                                                </a>{" "}
                                                <a href="#" className="btn btn-info">
                                                    <img src="/assets/images/icons/product.svg" alt="Products" />{" "}
                                                    Products
                                                </a>
                                                <h3>With us</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-pagination" />
                                </div>
                            </div>
    </>
  )
}

export default ListYourCompany
