import React, { useEffect, useState } from 'react';
import useRequest from '../../hooks/useRequest';
import { useParams, useNavigate } from 'react-router-dom';
import { useSearch } from '../../contexts/SearchContext';
import moment from 'moment/moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import { toast, ToastContainer } from 'react-toastify';

const JobDescription = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [jobData, setJobData] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [similarJobs, setSimilarJobs] = useState(null);
  const { slug } = useParams();
  const [apiSlug, setApiSlug] = useState(slug);
  const imageUrl = process.env.REACT_APP_CLOUDFRONT_URL;
  const { request: jobRequest, response: jobResponse } = useRequest();
  const { request: applyJobRequest, response: applyJobResponse } = useRequest();
  const { setSelectedRoute, formDataValues, setFormDataValues, setRouteSlug } =
    useSearch();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    file: null,
    jobId: jobData?.id,
  });

  console.log('Route paramas are ====>>>>>> ', params);

  useEffect(() => {
    setRouteSlug(params?.slug);
  }, [params]);
  useEffect(() => {
    setToken(localStorage.getItem('access_token'));
    setSelectedRoute('jobDetails');
    setRouteSlug(params?.slug);
    jobRequest('GET', `/public/jobs/${apiSlug}`);
  }, []);

  useEffect(() => {
    jobRequest('GET', `/public/jobs/${apiSlug}`);
  }, [apiSlug]);

  useEffect(() => {
    if (jobResponse && jobResponse.status === 'SUCCESS') {
      setIsLoading(false);
      setJobData(jobResponse.data);
    } else if (
      jobResponse &&
      jobResponse.status === 'FAIL' &&
      jobResponse.message === '[Job_not_found.]'
    ) {
      setJobData('No jobs found');
      setIsLoading(false);
    }
  }, [jobResponse]);

  useEffect(() => {
    if (formDataValues) {
      setFormData(formDataValues);
    }
  }, []);

  useEffect(() => {
    // Assuming jobData is set somewhere, e.g., from an API call
    if (jobData && jobData?.similar_jobs?.length > 0) {
      const firstFourJobs = jobData.similar_jobs.slice(0, 4);
      setSimilarJobs(firstFourJobs);
    }
  }, [jobData]);

  useEffect(() => {
    if (applyJobResponse?.status === 'SUCCESS') {
      toast.success('Applied for job successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 4000,
      });
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
        file: null,
        jobId: jobData?.id,
      });
    } else if (applyJobResponse?.status === 'FAIL') {
      toast.error('Failed', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 4000,
      });
    }
  }, [applyJobResponse]);

  const handleChange = (e) => {
    console.log('executing');
    const { name, value, type, files } = e.target;
    console.log('Console magic ==========>>>>>>>>>>>>>>˘', {
      name,
      value,
      type,
      files,
    });
    if (name === 'file') {
      console.log('File selected is ', e.target.files);
      setFormData({ ...formData, file: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object to send file and other data
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('mobile', formData.phone); // Changed to match API schema
    formDataToSend.append('message', formData.message);
    formDataToSend.append('job_id', jobData?.id); // Added job_id
    if (formData.file) {
      formDataToSend.append('resume', formData.file); // Changed to match API schema
    }

    if (token) {
      applyJobRequest('POST', '/apply-job', formDataToSend);
    } else {
      setFormDataValues(formData);
      navigate('/gb/en/auth/signup');
    }
  };

  console.log('Form data is ======>>>>>>>>>>> ', formData);

  return (
    <div>
      <div class='job-detail  bg-white pt-10'>
        <div class='container'>
          <div class='row'>
            <div class='col-sm-12'>
              <div class='row'>
                <div class='col-sm-8'>
                  <div class='top-md'>
                    <div class='title-md'>
                      <div class='flex-title-md'>
                        <div class='story-title-md'>
                          <img src={`${imageUrl}${jobData?.image}`} alt='alt' />
                        </div>
                        <div class='non'>
                          <h1>{jobData?.title}</h1>
                          <div class='rating-job-title'>
                            <span>Company Name</span>{' '}
                            <a href='#'>
                              <i class='fa-solid fa-star'></i>
                            </a>{' '}
                            4.5 Reviews
                          </div>
                          <ul class='list-title-md'>
                            <li>
                              <img
                                src='/assets/images/icons/location.svg'
                                alt='location'
                                class='bookmark-icon'
                              />
                              Dubai
                            </li>
                            <li>
                              <img
                                src='/assets/images/icons/clock.svg'
                                alt='clock'
                                class='bookmark-icon'
                              />
                              Full time
                            </li>
                            <li>
                              <img
                                src='/assets/images/icons/experience.svg'
                                alt='experience'
                                class='bookmark-icon'
                              />
                              {jobData?.experience}
                            </li>
                            <li>
                              <img
                                src='/assets/images/icons/not-disclosed.svg'
                                alt='not-disclosed'
                                class='bookmark-icon'
                              />
                              {jobData?.salary}
                            </li>
                          </ul>
                          <div class='btn-job-title'>
                            {' '}
                            <a href='#' class='btn btn-outline-primary'>
                              Send me jobs like this
                            </a>{' '}
                          </div>
                        </div>
                      </div>
                      <div class='share-vert'>
                        <a href='#'>
                          <img
                            src='/assets/images/icons/share-2.svg'
                            alt='share'
                          />
                        </a>{' '}
                        <a href='#'>
                          <img
                            src='/assets/images/icons/bookmark.svg'
                            alt='bookmark'
                            class='bookmark-icon'
                          />
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-sm-4'>
                  <div class='activity'>
                    <h3>Job Activity</h3>
                    <ul>
                      <li>
                        <div>
                          <img src='/assets/images/icons/eye.svg' alt='eye' />
                          158
                        </div>
                        <small>Unique Views</small>
                      </li>
                      <li>
                        <div>
                          <img
                            src='/assets/images/icons/bookmark.svg'
                            alt='bookmark'
                            class='bookmark-icon'
                          />
                          12
                        </div>
                        <small>Favourite</small>
                      </li>
                      <li>
                        <div>
                          <img
                            src='/assets/images/icons/phone-3.svg'
                            alt='phone'
                          />
                          4
                        </div>
                        <small>Contacted</small>
                      </li>
                      <li>
                        <div>
                          <img
                            src='/assets/images/icons/google.svg'
                            alt='google'
                          />
                          858
                        </div>
                        <small>Reviews</small>
                      </li>
                    </ul>
                    <div class='mf-reviews'>
                      <div class='icons-mf-reviews'>
                        {' '}
                        <img src='/assets/images/favicon.png' alt='alt' />{' '}
                        <a href='#'>
                          <i class='fa-regular fa-star'></i>
                          <i class='fa-regular fa-star'></i>
                          <i class='fa-regular fa-star'></i>
                          <i class='fa-regular fa-star'></i>
                          <i class='fa-regular fa-star'></i>
                        </a>{' '}
                        <small>0 Reviews</small>{' '}
                      </div>
                      <button>Submit Review</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class='row'>
                <div class='col-sm-8'>
                  <div class='benefits-perks'>
                    <div class='heading'>
                      <h2>Benefits & Perks</h2>
                    </div>
                    <ul>
                      <li>
                        <i class='fa-solid fa-notes-medical'></i> Health
                        Insurance{' '}
                      </li>
                      <li>
                        <i class='fa-solid fa-house-laptop'></i> Work for home{' '}
                      </li>
                      <li>
                        <i class='fa-solid fa-chalkboard-user'></i> Soft Skill
                        Training{' '}
                      </li>
                      <li>
                        <i class='fa-solid fa-laptop'></i> Job Training{' '}
                      </li>
                      <li>
                        <i class='fa-solid fa-mug-saucer'></i> Cafeteria{' '}
                      </li>
                      <li>
                        <i class='fa-solid fa-car'></i> Transport{' '}
                      </li>
                    </ul>
                  </div>
                  <div class='job-description'>
                    <div class='heading'>
                      <h2>Job description</h2>
                    </div>
                    <div class='row'>
                      <div class='col-sm-8'>
                        <p>{jobData?.description}</p>
                        <div class='description-md'>
                          <div class='heading'>
                            <h2>Responsibilities</h2>
                          </div>
                          <p>{jobData?.responsibility}</p>
                        </div>
                        <div class='description-md'>
                          <div class='heading'>
                            <h2>Requirement</h2>
                          </div>
                          <p>{jobData?.requirement}</p>
                        </div>
                      </div>
                      <div class='col-sm-4'>
                        <div class='role-job'>
                          <small>Role</small>
                          <h4>Business Development Executive (BDE)</h4>
                        </div>
                        <div class='role-job'>
                          <small>Industry Type</small>
                          <h4>Miscellaneous</h4>
                        </div>
                        <div class='role-job'>
                          {' '}
                          <small>Department</small>
                          <h4>Sales & Business Development</h4>
                        </div>
                        <div class='role-job'>
                          <small>Employment Type</small>
                          <h4>Full Time, Permanent</h4>
                        </div>
                        <div class='role-job'>
                          {' '}
                          <small>Education</small>
                          <h4>UG: Any Graduate</h4>
                        </div>
                      </div>
                    </div>
                    <div class='description-md'>
                      <div class='heading'>
                        <h2>About company</h2>
                      </div>
                      <p>
                        Ceasefire Headquartered in India, Ceasefire is a fast
                        emerging global fire safety brand, known for
                        manufacturing globally-certified (BRE LPCB, BSI Kite
                        Mark, VDS), firefighting solutions that are built at the
                        very forefront of technology. Ceasefire today is a
                        leading manufacturer of a 360 degree unified fire
                        fighting product portfolio and a fast emerging global
                        name that protects millions across the globe. The
                        company has set its foot in the UK with an aim to
                        fulfill the market gap by offering a holistic fire
                        safety range to the customers, all under one banner.
                        With massive investment in terms of office.
                      </p>
                    </div>
                  </div>
                  <div class='location'>
                    <div class='heading'>
                      <h2>Company Info</h2>
                    </div>
                    <p>
                      <i class='fa-solid fa-location-dot'></i> Al Raha Beach,
                      Abu Dhabi, UAE
                    </p>
                    <iframe
                      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462562.6509516108!2d54.94755498679835!3d25.07575945545691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1644662298002!5m2!1sen!2sin'
                      width='600'
                      height='450'
                      //   style='border:0;'
                      allowfullscreen=''
                      loading='lazy'
                    ></iframe>
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='enquiry'>
                    <h3 className='my-1'>Apply fo this job</h3>
                    <form onSubmit={handleSubmit} className='mt-3'>
                      <label>Your name *</label>
                      <input
                        type='text'
                        name='name'
                        placeholder='e.g Mohamed'
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      <label>Your email *</label>
                      <input
                        type='email'
                        name='email'
                        placeholder='e.g abscf@gmail.com'
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label>Upload your CV / Resume</label>
                      <div className='attached-enquiry'>
                        <input
                          type='file'
                          name='file'
                          onChange={handleChange}
                        />
                        {formData?.file && formData?.file?.name
                          ? formData?.file?.name
                          : 'Select and Upload your CV / Resume'}
                        <i className='fa-solid fa-arrow-up-from-bracket'></i>
                      </div>
                      <label>Your phone *</label>
                      <input
                        type='text'
                        name='phone'
                        placeholder='e.g +97142 524242'
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                      <label>Your message *</label>
                      <textarea
                        name='message'
                        placeholder='Sameer! I saw your ad on MyFinder. When is it available for viewing? Thanks.'
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                      <button type='submit'>
                        <i className='fa-solid fa-envelope'></i> Apply Job
                      </button>
                    </form>
                    <small>
                      By clicking on 'Inquire Now', I agree to the MyFinder{' '}
                      <a href='#'>Terms & Conditions</a> and{' '}
                      <a href='#'>Privacy Policy</a>
                    </small>{' '}
                  </div>
                </div>

                <div className='row mb-lg-4 mx-auto'>
                  <div class='heading mx-auto'>
                    <h2>Similar Job</h2>
                    <button
                      href='#'
                      class='btn-heading'
                      onClick={() => navigate('/gb/en/joblisting')}
                    >
                      View All<i class='fa-solid fa-chevron-right'></i>
                    </button>{' '}
                  </div>
                  <div>
                    <Swiper
                      // Use the modules you need
                      spaceBetween={30} // Space between slides
                      slidesPerView={4} // Number of slides per view
                      pagination={{ clickable: true }} // Enable pagination bullets
                    >
                      {similarJobs &&
                        similarJobs.map((job) => (
                          <SwiperSlide key={job.id}>
                            {' '}
                            {/* Add a key prop */}
                            <div
                              className='itembox ib-job'
                              onClick={() => setApiSlug(job?.slug)}
                            >
                              <div className='imgeffect'>
                                <div className='ib-date'>
                                  {moment(job?.updated_at).fromNow()}
                                </div>
                                <div className='ib-wishlist'>
                                  <a href='#'>
                                    <i className='fa-regular fa-heart'></i>
                                  </a>
                                </div>
                                <a href='#'>
                                  <img
                                    src='/assets/images/job/1.jpg'
                                    alt='Job Image'
                                  />
                                </a>
                              </div>
                              <div className='info-itembox flex-itembox'>
                                <div className='non'>
                                  <h3>
                                    <a href='#'>{job?.title}</a>{' '}
                                    {/* Use job.title */}
                                  </h3>
                                  <h4>{job?.companyName}</h4>{' '}
                                  {/* Use job.companyName */}
                                  <p>
                                    Lorem ipsum dolor sit ametur adipcing elit.
                                  </p>
                                </div>
                                <a href='#'>
                                  <img
                                    src='/assets/images/logo-1.png'
                                    alt='Company Logo'
                                  />
                                </a>
                              </div>
                              <ul className='data-itembox'>
                                <li>
                                  <img
                                    src='/assets/images/icons/clock.svg'
                                    alt='clock'
                                  />
                                  {job.jobType} {/* Use job.jobType */}
                                </li>
                                <li>
                                  <img
                                    src='/assets/images/icons/location.svg'
                                    alt='location'
                                  />
                                  {job.location} {/* Use job.location */}
                                </li>
                              </ul>
                              <ul className='data-itembox'>
                                <li>
                                  <img
                                    src='/assets/images/icons/not-disclosed.svg'
                                    alt='salary'
                                  />
                                  Salary - AED {job.salary}{' '}
                                  {/* Use job.salary */}
                                </li>
                                <li>
                                  <a href='#'>
                                    <img
                                      src='/assets/images/icons/location.svg'
                                      alt='apply'
                                    />
                                    Apply Now
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div class='offer-slide'>
                  <div class='swiper offer-slick'>
                    <div class='swiper-wrapper'>
                      <div class='swiper-slide'>
                        {' '}
                        <img src='/assets/images/offer-slider.jpg' alt='alt' />
                        <div class='caption-offer-slide'>
                          <h3>Headline</h3>
                          <h4>Sub headline</h4>
                        </div>
                      </div>
                      <div class='swiper-slide'>
                        {' '}
                        <img src='/assets/images/offer-slider.jpg' alt='alt' />
                        <div class='caption-offer-slide'>
                          <h3>Headline</h3>
                          <h4>Sub headline</h4>
                        </div>
                      </div>
                      <div class='swiper-slide'>
                        {' '}
                        <img src='/assets/images/offer-slider.jpg' alt='alt' />
                        <div class='caption-offer-slide'>
                          <h3>Headline</h3>
                          <h4>Sub headline</h4>
                        </div>
                      </div>
                      <div class='swiper-slide'>
                        {' '}
                        <img src='/assets/images/offer-slider.jpg' alt='alt' />
                        <div class='caption-offer-slide'>
                          <h3>Headline</h3>
                          <h4>Sub headline</h4>
                        </div>
                      </div>
                      <div class='swiper-slide'>
                        {' '}
                        <img src='/assets/images/offer-slider.jpg' alt='alt' />
                        <div class='caption-offer-slide'>
                          <h3>Headline</h3>
                          <h4>Sub headline</h4>
                        </div>
                      </div>
                    </div>
                    <div class='swiper-pagination'></div>
                  </div>
                </div>
                <div class='news-updates '>
                  <div class='heading mb-3 mt-5'>
                    <h2>News & Updates</h2>
                    <a href='#' class='btn-heading'>
                      View All<i class='fa-solid fa-chevron-right'></i>
                    </a>{' '}
                  </div>
                  <div class='swiper news-ml-slick'>
                    <div class='swiper-wrapper'>
                      <div class='swiper-slide'>
                        <div class='itembox ib-news'>
                          <div class='imgeffect'>
                            <div class='ib-date'>5 Days ago</div>
                            <div class='ib-wishlist'>
                              <a href='#'>
                                <i class='fa-regular fa-heart'></i>
                              </a>
                            </div>
                            <a href='#'>
                              <img src='/assets/images/news/1.jpg' alt='alt' />
                            </a>
                          </div>
                          <div class='info-itembox'>
                            <h3>
                              <a href='#'>Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur
                              adipiscing elit ru trum blandit justo, id rutruvl
                              9m diam eleme ntumv eget.Aliquam lacinia
                              consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class='swiper-slide'>
                        <div class='itembox ib-news'>
                          <div class='imgeffect'>
                            <div class='ib-date'>5 Days ago</div>
                            <div class='ib-wishlist'>
                              <a href='#'>
                                <i class='fa-regular fa-heart'></i>
                              </a>
                            </div>
                            <a href='#'>
                              <img src='/assets/images/news/2.jpg' alt='alt' />
                            </a>
                          </div>
                          <div class='info-itembox'>
                            <h3>
                              <a href='#'>Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur
                              adipiscing elit ru trum blandit justo, id rutruvl
                              9m diam eleme ntumv eget.Aliquam lacinia
                              consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class='swiper-slide'>
                        <div class='itembox ib-news'>
                          <div class='imgeffect'>
                            <div class='ib-date'>5 Days ago</div>
                            <div class='ib-wishlist'>
                              <a href='#'>
                                <i class='fa-regular fa-heart'></i>
                              </a>
                            </div>
                            <a href='#'>
                              <img src='/assets/images/news/3.jpg' alt='alt' />
                            </a>
                          </div>
                          <div class='info-itembox'>
                            <h3>
                              <a href='#'>Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur
                              adipiscing elit ru trum blandit justo, id rutruvl
                              9m diam eleme ntumv eget.Aliquam lacinia
                              consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class='swiper-slide'>
                        <div class='itembox ib-news'>
                          <div class='imgeffect'>
                            <div class='ib-date'>5 Days ago</div>
                            <div class='ib-wishlist'>
                              <a href='#'>
                                <i class='fa-regular fa-heart'></i>
                              </a>
                            </div>
                            <a href='#'>
                              <img src='/assets/images/news/4.jpg' alt='alt' />
                            </a>
                          </div>
                          <div class='info-itembox'>
                            <h3>
                              <a href='#'>Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur
                              adipiscing elit ru trum blandit justo, id rutruvl
                              9m diam eleme ntumv eget.Aliquam lacinia
                              consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='app-download'>
                  <div class='row'>
                    <div class='col-sm-4'>
                      <img
                        src='/assets/images/app-download.png'
                        alt='app'
                        class='pic-app-download'
                      />
                    </div>
                    <div class='col-sm-8'>
                      <h2>
                        Available at{' '}
                        <small>
                          Lorem ipsum dolor sit amet consectetur adipiscing elit
                        </small>
                      </h2>
                      <div class='btn-app-download'>
                        {' '}
                        <a href='#'>
                          <img
                            src='/assets/images/icons/android.svg'
                            alt='android'
                          />
                        </a>{' '}
                        <a href='#'>
                          <img src='/assets/images/icons/ios.svg' alt='ios' />
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default JobDescription;
