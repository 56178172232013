import React from "react"

const ListAndSell = () => {
  return (
    <>
      <div className="module-listing main-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-ml">
                <div className="flex-wraper">
                  <h1>Find Companies / Profiles (2684)</h1>
                  <div className="filter-ml">
                    <ul className="btn-listview">
                      <li className="map-lv"><a href="#"><img src="/assets/images/icons/location.svg" alt="location"
                        ondragstart="return false" /> Map</a></li>
                      <li className="selected"><a href="#"><img src="/assets/images/icons/thumb.svg" alt="thumb"
                        ondragstart="return false" /></a></li>
                      <li><a href="#"><img src="/assets/images/icons/list.svg" alt="list" ondragstart="return false" /></a></li>
                    </ul>
                    <div className="sort-filter">
                      <div className="field-sort-filter">
                        <select>
                          <option selected="selected">Category</option>
                          <option value="Property">Property</option>
                          <option value="Motors">Motors</option>
                          <option value="Jobs">Jobs</option>
                        </select>
                      </div>
                      <div className="field-sort-filter">
                        <select>
                          <option selected="selected">Subcategory</option>
                          <option value="Entertainment">Entertainment</option>
                          <option value="Restaurants">Restaurants</option>
                          <option value="Rooftops">Rooftops</option>
                        </select>
                      </div>
                      <div className="field-sort-filter">
                        <select>
                          <option selected="selected">Sort by</option>
                          <option value="New to Old">New to Old</option>
                          <option value="Old to New">Old to New</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="category-ml">
                <div className="flex-wraper">
                  <div className="swiper category-ml-slick">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide"><a href="#">Property <span>(243)</span></a></div>
                      <div className="swiper-slide"><a href="#">Crypto coin <span>(73)</span></a></div>
                      <div className="swiper-slide"><a href="#">Motors <span>(243)</span></a></div>
                      <div className="swiper-slide"><a href="#">Jobs <span>(135)</span></a></div>
                      <div className="swiper-slide"><a href="#">Hotels <span>(26)</span></a></div>
                      <div className="swiper-slide"><a href="#">Talents <span>(26)</span></a></div>
                      <div className="swiper-slide"><a href="#">Buy & Sell <span>(26)</span></a></div>
                    </div>
                  </div>
                  <div className="more-category"> <a href="#">More</a> </div>
                </div>
              </div>
              <div className="all-ml">
                <div className="row">
                  <div className="col-sm-9">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="itembox ib-listing">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                            <a href="#"><img src="/assets/images/listing/1.jpg" alt="alt" /></a>
                          </div>
                          <div className="info-itembox flex-itembox">
                            <div className="non">
                              <h3><a href="#">Company / Profile Name</a></h3>
                              <p>Antler is the investor backing the world's most driven founders, from day zero to
                                greatness.</p>
                            </div>
                            <a href="#"><img src="/assets/images/logo-1.png" alt="alt" /></a>
                          </div>
                          <ul className="data-itembox">
                            <li><img src="/assets/images/icons/location.svg" alt="list" ondragstart="return false" />Dubai, UAE</li>
                            <li className="review-ib"><img src="/assets/images/icons/google.svg" alt="list" ondragstart="return false" />
                              <a href="#"><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i
                                className="fa-solid fa-star"></i><i className="fa-regular fa-star"></i><i
                                  className="fa-regular fa-star"></i></a> <strong>4.5</strong></li>
                          </ul>
                          <ul className="data-itembox">
                            <li><a href="#"><img src="/assets/images/icons/enquiry.svg" alt="enquiry"
                              ondragstart="return false" />Send a Inquiry</a></li>
                            <li><a href="#"><img src="/assets/images/icons/ai.svg" alt="ai" ondragstart="return false" />Fredo AI
                              Chatbot</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="itembox ib-listing">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                            <a href="#"><img src="/assets/images/listing/2.jpg" alt="alt" /></a>
                          </div>
                          <div className="info-itembox flex-itembox">
                            <div className="non">
                              <h3><a href="#">Company / Profile Name</a></h3>
                              <p>Antler is the investor backing the world's most driven founders, from day zero to
                                greatness.</p>
                            </div>
                            <a href="#"><img src="/assets/images/logo-1.png" alt="alt" /></a>
                          </div>
                          <ul className="data-itembox">
                            <li><img src="/assets/images/icons/location.svg" alt="list" ondragstart="return false" />Dubai, UAE</li>
                            <li className="review-ib"><img src="/assets/images/icons/google.svg" alt="list" ondragstart="return false" />
                              <a href="#"><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i
                                className="fa-solid fa-star"></i><i className="fa-regular fa-star"></i><i
                                  className="fa-regular fa-star"></i></a> <strong>4.5</strong></li>
                          </ul>
                          <ul className="data-itembox">
                            <li><a href="#"><img src="/assets/images/icons/enquiry.svg" alt="enquiry"
                              ondragstart="return false" />Send a Inquiry</a></li>
                            <li><a href="#"><img src="/assets/images/icons/ai.svg" alt="ai" ondragstart="return false" />Fredo AI
                              Chatbot</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="itembox ib-listing">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                            <a href="#"><img src="/assets/images/listing/3.jpg" alt="alt" /></a>
                          </div>
                          <div className="info-itembox flex-itembox">
                            <div className="non">
                              <h3><a href="#">Company / Profile Name</a></h3>
                              <p>Antler is the investor backing the world's most driven founders, from day zero to
                                greatness.</p>
                            </div>
                            <a href="#"><img src="/assets/images/logo-1.png" alt="alt" /></a>
                          </div>
                          <ul className="data-itembox">
                            <li><img src="/assets/images/icons/location.svg" alt="list" ondragstart="return false" />Dubai, UAE</li>
                            <li className="review-ib"><img src="/assets/images/icons/google.svg" alt="list" ondragstart="return false" />
                              <a href="#"><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i
                                className="fa-solid fa-star"></i><i className="fa-regular fa-star"></i><i
                                  className="fa-regular fa-star"></i></a> <strong>4.5</strong></li>
                          </ul>
                          <ul className="data-itembox">
                            <li><a href="#"><img src="/assets/images/icons/enquiry.svg" alt="enquiry"
                              ondragstart="return false" />Send a Inquiry</a></li>
                            <li><a href="#"><img src="/assets/images/icons/ai.svg" alt="ai" ondragstart="return false" />Fredo AI
                              Chatbot</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="itembox ib-listing">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                            <a href="#"><img src="/assets/images/listing/3.jpg" alt="alt" /></a>
                          </div>
                          <div className="info-itembox flex-itembox">
                            <div className="non">
                              <h3><a href="#">Company / Profile Name</a></h3>
                              <p>Antler is the investor backing the world's most driven founders, from day zero to
                                greatness.</p>
                            </div>
                            <a href="#"><img src="/assets/images/logo-1.png" alt="alt" /></a>
                          </div>
                          <ul className="data-itembox">
                            <li><img src="/assets/images/icons/location.svg" alt="list" ondragstart="return false" />Dubai, UAE</li>
                            <li className="review-ib"><img src="/assets/images/icons/google.svg" alt="list" ondragstart="return false" />
                              <a href="#"><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i
                                className="fa-solid fa-star"></i><i className="fa-regular fa-star"></i><i
                                  className="fa-regular fa-star"></i></a> <strong>4.5</strong></li>
                          </ul>
                          <ul className="data-itembox">
                            <li><a href="#"><img src="/assets/images/icons/enquiry.svg" alt="enquiry"
                              ondragstart="return false" />Send a Inquiry</a></li>
                            <li><a href="#"><img src="/assets/images/icons/ai.svg" alt="ai" ondragstart="return false" />Fredo AI
                              Chatbot</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="itembox ib-listing">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                            <a href="#"><img src="/assets/images/listing/2.jpg" alt="alt" /></a>
                          </div>
                          <div className="info-itembox flex-itembox">
                            <div className="non">
                              <h3><a href="#">Company / Profile Name</a></h3>
                              <p>Antler is the investor backing the world's most driven founders, from day zero to
                                greatness.</p>
                            </div>
                            <a href="#"><img src="/assets/images/logo-1.png" alt="alt" /></a>
                          </div>
                          <ul className="data-itembox">
                            <li><img src="/assets/images/icons/location.svg" alt="list" ondragstart="return false" />Dubai, UAE</li>
                            <li className="review-ib"><img src="/assets/images/icons/google.svg" alt="list" ondragstart="return false" />
                              <a href="#"><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i
                                className="fa-solid fa-star"></i><i className="fa-regular fa-star"></i><i
                                  className="fa-regular fa-star"></i></a> <strong>4.5</strong></li>
                          </ul>
                          <ul className="data-itembox">
                            <li><a href="#"><img src="/assets/images/icons/enquiry.svg" alt="enquiry"
                              ondragstart="return false" />Send a Inquiry</a></li>
                            <li><a href="#"><img src="/assets/images/icons/ai.svg" alt="ai" ondragstart="return false" />Fredo AI
                              Chatbot</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="itembox ib-listing">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                            <a href="#"><img src="/assets/images/listing/1.jpg" alt="alt" /></a>
                          </div>
                          <div className="info-itembox flex-itembox">
                            <div className="non">
                              <h3><a href="#">Company / Profile Name</a></h3>
                              <p>Antler is the investor backing the world's most driven founders, from day zero to
                                greatness.</p>
                            </div>
                            <a href="#"><img src="/assets/images/logo-1.png" alt="alt" /></a>
                          </div>
                          <ul className="data-itembox">
                            <li><img src="/assets/images/icons/location.svg" alt="list" ondragstart="return false" />Dubai, UAE</li>
                            <li className="review-ib"><img src="/assets/images/icons/google.svg" alt="list" ondragstart="return false" />
                              <a href="#"><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i
                                className="fa-solid fa-star"></i><i className="fa-regular fa-star"></i><i
                                  className="fa-regular fa-star"></i></a> <strong>4.5</strong></li>
                          </ul>
                          <ul className="data-itembox">
                            <li><a href="#"><img src="/assets/images/icons/enquiry.svg" alt="enquiry"
                              ondragstart="return false" />Send a Inquiry</a></li>
                            <li><a href="#"><img src="/assets/images/icons/ai.svg" alt="ai" ondragstart="return false" />Fredo AI
                              Chatbot</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="itembox ib-listing">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                            <a href="#"><img src="/assets/images/listing/1.jpg" alt="alt" /></a>
                          </div>
                          <div className="info-itembox flex-itembox">
                            <div className="non">
                              <h3><a href="#">Company / Profile Name</a></h3>
                              <p>Antler is the investor backing the world's most driven founders, from day zero to
                                greatness.</p>
                            </div>
                            <a href="#"><img src="/assets/images/logo-1.png" alt="alt" /></a>
                          </div>
                          <ul className="data-itembox">
                            <li><img src="/assets/images/icons/location.svg" alt="list" ondragstart="return false" />Dubai, UAE</li>
                            <li className="review-ib"><img src="/assets/images/icons/google.svg" alt="list" ondragstart="return false" />
                              <a href="#"><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i
                                className="fa-solid fa-star"></i><i className="fa-regular fa-star"></i><i
                                  className="fa-regular fa-star"></i></a> <strong>4.5</strong></li>
                          </ul>
                          <ul className="data-itembox">
                            <li><a href="#"><img src="/assets/images/icons/enquiry.svg" alt="enquiry"
                              ondragstart="return false" />Send a Inquiry</a></li>
                            <li><a href="#"><img src="/assets/images/icons/ai.svg" alt="ai" ondragstart="return false" />Fredo AI
                              Chatbot</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="itembox ib-listing">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                            <a href="#"><img src="/assets/images/listing/3.jpg" alt="alt" /></a>
                          </div>
                          <div className="info-itembox flex-itembox">
                            <div className="non">
                              <h3><a href="#">Company / Profile Name</a></h3>
                              <p>Antler is the investor backing the world's most driven founders, from day zero to
                                greatness.</p>
                            </div>
                            <a href="#"><img src="/assets/images/logo-1.png" alt="alt" /></a>
                          </div>
                          <ul className="data-itembox">
                            <li><img src="/assets/images/icons/location.svg" alt="list" ondragstart="return false" />Dubai, UAE</li>
                            <li className="review-ib"><img src="/assets/images/icons/google.svg" alt="list" ondragstart="return false" />
                              <a href="#"><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i
                                className="fa-solid fa-star"></i><i className="fa-regular fa-star"></i><i
                                  className="fa-regular fa-star"></i></a> <strong>4.5</strong></li>
                          </ul>
                          <ul className="data-itembox">
                            <li><a href="#"><img src="/assets/images/icons/enquiry.svg" alt="enquiry"
                              ondragstart="return false" />Send a Inquiry</a></li>
                            <li><a href="#"><img src="/assets/images/icons/ai.svg" alt="ai" ondragstart="return false" />Fredo AI
                              Chatbot</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="itembox ib-listing">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                            <a href="#"><img src="/assets/images/listing/2.jpg" alt="alt" /></a>
                          </div>
                          <div className="info-itembox flex-itembox">
                            <div className="non">
                              <h3><a href="#">Company / Profile Name</a></h3>
                              <p>Antler is the investor backing the world's most driven founders, from day zero to
                                greatness.</p>
                            </div>
                            <a href="#"><img src="/assets/images/logo-1.png" alt="alt" /></a>
                          </div>
                          <ul className="data-itembox">
                            <li><img src="/assets/images/icons/location.svg" alt="list" ondragstart="return false" />Dubai, UAE</li>
                            <li className="review-ib"><img src="/assets/images/icons/google.svg" alt="list" ondragstart="return false" />
                              <a href="#"><i className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i
                                className="fa-solid fa-star"></i><i className="fa-regular fa-star"></i><i
                                  className="fa-regular fa-star"></i></a> <strong>4.5</strong></li>
                          </ul>
                          <ul className="data-itembox">
                            <li><a href="#"><img src="/assets/images/icons/enquiry.svg" alt="enquiry"
                              ondragstart="return false" />Send a Inquiry</a></li>
                            <li><a href="#"><img src="/assets/images/icons/ai.svg" alt="ai" ondragstart="return false" />Fredo AI
                              Chatbot</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="btn-load">
                      <button>Load More</button>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="sidebar-ml">
                      <div className="video-ml"> <img src="/assets/images/video-tutorial.jpg" alt="alt" />
                        <div className="btn-video-ml">
                          <button data-bs-target="#video-modal" data-bs-toggle="modal" role="button"><i
                            className="fa-solid fa-play"></i></button>
                        </div>
                      </div>
                      <div className="modal fade" id="video-modal" aria-hidden="true" aria-labelledby="video-modal"
                        tabindex="-1">
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                              className="fas fa-times"></i></button>
                            <div className="modal-body">
                              <iframe src="https://www.youtube.com/embed/o7mcXmlF8ys?si=dRZyX7fBIe2CbMPp"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="register-ml"> <img src="/assets/images/thumb-register.jpg" alt="alt" />
                        <div className="caption-register-ml">
                          <h3>Register Now</h3>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <a href="#" className="btn-sidebar-ml">Register Now <i className="fa-solid fa-arrow-right-long"></i></a>
                        </div>
                      </div>
                      <div className="ads-ml"> <a href="#" target="_blank"><img src="/assets/images/ads-sidebar.jpg" alt="alt" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="offer-slide">
                <div className="swiper offer-slick">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide"> <img src="/assets/images/offer-slider.jpg" alt="alt" />
                      <div className="caption-offer-slide">
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className="swiper-slide"> <img src="/assets/images/offer-slider.jpg" alt="alt" />
                      <div className="caption-offer-slide">
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className="swiper-slide"> <img src="/assets/images/offer-slider.jpg" alt="alt" />
                      <div className="caption-offer-slide">
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className="swiper-slide"> <img src="/assets/images/offer-slider.jpg" alt="alt" />
                      <div className="caption-offer-slide">
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                    <div className="swiper-slide"> <img src="/assets/images/offer-slider.jpg" alt="alt" />
                      <div className="caption-offer-slide">
                        <h3>Headline</h3>
                        <h4>Sub headline</h4>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
              </div>
              <div className="news-updates">
                <div className="heading">
                  <h2>News & Updates</h2>
                  <a href="#" className="btn-heading">View All<i className="fa-solid fa-chevron-right"></i></a>
                </div>
                <div className="swiper news-ml-slick">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="itembox ib-news">
                        <div className="imgeffect">
                          <div className="ib-date">5 Days ago</div>
                          <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                          <a href="#"><img src="/assets/images/news/1.jpg" alt="alt" /></a>
                        </div>
                        <div className="info-itembox">
                          <h3><a href="#">Headline</a></h3>
                          <p>Lorem ipsum dolor sit afsmbet, consectetur adipiscing elit ru trum blandit justo, id rutruvl 9m
                            diam eleme ntumv eget.Aliquam lacinia consectetur.</p>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="itembox ib-news">
                        <div className="imgeffect">
                          <div className="ib-date">5 Days ago</div>
                          <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                          <a href="#"><img src="/assets/images/news/2.jpg" alt="alt" /></a>
                        </div>
                        <div className="info-itembox">
                          <h3><a href="#">Headline</a></h3>
                          <p>Lorem ipsum dolor sit afsmbet, consectetur adipiscing elit ru trum blandit justo, id rutruvl 9m
                            diam eleme ntumv eget.Aliquam lacinia consectetur.</p>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="itembox ib-news">
                        <div className="imgeffect">
                          <div className="ib-date">5 Days ago</div>
                          <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                          <a href="#"><img src="/assets/images/news/3.jpg" alt="alt" /></a>
                        </div>
                        <div className="info-itembox">
                          <h3><a href="#">Headline</a></h3>
                          <p>Lorem ipsum dolor sit afsmbet, consectetur adipiscing elit ru trum blandit justo, id rutruvl 9m
                            diam eleme ntumv eget.Aliquam lacinia consectetur.</p>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="itembox ib-news">
                        <div className="imgeffect">
                          <div className="ib-date">5 Days ago</div>
                          <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                          <a href="#"><img src="/assets/images/news/4.jpg" alt="alt" /></a>
                        </div>
                        <div className="info-itembox">
                          <h3><a href="#">Headline</a></h3>
                          <p>Lorem ipsum dolor sit afsmbet, consectetur adipiscing elit ru trum blandit justo, id rutruvl 9m
                            diam eleme ntumv eget.Aliquam lacinia consectetur.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="app-download">
                <div className="row">
                  <div className="col-sm-4"><img src="/assets/images/app-download.png" alt="app" className="pic-app-download" /></div>
                  <div className="col-sm-8">
                    <h2>Available at <small>Lorem ipsum dolor sit amet consectetur adipiscing elit</small></h2>
                    <div className="btn-app-download"> <a href="#"><img src="/assets/images/icons/android.svg" alt="android" /></a> <a
                      href="#"><img src="/assets/images/icons/ios.svg" alt="ios" /></a> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default ListAndSell;
