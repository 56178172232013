import React, { useEffect, useState } from 'react';
import MyReel from '../../../components/Common/Community/MyReel';
import Author from '../../../components/Common/Community/Author';
import RightSideNav from '../../../components/Common/Community/SideNav';
import Groups from '../../../components/Common/Community/Groups';
import Recomandation from '../../../components/Common/Community/Recomandation';
import Trendings from '../../../components/Common/Community/Trendings';
import { Link, useParams } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';

const GroupDetails = () => {
  const { slug } = useParams(); 
  const [groupDetails, setGroupDetails] = useState(null);
  const { request: detailsRequest, response: detailsResponse, error } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    detailsRequest("GET", `/groups/${slug}`);
  }, [slug]);

  useEffect(() => {
    if (detailsResponse && detailsResponse.status === "SUCCESS") {
      setGroupDetails(detailsResponse.data || {});
    }
  }, [detailsResponse]);

  // Handle loading and error states
  if (!groupDetails) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading group details.</div>;
  }

  return (
    <div>
      <MyReel />
      <Author />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <RightSideNav />
              <Groups />
              <Recomandation />
              <Trendings />
            </div>
            <div className="col-sm-9">
              <div className="group-detail-page">
                <div className="banner-group-detail">
                  <img src={`${cloudFrontUrl}${groupDetails.cover_image}`} alt="Group Cover" />
                </div>
                <div className="created-group">
                  <h2 className="heading-community">
                    {groupDetails.name}{" "}
                    <Link to="/">
                      <i className="fa-solid fa-user-group" /> View Requests
                    </Link>
                  </h2>
                  <div className="row">
                    <div className="col-sm-9">
                      <div className="add-created-group">
                        <button>
                          <i className="fa-regular fa-plus" /> Add a cover photo
                        </button>
                        <button>
                          <i className="fa-regular fa-plus" /> Add description
                        </button>
                      </div>
                      <div className="privacy-created-group">
                        <h4>
                          <i className="fa-solid fa-user-lock" /> {groupDetails.privacy}
                        </h4>
                        <p>
                          Only members can see who's in the group and what they post.
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="btn-created-group">
                        <Link to="/" className="btn btn-primary">
                          <i className="fa-regular fa-plus" /> Invite Friends
                        </Link>
                        <Link to="/" className="btn btn-outline-primary">
                          <i className="fa-regular fa-plus" /> Create Post
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-community">
                  <div className="heading-simple">
                    <div className="flex-heading-simple">
                      <img src="/assets/images/author/7.jpg" alt="Author" />
                      <div className="non">
                        <h3>{groupDetails.admin.name}</h3>
                        <small>{new Date(groupDetails.created_at).toLocaleDateString()}</small>
                      </div>
                    </div>
                  </div>
                  <p>
                    {groupDetails.admin.name} created the group{" "}
                    <strong>{groupDetails.name}</strong>.
                  </p>
                  <div className="social-post-cmty">
                    <ul>
                      <li>
                        <Link to="/">
                          <img src="/assets/images/icons/thumb.svg" alt="thumb" />
                          Like
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src="/assets/images/icons/comment.svg" alt="Comment" />
                          Comment
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img
                            src="/assets/images/icons/bookmark.svg"
                            alt="bookmark"
                            className="bookmark-icon"
                          />
                          Save
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src="/assets/images/icons/share.svg" alt="Share" />
                          Share
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetails;
