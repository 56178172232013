import React, { useEffect, useState } from 'react'
import Author from '../../components/Common/Community/Author'
import MyReel from '../../components/Common/Community/MyReel'
import RightSideNav from '../../components/Common/Community/SideNav'
import Groups from '../../components/Common/Community/Groups'
import Recomandation from '../../components/Common/Community/Recomandation'
import Trendings from '../../components/Common/Community/Trendings'
import BoostYourBusiness from '../../components/Common/Community/BoostYourBusiness'
import SuggestedGroups from '../../components/Common/Community/SuggestedGroups'
import Categries from '../../components/Common/Community/Categries'
import ListYourCompany from '../../components/Common/Community/ListYourCompany'
import useRequest from '../../hooks/useRequest'
import BtnPostSideBar from '../../components/Common/Community/BtnPostSideBar'

const Polls = () => {
  const [pollData, setPollData] = useState({});
  const { request: pollRequest, response: pollResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    pollRequest("GET", "/polls");
  }, []);

  useEffect(() => {
    if (pollResponse && pollResponse.status === "SUCCESS") {
      setPollData(pollResponse.data || {});
    }
  }, [pollResponse]);
  return (
    <div>
      <MyReel />
      <Author />

      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <RightSideNav />
              <Groups />
              <Recomandation />
              <Trendings />
            </div>
            <div className="col-sm-6">
              <div className="polls-page">
                <div className="search-filter">
                  <div className="search-cmty">
                    <form>
                      <i className="fa-solid fa-magnifying-glass" />
                      <input type="text" name="name" placeholder="Search Polls" />
                    </form>
                  </div>
                  <div className="btn-filter">
                    <button
                      data-bs-target="#create-poll"
                      data-bs-toggle="modal"
                      type="button"
                      className="btn btn-primary"
                    >
                      Create a Poll
                    </button>
                  </div>
                </div>
                <div
                  className="modal fade create-modal"
                  id="create-poll"
                  aria-hidden="true"
                  aria-labelledby="create-poll"
                  tabIndex={-1}
                >
                  <div className="modal-dialog modal-md">
                    <div className="modal-content">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa-solid fa-times" />
                      </button>
                      <div className="modal-body">
                        <div className="create-poll">
                          <h3>Create a Poll</h3>
                          <form method="post">
                            <label>Your question*</label>
                            <div className="field-community">
                              <input
                                type="text"
                                placeholder="Your question"
                                required=""
                              />
                            </div>
                            <label>Option 1*</label>
                            <div className="field-community">
                              <input
                                type="text"
                                placeholder="E.g Public transportation"
                                required=""
                              />
                            </div>
                            <label>Option 2*</label>
                            <div className="field-community">
                              <input
                                type="text"
                                placeholder="E.g Drive myself"
                                required=""
                              />
                            </div>
                            <div className="add-option-community">
                              <button>
                                <i className="fa-solid fa-plus" /> Add Option
                              </button>
                            </div>
                            <label>Poll duration</label>
                            <div className="field-community">
                              <select required="">
                                <option selected="selected">1 Week</option>
                                <option value="2 Week">2 Week</option>
                              </select>
                            </div>
                            <p className="agree-policy">
                              We don't allow requests for political opinions, medical
                              information or other sensitive data.
                            </p>
                            <div className="submit-btn-community">
                              {" "}
                              <a href="#">Back</a>
                              <input type="submit" defaultValue="Post Poll" />
                              <a href="#">
                                <i className="fa-solid fa-calendar-days" />
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="poll-list">
                  <div className="heading-simple">
                    {pollData.polls && pollData.polls.length > 0 ? (
                      pollData.polls.map((poll, index) => (
                        <div key={index}>
                          <div className="flex-heading-simple">
                            <img
                              src={`${cloudFrontUrl}${poll.group ? poll.group.logo : 'default-logo.jpg'}`}
                              alt={poll.group ? poll.group.name : 'Default Group'}
                            />
                            <div className="non">
                              <h3>
                                {poll.user.name}{" "}
                                <a href="#" className="btn">
                                  + Follow
                                </a>
                              </h3>
                              <small>{new Date(poll.created_at).toLocaleTimeString()}</small>
                            </div>
                          </div>

                          {/* Poll Question and Options */}
                          <div className="poll-community">
                            <h4>{poll.question}</h4>
                            {poll.options.map((option) => (
                              <div className="poll-option" key={option.id}>
                                <div className="poll-bar">
                                  <label>{option.option}</label>
                                </div>
                              </div>
                            ))}
                            <div className="status-poll">
                              {poll.total_votes} Votes{" "}
                              <span className="duration-status-poll">
                                <i className="fa-solid fa-clock" /> 1 Day Left
                              </span>
                              <button
                                data-bs-target="#view-poll-result"
                                data-bs-toggle="modal"
                                type="button"
                              >
                                View Results
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No polls available</p>
                    )}
                  </div>
                </div>

              </div>
            </div>
            <div className="col-sm-3">
              <BtnPostSideBar/>
              <BoostYourBusiness />
              <SuggestedGroups />
              <Categries />
              <ListYourCompany />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Polls
