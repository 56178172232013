import React, { createContext, useState, useContext } from 'react';

const SearchContext = createContext();

export const useSearch = () => {
  return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState({
    name: 'All',
    slug: 'all',
  });
  const [searchValue, setSearchValue] = useState('');
  const [selectedRoute, setSelectedRoute] = useState('');
  const [formDataValues, setFormDataValues] = useState(null);
  const [routeSlug, setRouteSlug] = useState('');

  return (
    <SearchContext.Provider
      value={{
        selectedCategory,
        setSelectedCategory,
        searchValue,
        setSearchValue,
        selectedRoute,
        setSelectedRoute,
        formDataValues,
        setFormDataValues,
        routeSlug,
        setRouteSlug,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
