import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomeHeader from '../components/Home/HomeHeader';
import HomeFooter from '../components/Home/HomeFooter';
import './homepage.css';

const HomeLayout = () => {
  return (
    <div className='home-page'>
      <HomeHeader />
      <ToastContainer />
      <Outlet />
      <HomeFooter />
    </div>
  );
};
export default HomeLayout;
