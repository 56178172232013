import React from 'react';
import { useParams, Link } from "react-router-dom";
import SocialMediaIcons from '../SocialMediaIcons';

const Footer = () => {
    const { country, lang } = useParams();

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="flex-wraper">
                            <p>&copy; 2024 Copyright myfinder.ai / All Rights Reserved.</p>
                            {/* <div className="smoicons">
                                <ul>
                                    <li><a href="#" target="_blank"><i className="fa-brands fa-linkedin-in"></i></a> </li>
                                    <li> <a href="#" target="_blank"><i className="fa-brands fa-youtube"></i></a> </li>
                                    <li> <a href="#" target="_blank"><i className="fa-brands fa-x-twitter"></i></a> </li>
                                    <li> <a href="#" target="_blank"><i className="fa-brands fa-facebook-f"></i></a> </li>
                                    <li> <a href="#" target="_blank"><i className="fa-brands fa-instagram"></i></a> </li>
                                    <li> <a href="#" target="_blank"><i className="fa-brands fa-telegram"></i></a></li>
                                    <li> <a href="#" target="_blank"><i className="fa-brands fa-pinterest-p"></i></a> </li>
                                </ul>
                            </div> */}
                            <SocialMediaIcons />
                            <div className="menu-footer">
                                <ul>
                                    <li><Link to={`/${country}/${lang}/about`}>About MyFinder</Link></li>
                                    <li><Link to={`/${country}/${lang}/community`}>MyCommunity</Link></li>
                                    <li><Link to={`/${country}/${lang}/attraction`}>Attractions</Link></li>
                                    <li><Link to={`/${country}/${lang}/privacy`}>Privacy</Link></li>
                                    <li><Link to={`/${country}/${lang}/help-center`}>Help Center</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
