import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const GoogleAuthCallback = ({ onLoginSuccess, onLoginFailure }) => {
  const { country, lang } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      onLoginSuccess(token);
      navigate(`/${country}/${lang}`);
    } else {
      onLoginFailure('Google login failed');
      navigate(`/${country}/${lang}/auth/login`);
    }
  }, [navigate, onLoginSuccess, onLoginFailure]);

  return <div>Processing Google login...</div>;
};

export default GoogleAuthCallback;
