import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const OfferSlider = () => {
  return (
    <div className="offer-slide mb-5">
      <div className="swiper offer-slick">
        <Swiper
          modules={[Pagination, Navigation, Autoplay]}
          pagination={{ clickable: true }} // Automatically add the pagination element
          loop={true}
          slidesPerView={1}
          spaceBetween={0}
          speed={1000}
          autoplay={{ delay: 99999, disableOnInteraction: false }}
          navigation={{ nextEl: '.offer-slick-next', prevEl: '.offer-slick-prev' }}
          breakpoints={{
            320: { slidesPerView: 1 },
            480: { slidesPerView: 1 },
            768: { slidesPerView: 1 },
            960: { slidesPerView: 1 },
          }}
        >
          <SwiperSlide>
            <img src="/assets/images/offer-slider.jpg" alt="alt" />
            <div className="caption-offer-slide">
              <h3>Headline</h3>
              <h4>Sub headline</h4>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/assets/images/offer-slider.jpg" alt="alt" />
            <div className="caption-offer-slide">
              <h3>Headline</h3>
              <h4>Sub headline</h4>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/assets/images/offer-slider.jpg" alt="alt" />
            <div className="caption-offer-slide">
              <h3>Headline</h3>
              <h4>Sub headline</h4>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/assets/images/offer-slider.jpg" alt="alt" />
            <div className="caption-offer-slide">
              <h3>Headline</h3>
              <h4>Sub headline</h4>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/assets/images/offer-slider.jpg" alt="alt" />
            <div className="caption-offer-slide">
              <h3>Headline</h3>
              <h4>Sub headline</h4>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/assets/images/offer-slider.jpg" alt="alt" />
            <div className="caption-offer-slide">
              <h3>Headline</h3>
              <h4>Sub headline</h4>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="swiper-pagination" ></div>
      </div>
    </div>
  );
};

export default OfferSlider;
