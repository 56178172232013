import { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { cloudFrontUrl } from "../../../config";
import moment from "moment";
import "moment-duration-format";
import { Link } from "react-router-dom";
import { getUserLocale } from "../../../utils/getUserLocale";
const AttractionList = () => {
  const { lang, country } = getUserLocale();
  const [attractionList, setAttractionList] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { request, response } = useRequest();

  const fetchAttractionList = async () => {
    await request(
      "GET",
      `/public/attractions?page_num=${page}&page_size=${pageSize}`
    );
  };
  useEffect(() => {
    fetchAttractionList();
  }, []);

  useEffect(() => {
    if (response) {
      setAttractionList(response.data);
    }
  }, [response]);
  return (
    <>
      <div class="row">
        {attractionList &&
          attractionList.map((item, index) => (
            <div key={index} class="col-sm-4">
              <div class="itembox ib-attraction">
                <div class="imgeffect">
                  <div class="ib-date">{moment(item.created_at).fromNow()}</div>
                  <div class="ib-wishlist">
                    <a href="#">
                      <i class="fa-solid fa-star"></i>
                    </a>
                    <a href="#">
                      <i class="fa-regular fa-heart"></i>
                    </a>
                  </div>
                  <Link to={`/${country}/${lang}/attraction/listing/${item.slug}`}>
                    <img src="/assets/images/attraction/1.jpg" alt="alt" />
                  </Link>
                </div>
                <div class="info-itembox flex-itembox">
                  <div class="non">
                    <h3>
                    <Link to={`/${country}/${lang}/attraction/listing/${item.slug}`}>{item.title}</Link>
                    </h3>
                    <p>
                     {item.description}
                    </p>
                  </div>
                  <Link to={`/${country}/${lang}/attraction/listing/${item.slug}`}>
                    <img src={`${cloudFrontUrl}${item.logo}`} alt={item.logo} />
                  </Link>
                </div>
                <ul class="data-itembox">
                  <li>
                    <img
                      src="/assets/images/icons/location.svg"
                      alt="list"
                      ondragstart="return false"
                    />
                    Abu Dhabi, UAE
                  </li>
                  <li class="review-ib">
                    <img
                      src="/assets/images/icons/google.svg"
                      alt="list"
                      ondragstart="return false"
                    />{" "}
                    <a href="#">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                      <i class="fa-regular fa-star"></i>
                    </a>
                    <strong>4.5</strong>
                  </li>
                </ul>
                <ul class="data-itembox">
                  <li>
                    <a href="#">
                      <img
                        src="/assets/images/icons/enquiry.svg"
                        alt="enquiry"
                        ondragstart="return false"
                      />
                      Send a Inquiry
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="/assets/images/icons/ai.svg"
                        alt="ai"
                        ondragstart="return false"
                      />
                      Fredo AI Chatbot
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ))}
      </div>
      <div class="btn-load">
        <button>Load More</button>
      </div>
    </>
  );
};
export default AttractionList;
