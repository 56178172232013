import React, { useEffect, useState, useRef } from "react";
import MyReel from "../../../components/Common/Community/MyReel";
import Author from "../../../components/Common/Community/Author";
import RightSideNav from "../../../components/Common/Community/SideNav";
import Groups from "../../../components/Common/Community/Groups";
import Recomandation from "../../../components/Common/Community/Recomandation";
import Trendings from "../../../components/Common/Community/Trendings";
import useRequest from "../../../hooks/useRequest";
import { useParams, NavLink } from "react-router-dom";

const Events = () => {
  const { country, lang } = useParams();
  //Handle API Response
  const [eventData, setEventData] = useState([]);

  // Refs for the input elements
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [logo, setLogo] = useState(null);
  const [eventType, setEventType] = useState(""); //Done
  const [name, setName] = useState("");
  const [timezone, setTimezone] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(false);
  const [privacy, setPrivacy] = useState("");
  const [description, setDescription] = useState("");

  const { request: eventRequest, response: eventResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    eventRequest("GET", "/events");
  }, []);

  useEffect(() => {
    if (eventResponse && eventResponse.status === "SUCCESS") {
      setEventData(eventResponse.data || []);
    }
  }, [eventResponse]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !eventType || !timezone || !startDate || !privacy) {
      alert("All fields are required");
      return;
    }

    if (eventType === "") {
      alert("All fields are required");
    }

    try {
      let accessToken = localStorage.getItem("access_token");
      const formData = new FormData();
      formData.append("event_type", eventType); // done
      formData.append("name", name); // done
      formData.append("timezone", timezone); //done
      formData.append("start_date_time", startDate); //done
      formData.append("description", description); //done
      formData.append("privacy_type", privacy);
      if (endDate) {
        formData.append("end_date_time", endDate); // Adding end date if selected
      }
      if (logo) {
        formData.append("cover_image", logo); //done
      }

      const response = await fetch(`${BASE_URL}/events`, {
        method: "POST",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
        body: formData,
      });

      const data = await response.json();
      if (data?.status === "SUCCESS") {
        resetForm();
        document.getElementById("modal-close").click();
        alert("Event created successfully!");
        await eventRequest("GET", "/events");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const resetForm = () => {
    setLogo(null);
    setEventType("");
    setName("");
    setTimezone("");
    setStartDate("");
    setEndDate(false);
    setPrivacy("");
    setDescription("");
  };

  // Handle Date Change
  const handleDateChange = (value, type) => {
    const formattedDate = new Date(value).toISOString();
    if (type === "start") {
      setStartDate(formattedDate);
      if (startDateRef.current) {
        startDateRef.current.blur(); // Close the calendar popup
      }
    } else if (type === "end") {
      setEndDate(formattedDate);
      if (endDateRef.current) {
        endDateRef.current.blur(); // Close the calendar popup
      }
    }
  };

  //Description Condition
  const getShortDescription = (description) => {
    const words = description.split(" ");
    if (words.length > 15) {
      return words.slice(0, 15).join(" ") + "...";
    }
    return description;
  };
  const sortByAlphabet = () => {
    if (eventData.length === 0) {
      return;
    }
    const sortedEvents = [...eventData].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setEventData(sortedEvents);
  };

  const formatMonth = (dateString) => {
    const options = { month: 'short' };
    return new Date(dateString).toLocaleDateString(undefined, options).toUpperCase();
  };
  const formatDate = (dateString) => {
    const options = { day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options).toUpperCase();
  };
  

  return (
    <div>
      <MyReel />
      <Author />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <RightSideNav />
              <Groups />
              <Recomandation />
              <Trendings />
            </div>
            <div className="col-sm-9">
              <div className="events-page">
                <div className="search-filter">
                  <div className="search-cmty">
                    <form>
                      <i className="fa-solid fa-magnifying-glass" />
                      <input
                        type="text"
                        name="name"
                        placeholder="Search Events"
                      />
                    </form>
                  </div>
                  <div className="btn-filter">
                    <button
                      data-bs-target="#create-event"
                      data-bs-toggle="modal"
                      type="button"
                      className="btn btn-primary"
                    >
                      Create an Event
                    </button>
                    <button onClick={sortByAlphabet} className="btn btn-primary">
                      Sort by A-Z
                    </button>
                  </div>
                </div>
                <div
                  className="modal fade create-modal"
                  id="create-event"
                  aria-hidden="true"
                  aria-labelledby="create-event"
                  tabIndex={-1}
                >
                  <div className="modal-dialog modal-md">
                    <div className="modal-content">
                      <button
                      id="modal-close"
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa-solid fa-times" />
                      </button>
                      <div className="modal-body">
                        <div className="create-event">
                          <h3>Create an Event</h3>
                          <div className="form">
                            <div className="drop-zone">
                              <div className="drop-zone__prompt">
                                <img
                                  src="/assets/images/placeholder.png"
                                  alt="alt"
                                />
                                <p>
                                  Drag or Upload cover image{" "}
                                  <span>
                                    Minimum width 480 pixels, 16:9 recommended
                                  </span>
                                </p>
                              </div>
                              <input
                                id="file-upload"
                                type="file"
                                onChange={(e) => setLogo(e.target.files[0])}
                                style={{
                                  display: "none",
                                }} /* Hide the default input */
                                required
                              />
                              <label
                                htmlFor="file-upload"
                                className="btn btn-primary"
                                style={{ cursor: "pointer" }}
                              >
                                Upload
                              </label>
                              {logo && <span>{logo.name}</span>}{" "}
                              {/* Show the file name after upload */}
                            </div>
                            <label>Event Type</label>
                            <div className="radio-field-community">
                              <input
                                type="radio"
                                name="event-type"
                                value="Online"
                                // checked={eventType === "Online"}
                                // onChange={() => setEventType("Online")}
                                id="online"
                                required=""
                              />
                              <label htmlFor="online">Online</label>
                              <input
                                type="radio"
                                name="event-type"
                                value="In person"
                                // checked={eventType === "In person"}
                                // onChange={() => setEventType("In person")}
                                id="in-person"
                              />
                              <label htmlFor="in-person">In person</label>
                            </div>
                            <label htmlFor="eventType">Event Type*</label>
                            <div className="field-community">
                              <select
                                id="eventType"
                                value={eventType}
                                onChange={(e) => setEventType(e.target.value)}
                                required
                              >
                                <option value="" disabled>
                                  Select Event Type
                                </option>
                                <option value="AUDIO_EVENT">AUDIO_EVENT</option>
                                <option value="LIVE_EVENT">LIVE_EVENT</option>
                                <option value="EXTERNAL_EVENT">
                                  EXTERNAL_EVENT
                                </option>
                              </select>
                            </div>

                            <label>Event name*</label>
                            <div className="field-community">
                              <input
                                type="text"
                                placeholder="Event name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                              />
                            </div>
                            <label>Timezone*</label>
                            <div className="field-community">
                              <input
                                type="text"
                                placeholder="(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"
                                value={timezone}
                                onChange={(e) => setTimezone(e.target.value)}
                                required=""
                              />
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <label>Start Date</label>
                                <div className="field-community">
                                  <i className="fas fa-calendar" />
                                  <input
                                    type="datetime-local"
                                    ref={startDateRef}
                                    value={
                                      startDate
                                        ? new Date(startDate)
                                            .toISOString()
                                            .slice(0, -1)
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleDateChange(e.target.value, "start")
                                    }
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <label>End Date</label>
                                <div className="field-community">
                                  <i className="fas fa-clock" />
                                  <input
                                    type="datetime-local"
                                    ref={endDateRef}
                                    value={
                                      endDate
                                        ? new Date(endDate)
                                            .toISOString()
                                            .slice(0, -1)
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleDateChange(e.target.value, "end")
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="end-date-community">
                              <input
                                type="checkbox"
                                id="end-date"
                                // checked={endDate}
                                // onChange={(e) => setEndDate(e.target.checked)}
                              />
                              <label htmlFor="end-date">End Date</label>
                            </div>
                            <label>Set privacy*</label>
                            <div className="field-community">
                              <select
                                value={privacy}
                                required=""
                                onChange={(e) => setPrivacy(e.target.value)}
                              >
                                <option selected="selected">
                                  Choose Privacy
                                </option>
                                <option value="PUBLIC">Public</option>
                                <option value="PRIVATE">Private</option>
                              </select>
                            </div>
                            <label>Description</label>
                            <div className="field-community">
                              <textarea
                                placeholder="Ex: topics, schedule, etc"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </div>
                            <div className="submit-btn-community">
                              <button
                                type="button"
                                className="btn btn-secondary m-2"
                                data-bs-dismiss="modal"
                              >
                                Back
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                              >
                                Next
                              </button>
                            </div>
                            <p className="agree-policy">
                              By continuing, you agree with LinkedIn's event
                              policy.
                              <NavLink to="#">Learn more</NavLink>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {eventData.length > 0 ? (
                    eventData.map((event, index) => (
                      <div key={index} className="col-sm-4">
                        <div class="event-list">
                          <div class="imgeffect">
                            <div class="btn-event-list">
                              {" "}
                              <a href="#" class="btn btn-primary">
                                <i class="fa-solid fa-microphone-lines"></i>{" "}
                                {event.event_type
                                  ? event.event_type
                                  : "Event Type"}
                              </a>{" "}
                              <a href="#">
                                <i class="fa-regular fa-heart"></i>
                              </a>{" "}
                            </div>
                            <a href="#">
                              {" "}
                              <img
                                src={
                                  event?.cover_image
                                    ? `${cloudFrontUrl}${event.cover_image}`
                                    : "/assets/images/event/2.jpg"
                                }
                                alt="Cover Image"
                              />{" "}
                            </a>
                          </div>
                          <div class="info-event-list">
                            <div class="date-event-list">
                              <small>{formatMonth(event.start_date_time)}</small> {formatDate(event.start_date_time)}
                            </div>
                            <div>
                              <h3>
                                <NavLink
                                  to={`/${country}/${lang}/community/events/${event.slug}`}
                                >
                                  {event.name ? event.name : "Event Name"}
                                </NavLink>
                              </h3>
                              <p>
                                {event.description
                                  ? event.description
                                  : "Event Description"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No events available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
