import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { useSearch } from '../../contexts/SearchContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { cloudFrontUrl } from '../../config';

const ListingDetailGallery = ({ galleries }) => {
    const { country, lang, user_slug, listing_slug } = useParams();


    return (
        <div className="col-sm-7">
            <div className="v-slideshow slideshow">
                <div className="swiper slide-slideshow">
                    <div className="abs-btn-share">
                        <div className="verified-page"><i className="fa-solid fa-check"></i> Verified</div>
                        <div className="btn-share"> <a href="#"><i className="fa-sharp fa-solid fa-share"></i></a> <a href="#"><i
                            className="fa-regular fa-bookmark"></i></a> </div>
                    </div>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        navigation={{
                            nextEl: '.offer-htab-next',
                            prevEl: '.offer-htab-prev',
                        }}
                        className="swiper-wrapper"
                    >
                        {galleries && galleries.map((data, index) => (
                            <SwiperSlide key={index}>
                                <img src={cloudFrontUrl + data?.image} alt="alt" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="swiper-control">
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                </div>

                <div className="swiper thumb-slideshow">
                    <div className="swiper-wrapper">
                        {galleries && galleries.map((data, index) => (
                            <div className="swiper-slide" key={index}><img src={cloudFrontUrl + data?.image} alt="alt" /></div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingDetailGallery;
