import DownloadApp from "../downloadApp";
import OfferSlider from "../offerSlider";
import AdvBanner from "./advBanner";
import AttractionList from "./attractionList";
import AttractionNews from "./attractionNews";
import AttractionRegister from "./attractionRegister";
import AttractionVideo from "./attractionVideo";
import HorizontalBanner from "./horizontalBanner";

const AttractionListing = () => {
  return (
    <div class="attraction-listing module-listing main-container">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="title-ml">
              <div class="flex-wraper">
                <h1>Find Attractions (2684)</h1>
                <div class="filter-ml">
                  <ul class="btn-listview">
                    <li class="map-lv">
                      <a href="#">
                        <img
                          src="/assets/images/icons/location.svg"
                          alt="location"
                          ondragstart="return false"
                        />{" "}
                        Map
                      </a>
                    </li>
                    <li class="selected">
                      <a href="#">
                        <img
                          src="/assets/images/icons/thumb.svg"
                          alt="thumb"
                          ondragstart="return false"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="/assets/images/icons/list.svg"
                          alt="list"
                          ondragstart="return false"
                        />
                      </a>
                    </li>
                  </ul>
                  <div class="sort-filter">
                    <div class="field-sort-filter">
                      <select>
                        <option selected="selected">Category</option>
                        <option value="Property">Property</option>
                        <option value="Motors">Motors</option>
                        <option value="Jobs">Jobs</option>
                      </select>
                    </div>
                    <div class="field-sort-filter">
                      <select>
                        <option selected="selected">Subcategory</option>
                        <option value="Entertainment">Entertainment</option>
                        <option value="Restaurants">Restaurants</option>
                        <option value="Rooftops">Rooftops</option>
                      </select>
                    </div>
                    <div class="field-sort-filter">
                      <select>
                        <option selected="selected">Sort by</option>
                        <option value="New to Old">New to Old</option>
                        <option value="Old to New">Old to New</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="category-ml">
              <div class="flex-wraper">
                <div class="swiper category-ml-slick">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <a href="#">
                        Tours <span>(132)</span>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#">
                        Attractions <span>(132)</span>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#">
                        Activites <span>(132)</span>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#">
                        Rentals <span>(132)</span>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#">
                        Landmarks <span>(132)</span>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#">
                        Activites <span>(132)</span>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#">
                        Attractions <span>(132)</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="more-category">
                  <a href="#">More</a>
                </div>
              </div>
            </div>
            <div class="all-ml">
              <div class="row">
                <div class="col-sm-9">
                  <AttractionList />
                  
                </div>
                <div class="col-sm-3">
                  <div class="sidebar-ml">
                    <AttractionVideo />
                    <AttractionRegister />
                    <AdvBanner />
                  </div>
                </div>
              </div>
            </div>
            <OfferSlider/>
            

            <AttractionNews />
            <DownloadApp />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttractionListing;
