import { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { cloudFrontUrl } from "../../../config";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

import {Swiper, SwiperSlide } from "swiper/react";

const AttractionNews = () => {
  const [attractionNews, setAttractionNews] = useState();
  const { request, response } = useRequest();
  const fetchAttractionNews = async () => {
    await request("GET", "/public/attraction-news");
  };
  useEffect(() => {
    fetchAttractionNews();
  }, []);

  useEffect(() => {
    if (response) {
      setAttractionNews(response.data);
    }
  }, [response]);

  return (
    <div class="news-updates">
      <div class="heading">
        <h2>News & Updates</h2>
        <a href="#" class="btn-heading">
          View All<i class="fa-solid fa-chevron-right"></i>
        </a>
      </div>
      <div class="swiper news-ml-slick">
        <Swiper
          modules={[Pagination, Navigation, Autoplay]}
          pagination={{ clickable: true }} // Automatically add the pagination element
          loop={true}
          slidesPerView={4}
          spaceBetween={20}
          speed={1000}
          autoplay={{ delay: 99999, disableOnInteraction: false }}
          navigation={{ nextEl: ".news-ml-next", prevEl: ".news-ml-prev" }}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 0 },
            480: { slidesPerView: 2, spaceBetween: 10 },
            768: { slidesPerView: 3, spaceBetween: 10 },
            960: { slidesPerView: 4, spaceBetween: 20 },
          }}
        >
          {attractionNews &&
            attractionNews.map((item, index) => (
              <SwiperSlide key={index}>
                <div class="itembox ib-news">
                  <div class="imgeffect">
                    <div class="ib-date">5 Days ago</div>
                    <div class="ib-wishlist">
                      <a href="#">
                        <i class="fa-regular fa-heart"></i>
                      </a>
                    </div>
                    <a href="#">
                      <img
                        src={`${cloudFrontUrl}${item.image}`}
                        alt={item.image}
                      />
                    </a>
                  </div>
                  <div class="info-itembox">
                    <h3>
                      <a href="#">{item.headline}</a>
                    </h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div class="swiper-wrapper"></div>
      </div>
    </div>
  );
};
export default AttractionNews;
