import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { useSearch } from "../../contexts/SearchContext";
import { cloudFrontUrl } from "../../config";
import Tab from "./Tabs";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import moment from "moment/moment";

const Search = () => {
  const swiper = useSwiper();
  const { country, lang } = useParams();
  const location = useLocation();

  const { selectedCategory, searchValue, setSelectedCategory, setSearchValue } =
    useSearch();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [subCategory, setSubCategory] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [images, setImages] = useState(null);
  const [blogs, setBlogs] = useState(null);
  const [activeTab, setActiveTab] = useState("videos");

  const { request: searchRequest, response: searchResponse } = useRequest();
  const { request: categoryRequest, response: categoryResponse } = useRequest();
  const { request: subCategoryRequest, response: subCategoryResponse } =
    useRequest();

  const handleSearch = (page = 0) => {
    setIsLoading(true);
    let query = `page_num=${page}&page_size=10`;
    if (selectedCategory?.slug !== "all") {
      query += `&category_id=${selectedCategory?.id}`;
    }
    if (subCategory) {
      query += `&sub_category_id=${subCategory?.id}`;
    }
    if (searchValue) {
      query += `&search=${searchValue}`;
    }
    if (orderBy) {
      query += `&sort_by=created_at&order_by=${orderBy}`;
    }
    searchRequest("GET", `/public/search?${query}`);
  };

  const handleSelectCategory = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    const category = categories.find((cat) => cat.id === selectedId);
    console.info(category);
    setSelectedCategory(category);
  };

  const handleSubCategory = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    const selectedSubCategory = subCategories.find(
      (subCat) => subCat.id === selectedId
    );
    setSubCategory(selectedSubCategory);
  };

  const loadMoreData = () => {
    if (searchResults.length < totalRecords) {
      handleSearch(pageNum + 1);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
    }
    if (searchValue) {
      setSearchValue(searchValue);
    }
    handleSearch();
  }, [selectedCategory, searchValue]);

  useEffect(() => {
    if (searchResponse && searchResponse.status === "SUCCESS") {
      setSearchResults(searchResponse?.data);
      setTotalRecords(searchResponse?.total);
      setPageNum(searchResponse.page_num);
      setVideoData(searchResponse.data?.media_data?.videos);
      setImages(searchResponse.data?.media_data?.images);
      setBlogs(searchResponse.data?.media_data?.blogs);
      setIsLoading(false);
    }
  }, [searchResponse]);

  useEffect(() => {
    categoryRequest("GET", "/public/category");
    if (selectedCategory?.id) {
      subCategoryRequest("GET", `/public/sub-category/${selectedCategory.id}`);
    }
  }, []);

  useEffect(() => {
    if (categoryResponse && categoryResponse.status === "SUCCESS") {
      setCategories(categoryResponse.data);
    }
    if (subCategoryResponse && subCategoryResponse.status === "SUCCESS") {
      setSubCategories(subCategoryResponse.data);
    }
  }, [categoryResponse, subCategoryResponse]);

  useEffect(() => {
    if (selectedCategory?.id) {
      subCategoryRequest("GET", `/public/sub-category/${selectedCategory.id}`);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (subCategory) {
      handleSearch();
    }
  }, [subCategory]);

  console.log("Searched results are  ======>>>> ", searchResponse);

  return (
    <div className="module-search main-container">
      <div className="container">
        <div className="row">
          <div className="col-sm-7">
            <div className="category-ms">
              <div className="swiper category-ms-slick">
                <div className="swiper-wrapper">
                  {subCategories &&
                    subCategories.map((data, index) => (
                      <div
                        className={
                          subCategory?.id === data?.id
                            ? "swiper-slide active"
                            : "swiper-slide"
                        }
                        key={index}
                        onClick={() => setSubCategory(data)}
                      >
                        <a href="javascript:void(0);">{data?.name}</a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="title-ms mt-lg-4">
              <h1>Best {selectedCategory?.name} Companies</h1>
              <div className="filter-ms">
                <form>
                  <div className="field-filter-ms">
                    <select onChange={(e) => handleSelectCategory(e)}>
                      <option value={selectedCategory?.id} selected="selected">
                        Category
                      </option>
                      {categories &&
                        categories.map((data, index) => (
                          <option key={index} value={data?.id}>
                            {data?.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="field-filter-ms">
                    <select onChange={(e) => handleSubCategory(e)}>
                      <option value={subCategory?.id} selected="selected">
                        Sub Category
                      </option>
                      {subCategories &&
                        subCategories.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data?.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="field-filter-ms">
                    <select
                      value={orderBy}
                      onChange={(e) => setOrderBy(e.target.value)}
                    >
                      <option selected="selected">Sort by</option>
                      <option value="desc">New to Old</option>
                      <option value="asc">Old to New</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
            <div className="all-ms">
              <div className="row">
                <Swiper slidesPerView={5} spaceBetween={"50px"}>
                  {searchResults?.featured &&
                    searchResults?.featured?.map((data, index) => (
                      <>
                        <SwiperSlide>
                          <div className="col-md-4" key={index}>
                            <div className="featurebox fb-search">
                              <div className="imgeffect">
                                <div className="fb-date">
                                  {moment(data?.created_at).fromNow()}
                                </div>
                                <div className="fb-wishlist">
                                  <a href="#">
                                    <i className="fa-regular fa-heart"></i>
                                  </a>
                                </div>
                                <Link
                                  to={`/${country}/${lang}/listing/company/${data?.slug}`}
                                >
                                  <img
                                    src={
                                      data.user_gallery.length > 0
                                        ? cloudFrontUrl +
                                          data.user_gallery[0].image
                                        : "/assets/images/listing/1.jpg"
                                    }
                                    alt="alt"
                                  />
                                </Link>
                              </div>
                              <div className="info-featurebox flex-featurebox">
                                <div className="non">
                                  <h3>
                                    <Link
                                      to={`/${country}/${lang}/listing/company/${data?.slug}`}
                                    >
                                      {data?.name}
                                    </Link>
                                  </h3>
                                  <p>{data?.user_profile?.description}</p>
                                </div>
                                <Link
                                  to={`/${country}/${lang}/listing/company/${data?.slug}`}
                                >
                                  <img
                                    src={
                                      data?.user_profile?.logo
                                        ? cloudFrontUrl +
                                          data?.user_profile?.logo
                                        : "/assets/images/logo-1.png"
                                    }
                                    alt="alt"
                                  />
                                </Link>
                              </div>
                              <ul className="data-featurebox">
                                <li>
                                  <img
                                    src="/assets/images/icons/location.svg"
                                    alt="list"
                                    ondragstart="return false"
                                  />
                                  {data?.user_contact?.location}
                                </li>
                                <li className="review-fb">
                                  <img
                                    src="/assets/images/icons/google.svg"
                                    alt="list"
                                    ondragstart="return false"
                                  />{" "}
                                  <a href="#">
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                  </a>{" "}
                                  <strong>
                                    {data?.user_contact?.google_rating}
                                  </strong>
                                </li>
                              </ul>
                              <ul className="data-featurebox">
                                <li>
                                  <a href="#">
                                    <img
                                      src="/assets/images/icons/enquiry.svg"
                                      alt="enquiry"
                                      ondragstart="return false"
                                    />
                                    Send a Inquiry
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      src="/assets/images/icons/ai.svg"
                                      alt="ai"
                                      ondragstart="return false"
                                    />
                                    AI Fredo Chatbot
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </SwiperSlide>
                      </>
                    ))}
                </Swiper>
              </div>
              {/* <div className="btn-load">
                                <button>Load More</button>
                            </div> */}
            </div>

            <div className="featured-company">
              <Swiper
                // slidesPerView={3.5}
                slidesPerView="auto"
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }} // Enable navigation
                modules={[Navigation]} // Add the Navigation module
                className="mySwiper"
                spaceBetween={5}
              >
                <SwiperSlide style={{ width: "30px" }}></SwiperSlide>
                {searchResults?.blogs &&
                  searchResults?.blogs.map((blog, index) => (
                    <SwiperSlide key={index} style={{ width: "13rem" }}>
                      <div className="item-featured-company">
                        <div className="logo-featured-company">
                          <a href="#">
                            <img src="/assets/images/logo-1.png" alt="alt" />
                            Hello
                          </a>
                        </div>
                        <h3>
                          <a href="#">{blog?.title}</a>
                        </h3>
                        <p>{blog?.content}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                <div className="swiper-button-next">
                  <i class="fa-solid fa-arrow-right"></i>
                </div>
                <div className="swiper-button-prev">
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </Swiper>
            </div>
            <div className="company-ms">
              {searchResults?.results &&
                searchResults?.results?.map((data, index) => (
                  <div className="item-company-ms" key={index}>
                    <div className="flex-featured-company">
                      <Link
                        to={`/${country}/${lang}/search/company/${data?.slug}`}
                      >
                        <img
                          src={
                            data?.user_profile?.logo
                              ? cloudFrontUrl + data?.user_profile?.logo
                              : "/assets/images/logo-1.png"
                          }
                          alt="alt"
                        />
                      </Link>
                      <div className="non">
                        <h3>
                          <Link
                            to={`/${country}/${lang}/search/company/${data?.slug}`}
                          >
                            {data?.name}
                          </Link>
                        </h3>
                        <p className="limited-lines">
                          {data?.user_profile?.description}
                        </p>
                        <ul>
                          <li>
                            Website:{" "}
                            <a
                              href={data?.user_contact?.website}
                              target="_blank"
                            >
                              {data?.user_contact?.website}
                            </a>
                          </li>
                          <li>
                            Call:{" "}
                            <a href="tel:971551234567" target="_blank">
                              {data?.mobile}
                            </a>
                          </li>
                          <li>
                            Whatsapp:{" "}
                            <a
                              href="https://api.whatsapp.com/send?phone=971551234567"
                              target="_blank"
                            >
                              {data?.user_contact?.mobile}
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span>Enquiry Now</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <button>
                      AI Fredo Chatbot{" "}
                      <img src="/assets/images/ai/fredo.png" alt="ai" />
                    </button>
                  </div>
                ))}
              {searchResults.length < totalRecords && (
                <div className="btn-load">
                  <button onClick={loadMoreData} disabled={isLoading}>
                    {isLoading ? "Loading..." : "Load More"}
                  </button>
                </div>
              )}
            </div>
            <div style={{ height: "100px" }}></div>
          </div>
          <div className="col-sm-5">
            <Tab
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              blogs={blogs}
              images={images}
              videoData={videoData}
              cloudFrontUrl={cloudFrontUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
