import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import moment from "moment";

const AgentDetialPage = () => {
  const [data, setData] = useState(null);
  const { country, lang, agent_slug, listing_slug } = useParams();
  const { request: agentRequest, response: agentResponse } = useRequest();

  useEffect(() => {
    agentRequest("GET", `/public/agent/${agent_slug}`);
  }, []);

  useEffect(() => {
    setData(agentResponse?.data);
  }, [agentResponse]);

  return (
    <div class="agent-page bg-white main-container">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="top-mp">
              <div class="row">
                <div class="col-sm-6">
                  <div class="title-mp">
                    <div class="flex-title-mp">
                      <div class="story-title-mp">
                        <img src="/assets/images/logo-1.png" alt="alt" />
                      </div>
                      <div class="non">
                        <h1>{data?.name}</h1>
                        <ul class="list-title-mp">
                          <li>
                            <img
                              src="/assets/images/icons/store.svg"
                              alt="store"
                            />
                            {data?.listings?.length} Listings
                          </li>
                          <li>
                            <img
                              src="/assets/images/icons/language.svg"
                              alt="language"
                            />
                            {data?.language}
                          </li>
                          <li>
                            <img
                              src="/assets/images/icons/location.svg"
                              alt="location"
                            />
                            {data?.location}
                          </li>
                          <li>
                            <img
                              src="/assets/images/icons/clock-2.svg"
                              alt="clock"
                            />
                            Responds within 5m
                          </li>
                          <li>
                            <img
                              src="/assets/images/icons/calendar.svg"
                              alt="calendar"
                            />
                            Member Since 2018
                          </li>
                          <li>
                            <img
                              src="/assets/images/icons/agent-3.svg"
                              alt="agent"
                            />
                            <b><u>Property Finder</u></b>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="share-vert">
                      <a href="#">
                        <img
                          src="/assets/images/icons/share-2.svg"
                          alt="share"
                          ondragstart="return false"
                        />
                      </a>
                      <a href="#">
                        <img
                          src="/assets/images/icons/bookmark.svg"
                          alt="bookmark"
                          class="bookmark-icon"
                          ondragstart="return false"
                        />
                      </a>
                    </div>
                  </div>
                  <div class="about-title-mp">
                    <div class="heading">
                      <h2>About Me</h2>
                    </div>
                    <p>
                      Jasmine Underwood relocated to Dubai from Scotland in 2023
                      to pursue a career in the Dubai real estate market. After
                      working in customer-facing and service roles in Edinburgh
                      for nearly a decade, asmine has developed extensive
                      knowledge of Dubai's dynamic real estate market. Her
                      primary focus is on four highly sought-after communitie.
                    </p>
                    <p>
                      {data?.description}
                      Hom.
                    </p>
                  </div>
                  <div class="wrap-rating-mp">
                    <div class="heading">
                      <h2>Reviews &amp; Ratings</h2>
                    </div>
                    <div class="rating-mp">
                      <div class="flex-rating-mp">
                        <span>4.5</span>
                        <p>
                          8 Ratings
                          <a href="#">
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-regular fa-star"></i>
                            <i class="fa-regular fa-star"></i>
                          </a>
                        </p>
                      </div>
                      <div class="non">
                        <h4>Start your Review</h4>
                        <a href="#">
                          <i class="fa-regular fa-star"></i>
                          <i class="fa-regular fa-star"></i>
                          <i class="fa-regular fa-star"></i>
                          <i class="fa-regular fa-star"></i>
                          <i class="fa-regular fa-star"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="chatbot">
                    <div class="header-chatbot">
                      <div class="non">
                        <img src="/assets/images/ai/fredo.png" alt="alt" />
                        <h3>
                          FREDO <small>AI ChatBot</small>
                        </h3>
                      </div>
                      <button>
                        <img
                          src="/assets/images/icons/voice-wave.svg"
                          alt="alt"
                        />
                      </button>
                    </div>
                    <div class="flex-chatbot">
                      <div class="all-chatbox">
                        <div class="chatbox">
                          <div class="logo-chatbox">
                            <img
                              src="/assets/images/icons/fredo.svg"
                              ondragstart="return false"
                              alt="fredo"
                            />
                          </div>
                          <div class="data-chatbox">
                            <p>
                              For Inquiry please give me, your following
                              information
                            </p>
                            <form>
                              <input
                                type="text"
                                name="name_field"
                                placeholder="Name"
                                required
                              />
                              <input
                                type="email"
                                name="email_field"
                                placeholder="Email"
                              />
                              <input
                                type="email"
                                name="phonel_field"
                                placeholder="Phone Number"
                              />
                              <textarea
                                name="message_field"
                                placeholder="Your Message"
                              ></textarea>
                            </form>
                          </div>
                        </div>
                        <div class="chatbox">
                          <div class="logo-chatbox">
                            <img
                              src="/assets/images/icons/fredo.svg"
                              ondragstart="return false"
                              alt="fredo"
                            />
                          </div>
                          <div class="data-chatbox">
                            <p>
                              FREDO, show me this Business' following
                              information
                            </p>
                            <div class="btns-chatbox">
                              <a href="#" class="whatsapp-chatbox">
                                <i class="fa-brands fa-whatsapp"></i>Whatsapp
                              </a>
                              <a href="#" class="phone-chatbox">
                                <i class="fa-solid fa-phone-volume"></i>Phone
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="chatbox">
                          <div class="logo-chatbox">
                            <img
                              src="/assets/images/icons/fredo.svg"
                              ondragstart="return false"
                              alt="fredo"
                            />
                          </div>
                          <div class="data-chatbox flex-data-chatbox">
                            <p>Fredo, request</p>
                            <div class="btns-chatbox">
                              <a href="#" class="phone-chatbox">
                                <i class="fa-solid fa-phone-volume"></i>Call
                                Back
                              </a>
                              <a href="#" class="phone-chatbox">
                                <i class="fa-solid fa-file"></i>e Brochure
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="footer-chatbot">
                        <form method="post">
                          <button>
                            <i class="fa-solid fa-arrow-up-from-bracket"></i>
                            Upload
                          </button>
                          <div class="field-footer-chatbot">
                            <input
                              type="text"
                              name="search"
                              placeholder="Search here"
                            />
                          </div>
                          <div class="submit-footer-chatbot">
                            <button>
                              <i class="fa-solid fa-microphone"></i>
                            </button>
                            <input type="submit" value="Sent" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="all-ml">
              <div class="title-ml">
                <div class="flex-wraper">
                  <h1>Active listings</h1>
                  <div class="filter-ml">
                    <ul class="btn-listview">
                      <li class="map-lv">
                        <a href="#">
                          <img
                            src="/assets/images/icons/location.svg"
                            alt="location"
                            ondragstart="return false"
                          />
                          Map
                        </a>
                      </li>
                      <li class="selected">
                        <a href="#">
                          <img
                            src="/assets/images/icons/thumb.svg"
                            alt="thumb"
                            ondragstart="return false"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src="/assets/images/icons/list.svg"
                            alt="list"
                            ondragstart="return false"
                          />
                        </a>
                      </li>
                    </ul>
                    <div class="sort-filter">
                      <div class="field-sort-filter">
                        <select>
                          <option selected="selected">Sort Default</option>
                          <option value="New to Old">New to Old</option>
                          <option value="Old to New">Old to New</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                {data &&
                  data?.listings?.map((item) => (
                    <div class="col-sm-3">
                      <div class="itembox ib-listing">
                        <div class="imgeffect">
                          <div class="ib-date">
                            {moment(item?.created_at).fromNow()}
                          </div>
                          <div class="ib-wishlist">
                            <a href="#">
                              <i class="fa-regular fa-heart"></i>
                            </a>
                          </div>
                          <a href="#">
                            <img src="/assets/images/listing/1.jpg" alt="alt" />
                          </a>
                        </div>
                        <div class="info-itembox flex-itembox">
                          <div class="non">
                            <h3>
                              <Link
                                to={`/${country}/${lang}/company/${agent_slug}/listing/${item?.slug}`}
                              >
                                {item?.title}
                              </Link>
                            </h3>
                            <p>{item?.description}</p>
                          </div>
                          <a href="#">
                            <img
                              src={`${process.env.REACT_APP_CLOUDFRONT_URL}${item?.logo}`}
                              alt="alt"
                            />
                          </a>
                        </div>
                        <ul class="data-itembox">
                          <li>
                            <img
                              src="/assets/images/icons/location.svg"
                              alt="list"
                              ondragstart="return false"
                            />
                            {item?.location}
                          </li>
                          <li class="review-ib">
                            <img
                              src="/assets/images/icons/google.svg"
                              alt="list"
                              ondragstart="return false"
                            />
                            <a href="#">
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-regular fa-star"></i>
                              <i class="fa-regular fa-star"></i>
                            </a>
                            <strong>{item?.google_rating}</strong>
                          </li>
                        </ul>
                        <ul class="data-itembox">
                          <li>
                            <img
                              src="/assets/images/icons/enquiry.svg"
                              alt="enquiry"
                              ondragstart="return false"
                            />
                            Send a Inquiry
                          </li>
                          <li>
                            <img
                              src="/assets/images/icons/ai.svg"
                              alt="ai"
                              ondragstart="return false"
                            />
                            Fredo AI Chatbot
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
              </div>
              <div class="btn-load">
                <button>Load More</button>
              </div>
            </div>
            <div class="offer-slide">
              <div class="swiper offer-slick">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <img src="/assets/images/offer-slider.jpg" alt="alt" />
                    <div class="caption-offer-slide">
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <img src="/assets/images/offer-slider.jpg" alt="alt" />
                    <div class="caption-offer-slide">
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <img src="/assets/images/offer-slider.jpg" alt="alt" />
                    <div class="caption-offer-slide">
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <img src="/assets/images/offer-slider.jpg" alt="alt" />
                    <div class="caption-offer-slide">
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <img src="/assets/images/offer-slider.jpg" alt="alt" />
                    <div class="caption-offer-slide">
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
            <div class="app-download mt-lg-5">
              <div class="container">
                <div class="flex-app-download">
                  <div class="row">
                    <div class="col-sm-4">
                      <img
                        src="/assets/images/app-download.png"
                        alt="app"
                        class="pic-app-download"
                      />
                    </div>
                    <div class="col-sm-8">
                      <h2>
                        Available at
                        <small>
                          Lorem ipsum dolor sit amet consectetur adipiscing elit
                        </small>
                      </h2>
                      <div class="btn-app-download">
                        <a href="#">
                          <img
                            src="/assets/images/icons/android.svg"
                            alt="android"
                          />
                        </a>
                        <a href="#">
                          <img src="/assets/images/icons/ios.svg" alt="ios" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentDetialPage;
