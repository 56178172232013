import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate('/en/gb/auth/signup');
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleBackdropClick = (e) => {
    if (e.target.className.includes('modal')) {
      closeModal();
    }
  };

  return (
    <div className='sidebar-ml'>
      {/* Video Thumbnail */}
      <div className='video-ml'>
        <img src='/assets/images/video-tutorial.jpg' alt='Video Tutorial' />
        <div className='btn-video-ml'>
          <button type='button' onClick={openModal} className='btn btn-primary'>
            <i className='fa-solid fa-play' />
          </button>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div
          className='modal fade show'
          style={{ display: 'block', zIndex: 1050 }}
          onClick={handleBackdropClick}
          tabIndex={-1}
          role='dialog'
        >
          <div
            className="modal-dialog modal-lg"
            role="document"
            style={{
              zIndex: 1060, // Reasonably high z-index
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff", // Modal dialog should have a white background
              borderRadius: "8px", // Optional: to make the modal look nicer
            }}
          >
            <div className='modal-content'>
              <button
                type='button'
                className='btn-close'
                onClick={closeModal}
                aria-label='Close'
                style={{ zIndex: 1065 }} // Ensure the close button is visible
              >
                <i className='fas fa-times' />
              </button>
              <div className='modal-body'>
                <iframe
                  src='https://www.youtube.com/embed/o7mcXmlF8ys?si=dRZyX7fBIe2CbMPp'
                  title='YouTube video player'
                  frameBorder={0}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                  style={{ width: '100%', height: '400px' }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Register Section */}
      <div className='register-ml'>
        <img src='/assets/images/thumb-register.jpg' alt='alt' />
        <div className='caption-register-ml'>
          <h3>Register Now</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <button className='btn-sidebar-ml' onClick={handleNavigation}>
            Register Now <i className='fa-solid fa-arrow-right-long' />
          </button>
        </div>
      </div>

      {/* Ads Section */}
      <div className='ads-ml'>
        <a href='#' target='_blank'>
          <img src='/assets/images/ads-sidebar.jpg' alt='alt' />
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
