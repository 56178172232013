import React from 'react'
import CreateAPost from './CreateAPost'

const BtnPostSideBar = () => {
  return (
    <>
      <div className="write-ai">
        {" "}
        <a href="#">
          {" "}
          <img src="/assets/images/ai/fredo.png" alt="ai" />{" "}
          <span>
            Write with AI <small>Article &amp; Blog</small>
          </span>{" "}
        </a>{" "}
      </div>
    </>
  )
}

export default BtnPostSideBar
