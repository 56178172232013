import React, { useEffect, useState, useRef } from "react";
import MyReel from "../../components/Common/Community/MyReel";
import Author from "../../components/Common/Community/Author";
import RightSideNav from "../../components/Common/Community/SideNav";
import Groups from "../../components/Common/Community/Groups";
import Recomandation from "../../components/Common/Community/Recomandation";
import Trendings from "../../components/Common/Community/Trendings";
import BtnPostSideBar from "../../components/Common/Community/BtnPostSideBar";
import BoostYourBusiness from "../../components/Common/Community/BoostYourBusiness";
import SuggestedGroups from "../../components/Common/Community/SuggestedGroups";
import ListYourCompany from "../../components/Common/Community/ListYourCompany";
import Categories from "../../components/Common/Community/Categries";
import useRequest from "../../hooks/useRequest";
import CreateAPost from "../../components/Common/Community/CreateAPost";
import { Toast } from "../../utils/ToastNotification";

const Blog = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

  const [blogData, setBlogData] = useState([]);
  const { request: blogRequest, response: blogResponse } = useRequest();

  const modalRef=useRef(null);

  const [headline, setHeadline] = useState("");
  const [content, setContent] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [coverImageName, setCoverImageName] = useState("");
  const [logo,setLogo]=useState(null);
  const [logoName, setLogoName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    blogRequest("GET", "/directory-blog?page_num=0&page_size=10");

  }, []);

  useEffect(() => {
    if (blogResponse && blogResponse.status === "SUCCESS") {
      setBlogData(blogResponse.data || []);
    }
  }, [blogResponse]);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setForm({
  //     ...form,
  //     [name]: value,
  //   });
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the file
    if (file) {
      setCoverImage(file); // Set file to state
      setCoverImageName(file.name); // Set the file name to state
    }
  };

  const handlelogoChange = (e) => {
    const logo = e.target.files[0]; // Get the file
    if (logo) {
      setLogo(logo); // Set file to state
      setLogoName(logo.name); // Set the file name to state
    }
  };

  const resetForm = () => {
    setHeadline("");
    setContent("");
    setLogo(null);
    setLogoName("");
    setCoverImage(null);
    setCoverImageName("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!headline || !content || !logo) {
      alert("Please fill all required fields.");
      return;
    }

    // Assign default values
  const defaultCategoryId = 1;      // Set a default value for categoryId
  const defaultSubCategoryId = 1;   // Set a default value for subCategoryId


    try {
      let accessToken = localStorage.getItem("access_token");
      const formData = new FormData();
      formData.append("title", headline);
      formData.append("content", content);
      formData.append("category_id", defaultCategoryId);        // Set default categoryId
    formData.append("sub_category_id", defaultSubCategoryId);  // Set default subCategoryId
      if (coverImage) {
        formData.append("thumbnail_image", coverImage); // Append image if present
      }
      if (logo) {
        formData.append("feature_image",logo);
      }

      const response = await fetch(`${BASE_URL}/directory-blog`, {
        method: "POST",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
        body: formData,
      });

      const data = await response.json();
      if (data?.status === "SUCCESS") {
        resetForm();
        document.getElementById("modal-close").click();
        alert("Blog created successfully!");
        await blogRequest("GET", "/directory-blog?page_num=0&page_size=10");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClick = () => {
    if (modalOpen) {
      modalRef.current.click(); // Close the modal
    }
  };
  return (
    <div>
      <MyReel />
      <Author />

      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <RightSideNav />
              <Groups />
              <Recomandation />
              <Trendings />
            </div>
            <div className="col-sm-6">
              <div className="blog-page">
                <div className="search-filter">
                  <div className="search-cmty">
                    <form>
                      <i className="fa-solid fa-magnifying-glass" />
                      <input
                        type="text"
                        name="name"
                        placeholder="Search Blog"
                      />
                    </form>
                  </div>
                  <div className="btn-filter">
                    <button
                      data-bs-target="#create-blog"
                      data-bs-toggle="modal"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setModalOpen(true)}
                    >
                      Create a Blog
                    </button>
                  </div>
                </div>

                {/* Create Blog Modal */}
                <div
                  className="modal fade create-modal"
                  id="create-blog"
                  aria-hidden="false"
                  ref={modalRef}
                  aria-labelledby="create-blog"
                  tabIndex={-1}
                  
                >
                  <div className="modal-dialog modal-md">
                    <div className="modal-content">
                      <button
                        type="button"
                        id="modal-close"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa-solid fa-times" />
                      </button>
                      <div className="modal-body">
                        <div className="create-blog">
                          <h3>Create a Blog</h3>
                          <form onSubmit={handleSubmit}>
                          <div  className="drop-zone">
                              <div style={{height: "100px"}} className="drop-zone__prompt">
                                <img
                                  src="/assets/images/placeholder.png"
                                  alt="alt"
                                />
                                <p>
                                  Add a Logo image that complements your
                                  Blog to attract more readers.{" "}
                                  <span>
                                    We recommend uploading an image that is
                                    250 x 100 pixels.
                                  </span>
                                </p>
                              </div>
                              <input
                                type="file"
                                name="myFile"
                                className="drop-zone__input"
                                onChange={handlelogoChange} // Capture file input
                                id="logoInput"  // Added id for programmatic clicking
                                style={{ display: 'none' }} // Hide input, but still functional
                              />
                              <button
                                type="button"
                                onClick={() => document.getElementById("logoInput").click()}  // Trigger logo input on button click
                              >
                                Upload / Drag & Drop Here
                              </button>
                              {/* Show image name and remove option if image is selected */}
                              {logoName && (
                                <div className="image-preview">
                                  <p>Selected Image: {logoName}</p>
                                </div>)}
                              </div>
                            <div className="field-community">
                              <input
                                type="text"
                                placeholder="Headline"
                                value={headline}
                                onChange={(e) => setHeadline(e.target.value)}
                                required
                              />
                            </div>
                            <div className="drop-zone">
                              <div className="drop-zone__prompt">
                                <img
                                  src="/assets/images/placeholder.png"
                                  alt="alt"
                                />
                                <p>
                                  Add a cover image that complements your
                                  article to attract more readers.{" "}
                                  <span>
                                    We recommend uploading an image that is
                                    1920x1080 pixels.
                                  </span>
                                </p>
                              </div>
                              <input
                                type="file"
                                name="myFile"
                                className="drop-zone__input"
                                onChange={handleImageChange} // Capture file input
                                id="fileInput"  // Added id for programmatic clicking
                                style={{ display: 'none' }} // Hide input, but still functional
                              />
                              <button
                                type="button"
                                onClick={() => document.getElementById("fileInput").click()}  // Trigger file input on button click
                              >
                                Upload / Drag & Drop Here
                              </button>
                              {/* Show image name and remove option if image is selected */}
                              {coverImageName && (
                                <div className="image-preview">
                                  <p>Selected Image: {coverImageName}</p>
                                </div>)}
                              </div>
                              <textarea
                                placeholder="Write Here"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                              />
                              <div className="flex-editornav">
                                <nav className="editornav">
                                  <ul>
                                    <li className="editor-btnsubnav">
                                      <a href="#">
                                        New Blog{" "}
                                        <i className="fa-solid fa-caret-down" />
                                      </a>
                                      <ul className="submenu">
                                        <li>
                                          <a href="#">New Blog</a>
                                        </li>
                                        <li>
                                          <a href="#">New Blog</a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li className="editor-btnsubnav">
                                      <a href="#">
                                        Normal{" "}
                                        <i className="fa-solid fa-caret-down" />
                                      </a>
                                      <ul className="submenu">
                                        <li>
                                          <a href="#">Normal</a>
                                        </li>
                                        <li>
                                          <a href="#">Paragraph</a>
                                        </li>
                                        <li>
                                          <a href="#">Heading</a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <button type="button">
                                        <i className="fa-solid fa-bold" />
                                      </button>
                                      <button type="button">
                                        <i className="fa-solid fa-italic" />
                                      </button>
                                      <button type="button">
                                        <i className="fa-solid fa-underline" />
                                      </button>
                                    </li>
                                    <li>
                                      <button type="button">
                                        <i className="fa-solid fa-list-ol" />
                                      </button>
                                      <button type="button">
                                        <i className="fa-solid fa-list-ul" />
                                      </button>
                                    </li>
                                    <li>
                                      <button type="button">
                                        <i className="fa-solid fa-link" />
                                      </button>
                                    </li>
                                  </ul>
                                </nav>
                                <input onClick={handleClick} type="submit" value="Post" />
                              </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Blog Posts */}
                <div className="all-post-community">
                  {blogData && blogData.length > 0 ? (
                    blogData.map((blog, index) => (
                      <div key={index} className="post-community">
                        <div class="heading-post-cmty">
                          <div class="date-post-cmty">
                            <a href="#">
                              <i class="fa-solid fa-ellipsis-vertical"></i>
                            </a>{" "}
                            May 31, 15:30
                          </div>
                          <img src="/assets/images/admin-1.jpg" alt="alt"></img>
                          <div class="name-post-cmty">
                            <h3>{blog.title}</h3>
                            <div class="author-post-cmty">
                              <img
                                src="/assets/images/author/1.jpg"
                                alt="alt"
                              ></img>{" "}
                              Jonathan
                            </div>
                          </div>
                        </div>
                        <div class="desc-post-cmty">
                          <p>{blog.content}</p>
                          <div class="imgeffect">
                            <a href="#">
                              <img
                                src={`${cloudFrontUrl}${blog.feature_image}`}
                                alt={`${blog.feature_image}`}
                              />
                            </a>
                          </div>
                        </div>
                        <div class="social-post-cmty">
                          {" "}
                          <a href="#">
                            <img
                              src="/assets/images/icons/thumb.svg"
                              alt="thumb"
                            ></img>
                            Jackson and 12,850 others Liked
                          </a>
                          <ul>
                            <li>
                              <a href="#">
                                <img
                                  src="/assets/images/icons/thumb.svg"
                                  alt="thumb"
                                ></img>
                                Like
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src="/assets/images/icons/comment.svg"
                                  alt="Comment"
                                ></img>
                                Comment
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src="/assets/images/icons/bookmark.svg"
                                  alt="bookmark"
                                  class="bookmark-icon"
                                ></img>
                                Save
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src="/assets/images/icons/share.svg"
                                  alt="Share"
                                ></img>
                                Share
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No blog posts available.</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <BtnPostSideBar />
              <CreateAPost />
              <BoostYourBusiness />
              <SuggestedGroups />
              <Categories />
              <ListYourCompany />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
