import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const AuthSubHeader = () => {
  const navigate = useNavigate();
  const { country, lang } = useParams();
  const location = useLocation();

  // Determine the active tab based on the current URL
  const currentPath = location.pathname;
  const activeTab = currentPath.includes('/auth/signup')
    ? 'signup-tab'
    : currentPath.includes('/auth/login')
    ? 'signin-tab'
    : currentPath.includes('/auth/face')
    ? 'faceid-tab'
    : '';

  const handleNavigation = (path) => {
    navigate(path); // Navigate to the new path
  };

  return (
    <ul className='tab-menu'>
      <li
        data-tab='signup-tab'
        className={`poppins-semibold text-[28px] ${
          activeTab === 'signup-tab' ? 'active' : ''
        }`}
        onClick={() => handleNavigation(`/${country}/${lang}/auth/signup`)}
      >
        Sign Up
      </li>
      <li
        data-tab='signin-tab'
        className={`poppins-semibold text-[28px] ${
          activeTab === 'signin-tab' ? 'active' : ''
        }`}
        onClick={() => handleNavigation(`/${country}/${lang}/auth/login`)}
      >
        Log In
      </li>
      <li
        data-tab='faceid-tab'
        className={`poppins-semibold text-[28px] ${
          activeTab === 'faceid-tab' ? 'active' : 'flex'
        }`}
        onClick={() => handleNavigation(`/${country}/${lang}/auth/face`)}
      >
        <img src='/assets/images/icons/face-id.svg' alt='Face ID' />
        Face ID
      </li>
    </ul>
  );
};

export default AuthSubHeader;
