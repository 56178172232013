import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { useSearch } from '../../contexts/SearchContext';
import { cloudFrontUrl } from '../../config';
import ListingDetailGallery from './ListingDetailGallery';
import VTabModule from '../detail/VTabModule';
import moment from 'moment';

const ListingDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { country, lang, user_slug, listing_slug } = useParams();
  const [loading, setIsLoading] = useState(false);

  const [data, setData] = useState(null);
  const { request: Request, response: Response } = useRequest();
  const [userId, setUserId] = useState();
  const [userType, setUserType] = useState();
  const [rating, setRating] = useState(0);
  const [reviewData, setReviewData] = useState([]);
  const [recommendationData, setRecommendationData] = useState([]);

  const [reviewFormData, setReviewFormData] = useState({
    name: '',
    email: '',
    message: '',
    listing_id: null,
    rating: rating,
    review_type: userType,
    user_id: userId,
  });
  const [recommendationFormData, setRecommendationFormData] = useState({
    recommendation_type: '',
    user_id: '',
    message: '',
    listing_id: null,
  });

  const { request: reviewPostRequest, response: reviewPostResponse } =
    useRequest();
  const {
    request: recommendationPostRequest,
    response: recommendationPostResponse,
  } = useRequest();

  const { request: reviewsRequest, response: reviewsResponse } = useRequest();
  const { request: recommendationRequest, response: recommendationResponse } =
    useRequest();

  useEffect(() => {
    Request('GET', `/public/listing/${listing_slug}`);
  }, []);

  useEffect(() => {
    if (Response && Response.status === 'SUCCESS') {
      setData(Response?.data);
    }
  }, [Response]);

  useEffect(() => {
    const id = localStorage.getItem('user_id');
    const type = localStorage.getItem('user_type');

    const getSessionData = async () => {
      let review = await sessionStorage.getItem('reviewFormData');
      let recommendation = await sessionStorage.getItem(
        'recommendationFormData'
      );
      if (review) {
        let data = JSON.parse(review);
        setReviewFormData(data);
        setRating(data?.rating);
      }

      console.log('Data is ', recommendation);
      if (recommendation) {
        let data = JSON.parse(recommendation);
        setRecommendationFormData(data);
      }
    };
    setUserId(id);
    setUserType(type);
    getSessionData();
  }, []);

  useEffect(() => {
    if (userId && userType) {
      reviewsRequest('GET', `/public/reviews/${userType}/${userId}`);
      recommendationRequest(
        'GET',
        `/public/recommendations/${userType}/${userId}`
      );

      setRecommendationFormData((prev) => ({
        ...prev,
        user_id: parseInt(userId),
        recommendation_type: userType,
      }));
      setReviewFormData((prev) => ({
        ...prev,
        user_id: parseInt(userId),
        review_type: userType,
      }));
    }
  }, [userId, userType]);

  useEffect(() => {
    if (reviewsResponse && reviewsResponse.status === 'SUCCESS') {
      setReviewData(reviewsResponse.data);
      setIsLoading(false);
    } else if (
      reviewsResponse &&
      reviewsResponse.status === 'FAIL' &&
      reviewsResponse.message === '[Job_not_found.]'
    ) {
      setReviewData('No jobs found');
      setIsLoading(false);
    }
  }, [reviewsResponse]);

  useEffect(() => {
    if (recommendationResponse && recommendationResponse.status === 'SUCCESS') {
      setRecommendationData(recommendationResponse.data);
      setIsLoading(false);
    } else if (
      recommendationResponse &&
      recommendationResponse.status === 'FAIL' &&
      recommendationResponse.message === '[Job_not_found.]'
    ) {
      setRecommendationData('No jobs found');
      setIsLoading(false);
    }
  }, [recommendationResponse]);

  const handleReviewSubmit = (e) => {
    e.preventDefault();
    let token = localStorage.getItem('access_token');
    if (token) {
      reviewPostRequest('POST', `/reviews`, reviewFormData);
    } else {
      console.log('Saving');
      sessionStorage.setItem('reviewFormData', JSON.stringify(reviewFormData));
      navigate(`/${country}/${lang}/auth/login?redirect=${location?.pathname}`);
    }
  };

  const handleRecommendationSubmit = (e) => {
    e.preventDefault();
    let token = localStorage.getItem('access_token');
    console.log('token is ', token);
    if (token) {
      recommendationPostRequest(
        'POST',
        `/recommendations`,
        recommendationFormData
      );
    } else {
      console.log('implementing');
      sessionStorage.setItem(
        'recommendationFormData',
        JSON.stringify(recommendationFormData)
      );
      navigate(`/${country}/${lang}/auth/login?redirect=${location?.pathname}`);
    }
  };

  const handleClick = (value) => {
    setRating(value);
    setReviewFormData((prev) => ({ ...prev, rating: value }));
  };

  return (
    <div className='store-detail  bg-white pt-20'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='top-md'>
              <div className='row'>
                <ListingDetailGallery galleries={data?.galleries} />
                <div className='col-sm-5'>
                  <div className='chatbot'>
                    <div className='header-chatbot'>
                      <div className='non'>
                        {' '}
                        <img src='/assets/images/ai/fredo.png' alt='alt' />
                        <h3>
                          FREDO <small>AI ChatBot</small>
                        </h3>
                      </div>
                      <button>
                        <img
                          src='/assets/images/icons/voice-wave.svg'
                          alt='alt'
                        />
                      </button>
                    </div>
                    <div className='flex-chatbot'>
                      <div className='all-chatbox'>
                        <div className='chatbox'>
                          <div className='logo-chatbox'>
                            <img
                              src='/assets/images/icons/fredo.svg'
                              alt='fredo'
                            />
                          </div>
                          <div className='data-chatbox'>
                            <p>
                              For Inquiry please give me, your following
                              information
                            </p>
                            <form>
                              <input
                                type='text'
                                name='name_field'
                                placeholder='Name'
                                required
                              />
                              <input
                                type='email'
                                name='email_field'
                                placeholder='Email'
                              />
                              <input
                                type='email'
                                name='phonel_field'
                                placeholder='Phone Number'
                              />
                              <textarea
                                name='message_field'
                                placeholder='Your Message'
                              ></textarea>
                            </form>
                          </div>
                        </div>
                        <div className='chatbox'>
                          <div className='logo-chatbox'>
                            <img
                              src='/assets/images/icons/fredo.svg'
                              alt='fredo'
                            />
                          </div>
                          <div className='data-chatbox'>
                            <p>
                              FREDO, show me this Business' following
                              information
                            </p>
                            <div className='btns-chatbox'>
                              <a href='#' className='whatsapp-chatbox'>
                                <i className='fa-brands fa-whatsapp'></i>
                                Whatsapp
                              </a>
                              <a href='#' className='phone-chatbox'>
                                <i className='fa-solid fa-phone-volume'></i>
                                Phone
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className='chatbox'>
                          <div className='logo-chatbox'>
                            <img
                              src='/assets/images/icons/fredo.svg'
                              alt='fredo'
                            />
                          </div>
                          <div className='data-chatbox flex-data-chatbox'>
                            <p>Fredo, request</p>
                            <div className='btns-chatbox'>
                              <a href='#' className='phone-chatbox'>
                                <i className='fa-solid fa-phone-volume'></i>Call
                                Back
                              </a>
                              <a href='#' className='phone-chatbox'>
                                <i className='fa-solid fa-file'></i>e Brochure
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='footer-chatbot'>
                        <form method='post'>
                          <button>
                            <i className='fa-solid fa-arrow-up-from-bracket'></i>{' '}
                            Upload
                          </button>
                          <div className='field-footer-chatbot'>
                            <input
                              type='text'
                              name='search'
                              placeholder='Search here'
                            />
                          </div>
                          <div className='submit-footer-chatbot'>
                            <button>
                              <i className='fa-solid fa-microphone'></i>
                            </button>
                            <input type='submit' value='Sent' />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='sec-title-md'>
              <div className='row'>
                <div className='col-sm-4'>
                  <div className='title-md'>
                    <div className='flex-title-md'>
                      <div className='story-title-md'>
                        <img src={cloudFrontUrl + data?.logo} alt='alt' />
                      </div>
                      <div className='non'>
                        <h1>{data?.title}</h1>
                        <div className='category-title-md'>
                          <a href='#'>Category | Subcategory</a>
                        </div>
                        <div className='location-title-md'>
                          <img
                            src='/assets/images/icons/location.svg'
                            alt='location'
                          />
                          Location
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='rating-md'>
                    <ul>
                      <li>
                        <h4>MyFinder Review</h4>
                        <div className='flex-rating-md'>
                          <div className='logo-rating-md'>
                            <img
                              src='/assets/images/favicon.png'
                              alt='favicon'
                            />
                          </div>
                          <div className='non'>
                            <p>
                              4.5 <small>(45 Rating)</small>
                            </p>
                            <a href='#'>
                              <i className='fa-solid fa-star'></i>
                              <i className='fa-solid fa-star'></i>
                              <i className='fa-solid fa-star'></i>
                              <i className='fa-regular fa-star'></i>
                              <i className='fa-regular fa-star'></i>
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <h4>Google Review</h4>
                        <div className='flex-rating-md'>
                          <div className='logo-rating-md'>
                            <img
                              src='/assets/images/icons/google.svg'
                              alt='favicon'
                            />
                          </div>
                          <div className='non'>
                            <p>
                              {data?.google_rating} <small>(45 Rating)</small>
                            </p>
                            <a href='#'>
                              <i className='fa-solid fa-star'></i>
                              <i className='fa-solid fa-star'></i>
                              <i className='fa-solid fa-star'></i>
                              <i className='fa-regular fa-star'></i>
                              <i className='fa-regular fa-star'></i>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div
                    className='agent-box'
                    onClick={() =>
                      navigate(`/${lang}/${country}/agent/${data?.agent?.slug}`)
                    }
                  >
                    {' '}
                    <img src='/assets/images/profile.jpg' alt='alt' />
                    <div className='non'>
                      <h3>{data?.agent?.name}</h3>
                      <p className='text-ellipsis'>
                        {data?.agent?.description}
                      </p>
                      <h4>{data?.agent?.location} </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mid-md'>
              <div className='row'>
                <div className='col-sm-8'>
                  <div className='sec-status-md'>
                    <div className='row'>
                      <div className='col-sm-7'>
                        <div className='status-md'>
                          <h3>Status</h3>
                          <p>{data?.status}</p>
                        </div>
                      </div>
                      <div className='col-sm-5'>
                        <div className='btn-sm'>
                          <a href='#' className='btn btn-primary'>
                            <i className='fa-solid fa-star'></i> Write a Review
                          </a>{' '}
                          <a href='#' className='btn btn-primary'>
                            <i className='fa-solid fa-comment-dots'></i>
                            Recommendations
                          </a>{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='description-md'>
                    <div className='heading'>
                      <h3>Description</h3>
                    </div>
                    <p>{data?.description}</p>
                  </div>
                  <div className='list-amenity'>
                    <div className='heading'>
                      <h3>Amenities</h3>
                    </div>
                    <ul>
                      {data?.amenities &&
                        data?.amenities.map((amenity, index) => (
                          <li key={index}>
                            {/* <i className="fa-solid fa-person"></i> */}
                            <img
                              src={cloudFrontUrl + amenity?.amenities?.icon}
                              alt={amenity?.amenities?.name}
                              width={25}
                            />
                            {amenity?.amenities?.name}
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='box-md'>
                        <div className='heading'>
                          <h3>Company Profile</h3>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, conse ctetur adi piscing
                          elit.{' '}
                          <a href='#' className='btn btn-primary'>
                            Download
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='box-md'>
                        <div className='heading'>
                          <h3>Floor Plan</h3>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, conse ctetur adi piscing
                          elit.{' '}
                          <a href='#' className='btn btn-primary'>
                            Download
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='enquiry'>
                    <div className='heading'>
                      <h3>Inquire Now</h3>
                    </div>
                    <form>
                      <label>Your name</label>
                      <input type='text' placeholder='e.g Mohamed' required />
                      <label>Your email</label>
                      <input
                        type='email'
                        placeholder='e.g abcd@gmail.com'
                        required
                      />
                      <label>Your phone</label>
                      <input
                        type='text'
                        placeholder='e.g +971 42 524242'
                        required
                      />
                      <label>Your message</label>
                      <textarea placeholder='Sameer! I saw your ad on My Finder. When is it available for viewing? Thanks.'></textarea>
                      <button>
                        <i className='fa-regular fa-envelope'></i> Inquiry Now
                      </button>
                      <small>
                        By clicking on 'Inquire Now', I agree to the MyFinder{' '}
                        <a href='#'>Terms &amp; Conditions</a>
                        and <a href='#'>Privacy Policy</a>
                      </small>
                    </form>
                    <div className='contact-enquiry'>
                      {' '}
                      <a href='tel:971509545000'>
                        Call <i className='fa-solid fa-phone'></i>
                      </a>{' '}
                      <a
                        href='https://api.whatsapp.com/send?phone=971509545000'
                        target='_blank'
                      >
                        <i className='fa-brands fa-whatsapp'></i> Whatsapp
                      </a>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='location'>
              <div className='heading'>
                <h3>Location & Near by</h3>
              </div>
              <p>
                <i className='fa-solid fa-location-dot'></i> NO 18 Mishui Avenue
                Gaomi, 64258
              </p>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462562.6509516108!2d54.94755498679835!3d25.07575945545691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1644662298002!5m2!1sen!2sin'
                width='600'
                height='450'
                style={{ border: '0;' }}
                allowfullscreen=''
                loading='lazy'
              ></iframe>
            </div>
            {/* <div className="social-activity">
              <div className="vtab">
                <ul className="vtab-menu">
                  <li data-tab="mystories-vtab" className="active">
                    <img src="/assets/images/icons/story.svg" alt="story" />{" "}
                    MyStories
                  </li>
                  <li data-tab="myreels-vtab">
                    <img src="/assets/images/icons/reels.svg" alt="reels" />{" "}
                    MyReels
                  </li>
                  <li data-tab="mylive-vtab">
                    <img src="/assets/images/icons/live.svg" alt="live" />{" "}
                    MyLive
                  </li>
                  <li data-tab="mycommunity-vtab">
                    <img
                      src="/assets/images/icons/community.svg"
                      alt="community"
                    />{" "}
                    MyCommunity
                  </li>
                </ul>
                <div className="wraper-vtab">
                  <div id="mystories-vtab" className="vtab-content active">
                    <div className="swiper story-slick-vtab">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div className="item-story">
                            <div className="cap-item-story">
                              <div className="date-item-story">1 days ago</div>
                            </div>
                            <div className="imgeffect">
                              {" "}
                              <img src="/assets/images/reels-1.jpg" alt="alt" />
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="item-story">
                            <div className="cap-item-story">
                              <div className="date-item-story">1 days ago</div>
                            </div>
                            <div className="imgeffect">
                              {" "}
                              <img src="/assets/images/reels-2.jpg" alt="alt" />
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="item-story">
                            <div className="cap-item-story">
                              <div className="date-item-story">1 days ago</div>
                            </div>
                            <div className="imgeffect">
                              {" "}
                              <img src="/assets/images/reels-1.jpg" alt="alt" />
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="item-story">
                            <div className="cap-item-story">
                              <div className="date-item-story">1 days ago</div>
                            </div>
                            <div className="imgeffect">
                              {" "}
                              <img src="/assets/images/reels-2.jpg" alt="alt" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-control">
                      <div className="swiper-button-prev story-vtab-prev"></div>
                      <div className="swiper-button-next story-vtab-next"></div>
                    </div>
                  </div>
                  <div id="myreels-vtab" className="vtab-content">
                    <div className="swiper reels-slick-vtab">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div className="item-story">
                            <div className="cap-item-story">
                              <a href="#">
                                <img
                                  src="/assets/images/logo-1.png"
                                  alt="alt"
                                />
                              </a>
                              <small className="date-item-story">
                                1 days ago
                              </small>
                            </div>
                            <div className="imgeffect">
                              {" "}
                              <img src="/assets/images/reels-1.jpg" alt="alt" />
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="item-story">
                            <div className="cap-item-story">
                              <a href="#">
                                <img
                                  src="/assets/images/logo-1.png"
                                  alt="alt"
                                />
                              </a>
                              <small className="date-item-story">
                                1 days ago
                              </small>
                            </div>
                            <div className="imgeffect">
                              {" "}
                              <img src="/assets/images/reels-2.jpg" alt="alt" />
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="item-story">
                            <div className="cap-item-story">
                              <a href="#">
                                <img
                                  src="/assets/images/logo-1.png"
                                  alt="alt"
                                />
                              </a>
                              <small className="date-item-story">
                                1 days ago
                              </small>
                            </div>
                            <div className="imgeffect">
                              {" "}
                              <img src="/assets/images/reels-1.jpg" alt="alt" />
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="item-story">
                            <div className="cap-item-story">
                              <a href="#">
                                <img
                                  src="/assets/images/logo-1.png"
                                  alt="alt"
                                />
                              </a>
                              <small className="date-item-story">
                                1 day ago
                              </small>
                            </div>
                            <div className="imgeffect">
                              {" "}
                              <img src="/assets/images/reels-2.jpg" alt="alt" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-control">
                      <div className="swiper-button-prev reels-vtab-prev"></div>
                      <div className="swiper-button-next reels-vtab-next"></div>
                    </div>
                  </div>
                  <div id="mylive-vtab" className="vtab-content">
                    <div className="swiper live-slick-vtab">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div className="item-mylive">
                            <div className="imgeffect">
                              {" "}
                              <a href="#">
                                <img
                                  src="/assets/images/reels-1.jpg"
                                  alt="mylive"
                                />
                              </a>
                              <div className="cap-item-mylive">
                                <div className="btns-mylive">
                                  {" "}
                                  <a href="#">
                                    <i className="fa-brands fa-youtube"></i>{" "}
                                    Live
                                  </a>
                                  <span>
                                    <i className="fa-solid fa-eye"></i>10.8K
                                  </span>{" "}
                                </div>
                                <small className="date-item-mylive">
                                  1 days ago
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="item-mylive">
                            <div className="imgeffect">
                              {" "}
                              <a href="#">
                                <img
                                  src="/assets/images/reels-2.jpg"
                                  alt="mylive"
                                />
                              </a>
                              <div className="cap-item-mylive">
                                <div className="btns-mylive">
                                  {" "}
                                  <a href="#">
                                    <i className="fa-brands fa-youtube"></i>{" "}
                                    Live
                                  </a>
                                  <span>
                                    <i className="fa-solid fa-eye"></i>10.8K
                                  </span>{" "}
                                </div>
                                <small className="date-item-mylive">
                                  1 days ago
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="item-mylive">
                            <div className="imgeffect">
                              {" "}
                              <a href="#">
                                <img
                                  src="/assets/images/reels-1.jpg"
                                  alt="mylive"
                                />
                              </a>
                              <div className="cap-item-mylive">
                                <div className="btns-mylive">
                                  {" "}
                                  <a href="#">
                                    <i className="fa-brands fa-youtube"></i>{" "}
                                    Live
                                  </a>
                                  <span>
                                    <i className="fa-solid fa-eye"></i>10.8K
                                  </span>{" "}
                                </div>
                                <small className="date-item-mylive">
                                  1 days ago
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="item-mylive">
                            <div className="imgeffect">
                              {" "}
                              <a href="#">
                                <img
                                  src="/assets/images/reels-2.jpg"
                                  alt="mylive"
                                />
                              </a>
                              <div className="cap-item-mylive">
                                <div className="btns-mylive">
                                  {" "}
                                  <a href="#">
                                    <i className="fa-brands fa-youtube"></i>{" "}
                                    Live
                                  </a>
                                  <span>
                                    <i className="fa-solid fa-eye"></i>10.8K
                                  </span>{" "}
                                </div>
                                <small className="date-item-mylive">
                                  1 days ago
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-control">
                      <div className="swiper-button-prev live-vtab-prev"></div>
                      <div className="swiper-button-next live-vtab-next"></div>
                    </div>
                  </div>
                  <div id="mycommunity-vtab" className="vtab-content">
                    <div className="swiper cmty-slick-vtab">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div className="sm-cmty">
                            <h3>
                              <a href="#">Dribble Shots Community</a>{" "}
                              <small>5h</small>
                            </h3>
                            <p>
                              MyFinder leading <a href="#">@SearchPortal</a>{" "}
                              that makes it easy to find, possibly in the
                              <a href="#">@smartest</a> way.
                            </p>
                            <div className="imgeffect">
                              <a href="#">
                                <img src="/assets/images/offer.jpg" alt="alt" />
                              </a>
                            </div>
                            <div className="btn-sm-cmty">
                              {" "}
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>{" "}
                              <a href="#">
                                <i className="fa-regular fa-comment"></i>
                              </a>{" "}
                              <a href="#">
                                {" "}
                                <i className="fa-regular fa-bookmark"></i>
                              </a>{" "}
                              <a href="#">
                                <i className="fa-regular fa-paper-plane"></i>
                              </a>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="sm-cmty">
                            <h3>
                              <a href="#">Dribble Shots Community</a>{" "}
                              <small>5h</small>
                            </h3>
                            <p>
                              MyFinder leading <a href="#">@SearchPortal</a>{" "}
                              that makes it easy to find, possibly in the
                              <a href="#">@smartest</a> way.
                            </p>
                            <div className="imgeffect">
                              <a href="#">
                                <img src="/assets/images/offer.jpg" alt="alt" />
                              </a>
                            </div>
                            <div className="btn-sm-cmty">
                              {" "}
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>{" "}
                              <a href="#">
                                <i className="fa-regular fa-comment"></i>
                              </a>{" "}
                              <a href="#">
                                {" "}
                                <i className="fa-regular fa-bookmark"></i>
                              </a>{" "}
                              <a href="#">
                                <i className="fa-regular fa-paper-plane"></i>
                              </a>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="sm-cmty">
                            <h3>
                              <a href="#">Dribble Shots Community</a>{" "}
                              <small>5h</small>
                            </h3>
                            <p>
                              MyFinder leading <a href="#">@SearchPortal</a>{" "}
                              that makes it easy to find, possibly in the
                              <a href="#">@smartest</a> way.
                            </p>
                            <div className="imgeffect">
                              <a href="#">
                                <img src="/assets/images/offer.jpg" alt="alt" />
                              </a>
                            </div>
                            <div className="btn-sm-cmty">
                              {" "}
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>{" "}
                              <a href="#">
                                <i className="fa-regular fa-comment"></i>
                              </a>{" "}
                              <a href="#">
                                {" "}
                                <i className="fa-regular fa-bookmark"></i>
                              </a>{" "}
                              <a href="#">
                                <i className="fa-regular fa-paper-plane"></i>
                              </a>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="sm-cmty">
                            <h3>
                              <a href="#">Dribble Shots Community</a>{" "}
                              <small>5h</small>
                            </h3>
                            <p>
                              MyFinder leading <a href="#">@SearchPortal</a>{" "}
                              that makes it easy to find, possibly in the
                              <a href="#">@smartest</a> way.
                            </p>
                            <div className="imgeffect">
                              <a href="#">
                                <img src="/assets/images/offer.jpg" alt="alt" />
                              </a>
                            </div>
                            <div className="btn-sm-cmty">
                              {" "}
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>{" "}
                              <a href="#">
                                <i className="fa-regular fa-comment"></i>
                              </a>{" "}
                              <a href="#">
                                {" "}
                                <i className="fa-regular fa-bookmark"></i>
                              </a>{" "}
                              <a href="#">
                                <i className="fa-regular fa-paper-plane"></i>
                              </a>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-control">
                      <div className="swiper-button-prev cmty-vtab-prev"></div>
                      <div className="swiper-button-next cmty-vtab-next"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <VTabModule />
            <div className='review-module'>
              <div className='tab-review-module'>
                <ul className='tab-menu'>
                  <li data-tab='review-tab-module' className='active'>
                    Reviews
                  </li>
                  <li data-tab='recommendation-tab-module'>Recommendations</li>
                </ul>
                <div id='review-tab-module' className='tab-content active'>
                  <div className='row'>
                    <div className='col-sm-8'>
                      <div className='all-comment-rm'>
                        {reviewData &&
                          reviewData.map((review) => (
                            <div className='comment-rm'>
                              <div className='author-comment-rm'>
                                {' '}
                                <img
                                  src='/assets/images/user-1.png'
                                  alt='alt'
                                />
                                <h4>
                                  {review?.name}{' '}
                                  <small>
                                    Posted{' '}
                                    {moment(review?.created_at).fromNow()}
                                  </small>
                                </h4>
                              </div>
                              <p>{review?.message}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='enquiry'>
                        <h3 className='mb-3'>Write a review</h3>
                        <form onSubmit={handleReviewSubmit}>
                          <input
                            type='text'
                            name='name_field'
                            placeholder='Your name'
                            required
                            value={reviewFormData?.name}
                            onChange={(e) =>
                              setReviewFormData((prev) => ({
                                ...prev,
                                name: e.target.value,
                              }))
                            }
                          />
                          <input
                            type='email'
                            name='email_field'
                            placeholder='Email id'
                            required
                            value={reviewFormData?.email}
                            onChange={(e) =>
                              setReviewFormData((prev) => ({
                                ...prev,
                                email: e.target.value,
                              }))
                            }
                          />
                          <textarea
                            name='message_field'
                            placeholder='Your message'
                            value={reviewFormData?.message}
                            onChange={(e) =>
                              setReviewFormData((prev) => ({
                                ...prev,
                                message: e.target.value,
                              }))
                            }
                          ></textarea>
                          <div className='d-flex align-items-center mt-2 mb-3'>
                            {[1, 2, 3, 4, 5].map((star) => (
                              <i
                                key={star}
                                className={`fa fa-star ${
                                  star <= rating ? 'text-warning' : 'text-muted'
                                } mx-1`}
                                onClick={() => handleClick(star)}
                                style={{ cursor: 'pointer' }}
                              ></i>
                            ))}
                            <span className='ml-2'>Rating: {rating} / 5</span>
                          </div>
                          <input type='submit' className='w-100' value='Submit' />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div id='recommendation-tab-module' className='tab-content'>
                  <div className='row'>
                    <div className='col-sm-8'>
                      <div className='all-comment-rm'>
                        <div className='comment-rm'>
                          <div className='author-comment-rm'>
                            {' '}
                            <img src='/assets/images/user-2.png' alt='alt' />
                            <h4>
                              David <small>Posted on 1 hour ago</small>
                            </h4>
                          </div>
                          <p>
                            I pop in here for a drink with my friends once in a
                            while, great service!. Friendly staff who al
                            Friendly staff who al speak English as well speak
                            English as well. The drinks are always good and
                            baked goods are fresh. They open at 12pm and close
                            at 2 am. Friendly staff who al speak English as
                            well.
                          </p>
                        </div>
                        <div className='comment-rm'>
                          <div className='author-comment-rm'>
                            {' '}
                            <img src='/assets/images/user-1.png' alt='alt' />
                            <h4>
                              Jonathan <small>Posted on 1 hour ago</small>
                            </h4>
                          </div>
                          <p>
                            We take great pride in providing the best culinary
                            experiences. We will be sure to pass your kind words
                            to Rajan, who will be delighted to hear that he made
                            your experience particularly memorable. We very much
                            look forward to having you with us again in the near
                            future.Management, Outdoor Operations.
                          </p>
                        </div>
                        <div className='btn-load'>
                          <button>Load More</button>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='enquiry'>
                        <h3>Write a recommendation</h3>
                        <form handleClick={handleRecommendationSubmit}>
                          <textarea
                            name='message_field'
                            placeholder='Your message'
                            value={recommendationFormData?.message}
                            onChange={(e) =>
                              recommendationFormData((prev) => ({
                                ...prev,
                                message: e.target.value,
                              }))
                            }
                          ></textarea>
                          <input type='submit' value='Submit' />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='offer-slide'>
              <div className='swiper offer-slick'>
                <div className='swiper-wrapper'>
                  <div className='swiper-slide'>
                    {' '}
                    <img src='/assets/images/offer-slider.jpg' alt='alt' />
                    <div className='caption-offer-slide'>
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div className='swiper-slide'>
                    {' '}
                    <img src='/assets/images/offer-slider.jpg' alt='alt' />
                    <div className='caption-offer-slide'>
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div className='swiper-slide'>
                    {' '}
                    <img src='/assets/images/offer-slider.jpg' alt='alt' />
                    <div className='caption-offer-slide'>
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div className='swiper-slide'>
                    {' '}
                    <img src='/assets/images/offer-slider.jpg' alt='alt' />
                    <div className='caption-offer-slide'>
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                  <div className='swiper-slide'>
                    {' '}
                    <img src='/assets/images/offer-slider.jpg' alt='alt' />
                    <div className='caption-offer-slide'>
                      <h3>Headline</h3>
                      <h4>Sub headline</h4>
                    </div>
                  </div>
                </div>
                <div className='swiper-pagination'></div>
              </div>
            </div>
            <div className='app-download mt-lg-5'>
              <div className='container'>
                <div className='flex-app-download'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <img
                        src='/assets/images/app-download.png'
                        alt='app'
                        className='pic-app-download'
                      />
                    </div>
                    <div className='col-sm-8'>
                      <h2>
                        Available at{' '}
                        <small>
                          Lorem ipsum dolor sit amet consectetur adipiscing elit
                        </small>
                      </h2>
                      <div className='btn-app-download'>
                        {' '}
                        <a href='#'>
                          <img
                            src='/assets/images/icons/android.svg'
                            alt='android'
                          />
                        </a>{' '}
                        <a href='#'>
                          <img src='/assets/images/icons/ios.svg' alt='ios' />
                        </a>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingDetail;
