import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Common/Header';
import Footer from '../components/Common/Footer';

const CommunityLayout = () => {
  console.log('Community layout');
  return (
    <>
      {' '}
      <Header />
      <ToastContainer />
      <Outlet />
      <Footer />
    </>
  );
};

export default CommunityLayout;
