import React, { createContext, useState, useEffect } from 'react';

export const EmailContext = createContext();

export const EmailProvider = ({ children }) => {
  const [email, setEmail] = useState(localStorage.getItem('email'));
  
  useEffect(() => {
    if (email) {
      localStorage.setItem('email', email);
    }
  }, [email]);

  return (
    <EmailContext.Provider value={{ email, setEmail }}>
      {children}
    </EmailContext.Provider>
  );
};
