import React, { useEffect, useState } from 'react';
import MyReel from '../../components/Common/Community/MyReel';
import Author from '../../components/Common/Community/Author';
import RightSideNav from '../../components/Common/Community/SideNav';
import Groups from '../../components/Common/Community/Groups';
import Recomandation from '../../components/Common/Community/Recomandation';
import Trendings from '../../components/Common/Community/Trendings';
import BoostYourBusiness from '../../components/Common/Community/BoostYourBusiness';
import SuggestedGroups from '../../components/Common/Community/SuggestedGroups';
import ListYourCompany from '../../components/Common/Community/ListYourCompany';
import Categries from '../../components/Common/Community/Categries';
import useRequest from '../../hooks/useRequest';
import BtnPostSideBar from '../../components/Common/Community/BtnPostSideBar';

const News = () => {
    const [newsData, setNewsData] = useState([]);
    const { request: newsRequest, response: newsResponse } = useRequest();
    const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

    useEffect(() => {
        newsRequest('GET', '/news');
    }, []);

    useEffect(() => {
        if (newsResponse && newsResponse.status === 'SUCCESS') {
            setNewsData(newsResponse.data || []);
        }
    }, [newsResponse]);

    return (
        <div>
            <MyReel />
            <Author />

            <div className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3">
                            <RightSideNav />
                            <Groups />
                            <Recomandation />
                            <Trendings />
                        </div>
                        <div className="col-sm-6">
                            <div className="news-page">
                                {newsData && newsData.length > 0 ? (
                                    newsData.map((news, index) => (
                                        <div key={index} className="post-community">
                                            <div className="heading-post-cmty">
                                                <div className="date-post-cmty">
                                                    <a href="#">
                                                        <i className="fa-solid fa-ellipsis-vertical" />
                                                    </a>{' '}
                                                    {new Date(news.created_at).toLocaleDateString()} -{' '}
                                                    {new Date(news.created_at).toLocaleTimeString()}
                                                </div>
                                                <div className="name-post-cmty">
                                                    <h3>{news.headline}</h3>
                                                    <div className="author-post-cmty">
                                                        <img
                                                            src={`${cloudFrontUrl}${news.upload}`}
                                                            alt="news"
                                                            className="news-image"
                                                        />{' '}
                                                        {news.category.name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="desc-post-cmty">
                                                <p>{news.description}</p>
                                                <div className="imgeffect">
                                                    <a href="#">
                                                        <img
                                                            src={`${cloudFrontUrl}${news.upload}`}
                                                            alt="news"
                                                            className="news-thumbnail"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="social-post-cmty">
                                                <a href="#">
                                                    <img
                                                        src="/assets/images/icons/thumb.svg"
                                                        alt="thumb"
                                                    />
                                                    Like
                                                </a>
                                                <ul>
                                                    <li>
                                                        <a href="#">
                                                            <img
                                                                src="/assets/images/icons/comment.svg"
                                                                alt="Comment"
                                                            />
                                                            Comment
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img
                                                                src="/assets/images/icons/bookmark.svg"
                                                                alt="bookmark"
                                                                className="bookmark-icon"
                                                            />
                                                            Save
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img src="/assets/images/icons/share.svg" alt="Share" />
                                                            Share
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No news available</p>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <BtnPostSideBar/>
                            <BoostYourBusiness />
                            <SuggestedGroups />
                            <Categries />
                            <ListYourCompany />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default News;
