import React, { useState, useEffect, useRef } from 'react';
import {
  useParams,
  useNavigate,
  Link,
  useRoutes,
  useLocation,
} from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher';
import useRequest from '../../hooks/useRequest';
import { useSearch } from '../../contexts/SearchContext';
import { dashboardUrl } from '../../config';
import AiTools from '../../pages/ai-tools/AiTools';

const Header = () => {
  const { country, lang } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const isLogin = Boolean(localStorage.getItem('access_token'));

  const { slug, user_slug } = useParams();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const { selectedCategory, setSelectedCategory, searchValue, setSearchValue } =
    useSearch();

  const [categories, setCategories] = useState([]);
  const { request: categoryRequest, response: categoryResponse } = useRequest();

  const handleDashboardClick = () => {
    if (isLogin) {
      window.open(dashboardUrl, '_blank');
    }
  };

  const handleToggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  const handleOptionClick = (data) => {
    setSelectedCategory(data);

    setIsDropdownVisible(false);

    if (slug || user_slug || location.pathname === '/us/en/influencers') {
      navigate(`/${country}/${lang}/joblisting`);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    console.log(
      'MyFinder - handleSearchSubmit - selectedCategory:',
      selectedCategory
    );
    console.log('Search Value:', searchValue);

    if (searchValue) {
      if (selectedCategory.slug === 'jobs') {
        console.log('Navigating to job listing...');
        navigate(`/${country}/${lang}/joblisting`);
      } else {
        navigate(`/${country}/${lang}/search`, {
          state: {
            category: selectedCategory.slug === 'all' ? null : selectedCategory,
            search: searchValue,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (categoryResponse && categoryResponse.status === 'SUCCESS') {
      setCategories(categoryResponse.data);
    }
  }, [categoryResponse]);

  useEffect(() => {
    categoryRequest('GET', '/public/category');
    document.addEventListener('click', handleClickOutside, true);

    // Scroll handling for sticky header
    const handleScroll = () => {
      const header = document.querySelector('header');
      if (header) {
        if (window.scrollY > 100) {
          header.classList.add('sticky');
        } else {
          header.classList.remove('sticky');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleModal = () => {
    setShowModal(true);
  };

  return (
    <header>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='flex-wraper'>
              <div className='logo'>
                <Link to={`/${country}/${lang}`}>
                  <img src='/assets/images/logo.svg' alt='MyFinder' />
                </Link>
              </div>
              <div className='search-header'>
                <div className='search'>
                  <div className='border-none all-search' ref={dropdownRef}>
                    <a
                      className='btn-all-search capitalize'
                      onClick={handleToggleDropdown}
                    >
                      {selectedCategory?.name}
                      <i
                        className={`fa-solid fa-caret-down arrow-all-dropdown ${
                          isDropdownVisible ? 'arrow-all-dropdown-hide' : ''
                        }`}
                      ></i>
                      <i
                        className={`fa-solid fa-xmark close-all-dropdown ${
                          isDropdownVisible ? 'close-all-dropdown-show' : ''
                        }`}
                      ></i>
                    </a>
                    <ul
                      className='all-dropdown mt-2'
                      style={{ display: isDropdownVisible ? 'block' : 'none' }}
                    >
                      <li>
                        <a
                          href='javascript:void(0);'
                          onClick={() =>
                            handleOptionClick({ name: 'All', slug: 'all' })
                          }
                        >
                          All
                        </a>
                      </li>
                      {categories.map((data, index) => (
                        <li key={index}>
                          <a
                            href='javascript:void(0);'
                            onClick={() => handleOptionClick(data)}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {/* <img
                              src={`/assets/images/icons/${data.slug}.svg`}
                              alt={data.slug}
                              draggable="false"
                            /> */}
                            {data.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <form onSubmit={handleSearchSubmit}>
                    <div className='flex-search'>
                      <input
                        type='text'
                        name='search'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder='Search here...'
                      />
                      <div className='btn-search'>
                        <a href='#'>
                          <img
                            src='/assets/images/icons/voice.svg'
                            alt='voice'
                            onDragStart={(e) => e.preventDefault()}
                          />
                        </a>
                        <button type='submit'>
                          <img
                            src='/assets/images/icons/submit-arrow.svg'
                            alt='submit'
                          />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className='ai-search'>
                  <button>
                    <img src='/assets/images/ai/fredo.png' alt='ai' />
                  </button>
                </div>
              </div>
              <div className='menubar'>
                <ul>
                  <li>
                    <button
                      onClick={handleModal}
                      className='link-menubar ai-menubar'
                    >
                      AI Tools
                    </button>
                  </li>
                  <li className='signin-menubar'>
                    {isLogin ? (
                      <a
                        href='#'
                        className='link-menubar'
                        onClick={handleDashboardClick}
                      >
                        Dashboard
                      </a>
                    ) : (
                      <>
                        <Link
                          to={`/${country}/${lang}/auth/login`}
                          className='active link-menubar'
                        >
                          Sign In
                        </Link>
                        <Link
                          to={`/${country}/${lang}/auth/signup`}
                          className='link-menubar'
                        >
                          Sign Up
                        </Link>
                      </>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && <AiTools setShowModal={setShowModal} />}
    </header>
  );
};

export default Header;
