import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useRequest from "../../../hooks/useRequest";
import { Link } from "react-router-dom";

const MyReel = () => {
  const [reelData, setReelData] = useState([]);
  const { request: ReelRequest, response: ReelResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    ReelRequest("GET", "/community/my-reel");
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (ReelResponse && ReelResponse.status === "SUCCESS") {
      setReelData(ReelResponse.data || {});
    }
  }, [ReelResponse]);

  const options = {
    items: 9,
    loop: true,
    autoplay: true,
    AnimationTimeline: 2000,
    animateOut: "slideOutUp",
    nav: true,
    dots: false,
    margin: 10,
    responsive: {
      1100: {
        items: 9,
      },
      724: {
        items: 7,
      },
      500: {
        items: 5,
      },
      370: {
        items: 3,
      },
    },
  };

  return (
    <>
      <div className="category-slide">
        <OwlCarousel className="owl-theme" {...options}>
          {reelData.length > 0 ? (
            reelData.map((reel, index) =>
              reel.customer && reel.customer.user_profile ? (
                <div key={index} className="swiper-slide">
                  <div className="item-category-slide">
                    <img
                      src={`${cloudFrontUrl}${
                        reel.image || "default-image.jpg"
                      }`}
                      alt="Reel image"
                    />
                    <div className="caption-category-slide">
                      <img
                        src={`${cloudFrontUrl}${
                          reel.customer.user_profile.logo || "default-logo.jpg"
                        }`}
                        alt="Customer logo"
                      />
                      <h3>
                        <Link to="/">{reel.reel_type}</Link>{" "}
                        <small>
                          {reel.customer.user_profile.description
                            ? reel.customer.user_profile.description
                                .split(" ")
                                .slice(0, 10)
                                .join(" ") +
                              (reel.customer.user_profile.description.split(" ")
                                .length > 10
                                ? "..."
                                : "")
                            : "No description available"}
                        </small>
                      </h3>
                    </div>
                  </div>
                </div>
              ) : null
            )
          ) : (
            <p></p>
          )}
        </OwlCarousel>
      </div>
    </>
  );
};

export default MyReel;
