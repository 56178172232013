import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import "./About.css";
import { cloudFrontUrl } from "../../config";

const About = () => {
  const [aboutUsList, setAboutUsList] = useState([]);

  const { request: fetchAboutUsRequest, response: aboutUsResponse } =
    useRequest();

  useEffect(() => {
    fetchAboutUsRequest("GET", "/public/cms-about-us");
  }, []);

  useEffect(() => {
    if (aboutUsResponse && aboutUsResponse.status === "SUCCESS") {
      setAboutUsList(aboutUsResponse.data || []);
    }
  }, [aboutUsResponse]);

  return (
    <div className="about-page main-container bg-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            {aboutUsList.map((item, index) => (
              <div key={index} className="content-about">
                <div className="row">
                  <div className="col-sm-6">
                    <img
                      src={
                        cloudFrontUrl + item.image || "/assets/images/about.jpg"
                      }
                      alt="about"
                    />
                    {console.log(`/assets/images/${item.image}`)}
                  </div>
                  <div className="col-sm-6">
                    <h2>{item.headline}</h2>
                    <p>{item.content}</p>
                  </div>
                </div>
              </div>
            ))}
            {/* <div className="content-about">
                            <div className="row">
                                <div className="col-sm-6"><img src="/assets/images/about.jpg" alt="about" /></div>
                                <div className="col-sm-6">
                                    <h2>About Us</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi hendrerit bibendum luctus. Quisque
                                        ultrices sapien lectus, id volutpat velit imperdiet et. Integer id imperdiet mi. Aliquam feugiat ipsum
                                        gravida, imperdiet arcu elementum, ultrices diam. Mauris placerat, mauris ut ornare auctor, dolor
                                        felis placerat tortor, quis mollis urna tellus sit amet dui. Cras justo turpis, tristique id venenatis
                                        sit amet, tincidunt ac elit. Vivamus eu condimentum dolor.</p>
                                </div>
                            </div>
                        </div>
                        <div className="content-about">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2>Mission</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi hendrerit bibendum luctus. Quisque
                                        ultrices sapien lectus, id volutpat velit imperdiet et. Integer id imperdiet mi. Aliquam feugiat ipsum
                                        gravida, imperdiet arcu elementum, ultrices diam. Mauris placerat, mauris ut ornare auctor, dolor
                                        felis placerat tortor, quis mollis urna tellus sit amet dui. Cras justo turpis, tristique id venenatis
                                        sit amet, tincidunt ac elit. Vivamus eu condimentum dolor.</p>
                                </div>
                                <div className="col-sm-6"><img src="/assets/images/about.jpg" alt="about" /></div>
                            </div>
                        </div>
                        <div className="content-about">
                            <div className="row">
                                <div className="col-sm-6"><img src="/assets/images/about.jpg" alt="about" /></div>
                                <div className="col-sm-6">
                                    <h2>Vission</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi hendrerit bibendum luctus. Quisque
                                        ultrices sapien lectus, id volutpat velit imperdiet et. Integer id imperdiet mi. Aliquam feugiat ipsum
                                        gravida, imperdiet arcu elementum, ultrices diam. Mauris placerat, mauris ut ornare auctor, dolor
                                        felis placerat tortor, quis mollis urna tellus sit amet dui. Cras justo turpis, tristique id venenatis
                                        sit amet, tincidunt ac elit. Vivamus eu condimentum dolor.</p>
                                </div>
                            </div>
                        </div>
                        <div className="content-about">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2>Values</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi hendrerit bibendum luctus. Quisque
                                        ultrices sapien lectus, id volutpat velit imperdiet et. Integer id imperdiet mi. Aliquam feugiat ipsum
                                        gravida, imperdiet arcu elementum, ultrices diam. Mauris placerat, mauris ut ornare auctor, dolor
                                        felis placerat tortor, quis mollis urna tellus sit amet dui. Cras justo turpis, tristique id venenatis
                                        sit amet, tincidunt ac elit. Vivamus eu condimentum dolor.</p>
                                </div>
                                <div className="col-sm-6"><img src="/assets/images/about.jpg" alt="about" /></div>
                            </div>
                        </div> */}
            <div className="news-updates">
              <div className="heading">
                <h2>News & Updates</h2>
                <a href="#" className="btn-heading">
                  View All<i className="fa-solid fa-chevron-right"></i>
                </a>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="swiper news-ml-slick">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/listing/1.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget.Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/listing/1.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget.Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/listing/1.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget.Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="itembox ib-news">
                          <div className="imgeffect">
                            <div className="ib-date">5 Days ago</div>
                            <div className="ib-wishlist">
                              <a href="#">
                                <i className="fa-regular fa-heart"></i>
                              </a>
                            </div>
                            <a href="#">
                              <img src="/assets/images/listing/1.jpg" alt="alt" />
                            </a>
                          </div>
                          <div className="info-itembox">
                            <h3>
                              <a href="#">Headline</a>
                            </h3>
                            <p>
                              Lorem ipsum dolor sit afsmbet, consectetur adipiscing
                              elit ru trum blandit justo, id rutruvl 9m diam eleme
                              ntumv eget.Aliquam lacinia consectetur.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="app-download">
              <div className="row">
                <div className="col-sm-4">
                  <img
                    src="/assets/images/app-download.png"
                    alt="app"
                    className="pic-app-download"
                  />
                </div>
                <div className="col-sm-8">
                  <h2>
                    Available at{" "}
                    <small>
                      Lorem ipsum dolor sit amet consectetur adipiscing elit
                    </small>
                  </h2>
                  <div className="btn-app-download">
                    {" "}
                    <a href="#">
                      <img
                        src="/assets/images/icons/android.svg"
                        alt="android"
                      />
                    </a>{" "}
                    <a href="#">
                      <img src="/assets/images/icons/ios.svg" alt="ios" />
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
