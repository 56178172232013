import { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { cloudFrontUrl } from "../../config";

const LandingBanner = () => {
  const { request, response } = useRequest();
  const [banner, setBanner] = useState();
  const fetchLandingBanner = async () => {
    await request("GET", "/public/attraction-landing-banner");
  };
  useEffect(() => {
    fetchLandingBanner();
  }, []);

  useEffect(() => {
    if (response) {
      setBanner(response.data);
    }
  }, [response]);

  return (
    <>
      {banner &&
        banner.map((item, index) => (
          <div key={index} className="guide-attraction-home">
            <div className="imgeffect">
              <img src={`${cloudFrontUrl}${item.image}`} alt={item.image} />
              <div className="caption-guide-attraction-home">
                <h3>{item.headline}</h3>
                <p>{item.description}</p>
                <a href={item.button_url} className="btn btn-primary">
                  Read More
                </a>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
export default LandingBanner;
