import React, { useState, useEffect } from 'react';
import useRequest from '../hooks/useRequest';

const SocialMediaIcons = () => {
    const [socialMediaList, setSocialMediaList] = useState([]);

    const { request: fetchSocialMediaRequest, response: socialMediaResponse } = useRequest();

    useEffect(() => {
        fetchSocialMediaRequest('GET', '/public/social-media');
    }, []);

    useEffect(() => {
        if (socialMediaResponse && socialMediaResponse.status === "SUCCESS") {
            setSocialMediaList(socialMediaResponse.data || []);
        }
    }, [socialMediaResponse]);

    const defaultIcons = [
        { type: 'linkedin', url: 'https://www.linkedin.com/company/104906562/admin/dashboard' },
        { type: 'youtube', url: 'https://www.youtube.com/@MyFinderAI' },
        { type: 'x', url: 'https://x.com/myfinderai' },
        { type: 'facebook', url: 'https://www.facebook.com/profile.php?id=61564768379862' },
        { type: 'instagram', url: 'https://www.instagram.com/myfinderai/' },
        { type: 'telegram', url: '#' },
        { type: 'pinterest', url: 'https://www.pinterest.com/myfinderai' },
    ];

    const getIconClass = (type) => {
        switch (type) {
            case 'facebook':
                return 'fa-brands fa-facebook-f';
            case 'instagram':
                return 'fa-brands fa-instagram';
            case 'x':
                return 'fa-brands fa-x-twitter';
            case 'linkedin':
                return 'fa-brands fa-linkedin-in';
            case 'youtube':
                return 'fa-brands fa-youtube';
            case 'telegram':
                return 'fa-brands fa-telegram';
            case 'pinterest':
                return 'fa-brands fa-pinterest-p';
            default:
                return "";
        }
    };

    const mergedIcons = defaultIcons.map((defaultIcon) => {
        const matchingIcon = socialMediaList.find(
            (item) => item.social_media_type === defaultIcon.type && item.is_active
        );
        return {
            ...defaultIcon,
            url: matchingIcon ? matchingIcon.social_media_url : defaultIcon.url,
            isActive: !!matchingIcon,
        };
    });

    return (
        <div className="smoicons">
            <ul>
                {mergedIcons.map((icon, index) => (
                    <li key={index}>
                        <a
                            href={icon.isActive ? icon.url : '#'}
                            target={icon.isActive ? "_blank" : "_self"}
                            rel="noopener noreferrer"
                            title={icon.type}
                        >
                            <i className={getIconClass(icon.type)}></i>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SocialMediaIcons;