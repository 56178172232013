import { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { cloudFrontUrl } from "../../../config";

const AttractionRegister = () => {
  const [attractionRegister, setAttractionRegister] = useState();
  const { request, response } = useRequest();
  const fetchAttractionRegister = async () => {
    await request("GET", "/public/attraction-register");
  };
  useEffect(() => {
    fetchAttractionRegister();
  }, []);

  useEffect(() => {
    if (response) {
      setAttractionRegister(response.data);
    }
  }, [response]);

  return (
    <>
      {attractionRegister &&
        attractionRegister.map((item, index) => (
          <div key={index} class="register-ml">
            <img src={`${cloudFrontUrl}${item.image}`} alt={item.image} />
            <div class="caption-register-ml">
              <h3>{item.headline}</h3>
              <p>{item.description}</p>
              <a href={item.button_url} class="btn-sidebar-ml">
                Register Now
                <i class="fa-solid fa-arrow-right-long"></i>
              </a>
            </div>
          </div>
        ))}
    </>
  );
};
export default AttractionRegister;
