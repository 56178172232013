import React from 'react';
import './Style.css'

const ImagesTab = ({ images, cloudFrontUrl }) => {
    const groupedImages = [];

    for (let i = 0; i < images.length; i += 2) {
        groupedImages.push(images.slice(i, i + 2));
    }

    return (
        <div id="images-tabs-ms" className="" style={{ paddingTop: '2rem' }}>
            <div className="gallery-ms">
                {groupedImages.map((pair, index) => (
                    <div className="image-row" key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {pair.map((image, imgIndex) => (
                            <div key={imgIndex} style={{ flex: '0 0 48%' }}>
                                <a
                                    className="fancybox"
                                    href={cloudFrontUrl + image.image}
                                    data-fancybox-group="gallery"
                                >
                                    <img
                                        src={cloudFrontUrl + image.image}
                                        alt="Gallery"
                                        style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImagesTab;
