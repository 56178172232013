import { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { cloudFrontUrl } from "../../config";

const Covered = () => {
  const [covered, setCovered] = useState();
  const { request: coveredReq, response: coveredRes } = useRequest();
  const fetchCovered = async () => {
    await coveredReq("GET", "/public/attraction-covered");
  };
  useEffect(() => {
    fetchCovered();
  }, []);

  useEffect(() => {
    if (coveredRes) {
      setCovered(coveredRes.data);
    }
  }, [coveredRes]);

  return (
    <div className="covered-attraction">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="heading-attraction-home">
              <h2 className="mb-3">We've got you covered</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {covered &&
            covered.map((item, index) => (
              <div key={index} className="col-sm-4">
                <div className="item-covered-attraction">
                  <img src={`${cloudFrontUrl}${item.image}`} alt="alt" />
                  <h3>{item.headline}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default Covered;
