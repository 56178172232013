import React, { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";

const Recomandation = () => {
  const [recommendationsData, setRecommendationsData] = useState([]);
  const { request: RecommendationsRequest, response: recommendationsResponse } =
    useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
  useEffect(() => {
    RecommendationsRequest("GET", "/community/recommendations");
  }, []);
  useEffect(() => {
    if (
      recommendationsResponse &&
      recommendationsResponse.status === "SUCCESS"
    ) {
      setRecommendationsData(recommendationsResponse.data || {});
    }
  }, [recommendationsResponse]);
  return (
    <>
      <div className="recom-community box-community">
        <h2>Recommendation</h2>
        <ul className="online-cmty">
          {recommendationsData.length > 0 ? (
            recommendationsData.map((recommendations, index) => (
              <li key={index}>
                <div>
                  <img
                    src="/assets/images/author/4.jpg"
                    alt={`${recommendations.username}`}
                  />
                  <h4>
                    {recommendations.name} <i className="fa-solid fa-check" />{" "}
                    <small>{recommendations.email}</small>
                  </h4>
                </div>
                <a href="#" className="btn btn-primary">
                  Follow
                </a>
              </li>
            ))
          ) : (
            <p>No Recommendations available</p>
          )}
        </ul>
      </div>
    </>
  );
};

export default Recomandation;
