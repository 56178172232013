import React from "react";

const categories = [
  { name: "Property", count: 243 },
  { name: "Crypto coin", count: 73 },
  { name: "Motors", count: 243 },
  { name: "Jobs", count: 130 },
  { name: "Hotels", count: 26 },
  { name: "Talents", count: 50 },
  { name: "Buy & Sell", count: 20 },
  { name: "MORE", count: null },
];

function Categories() {
  return (
    <div className="categories-container">
      {categories.map((category, index) => (
        <div key={index} className="category">
          {category.name} {category.count && <span className="count">({category.count})</span>}
        </div>
      ))}
    </div>
  );
}

export default Categories;
