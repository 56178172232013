import React, { useState } from 'react';

const VideosTab = ({ videoData, cloudFrontUrl }) => {
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);

    const handleVideoClick = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
    };

    const closeVideo = () => {
        setSelectedVideoUrl(null);
    };

    return (
        <div id="videos-tabs-ms" style={{ paddingTop: '2rem' }}>
            {videoData && videoData.length > 0 ? (
                videoData.map((video) => (
                    <div key={video.id} style={{ marginBottom: '1.5rem' }}>
                        <button
                            style={{ position: 'relative', display: 'block' }}
                            onClick={() => handleVideoClick(video.video_url)}
                        >
                            <img
                                src={video.image || "/assets/images/video-tutorial.jpg"}
                                alt="video"
                                style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                            />
                            <i className="fa-solid fa-play"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#fff',
                                    fontSize: '2rem'
                                }}></i>
                        </button>
                        <div className="flex-videos-ms" style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                            <img
                                src={video.image || "/assets/images/logo-1.png"}
                                alt="logo"
                                style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%' }}
                            />
                            <div className="non" style={{ marginLeft: '1rem' }}>
                                <h3>{video.title || 'Headline'}</h3>
                                <p>{video.description || 'Lorem ipsum dolor'}</p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>No videos available.</p>
            )}

            {selectedVideoUrl && (
                <div className="video-modal" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                    <div style={{ position: 'relative', width: '80%', height: '80%' }}>
                        <button onClick={closeVideo} style={{ position: 'absolute', top: '10px', right: '10px', background: 'red', color: '#fff', border: 'none', borderRadius: '5px', padding: '5px 10px' }}>
                            Close
                        </button>
                        <iframe
                            width="100%"
                            height="100%"
                            src={selectedVideoUrl}
                            title="Video"
                            frameBorder="0"
                            allowFullScreen
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideosTab;