import React, { useEffect, useState } from 'react';
import useRequest from '../../hooks/useRequest';

const Help = () => {
    const [faqs, setFaqs] = useState([]);
    const [categoriesData, setCategories] = useState([]);
    const [helpCenter, setHelpCenter] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [visibleFaqs, setVisibleFaqs] = useState(4);
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [expanded, setExpanded] = useState(false);

    const { request: categoriesRequest, response: categoriesResponse } = useRequest();
    const { request: helpCenterRequest, response: helpCenterResponse } = useRequest();
    const { request: subCategoriesRequest, response: subCategoriesResponse } = useRequest();

    useEffect(() => {
        categoriesRequest('GET', '/public/category'); // Fetch categories
    }, []);

    useEffect(() => {
        if (categoriesResponse && categoriesResponse.status === "SUCCESS") {
            setCategories(categoriesResponse.data || []);
        }
    }, [categoriesResponse]);

    useEffect(() => {
        if (selectedCategory) {
            subCategoriesRequest(
                'GET',
                `/public/sub-category/${selectedCategory}`
            );
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (subCategoriesResponse && subCategoriesResponse.status === "SUCCESS") {
            setSubCategories(subCategoriesResponse.data || []);
        }
    }, [subCategoriesResponse]);

    useEffect(() => {
        helpCenterRequest('GET', '/public/cms-help-center');
    }, []);

    useEffect(() => {
        if (helpCenterResponse && helpCenterResponse.status === "SUCCESS") {
            setHelpCenter(helpCenterResponse.help_center_data);
            setFaqs(helpCenterResponse.faqs);
            setShowLoadMore(helpCenterResponse.faqs.length > 4);
        }
    }, [helpCenterResponse]);

    // Handle category change
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    // Filter subcategories based on selected category
    const filteredSubCategories = subCategories;

    const handleLoadMore = () => {
        setVisibleFaqs(prevVisibleFaqs => prevVisibleFaqs + 4);
        if (faqs.length <= visibleFaqs + 4) {
            setShowLoadMore(false);
        }
        setExpanded(true);
    };

    const handleShowLess = () => {
        setVisibleFaqs(4);
        setShowLoadMore(faqs.length > 4);
        setExpanded(false);
    };

    return (
        <div className="help-page main-container bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="contact-help">
                            <h1 className="text-2xl mb-3">Help Center</h1>
                            <ul>
                                <li><a href={`mailto:${helpCenter?.email}`}><i className="fa-solid fa-envelope"></i><span><small>Email
                                    Support</small>{helpCenter?.email || 'support@myfidner.ai'}</span></a></li>
                                <li><a href={`https://api.whatsapp.com/send?phone=${helpCenter?.whatsapp_number}`} target="_blank"><i
                                    className="fa-brands fa-whatsapp"></i><span><small>WhatsApp Support</small>{helpCenter?.whatsapp_number || '+971 55 1234567'}</span></a>
                                </li>
                                <li><a href="#"><i className="fa-solid fa-user-group"></i><span><small>Help us make your experience
                                    better.</small>Leave Feedback</span></a></li>
                                <li><a href="#"><i className="fa-regular fa-rectangle-list"></i><span><small>Fill a Form</small>Get Quick
                                    Solution</span></a></li>
                            </ul>
                        </div>
                        <div className="faq-help">
                            <div className="flex-wraper">
                                <h1 className="text-2xl mb-3 pt-3">FAQs</h1>
                                <div className="sort-filter">
                                    <div className="field-sort-filter">
                                        <select onChange={handleCategoryChange} value={selectedCategory}>
                                            <option value="">Category</option>
                                            {categoriesData.map(category => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="field-sort-filter">
                                        <select>
                                            <option value="">Subcategory</option>
                                            {filteredSubCategories.map(subCat => (
                                                <option key={subCat.id} value={subCat.id}>{subCat.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="field-sort-filter">
                                        <select>
                                            <option selected="selected">Sort by</option>
                                            <option value="New to Old">New to Old</option>
                                            <option value="Old to New">Old to New</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion" id="accordion-faq">
                                {faqs.slice(0, visibleFaqs).map(faq => (
                                    <div key={faq.id} className="accordion-item">
                                        <button data-bs-target={`#collapse-${faq.id}`} className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" aria-expanded="true">
                                            {faq.question}
                                        </button>
                                        <div id={`collapse-${faq.id}`} className="accordion-collapse collapse" data-bs-parent="#accordion-faq">
                                            <div className="accordion-body">
                                                <p>{faq.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {showLoadMore && !expanded && (
                                    <div className="btn-load">
                                        <button onClick={handleLoadMore}>Load More</button>
                                    </div>
                                )}
                                {expanded && (
                                    <div className="btn-load">
                                        <button onClick={handleShowLess}>Show Less</button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="news-updates">
                            <div className="heading">
                                <h2>News & Updates</h2>
                                <a href="#" className="btn-heading">View All<i className="fa-solid fa-chevron-right"></i></a>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="swiper news-ml-slick">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="itembox ib-news">
                                                    <div className="imgeffect">
                                                        <div className="ib-date">5 Days ago</div>
                                                        <div className="ib-wishlist"><a href="#"><i className="fa-regular fa-heart"></i></a></div>
                                                        <a href="#"><img src="/assets/images/listing/1.jpg" alt="alt" /></a>
                                                    </div>
                                                    <div className="info-itembox">
                                                        <h3><a href="#">Headline</a></h3>
                                                        <p>Lorem ipsum dolor sit afsmbet, consectetur adipiscing elit ru trum blandit justo, id rutruvl 9m
                                                            diam eleme ntumv eget.Aliquam lacinia consectetur.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Additional swiper slides here */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="app-download">
                            <div className="row">
                                <div className="col-sm-4"><img src="/assets/images/app-download.png" alt="app" className="pic-app-download" /></div>
                                <div className="col-sm-8">
                                    <h2>Available at <small>Lorem ipsum dolor sit amet consectetur adipiscing elit</small></h2>
                                    <div className="btn-app-download"> <a href="#"><img src="/assets/images/icons/android.svg" alt="android" /></a> <a
                                        href="#"><img src="/assets/images/icons/ios.svg" alt="ios" /></a> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Help;
