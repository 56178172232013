import React, { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";

const Categries = () => {
  const [categoryData, setCategoryData] = useState([]);
  const { request: CategoryRequest, response: categoryResponse } = useRequest();
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
  useEffect(() => {
    CategoryRequest("GET", "/public/category");
  }, []);
  useEffect(() => {
    if (categoryResponse && categoryResponse.status === "SUCCESS") {
      setCategoryData(categoryResponse.data || {});
    }
  }, [categoryResponse]);
  return (
    <>
      <div className="category-community box-community">
        <h2>Categories</h2>

        <ul>
          {categoryData.length > 0 ? (
            categoryData.map((category, index) => (
              <li key={index}>
                <a href="#">
                  <img
                    src={`/assets/images/icons/${category.slug}.svg`}
                    alt={`${category.name}`}
                  />
                  {category.name}
                </a>
              </li>
            ))
          ) : (
            <p>No Categories available</p>
          )}
        </ul>
      </div>
    </>
  );
};

export default Categries;
