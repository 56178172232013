import React, { useEffect, useState } from 'react'
import MyReel from '../../../components/Common/Community/MyReel'
import Author from '../../../components/Common/Community/Author'
import RightSideNav from '../../../components/Common/Community/SideNav'
import Groups from '../../../components/Common/Community/Groups'
import Recomandation from '../../../components/Common/Community/Recomandation'
import Trendings from '../../../components/Common/Community/Trendings'
import { useParams } from 'react-router-dom'
import useRequest from '../../../hooks/useRequest'

const EventDetails = () => {
    const { slug } = useParams();
    const [eventDetails, setEventDetails] = useState(null);
    const { request: detailsRequest, response: detailsResponse, error } = useRequest();
    const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

    useEffect(() => {
        detailsRequest("GET", `/events/${slug}`);
    }, [slug]);

    useEffect(() => {
        if (detailsResponse && detailsResponse.status === "SUCCESS") {
            setEventDetails(detailsResponse.data || {});
        }
    }, [detailsResponse]);

    // Handle loading and error states
    if (!eventDetails) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading Event details.</div>;
    }

    // Extract event data
    const {
        cover_image,
        name,
        description,
        start_date_time,
        end_date_time,
        timezone,
        user: { name: hostName, user_profile: { logo } = {} } = {},
    } = eventDetails;

    return (
        <div>
            <MyReel />
            <Author />
            <div className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3">
                            <RightSideNav />
                            <Groups />
                            <Recomandation />
                            <Trendings />
                        </div>
                        <div className="col-sm-9">
                            <div className="event-detail-page">
                                <div className="banner-event-detail">
                                    <img src={cover_image ? `${cloudFrontUrl}${cover_image}` : "/assets/images/event/gallery.jpg"} alt="Event cover" />
                                </div>
                                <div className="about-event">
                                    <div className="flex-wraper">
                                        <div className="non">
                                            <h2>{name}</h2>
                                            {/* TODO */}
                                            <p>Still This is not Come from Api </p>
                                            <div className="profile-about-event">
                                                <img src={logo ? `${cloudFrontUrl}${logo}` : "/assets/images/author/7.jpg"} alt="Host" />
                                                <h3>
                                                    {hostName} <small>Host</small>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="non">
                                            <div className="btn-about-event">
                                                <a href="#" className="btn btn-primary">
                                                    <i className="fa-solid fa-microphone-lines" /> Audio Event
                                                </a>
                                                <a href="#" className="btn btn-primary">
                                                    <i className="fa-solid fa-calendar" /> {new Date(start_date_time).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: 'short',
                                                        year: 'numeric',
                                                    })}{" "}
                                                    {new Date(start_date_time).toLocaleTimeString('en-GB', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true,
                                                    }).replace('am', 'AM').replace('pm', 'PM')}
                                                </a>
                                                <a href="#" className="btn btn-primary">
                                                    <i className="fa-solid fa-calendar" /> {new Date(start_date_time).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: 'short',
                                                        year: 'numeric',
                                                    })}{" "}
                                                    {new Date(end_date_time).toLocaleTimeString('en-GB', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true,
                                                    }).replace('am', 'AM').replace('pm', 'PM')}
                                                </a>
                                            </div>
                                            <div className="venue-about-event">
                                                {timezone ? timezone : "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"}
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        {description ? description : "No description available"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventDetails;
