import React from 'react'

const CreateAPost = () => {
  return (
    <>
      <div className="btn-post-sidebar">
        <a href="#">
          <i className="fa-solid fa-plus" /> Create a Post
        </a>
      </div>
    </>
  )
}

export default CreateAPost
