import React from 'react';
import { Circles } from 'react-loader-spinner';
import './Loader.css'; // Import the CSS file for styling

const FullPageLoader = () => {
    return (
        <div className="loader-container">
            <div className="loader-overlay">
                <Circles
                    color="#00BFFF"
                    height={100}
                    width={100}
                />
            </div>
        </div>
    );
};

export default FullPageLoader;
